import { Typography, Box, Button } from '@mui/material';
import { useDeleteProfile } from '../api';

type props = {
  id: string;
  close: () => void;
};

export function DeleteProfile({ id, close }: props) {
  const { mutateAsync, isLoading } = useDeleteProfile();

  const handleDelete = () => {
    mutateAsync(id);
    close();
  };

  return (
    <>
      <Box>
        <Typography sx={{ textAlign: 'center' }}>
          Tem certeza que deseja deletar?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={isLoading}
            onClick={() => handleDelete()}
            sx={{ margin: '10px' }}
            variant="contained"
            color="error"
          >
            Sim
          </Button>
          <Button
            disabled={isLoading}
            onClick={close}
            sx={{ margin: '10px' }}
            variant="contained"
            color="success"
          >
            Não
          </Button>
        </Box>
      </Box>
    </>
  );
}
