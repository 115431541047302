import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CreateVehicle } from './CreateVehicle';
import { ListVehicles } from './ListVehicles';
import { UpdateVehicle } from './UpdateVehicle';

export const VehiclesRoutes = () => {
  return (
    <>
      <Can restriction="veículos" permissions={['renter']}>
        <Routes>
          <Route path="/" element={<ListVehicles />} />
          <Route path="/create" element={<CreateVehicle />} />
          <Route path="/update/:id" element={<UpdateVehicle />} />
        </Routes>
      </Can>
    </>
  );
};
