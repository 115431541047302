/* eslint-disable no-param-reassign */
import { useState } from 'react';
import * as z from 'zod';
import {
  Box,
  Button,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '@/utils/maskShared';

import { Form, InputField } from '@/components/Form';
import { useCreateCompany } from '@/features/companies/api';
import { makeStyles } from '@mui/styles';
import { CreateCompanyDTO } from '../types';

const schema = z.object({
  companyName: z.string({ required_error: 'Campo obrigatório' }),
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .email('Email inválido'),
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),

  optionalName: z.string({ required_error: 'Campo obrigatório' }),
  industry: z.string({ required_error: 'Campo obrigatório' }),
  cnpj: z
    .string({ required_error: 'Campo obrigatório' })
    .max(18, 'Máximo 18 caracteres')
    .min(18, 'Minimo 18 caracteres'),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  personToContact: z.string({ required_error: 'Campo obrigatório' }),
  zipCode: z
    .string({ required_error: 'Campo obrigatório' })
    .max(9, 'Máximo 9 caracteres'),
  address: z.string({ required_error: 'Campo obrigatório' }),
  city: z.string({ required_error: 'Campo obrigatório' }),
  neighborhood: z.string({ required_error: 'Campo obrigatório' }),
  priceKm: z.string({ required_error: 'Campo obrigatório' }),
  priceStart: z.string({ required_error: 'Campo obrigatório' }),
  payment: z.number(),
  multiply: z.number(),
  userEmail: z.string({ required_error: 'Campo obrigatório' }),
  userName: z.string({ required_error: 'Campo obrigatório' }),
  userPhone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  cpf: z
    .string({ required_error: 'Campo obrigatório' })
    .min(14, 'Deve ter pelo menos 14 caracteres'),
});

export function CreateCompany() {
  const { isLoading, mutateAsync } = useCreateCompany();
  const [checked, setChecked] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const paymentOptions = [
    {
      value: '',
      label: 'Selecione...',
    },
    {
      value: 1,
      label: 'Padrão',
    },
    {
      value: 2,
      label: 'Tabelado',
    },
  ];

  const multiplyOptions = [
    {
      value: '',
      label: 'Selecione...',
    },
    {
      value: 2,
      label: 'Sim',
    },
    {
      value: 0,
      label: 'Não',
    },
  ];

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const handlePriceStart = (value: any) => {
    return (
      value
        .replace(/[A-Za-z]/, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '.')
    );
  };

  return (
    <Box
      sx={{ background: theme.palette.mode === 'dark' ? '#212121' : '#FFF' }}
    >
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <Box>
          <Form<CreateCompanyDTO, typeof schema>
            id="create-company"
            onSubmit={values => {
              const statusSelected = checked ? 1 : 0;
              const data = { ...values, status: statusSelected };

              mutateAsync(data);
            }}
            schema={schema}
          >
            {({ register, formState }) => (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'red',
                    fontSize: '22px',
                  }}
                >
                  Cadastro da empresa
                </Typography>
                <Grid container>
                  <Grid sm={6} xs={12} item>
                    <InputField
                      size="small"
                      name="email"
                      type="email"
                      label="Email"
                      registration={register('email')}
                      error={!!formState.errors.email}
                      errorMessage={formState.errors.email?.message}
                      placeholder="Email da empresa"
                    />

                    <InputField
                      size="small"
                      name="companyName"
                      type="text"
                      label="Razão Social"
                      registration={register('companyName')}
                      error={!!formState.errors.companyName?.message}
                      errorMessage={formState.errors.companyName?.message}
                      placeholder="Nome real da empresa"
                    />

                    <InputField
                      size="small"
                      name="optionalName"
                      type="text"
                      label="Nome Fantasia"
                      registration={register('optionalName')}
                      placeholder="Nome da empresa"
                      error={!!formState.errors.optionalName}
                      errorMessage={formState.errors.optionalName?.message}
                    />
                    <InputField
                      size="small"
                      name="industry"
                      type="text"
                      label="Ramo"
                      placeholder="Ramo de atuação da empresa"
                      registration={register('industry')}
                      error={!!formState.errors.industry}
                      errorMessage={formState.errors.industry?.message}
                    />
                    <InputField
                      size="small"
                      name="cnpj"
                      type="text"
                      label="CNPJ"
                      placeholder="99.999.999/9999-99"
                      registration={register('cnpj')}
                      error={!!formState.errors.cnpj}
                      errorMessage={formState.errors.cnpj?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = cnpjMask(value);
                      }}
                    />

                    <InputField
                      size="small"
                      name="phone"
                      type="text"
                      label="Telefone"
                      placeholder="(99) 99999-9999"
                      registration={register('phone')}
                      error={!!formState.errors.phone}
                      errorMessage={formState.errors.phone?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = phoneMask(
                          value.replace(/[^\d]+/g, ''),
                        );
                      }}
                    />
                    <InputField
                      size="small"
                      name="personToContact"
                      type="text"
                      label="Pessoa para contato"
                      placeholder="Pessoa para contato na Empresa"
                      registration={register('personToContact')}
                      error={!!formState.errors.personToContact}
                      errorMessage={formState.errors.personToContact?.message}
                    />
                    <InputField
                      size="small"
                      name="zipCode"
                      type="text"
                      label="CEP"
                      placeholder="99999-999"
                      registration={register('zipCode')}
                      error={!!formState.errors.zipCode}
                      errorMessage={formState.errors.zipCode?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = cepMask(
                          value.replace(/(\d{5})(\d)/, '$1-$2'),
                        );
                      }}
                    />
                    <InputField
                      size="small"
                      name="address"
                      type="text"
                      label="Endereço"
                      placeholder="Logradouro e número da empresa"
                      registration={register('address')}
                      error={!!formState.errors.address}
                      errorMessage={formState.errors.address?.message}
                    />
                    <InputField
                      size="small"
                      name="city"
                      type="text"
                      label="Cidade"
                      placeholder="Cidade"
                      registration={register('city')}
                      error={!!formState.errors.city}
                      errorMessage={formState.errors.city?.message}
                    />
                    <InputField
                      size="small"
                      name="neighborhood"
                      type="text"
                      label="Bairro"
                      placeholder="Bairro"
                      registration={register('neighborhood')}
                      error={!!formState.errors.neighborhood}
                      errorMessage={formState.errors.neighborhood?.message}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box className={classes.wrapper}>
                      <InputField
                        size="small"
                        name="priceStart"
                        type="text"
                        label="Preço inicial"
                        placeholder="R$: 00.00"
                        registration={register('priceStart')}
                        error={!!formState.errors.priceStart}
                        errorMessage={formState.errors.priceStart?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = handlePriceStart(value);
                        }}
                      />
                      <InputField
                        size="small"
                        name="priceKm"
                        type="text"
                        label="Preço por km"
                        placeholder="R$: 00.00"
                        registration={register('priceKm')}
                        error={!!formState.errors.priceKm}
                        errorMessage={formState.errors.priceKm?.message}
                      />
                      <InputField
                        size="small"
                        name="payment"
                        type="text"
                        select
                        label="Método de cobrança"
                        registration={register('payment')}
                        error={!!formState.errors.payment}
                        errorMessage={formState.errors.payment?.message}
                      >
                        {paymentOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                      <InputField
                        size="small"
                        name="multiply"
                        select
                        type="text"
                        label="Cobrar deslocamento?"
                        registration={register('multiply')}
                        error={!!formState.errors.multiply}
                        errorMessage={formState.errors.multiply?.message}
                      >
                        {multiplyOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? 'white' : 'red',
                          fontSize: '22px',
                        }}
                      >
                        Cadastro do usuário administrador
                      </Typography>

                      <InputField
                        size="small"
                        name="userEmail"
                        type="text"
                        label="Login"
                        placeholder="Login do usuário master"
                        registration={register('userEmail')}
                        error={!!formState.errors.userEmail}
                        errorMessage={formState.errors.userEmail?.message}
                      />
                      <InputField
                        size="small"
                        name="password"
                        type="password"
                        label="Senha"
                        registration={register('password')}
                        error={!!formState.errors.password}
                        errorMessage={formState.errors.password?.message}
                      />
                      <InputField
                        size="small"
                        name="userName"
                        type="text"
                        label="Nome"
                        placeholder="Nome do usuário"
                        registration={register('userName')}
                        error={!!formState.errors.userName}
                        errorMessage={formState.errors.userName?.message}
                      />
                      <InputField
                        size="small"
                        name="userPhone"
                        type="text"
                        label="Telefone"
                        placeholder="(99) 99999-9999"
                        registration={register('userPhone')}
                        error={!!formState.errors.userPhone}
                        errorMessage={formState.errors.userPhone?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = phoneMask(
                            value.replace(/[^\d]+/g, ''),
                          );
                        }}
                      />
                      <InputField
                        size="small"
                        name="optionalName"
                        type="text"
                        label="CPF"
                        placeholder="999.999.999-99"
                        registration={register('cpf')}
                        error={!!formState.errors.cpf}
                        errorMessage={formState.errors.cpf?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = cpfMask(value);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormGroup>
                      {/* <FormControlLabel
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? 'white' : 'black',
                        }}
                        control={
                          <Checkbox
                            onChange={() => setChecked(prev => !prev)}
                            checked={checked}
                          />
                        }
                        label="Ativo"
                      /> */}
                    </FormGroup>
                    <Button
                      sx={{ marginTop: '10px' }}
                      variant="contained"
                      color="success"
                      type="submit"
                    >
                      {isLoading ? 'carregando...' : 'Adicionar'}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Form>
        </Box>
      </Paper>
    </Box>
  );
}
