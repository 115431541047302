import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Eject } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import car3D from '../../../assets/img/3d-car.png';
import passengerWaiting from '../../../assets/img/passengerWaiting.png';
import driverDefault from '../../../assets/img/driver-default.png';
import userDefault from '../../../assets/img/usuario.png';
import { useTripCounter } from '../api';

type Props = {
  icon: any;
  title: string;
  amount: number;
  percentage: string;
  percentageColor: string;
  percentageDescription: string;
  isLoading?: boolean;
  userData: { id: string; urlImage: string | null }[];
  driverData: { id: string; urlImage: string | null }[];
};

export const DashboardCards = ({
  icon,
  title,
  amount,
  percentage,
  percentageColor,
  percentageDescription,
  isLoading,
  userData,
  driverData,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderAvatars = () => {
    const maxAvatars = 3;
    const remaining = Math.max(amount - maxAvatars, 0);

    const avatars = [];
    if (title === 'Motoristas') {
      const total = driverData?.length || 0;
      const list = 3;
      const extra = total - list;
      let driverArray = [];
      // eslint-disable-next-line no-plusplus, no-lone-blocks

      driverArray = driverData?.slice(0, list)?.map(data => {
        return (
          <Avatar
            key={data.id}
            sx={{
              marginRight: '-10px',
              border: '3px solid',
              borderColor: theme.palette.mode === 'light' ? '#fff' : '#292929',
              width: '56px',
              height: '56px',
            }}
            src={data.urlImage || driverDefault}
          />
        );
      });

      if (extra > 0) {
        const extraData = (
          <Avatar
            key="remaining"
            sx={{
              marginRight: '-10px',
              border: '3px solid',
              borderColor: theme.palette.mode === 'light' ? '#fff' : '#292929',
              width: '56px',
              height: '56px',
            }}
          >
            +{extra}
          </Avatar>
        );
        return (
          <Box sx={{ display: 'flex' }}>
            {driverArray}
            {extraData}
          </Box>
        );
      }
      return driverArray;
    }

    if (title === 'Usuários') {
      const total = userData?.length;
      const list = 3;
      const extra = total - list;
      let userArray = [];
      // eslint-disable-next-line no-plusplus, no-lone-blocks

      userArray = userData?.slice(0, list)?.map(data => {
        return (
          <Avatar
            key={data.id}
            sx={{
              marginRight: '-10px',
              border: '3px solid',
              borderColor: theme.palette.mode === 'light' ? '#fff' : '#292929',
              width: '56px',
              height: '56px',
            }}
            src={data.urlImage || userDefault}
          />
        );
      });

      if (extra > 0) {
        const extraData = (
          <Avatar
            key="remaining"
            sx={{
              marginRight: '-10px',
              border: '3px solid',
              borderColor: theme.palette.mode === 'light' ? '#fff' : '#292929',
              width: '56px',
              height: '56px',
            }}
          >
            +{extra}
          </Avatar>
        );
        return (
          <Box sx={{ display: 'flex' }}>
            {userArray}
            {extraData}
          </Box>
        );
      }
      return userArray;
    }

    if (remaining > 0) {
      avatars.push(
        <Avatar
          key="remaining"
          sx={{
            marginRight: '-10px',
            border: '3px solid',
            borderColor: theme.palette.mode === 'light' ? '#fff' : '#292929',
            width: '56px',
            height: '56px',
          }}
        >
          +{remaining}
        </Avatar>,
      );
    }

    return avatars;
  };

  const getColorBasedOnPercentage = (percentage: number) => {
    if (percentage >= 90) {
      return '#f32e2e';
    }
    if (percentage >= 60) {
      return '#ff9123';
    }
    return '#13AE85';
  };

  const progressBarColor = getColorBasedOnPercentage(parseInt(percentage, 10));
  const { data: tripCounterData } = useTripCounter();
  return (
    <Grid
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      item
      xl={3}
      md={6}
      sm={12}
    >
      <Paper
        sx={{
          padding: isMobile ? '8px' : '10px',
          paddingBottom: '22px',
          width: isMobile ? '320px' : '400px',
          height: '230px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '10px',
          gap: '2px',
          margin: '10px',
          marginTop: isMobile ? '25px' : '20px',
          flexWrap: 'wrap',
          background: 'transparent',
          boxShadow: '4px 7px 17px -8px rgba(0,0,0,0.53)',
          position: 'relative',
          border: '1px solid',
          borderColor: theme.palette.mode === 'light' ? '#cfcfcf' : '#353535',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: 20,
              right: 10,
            }}
          >
            {icon}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '22px',
                  marginTop: '10px',
                  color:
                    theme.palette.mode === 'dark'
                      ? '#DFDFDF'
                      : 'rgba(22, 22, 22, 1)',
                }}
                variant="h3"
              >
                {isLoading ? <CircularProgress /> : amount}
              </Typography>
            </Box>
          </Box>
        </Box>
        {title !== 'Veículos' && title !== 'Passageiros em espera' && (
          <Box
            sx={{
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            {isLoading ? <CircularProgress /> : renderAvatars()}
          </Box>
        )}

        {title === 'Veículos' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, bottom: 60 }}>
              <img style={{ width: '50px' }} src={car3D} alt="car3D" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginRight: '10px',
                  color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                {percentageDescription}
              </Typography>
              <Typography
                sx={{
                  color: progressBarColor,
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                {isLoading ? <CircularProgress /> : percentage}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Typography sx={{ fontFamily: 'Orbitron', fontWeight: 'bold' }}>
                0
              </Typography>
              <Tooltip title={`Corridas: ${tripCounterData?.consumedTrips}`}>
                <Box
                  sx={{
                    background: '#dddddd',
                    width: '70%',
                    height: '24px',
                    borderRadius: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: `${percentage}`,
                      maxWidth: '100%',
                      height: '24px',
                      background: progressBarColor,
                      borderRadius: '24px',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '13px',
                        color: '#000',
                        zIndex: 999,
                        right: '-15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Eject
                        fontSize="small"
                        sx={{
                          color:
                            theme.palette.mode === 'light'
                              ? '#4D4D4D'
                              : '#303030',
                          padding: '0px',
                        }}
                      />
                      <Typography
                        sx={{
                          marginTop: '-5px',
                          fontFamily: 'Orbitron',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          color:
                            theme.palette.mode === 'light'
                              ? '#4D4D4D'
                              : '#DFDFDF',
                        }}
                      >
                        {tripCounterData?.consumedTrips}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
              <Typography sx={{ fontFamily: 'Orbitron', fontWeight: 'bold' }}>
                {tripCounterData?.totalTrips}
              </Typography>
            </Box>
          </Box>
        )}
        {title !== 'Passageiros em espera' && title !== 'Veículos' && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginRight: '10px',
                  color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                {percentageDescription}
              </Typography>
              <Typography
                sx={{
                  color: percentageColor,
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {isLoading ? <CircularProgress /> : percentage}
              </Typography>
            </Box>
            <Box
              sx={{
                background: '#dddddd',
                width: '70%',
                height: '24px',
                borderRadius: '24px',
              }}
            >
              {title !== 'Veículos' && (
                <Box
                  sx={{
                    width: `${percentage}`,
                    height: '24px',
                    background:
                      theme.palette.mode === 'light' ? '#13AE85' : '#2ce9a0',
                    borderRadius: '24px',
                  }}
                />
              )}
            </Box>
          </Box>
        )}
        {title === 'Passageiros em espera' && (
          <Box
            sx={{
              justifyContent: 'flex-start',
              display: 'flex',
              position: 'absolute',
              bottom: 5,
            }}
          >
            <img
              style={{ width: '50px' }}
              src={passengerWaiting}
              alt="passageiros em espera"
            />
          </Box>
        )}
      </Paper>
    </Grid>
  );
};
