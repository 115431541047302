import { Paper, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { BarChart } from './BarChart';
import { ChartType } from '../types';

type Props = {
  chartBg: string;
  title: string;
  percentage: string;
  percentageColor: string;
  lastUpdate: string;
  type: string;
  data?: ChartType[];
};

export const ChartComponent = ({
  chartBg,
  percentage,
  title,
  percentageColor,
  lastUpdate,
  type,
  data,
}: Props) => {
  const [chartData, setChartData] = useState<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const labels = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const dataset = data?.map((info: ChartType) => {
      return {
        label: info?.label || '',
        data: info?.amount?.map(data => data),
        backgroundColor: info?.bgColor,
        borderColor: 'white',
      };
    });

    const datas = {
      labels,
      datasets: dataset,
    };

    setChartData(datas);
  }, [data]);

  return (
    <>
      <Paper
        sx={{
          width: isMobile ? '320px' : '100%',
          padding: '20px',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          marginTop: '20px',
          flexDirection: 'column',
          height: '278px',
          background: 'transparent',
        }}
      >
        <Box
          sx={{
            background: chartBg,
            width: isMobile ? '310px' : '310px',
            borderRadius: '10px',
          }}
        >
          <BarChart chartData={chartData} type={type} />
        </Box>
        <Box sx={{ width: '100%', marginTop: '10px' }}>
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? '#979797' : '#717171',
            }}
            variant="h6"
          >
            {title}
          </Typography>
          <Typography sx={{ color: percentageColor }} variant="h6">
            {percentage}
          </Typography>
        </Box>

        <Box sx={{ width: '100%', marginTop: '20px' }}>
          <Typography
            sx={{ color: 'gray', display: 'flex', alignItems: 'center' }}
            variant="subtitle2"
          >
            <AccessTime sx={{ marginRight: '10px' }} /> {lastUpdate}
          </Typography>
        </Box>
      </Paper>
    </>
  );
};
