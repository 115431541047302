/* eslint-disable no-param-reassign */
import * as z from 'zod';
import { Form, InputField } from '@/components';
import {
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  MenuItem,
} from '@mui/material';
import { cpfMask, phoneMask } from '@/utils/maskShared';
import { makeStyles } from '@mui/styles';
import { UserValues } from '@/features/users';
import { useAuth } from '@/providers/auth';
import { useGetCompanyProfiles } from '@/features/profiles';
import { useNavigate } from 'react-router-dom';
import { useCreateOperator } from '../api';

const schema = z.object({
  email: z.string({ required_error: 'Campo obrigatório' }),
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  name: z.string({ required_error: 'Campo obrigatório' }),
  roleProfileId: z.string({ required_error: 'Campo obrigatório' }),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z
    .string({ required_error: 'Campo obrigatório' })
    .max(14, 'Máximo 14 caracteres'),
});

export function CreateOperatorForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: profiles } = useGetCompanyProfiles();
  const { mutateAsync, isLoading: loadingAdd } = useCreateOperator();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<UserValues, typeof schema>
          id="create-operator"
          onSubmit={values => {
            mutateAsync({
              companyId: user?.user?.companyId,
              email: values.email || undefined,
              password: values.password || undefined,
              name: values.name,
              individualRegistration: values.individualRegistration,
              phone: values.phone,
              roleProfileId: values?.roleProfileId || '',
            });
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Cadastro de operador
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <InputField
                    size="small"
                    name="email"
                    type="text"
                    label="Login"
                    registration={register('email')}
                    error={!!formState.errors.email}
                    errorMessage={formState.errors.email?.message}
                    placeholder="Login do usuário para acesso no app"
                  />
                  <InputField
                    size="small"
                    name="password"
                    type="password"
                    label="Senha"
                    registration={register('password')}
                    error={!!formState.errors.password}
                    errorMessage={formState.errors.password?.message}
                    placeholder="Senha para acesso"
                  />
                  <InputField
                    size="small"
                    name="name"
                    type="text"
                    label="Nome"
                    registration={register('name')}
                    error={!!formState.errors.name}
                    errorMessage={formState.errors.name?.message}
                    placeholder="Nome do usuário"
                  />
                  <InputField
                    size="small"
                    name="phone"
                    type="text"
                    label="Telefone"
                    registration={register('phone')}
                    error={!!formState.errors.phone}
                    errorMessage={formState.errors.phone?.message}
                    placeholder="(99) 99999-9999"
                    inputProps={{ minLength: 15, maxLength: 15 }}
                    onChange={(event: any) => {
                      const { value } = event.target;
                      event.target.value = phoneMask(
                        value.replace(/[^\d]+/g, ''),
                      );
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box className={classes.wrapper}>
                    <InputField
                      size="small"
                      name="individualRegistration"
                      type="text"
                      label="CPF"
                      registration={register('individualRegistration')}
                      error={!!formState.errors.individualRegistration}
                      errorMessage={
                        formState.errors.individualRegistration?.message
                      }
                      placeholder="999.999.999-99"
                      inputProps={{ minLength: 14, maxLength: 14 }}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = cpfMask(value);
                      }}
                    />
                    <InputField
                      size="small"
                      name="roleProfileId"
                      type="text"
                      select
                      label="Perfil"
                      registration={register('roleProfileId')}
                      error={!!formState.errors.roleProfileId}
                      errorMessage={formState.errors.roleProfileId?.message}
                    >
                      {profiles?.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Box>
                </Grid>
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={loadingAdd}
                  >
                    {loadingAdd ? 'Carregando...' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={loadingAdd}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
}
