import { PageLayout } from '@/components';
import { People } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { UsersListTable } from '../components/UsersListTable';

export function UsersList() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Usuários"
        subTitle={subTitle}
        icon={<People fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <UsersListTable />
      </PageLayout>
    </>
  );
}
