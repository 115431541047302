import { Paper, Card, Typography } from '@mui/material';
import useStyles from './styles';

type Props = {
  title: string | React.ReactNode;
  subTitle?: string;
  icon: React.ReactNode;
};

export function PageHeader({ title, subTitle, icon }: Props) {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <div className={classes.pageHeader}>
          <Card className={classes.pageIcon}>{icon}</Card>
          <div className={classes.pageTitle}>
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold' }}
              component="div"
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography variant="subtitle2" component="div">
                {subTitle}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
}
