import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

// AxiosResponse
// CompanyValues

type UseCompanyOptions = {
  id: string;
  config?: QueryConfig<typeof GetCompanyRoles>;
};

export async function GetCompanyRoles({ id }: { id: string }): Promise<
  {
    id?: string;
    type: string;
    isCompanyRole?: boolean;
  }[]
> {
  const response = await axios.get(`permissions/getCompanyPermissions/${id}`);
  return response.data.payload;
}

export const useCompanyRoles = ({ id, config }: UseCompanyOptions) => {
  return useQuery({
    queryKey: ['companyPermissions', id],
    queryFn: () => GetCompanyRoles({ id }),
    ...config,
  });
};
