/* eslint-disable no-nested-ternary */
import { useMediaQuery } from '@mui/material';
import { driversProps } from '../types';
import { DriverInfoDesktop } from './DriverInfoDesktop';
import { DriverInfoMobile } from './DriverInfoMobile';

type IDriverInfo = {
  clickedDriver: driversProps;
  setClickedDriver: (mode: undefined) => void;
  mode: string;
};

export const DriverInfo = ({
  clickedDriver,
  setClickedDriver,
  mode,
}: IDriverInfo) => {
  const matches = useMediaQuery('(max-width:850px)');
  return (
    <>
      {matches ? (
        <DriverInfoMobile
          clickedDriver={clickedDriver}
          setClickedDriver={setClickedDriver}
        />
      ) : (
        <DriverInfoDesktop
          mode={mode}
          clickedDriver={clickedDriver}
          setClickedDriver={setClickedDriver}
        />
      )}
    </>
  );
};
