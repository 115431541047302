import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';
import { SaveUserResponse, UserValues } from '@/features/users';

export type UpdateCompanyData = {
  data: UserValues;
};

export const UpdateOperator = ({
  data,
}: UpdateCompanyData): Promise<SaveUserResponse> => {
  return axios.put(`users/update/${data.id}`, data);
};

export const useUpdateOperator = () => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('operators');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('operators');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Operador atualizado com sucesso.',
      });
      navigate('/operators');
    },
    mutationFn: UpdateOperator,
  });
};
