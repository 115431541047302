import { Box, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { Detail } from './Detail';

interface IProps {
  va: string;
  tripAttachments: {
    fileUrl?: string;
    id: string;
    observation: string;
    price: string;
    type: string;
  }[];
}

export const AdditionalValues = ({ tripAttachments, va }: IProps) => {
  return (
    <>
      <Detail bold title="Valores adicionais" description="" />
      <ul>
        {tripAttachments?.map(attachment => (
          <li
            style={{
              gap: '10px',
            }}
            key={attachment.id}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>{attachment.type}:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography>
                  R$:
                  {Number(attachment.price).toFixed(2).replace('.', ',')}
                </Typography>
                {attachment.fileUrl ? (
                  <a
                    target="_blank"
                    href={`${attachment.fileUrl}`}
                    rel="noreferrer"
                  >
                    <Visibility
                      fontSize="small"
                      style={{
                        color: '#3394D9',
                      }}
                    />
                  </a>
                ) : (
                  <Typography>Recibo pendente</Typography>
                )}
              </Box>
            </Box>
          </li>
        ))}
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Subtotal (valores adicionais):</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{va}</Typography>
          </Box>
        </li>
      </ul>
    </>
  );
};
