import { Link, Table } from '@/components';
import { DeleteSharp, EditSharp } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';

interface MobileTableProps {
  tableData?: {
    id: string;
    corporateName: string;
    addressFrom: string;
    addressTo: string;
    distance: string;
    price: string;
  }[];
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function MobileTable({
  tableData = [],
  toggleDelete,
  loading,
}: MobileTableProps) {
  return (
    <Table<any>
      columns={[
        { title: 'Empresa', field: 'corporateName' },
        { title: 'Partida', field: 'addressFrom' },
        { title: 'Destino', field: 'addressTo' },
        { title: 'Valor cobrado (R$)', field: 'price' },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id } }) {
            return (
              <>
                <Link to={`./${id}`}>
                  <Tooltip title="Editar">
                    <IconButton>
                      <EditSharp sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title="Excluir">
                  <IconButton onClick={() => toggleDelete(id)}>
                    <DeleteSharp sx={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      ]}
      data={tableData.sort((a, b) =>
        a.corporateName.localeCompare(b.corporateName),
      )}
      loading={loading}
    />
  );
}
