/* eslint-disable react/no-array-index-key */
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { Route } from './Route';

interface RouteInformationProps {
  isLoading?: boolean;
  title: string;
  icon: React.ReactNode;
  background?: string;
  data: {
    route: string;
    avg?: number;
    count?: number;
  }[];
}

export const RouteInformation = ({
  title,
  icon,
  data,
  background = '#EEEEEE',
  isLoading = false,
}: RouteInformationProps) => {
  return (
    <Paper
      sx={{
        minWidth: '300px',
        padding: '10px',
        borderRadius: '20px',
        height: '650px',
        margin: '0px 5px',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto',
        },
      }}
    >
      {isLoading ? (
        <Box>
          <Skeleton
            sx={{ height: '50px', width: '100%', borderRadius: '5px' }}
          />
          <Skeleton
            sx={{ height: '50px', width: '100%', borderRadius: '5px' }}
          />
          <Skeleton
            sx={{ height: '50px', width: '100%', borderRadius: '5px' }}
          />
          <Skeleton
            sx={{ height: '50px', width: '100%', borderRadius: '5px' }}
          />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {icon}
            <Typography>{title}</Typography>
          </Box>
          <Box>
            {data?.map((item, i) => (
              <Route
                key={i}
                background={background}
                avg={item.avg}
                count={item.count}
                route={item.route}
              />
            ))}
          </Box>
        </>
      )}
    </Paper>
  );
};
