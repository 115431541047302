/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  label: string;
  amount: number[];
  bgColor: string;
  lastUpdate?: string;
  lastUpdateDate?: Date;
}

export const getLatestTrips = async (): Promise<IOutput[]> => {
  const response = await axios.get('trips/getLatestTrips');
  return response.data.payload;
};

type UseLatestTripsOptions = {
  config?: QueryConfig<typeof getLatestTrips>;
};

export const useLatestTrips = ({ config }: UseLatestTripsOptions = {}) => {
  return useQuery({
    queryKey: ['allLatestTrips'],
    queryFn: () => getLatestTrips(),
    ...config,
  });
};
