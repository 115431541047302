import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CreatePermissions } from './CreatePermissions';

export const PermissionsRoutes = () => {
  return (
    <>
      <Can permissions={['master']}>
        <Routes>
          <Route path="/" element={<CreatePermissions />} />
        </Routes>
      </Can>
    </>
  );
};
