import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  IChatMessage,
  useCheckMessages,
  useGetNotCheckedMessages,
  useListMessages,
} from '@/features/tripRequest';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useSocket } from './socketProvider';
import { useAuth } from './auth';

interface ChatProviderProps {
  children: ReactNode;
}

interface ChatContextData {
  notCheckedMessages:
    | {
        total: number;
        messages: IChatMessage[];
      }
    | undefined;
  isLoadingNotCheckedMessages: boolean;
  messages: IChatMessage[];
  selectedDriver: string | null;
  setSelectedDriver: (selectedDriver: string) => void;
  isLoadingMessages: boolean;
  fetchNextPage: () => void;
  checkMessages: ({ driverId }: { driverId: string }) => void;
  hasNextPage: boolean;
  closeChat: () => void;
  isChatOpen: boolean;
  toggleChat: () => void;
}

const ChatContext = createContext({} as ChatContextData);

const ChatProvider = ({ children }: ChatProviderProps): JSX.Element => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);

  const { mutateAsync: checkMessages } = useCheckMessages();

  const {
    close: closeChat,
    isOpen: isChatOpen,
    toggle: toggleChat,
  } = useDisclosure();

  const {
    data: notCheckedMessages,
    isLoading: isLoadingNotCheckedMessages,
    refetch: refetchNotCheckedMessages,
  } = useGetNotCheckedMessages();

  const {
    data: messagesData,
    isLoading: isLoadingMessages,
    fetchNextPage,
    hasNextPage,
    refetch: refetchMessages,
  } = useListMessages({
    driverId: selectedDriver || '',
    renterUserId: user?.user?.id,
  });

  useEffect(() => {
    const data = messagesData?.pages.map(page => page.messages).flat();
    setMessages(data || []);
  }, [messagesData]);

  useEffect(() => {
    if (selectedDriver) {
      refetchMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriver]);

  socket
    ?.off('driverMessageOnDesktop')
    .on('driverMessageOnDesktop', async (message: IChatMessage) => {
      if (selectedDriver === message.senderId) {
        setMessages(prev => [
          ...prev,
          { ...message, sentByDriver: true, checked: true },
        ]);
        checkMessages({ driverId: message.senderId });
      } else {
        refetchNotCheckedMessages();
      }
    });

  socket
    ?.off('driverChecksMessages')
    .on('driverChecksMessages', async (message: IChatMessage) => {
      if (selectedDriver === message.receiverId) {
        setMessages(prev =>
          prev.map(message => ({
            ...message,
            checked: true,
          })),
        );
      }
    });

  return (
    <ChatContext.Provider
      value={{
        notCheckedMessages,
        isLoadingNotCheckedMessages,
        messages,
        selectedDriver,
        setSelectedDriver,
        isLoadingMessages,
        fetchNextPage,
        hasNextPage: !!hasNextPage,
        checkMessages,
        closeChat,
        isChatOpen,
        toggleChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatProvider must be used with a ChatProvider.');
  }

  return context;
}

export { ChatProvider, useChat };
