/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

export const getCompanyIncome = async () => {
  try {
    const response = await axios.get('companies/getCompanyIncome');
    return response.data.payload;
  } catch (err) {
    console.log(err);
  }
};

type UseLatestTripsOptions = {
  config?: QueryConfig<typeof getCompanyIncome>;
};

export const UseCompanyIncome = ({ config }: UseLatestTripsOptions = {}) => {
  return useQuery({
    queryKey: ['companyIncome'],
    queryFn: () => getCompanyIncome(),
    ...config,
  });
};
