import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CreateVersionLog } from './CreateVersionLog';

export const VersionLogRoutes = () => {
  return (
    <>
      <Can permissions={['master']}>
        <Routes>
          <Route path="/" element={<CreateVersionLog />} />
        </Routes>
      </Can>
    </>
  );
};
