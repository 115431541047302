import { Table } from '@/components';
import { Pdf } from '@/utils/PDF';
import { Box, Button, Toolbar } from '@mui/material';
import { CSVLink } from 'react-csv';
import { useGetRequests } from '../api';

interface IRequestsTable {
  endDate: string;
  startDate: string;
}

export const RequestsTable = ({ endDate, startDate }: IRequestsTable) => {
  const { data, isLoading } = useGetRequests({
    endDate,
    startDate,
  });
  const headers = [
    {
      title: 'Data',
      field: 'date',
      colSpan: 2,
      label: 'Data',
      key: 'date',
    },
    {
      title: 'Api',
      field: 'api',
      colSpan: 2,
      label: 'Api',
      key: 'api',
    },
    {
      title: 'Descrição',
      field: 'description',
      colSpan: 2,
      label: 'Descrição',
      key: 'description',
    },
    {
      title: 'Cliente',
      field: 'userName',
      colSpan: 2,
      label: 'Cliente',
      key: 'userName',
    },
  ];
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {!!data && (
          <>
            <Toolbar>
              <Button
                variant="contained"
                type="button"
                color="error"
                onClick={() => {
                  Pdf({
                    data,
                    headers,
                  });
                }}
              >
                Gerar PDF
              </Button>
            </Toolbar>

            <Toolbar>
              <CSVLink
                style={{ textDecoration: 'none' }}
                headers={headers}
                filename={`Relatório-logs-${Date.now()}`}
                data={data}
              >
                <Button variant="contained" type="button" color="success">
                  Gerar Excel
                </Button>
              </CSVLink>
            </Toolbar>
          </>
        )}
      </Box>
      <Table
        columns={[
          { title: 'Data', field: 'date', colSpan: 2 },
          { title: 'Api', field: 'api', colSpan: 2 },
          { title: 'Descrição', field: 'description', colSpan: 2 },
          { title: 'Cliente', field: 'userName', colSpan: 2 },
        ]}
        data={data || []}
        loading={isLoading}
      />
    </>
  );
};
