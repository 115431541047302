import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';
import { SaveUserResponse, UserValues } from '../types';

export type UpdateCompanyData = {
  data: UserValues;
};

export const UpdateUser = ({
  data,
}: UpdateCompanyData): Promise<SaveUserResponse> => {
  return axios.put(`users/update/${data.id}`, data);
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof UpdateUser>;
};

export const useUpdateUser = ({ config }: UseUpdateCompaniesOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async updatingUser => {
      await queryClient.cancelQueries(['users', updatingUser?.data.id]);

      const previousUser = queryClient.getQueryData<UserValues>([
        'users',
        updatingUser?.data.id,
      ]);

      queryClient.setQueryData(['users', updatingUser?.data.id], {
        ...previousUser,
        ...updatingUser?.data,
        id: updatingUser?.data?.id,
      });

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(
          ['users', context.previousUser.data?.data.id],
          context.previousUser,
        );
      }
    },
    onSuccess: (data, _variables, context) => {
      queryClient.refetchQueries(['users', context.previousUser.id]);

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Usuário atualizado com sucesso.',
      });
      navigate('/users');
    },
    ...config,
    mutationFn: UpdateUser,
  });
};
