import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { useHasPermission } from '@/hooks/useHasPermission';
import { IWarning } from '../types';

export async function getWarnings(): Promise<IWarning[]> {
  const response = await axios.get('lastPositions/getAlerts');

  return response.data.payload;
}

type UseWarningOptions = {
  config?: QueryConfig<typeof getWarnings>;
};

export const useWarnings = ({ config }: UseWarningOptions = {}) => {
  const { user } = useAuth();
  const hasRole = useHasPermission('alerta de ignição');
  return useQuery({
    enabled: user?.user?.accessType === 'renter' && hasRole,
    queryKey: ['warnings'],
    queryFn: () => getWarnings(),
    ...config,
  });
};
