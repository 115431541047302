import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useUser } from '../api';
import { UserUpdate } from '../routes/UserUpdate';

export function UpdateUser() {
  const dataParams = useParams() as unknown as Record<string, any>;
  const id = dataParams.userId;
  const { data: usersQuery, isLoading } = useUser({ id });
  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <UserUpdate usersQuery={usersQuery} />
      )}
    </>
  );
}
