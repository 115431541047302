/* eslint-disable no-useless-escape */
/* eslint-disable import/no-duplicates */
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Filter } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import { useAddAdditionalValue } from '../../api/addAdditionalValue';
import { useGetAttachmentsByTripId, useUpdateAdditionalValue } from '../../api';
import { IAttachment } from '../../types';

interface IRequest {
  id: string;
  onSuccess: () => void;
}

interface IFormValues {
  type: string;
  observation: string;
  price: string;
  preview?: string;
  selectedFile?: File | null;
}

const typeOptions = [
  'Deslocamento adicional',
  'Alimentação',
  'Hospedagem',
  'Combustível',
  'Pedágio',
  'Estacionamento',
  'Outros',
];

export const AddAdditionalValueToTheTrip = ({ id, onSuccess }: IRequest) => {
  const { data: attachments } = useGetAttachmentsByTripId({ id });
  const [selectedAttachment, setSelectedAttachment] =
    useState<IAttachment | null>(null);
  const [formValues, setFormValues] = useState<IFormValues>({
    type: '',
    observation: '',
    price: '0',
    preview: '',
    selectedFile: null,
  });
  const [otherType, setOtherType] = useState('');
  const [showAddImageBox, setShowAddImageBox] = useState(true);
  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFormValues({ ...formValues, selectedFile: file });

    if (file) {
      const previewURL = URL.createObjectURL(file);
      setFormValues({ ...formValues, preview: previewURL, selectedFile: file });

      setShowAddImageBox(false);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const { mutateAsync, isLoading } = useAddAdditionalValue();
  const { mutateAsync: update, isLoading: isUpdating } =
    useUpdateAdditionalValue();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedAttachment) {
      console.log('1');
      update(
        {
          id: selectedAttachment.id,
          type: formValues.type === 'Outros' ? otherType : formValues.type,
          price: formValues.price.toString(),
          urlImage: formValues?.selectedFile,
          observation: formValues.observation,
          hasImageChanged: !!formValues.selectedFile,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        },
      );
    } else {
      mutateAsync(
        {
          tripId: id,
          type: formValues.type === 'Outros' ? otherType : formValues.type,
          price: formValues.price.toString(),
          urlImage: formValues?.selectedFile,
          observation: formValues.observation,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        },
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Button
            onClick={() => {
              setSelectedAttachment(null);
              setFormValues({
                type: '',
                observation: '',
                price: '0',
              });
              setShowAddImageBox(true);
            }}
            sx={{ width: '200px' }}
            color="success"
            variant="contained"
          >
            Novo registro
          </Button>
          {attachments?.map(item => (
            <Button
              key={item.id}
              sx={{ width: '200px' }}
              color={item.fileUrl ? 'info' : 'warning'}
              variant="contained"
              onClick={() => {
                setSelectedAttachment(item);
                setFormValues({
                  type: item.type,
                  observation: item.observation || '',
                  price: item.price || '0',
                  preview: item.fileUrl || '',
                });

                if (item.fileUrl) {
                  setShowAddImageBox(false);
                } else {
                  setShowAddImageBox(true);
                }
              }}
            >
              {item.fileUrl ? 'Completo' : 'Pendência'} -{' '}
              {format(new Date(item.createdAt), 'dd/MM HH:mm')}
            </Button>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flex: 2 }}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
          }}
          onSubmit={e => onSubmit(e)}
        >
          <TextField
            size="small"
            disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
            name="type"
            type="text"
            select
            sx={{ width: '100%' }}
            placeholder="Deslocamento adicional"
            label="Tipo"
            onChange={e => {
              setFormValues({ ...formValues, type: e.target.value });
            }}
            value={formValues.type}
          >
            {typeOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {formValues.type === 'Outros' && (
            <TextField
              size="small"
              disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
              name="otherType"
              type="text"
              sx={{ width: '100%' }}
              placeholder="Outros"
              label="Outros"
              onChange={e => {
                setOtherType(e.target.value);
              }}
              value={otherType}
            />
          )}

          <TextField
            size="small"
            disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
            name="observation"
            type="text"
            sx={{ width: '100%' }}
            placeholder="Observação"
            label="Observação"
            onChange={e => {
              setFormValues({ ...formValues, observation: e.target.value });
            }}
            value={formValues.observation}
          />

          <TextField
            size="small"
            disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
            name="price"
            type="number"
            sx={{ width: '100%' }}
            placeholder="15"
            label="Preço"
            onChange={e => {
              setFormValues({
                ...formValues,
                price: e.target.value,
              });
            }}
            value={formValues.price}
          />

          <Box sx={{ marginTop: '10px' }}>
            <Box
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: showAddImageBox ? 'flex' : 'none',
                marginTop: '5px',
                height: '118px',
                borderRadius: '10px',
                border: '1px dotted gray',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
              onClick={handleDivClick}
            >
              <input
                name="driverImage"
                type="file"
                disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
                accept=".jpeg, .jpg, .png"
                onChange={handleFileInput}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <Filter style={{ color: 'gray' }} />
              <Typography
                sx={{
                  color:
                    !!selectedAttachment && !!selectedAttachment?.fileUrl
                      ? 'gray'
                      : 'inherit',
                }}
              >
                Adicionar imagem
              </Typography>
            </Box>
            {formValues?.preview && (
              <Box>
                <Box
                  sx={{
                    background: 'white',
                    width: '118px',
                    height: '118px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    position: 'relative',
                    boxShadow: '0px 0px 10px 0px #0000004c',
                  }}
                  onClick={handleDivClick}
                >
                  <Filter style={{ position: 'absolute', color: '#fff' }} />
                  <img
                    src={formValues?.preview}
                    alt="Preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="error"
                  disabled={
                    !!selectedAttachment && !!selectedAttachment?.fileUrl
                  }
                  onClick={() => {
                    setFormValues({
                      ...formValues,
                      preview: '',
                      selectedFile: null,
                    });
                    setShowAddImageBox(true);
                  }}
                >
                  Remover
                </Button>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Button
              disabled={!!selectedAttachment && !!selectedAttachment?.fileUrl}
              variant="contained"
              color="success"
              type="submit"
            >
              {isLoading || isUpdating ? 'carregando...' : 'Salvar'}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
