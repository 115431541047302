import { Table } from '@/components';
import { ClockInTable } from '@/features/clockIn/components/ClockInTable';
import { ReportsProps } from '../types';

interface DesktopTableProps {
  tableData: any[];
  loading: boolean;
  chipData: any;
  type:
    | 'trips'
    | 'workJourney'
    | 'vehicleExchange'
    | 'location'
    | 'vehicleAndTripsRelation';
}

export function DesktopTable({
  tableData,
  loading,
  chipData,
  type,
}: DesktopTableProps) {
  switch (type) {
    case 'trips':
      return (
        <Table<ReportsProps>
          columns={chipData}
          data={tableData}
          loading={loading}
        />
      );
    case 'workJourney':
      return (
        <ClockInTable
          columns={chipData}
          loading={loading}
          tableData={tableData}
        />
      );
    default: {
      return (
        <Table<ReportsProps>
          columns={chipData}
          data={tableData}
          loading={loading}
        />
      );
    }
  }
}
