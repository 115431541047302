import * as z from 'zod';
import { ConfirmModal, ContentLayout, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useAuth } from '@/providers/auth';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useSectorsList } from '@/features/sectors';
import { DeleteOutline } from '@mui/icons-material';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useNotificationStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserImage, useUpdateUser } from '../api';
import { listUserResponse } from '../types';

const emailSchema = z.object({
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  password: z.string().optional(),
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Mínimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z.string().max(14, 'Máximo 14 caracteres').optional(),
  registration: z.any(),
  thirdPartyCompany: z.string().optional(),
  dynamicSector: z.boolean().optional(),
  sectorId: z.string().nullable().optional(),
  accessType: z.string().optional(),
  willHaveAccess: z.literal(true),
});

const noEmailSchema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Mínimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  registration: z.any(),
  thirdPartyCompany: z.string().optional(),
  dynamicSector: z.boolean().optional(),
  sectorId: z.string().nullable().optional(),
  accessType: z.string().optional(),
  willHaveAccess: z.literal(false),
});
const formSchema = z.discriminatedUnion('willHaveAccess', [
  emailSchema,
  noEmailSchema,
]);

type Props = {
  usersQuery?: listUserResponse;
};

export function UserUpdate({ usersQuery }: Props) {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [checked, setChecked] = useState(true);
  const { user } = useAuth();
  const { data: sectorsList } = useSectorsList();
  const { mutateAsync, isLoading: loadingUpdate } = useUpdateUser();
  const { mutateAsync: deleteUserImage, isLoading: isDeletingUserImage } =
    useDeleteUserImage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure();

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const refactoredAccessType = () => {
    const { accessType, email, thirdPartyCompany } = usersQuery || {};

    if (accessType === 'sector_admin') {
      return 'sector_admin';
    }

    if (accessType === 'user') {
      if (email) {
        return 'user';
      }
      if (thirdPartyCompany) {
        return 'user-third-party';
      }
      return 'user-without-access';
    }

    return 'user';
  };

  const preloadedValues = {
    password: '',
    email: usersQuery?.email,
    name: usersQuery?.name,
    phone: usersQuery?.phone,
    individualRegistration: usersQuery?.individualRegistration,
    dynamicSector: usersQuery?.dynamicSector || false,
    thirdPartyCompany: usersQuery?.thirdPartyCompany || '',
    sectorId: usersQuery?.sectorId || null,
    registration: usersQuery?.registration,
    accessType: refactoredAccessType(),
    willHaveAccess: !!usersQuery?.email,
  };

  const { handleSubmit, control, formState, setValue, watch, getValues } =
    useForm<any>({
      defaultValues: preloadedValues,
      resolver: formSchema && zodResolver(formSchema),
    });

  const OnSubmit = (values: any) => {
    if (!values.sectorId && !values.dynamicSector) {
      return addNotification({
        type: 'error',
        title: 'Erro',
        message: 'Selecione um setor',
      });
    }
    const statusSelected = !!checked;
    const email =
      values.accessType === 'user' || values.accessType === 'sector_admin'
        ? values.email
        : undefined;
    const password =
      values.accessType === 'user' || values.accessType === 'sector_admin'
        ? values.password
        : undefined;
    delete values.willHaveAccess;
    const data = {
      ...values,
      email,
      password,
      accessType:
        values?.accessType === 'sector_admin' ? 'sector_admin' : 'user',
      status: statusSelected,
      companyId: usersQuery?.companyId,
      id: usersQuery?.id,
    };

    return mutateAsync({ data });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  watch('willHaveAccess');
  watch('dynamicSector');

  return (
    <ContentLayout>
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <ConfirmModal
          close={closeDirtyFormModal}
          isOpen={isFormDirty}
          description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
          handleConfirmation={() => navigate(-1)}
        />
        <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
          <>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'red',
                fontSize: '22px',
              }}
            >
              Editando usuário
            </Typography>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="accessType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="accessType"
                      type="text"
                      select
                      label="Tipo de acesso"
                      onChange={onChange}
                      value={value}
                      defaultValue={usersQuery?.accessType}
                      errorMessage={formState.errors.accessType?.message}
                      error={!!formState.errors.accessType}
                    >
                      {user.user.accessType === 'company_admin' && (
                        <MenuItem
                          onClick={() => setValue('willHaveAccess', true)}
                          value="sector_admin"
                        >
                          Chefe de Setor
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => setValue('willHaveAccess', true)}
                        value="user"
                      >
                        Passageiro com acesso
                      </MenuItem>
                      <MenuItem
                        onClick={() => setValue('willHaveAccess', false)}
                        value="user-without-access"
                      >
                        Passageiro sem acesso
                      </MenuItem>
                      <MenuItem
                        onClick={() => setValue('willHaveAccess', false)}
                        value="user-third-party"
                      >
                        Passageiro externo
                      </MenuItem>
                    </InputField>
                  )}
                />
                {getValues('accessType') !== 'user-third-party' &&
                  getValues('accessType') !== 'user-without-access' && (
                    <>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="email"
                            type="text"
                            label="Login"
                            onChange={onChange}
                            value={value}
                            placeholder="Login do usuário"
                            errorMessage={formState.errors.email?.message}
                            error={!!formState.errors.email}
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="password"
                            type="password"
                            label="Senha"
                            onChange={onChange}
                            value={value}
                            errorMessage={formState.errors.password?.message}
                            error={!!formState.errors.password}
                          />
                        )}
                      />
                      <Controller
                        name="individualRegistration"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="individualRegistration"
                            type="text"
                            label="CPF"
                            inputProps={{ minLength: 11, maxLength: 14 }}
                            onChange={onChange}
                            value={value}
                            errorMessage={
                              formState.errors.individualRegistration?.message
                            }
                            error={!!formState.errors.individualRegistration}
                          />
                        )}
                      />
                    </>
                  )}

                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="name"
                      type="text"
                      label="Nome"
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.name?.message}
                      error={!!formState.errors.name}
                    />
                  )}
                />

                <Controller
                  name="registration"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="registration"
                      type="text"
                      label="Matrícula"
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.registration?.message}
                      error={!!formState.errors.registration}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="phone"
                      type="text"
                      label="Telefone"
                      inputProps={{ minLength: 8, maxLength: 15 }}
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.phone?.message}
                      error={!!formState.errors.phone}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box className={classes.wrapper}>
                  {getValues('accessType') === 'user-third-party' && (
                    <Controller
                      name="thirdPartyCompany"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="thirdPartyCompany"
                          type="text"
                          label="Empresa terceirizada"
                          inputProps={{ minLength: 1, maxLength: 100 }}
                          onChange={onChange}
                          value={value}
                          errorMessage={
                            formState.errors.thirdPartyCompany?.message
                          }
                          error={!!formState.errors.thirdPartyCompany}
                        />
                      )}
                    />
                  )}
                  {user.user.accessType === 'company_admin' && (
                    <>
                      {!getValues('dynamicSector') && (
                        <Controller
                          name="sectorId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              size="small"
                              name="sectorId"
                              select
                              type="text"
                              label="Setor"
                              onChange={onChange}
                              value={value}
                              errorMessage={formState.errors.sectorId?.message}
                              error={!!formState.errors.sectorId}
                            >
                              {sectorsList
                                ?.sort()
                                .map((item: any, key: any) => {
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <MenuItem key={key} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                            </InputField>
                          )}
                        />
                      )}
                      {getValues('accessType') !== 'sector_admin' && (
                        <FormControlLabel
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? 'white' : 'black',
                          }}
                          control={
                            <Checkbox
                              onChange={() => {
                                if (getValues('dynamicSector')) {
                                  setValue('dynamicSector', false);
                                } else {
                                  setValue('dynamicSector', true);
                                  setValue('sectorId', null);
                                }
                              }}
                              checked={getValues('dynamicSector')}
                            />
                          }
                          label="Setor dinâmico"
                        />
                      )}
                    </>
                  )}
                </Box>
                {usersQuery?.urlImage && (
                  <Box
                    sx={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      marginTop: '5px',
                      position: 'relative',
                    }}
                  >
                    {isDeleteModalOpen && (
                      <ConfirmModal
                        description="Deseja realmente excluir a imagem?"
                        handleConfirmation={() =>
                          deleteUserImage(usersQuery?.id)
                        }
                        isLoading={isDeletingUserImage}
                        isOpen={isDeleteModalOpen}
                        close={closeDeleteModal}
                      />
                    )}
                    <Box
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? '#b44141' : '#e46464',
                        position: 'absolute',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bottom: '-10px',
                        transform: 'translateX(35px)',
                        border:
                          theme.palette.mode === 'dark'
                            ? '4px solid #292929'
                            : '4px solid #fff',
                        cursor: 'pointer',
                      }}
                      onClick={() => openDeleteModal()}
                    >
                      <DeleteOutline style={{ fontSize: '20px' }} />
                    </Box>
                    <Box
                      sx={{
                        width: '118px',
                        height: '118px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 0px 10px 0px #0000004c',
                      }}
                    >
                      <img
                        src={usersQuery?.urlImage}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>

              <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={loadingUpdate}
                >
                  {loadingUpdate ? 'Carregando...' : 'Salvar'}
                </Button>
                <Button
                  onClick={() => handleGoBack()}
                  variant="contained"
                  color="primary"
                  disabled={loadingUpdate}
                >
                  Voltar
                </Button>
              </Box>
            </Grid>
          </>
        </form>
      </Paper>
    </ContentLayout>
  );
}
