/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  route: string;
  avg: number;
}

export const getSlowRoutes = async (): Promise<IOutput[]> => {
  const response = await axios.get('app-logs/getSlowRoutes');
  return response.data.payload;
};

type UseGetSlowRoutesOptions = {
  config?: QueryConfig<typeof getSlowRoutes>;
};

export const UseGetSlowRoutes = ({ config }: UseGetSlowRoutesOptions = {}) => {
  return useQuery({
    queryKey: ['slowRoutes'],
    queryFn: () => getSlowRoutes(),
    ...config,
  });
};
