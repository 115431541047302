import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

interface IRequest {
  workJourneyId: string;
  startedAt: string;
  enteredLunchAt: string;
  exitedLunchAt: string;
  finishedAt: string;
  observation?: string;
}

const updateWorkJourney = async ({
  enteredLunchAt,
  exitedLunchAt,
  finishedAt,
  startedAt,
  workJourneyId,
  observation,
}: IRequest): Promise<void> => {
  await axios.put(`/clockIn/updateWorkJourney/${workJourneyId}`, {
    enteredLunchAt,
    exitedLunchAt,
    finishedAt,
    startedAt,
    observation,
  });
};

export const useUpdateWorkJourney = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('workjourney');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workjourney');
      addNotification({
        type: 'success',
        title: 'Atualizado com sucesso',
      });
    },
    mutationFn: updateWorkJourney,
  });
};
