import { Link, Table } from '@/components';
import {
  Visibility,
  EditSharp,
  DeleteSharp,
  ChangeCircle,
} from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { Companies } from '..';
import { useDeleteCompany } from '../api';

interface DesktopTableProps {
  tableData: Companies[] | undefined;
  toggleEdit: (id: string) => void;
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function DesktopTable({
  tableData = [],
  toggleEdit,
  toggleDelete,
  loading,
}: DesktopTableProps) {
  const { mutateAsync } = useDeleteCompany();
  const activeCompany = (id: string) => {
    mutateAsync({ id });
  };

  return (
    <Table<Companies>
      columns={[
        { title: 'Nome', field: 'corporateName', colSpan: 2 },
        { title: 'Nome Fantasia', field: 'fantasyName', colSpan: 2 },
        { title: 'Ramo', field: 'industry', colSpan: 2 },
        { title: 'CNPJ', field: 'registeredNumber', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id, status } }) {
            return (
              <>
                <Tooltip title="visualizar">
                  <IconButton onClick={() => toggleEdit(id)}>
                    <Visibility sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>
                {status ? (
                  <>
                    <Link to={`./${id}`}>
                      <Tooltip title="Editar">
                        <IconButton>
                          <EditSharp sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Inativar">
                      <IconButton onClick={() => toggleDelete(id)}>
                        <DeleteSharp sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Reativar">
                    <IconButton onClick={() => activeCompany(id)}>
                      <ChangeCircle sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData.sort((a, b) =>
        a.corporateName.localeCompare(b.corporateName),
      )}
      loading={loading}
    />
  );
}
