/* eslint-disable no-nested-ternary */
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Car from '@/assets/img/car3d.png';
import { HighlightOff } from '@mui/icons-material';
import { driversProps } from '../types';

type IDriverInfo = {
  clickedDriver: driversProps;
  setClickedDriver: (mode: undefined) => void;
  mode: string;
};

export const DriverInfoDesktop = ({
  mode,
  clickedDriver,
  setClickedDriver,
}: IDriverInfo) => {
  const matches = useMediaQuery('(max-width:1070px)');

  if (mode !== 'hidden') {
    return <></>;
  }
  return (
    <>
      <Grid
        container
        sx={{
          position: 'absolute',
          bottom: matches ? '80px' : '80px',
          left: '50%',
          transform: matches ? 'translate(-48%)' : 'translate(-50%)',
          background: 'linear-gradient(180deg, #000B20 0%, #000000 68.75%)',
          boxShadow: ' 0px 5px 8px rgba(0, 0, 0, 0.25)',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          padding: '20px',
          width: matches ? '95%' : '70%',
          height: '274px',
          color: 'white',
        }}
      >
        <Grid
          item
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: '',
            height: '100%',
            paddingRight: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  background: 'lightblue',
                  width: '50px',
                  height: '50px',
                  position: 'relative',
                }}
              >
                {clickedDriver?.name.split(' ')[0][0]}
                {clickedDriver?.driverInProgress && clickedDriver?.started ? (
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      background: '#973C09',
                      position: 'absolute',
                      bottom: '5px',
                      right: '3px',
                      border: '2px solid #000309',
                      overflow: 'auto',
                    }}
                  />
                ) : clickedDriver?.driverInProgress &&
                  !clickedDriver?.started ? (
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      background: '#973C09',
                      position: 'absolute',
                      bottom: '5px',
                      right: '3px',
                      border: '2px solid #000309',
                      overflow: 'auto',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      background: '#2ED253',
                      position: 'absolute',
                      bottom: '5px',
                      right: '3px',
                      border: '2px solid #000309',
                      overflow: 'auto',
                    }}
                  />
                )}
              </Avatar>
            </Box>
            <Box
              sx={{
                width: '120px',
                height: '100px',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                }}
                src={Car}
                alt="Carro"
              />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                background: ' #EFEFEF',
                borderRadius: '15px',
                color: '#071D47',
                marginTop: '10px',
              }}
            >
              <Typography
                sx={{
                  height: '36px',
                  padding: '8px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                {clickedDriver?.name.split(' ')[0]}
              </Typography>
            </Box>
            <Box
              sx={{
                background: ' #EFEFEF',
                borderRadius: '15px',
                color: '#071D47',
                marginTop: '10px',
              }}
            >
              <Typography
                sx={{
                  height: '36px',
                  padding: '8px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                Placa: {clickedDriver?.plate}
              </Typography>
            </Box>

            <Box
              sx={{
                background: ' #EFEFEF',
                borderRadius: '15px',
                color: '#071D47',
                marginTop: '10px',
              }}
            >
              <Typography
                sx={{
                  height: '36px',
                  padding: '8px',
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                Status:{' '}
                {clickedDriver?.driverInProgress && clickedDriver?.started ? (
                  <Typography
                    sx={{
                      color: '#973C09',
                      marginLeft: '8px',
                      fontSize: '14px',
                    }}
                  >
                    Em progresso
                  </Typography>
                ) : clickedDriver?.driverInProgress &&
                  !clickedDriver?.started ? (
                  <Typography
                    sx={{
                      color: '#973C09',
                      marginLeft: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Em Progresso
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: '#2ED253',
                      marginLeft: '8px',
                      fontSize: '14px',
                    }}
                  >
                    Disponível
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: '80%',
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              Passageiro(s)
            </Typography>
            {clickedDriver?.users?.map(passenger => (
              <>
                <Typography
                  key={passenger.id}
                  sx={{ fontSize: '14px', marginTop: '10px' }}
                >
                  {passenger.name?.split(' ')[0] || '...'}
                </Typography>
              </>
            ))}
          </Box>
          <Box sx={{ height: '50px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              Destino
            </Typography>
            <Typography
              sx={{
                maxWidth: '200px',
                marginTop: '10px',
                fontSize: '14px',
                '& .fullAddress': {
                  display: 'none',
                },

                '&:hover .fullAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'block',
                },

                '&:hover .parcialAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'none',
                },
              }}
            >
              {!clickedDriver?.addressTo && '...'}
              <span className="fullAddress">{clickedDriver?.addressTo}</span>
              {clickedDriver?.addressTo?.length > 40 ? (
                <span className="parcialAddress">
                  {clickedDriver?.addressTo?.slice(0, 40)}...
                </span>
              ) : (
                <span className="parcialAddress">
                  {clickedDriver?.addressTo}
                </span>
              )}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: '80%',
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              Setor
            </Typography>
            <Typography sx={{ fontSize: '14px', marginTop: '10px' }}>
              {clickedDriver?.sector || '...'}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              Empresa
            </Typography>
            <Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
              {clickedDriver?.corporateName || '...'}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          md={3}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: '80%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'start',
              height: '100%',
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Horário previsto
              </Typography>
              <Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
                {clickedDriver?.estimatedDateToFinishTrip || '...'}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Hora da atualização
              </Typography>
              <Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
                {clickedDriver?.date}
              </Typography>
            </Box>
          </Box>

          <IconButton
            sx={{ padding: '0', color: 'white' }}
            onClick={() => setClickedDriver(undefined)}
          >
            <HighlightOff fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
