import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ICreateVehicleDTO, IVehicle } from '../types';

export async function registerVehicle(
  data: ICreateVehicleDTO,
): Promise<IVehicle> {
  return axios.post('vehicles', data);
}

export const useRegisterVehicle = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newDriver => {
      await queryClient.cancelQueries('vehicles');

      const previousDrivers = queryClient.getQueryData<IVehicle[]>('vehicles');

      queryClient.setQueryData('vehicles', [
        ...(previousDrivers || []),
        newDriver,
      ]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('vehicles', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('vehicles');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Placa cadastrada com sucesso.',
      });
      navigate(-1);
    },
    mutationFn: registerVehicle,
  });
};
