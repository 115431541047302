import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

type UseOperatorStatusOptions = {
  config?: MutationConfig<typeof changeOperatorStatus>;
};

export async function changeOperatorStatus(data: {id: string}): Promise<any> {
  return axios.put(`users/changeStatus/${data.id}`);
}

export const useChangeOperatorStatus = ({ config }: UseOperatorStatusOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('operators');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('operators', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('operators');
      if (data.data.payload.status === false) {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Operator inativado com sucesso.',
        });
      } else {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Operator reativado com sucesso.',
        });
      }
    },
    ...config,
    mutationFn: changeOperatorStatus,
  });
};
