import { Box, Typography } from '@mui/material';
import Logo from '@/assets/img/logo.png';

export const VoucherCard = ({
  name,
  status,
  width = '600px',
}: {
  name: string;
  status: string;
  width?: string;
}) => {
  return (
    <Box
      sx={{
        width: { width },
        height: '200px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        background:
          'linear-gradient(105.31deg, #02F7F1 0.9%, #2998FA 118.4%, #0D8EFF 205.99%)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        justifyContent: 'space-between',
        padding: '10px',
        alignItems: 'start',
        color: '#FFF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box>
          <img src={Logo} alt="logo" />
          <Typography sx={{ fontWeight: 'bold' }}>Voucher card</Typography>
        </Box>

        <Typography sx={{ fontWeight: 'bold', fontSize: '22px' }}>
          {status}
        </Typography>
      </Box>

      <Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Nome
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="h5">
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
