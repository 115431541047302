import { Box, Button, Typography } from '@mui/material';

export interface Props {
  onConfirm: () => void;
  onClose: () => void;
  price: number;
  isLoading: boolean;
}

export const RequestTripConfirmation = ({
  onConfirm,
  onClose,
  price,
  isLoading,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>Preço estimado: R$ {price.toFixed(2)}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button
          disabled={isLoading}
          sx={{ borderRadius: '16px' }}
          variant="contained"
          color="success"
          onClick={onConfirm}
        >
          Confirmar
        </Button>
        <Button
          disabled={isLoading}
          sx={{ borderRadius: '16px' }}
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};
