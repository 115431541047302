import { Table } from '@/components';
import { Pdf } from '@/utils/PDF';

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { IStatements } from '../types';

export const StatementTableContainer = ({ data }: { data: any }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Typography
        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
        variant={isMobile ? 'h6' : 'h4'}
      >
        Extrato
      </Typography>
      <>
        <Table<IStatements>
          data={data || []}
          rowsPerPage={isMobile ? 5 : 25}
          width={isMobile ? '50px' : '150px'}
          columns={[
            { title: 'Nome', field: 'name', colSpan: 1 },
            { title: 'Data', field: 'createdAt', colSpan: 1 },
            { title: 'Hora', field: 'hour', colSpan: 1 },
            { title: 'Motivo', field: 'reason', colSpan: 2, color: true },
            { title: 'Valor', field: 'value', colSpan: 1, color: true },
          ]}
        />
        {data && (
          <Box
            sx={{
              display: 'flex',
              padding: '10px 0',
            }}
          >
            <Button
              variant="contained"
              size={isMobile ? 'small' : 'medium'}
              type="button"
              color="error"
              onClick={() =>
                Pdf({
                  data,
                  headers: [
                    { label: 'Nome', key: 'name' },
                    { label: 'Data', key: 'createdAt' },
                    { label: 'Hora', key: 'hour' },
                    {
                      label: 'Motivo',
                      key: 'reason',
                    },
                    { label: 'Valor', key: 'value' },
                  ],
                })
              }
            >
              Gerar PDF
            </Button>

            <CSVLink
              style={{ textDecoration: 'none', marginLeft: '10px' }}
              headers={[
                { label: 'Nome', key: 'name' },
                { label: 'Data', key: 'createdAt' },
                { label: 'Hora', key: 'hour' },
                {
                  label: 'Motivo',
                  key: 'reason',
                },
                { label: 'Valor', key: 'value' },
              ]}
              data={data}
            >
              <Button
                size={isMobile ? 'small' : 'medium'}
                variant="contained"
                type="button"
                color="success"
              >
                Gerar Excel
              </Button>
            </CSVLink>
          </Box>
        )}
      </>
    </>
  );
};
