import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

interface IRequest {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  city?: string;
}

interface IOutput extends IRequest {
  id: string;
  userId: string;
}

export const addFavoriteAddress = (data: IRequest): Promise<IOutput> => {
  return axios.post(`favoriteAdresses/addFavorite`, data);
};

type UseAddAddressConfig = {
  config?: MutationConfig<typeof addFavoriteAddress>;
};

export const useAddFavoriteAddress = ({ config }: UseAddAddressConfig = {}) => {
  return useMutation({
    ...config,
    onMutate: async data => {
      queryClient.cancelQueries('favoriteAdresses');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('favoriteAdresses');
    },
    mutationFn: addFavoriteAddress,
  });
};
