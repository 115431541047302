import { DriversValues } from '@/features/drivers';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { IChatMessage } from '../types';

interface IOutput {
  driver: DriversValues;
  lastMessage: IChatMessage;
}

export async function listRentersConversations(): Promise<IOutput[]> {
  const response = await axios.get('chat/listRentersConversations');
  return response.data.payload;
}

type UseConversationOptions = {
  config?: QueryConfig<typeof listRentersConversations>;
};

export const useRentersConversations = ({
  config,
}: UseConversationOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['rentersConversations'],
    enabled: user?.user?.accessType === 'renter',
    queryFn: () => listRentersConversations(),
    ...config,
  });
};