import { ConfirmModal, InputField } from '@/components';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { useDriversPlateList } from '@/features/drivers';
import { useAuth } from '@/providers/auth';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IVehicleClass, useListClasses } from '@/features/vehicleClasses';
import { CheckOutlined } from '@mui/icons-material';
import { possibleIcons } from '@/utils/vehicleIcons';
import { IVehicle } from '../types';
import { useUpdateVehicle, useVehicleCategories } from '../api';

interface IRequest {
  vehicle: IVehicle;
}

const schema = z.object({
  licensePlate: z.string({ required_error: 'Campo obrigatório' }),
  model: z.string({ required_error: 'Campo obrigatório' }),
  vehicleCategoryId: z.string({ required_error: 'Campo obrigatório' }),
  vehicleIcon: z.string().optional().nullable(),
});

export const UpdateVehicleComponent = ({ vehicle }: IRequest) => {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const hasTracker = !!user.user.company.trackerId;
  const [writeLicensePlate, setWriteLicensePlate] = useState(false);
  const { data: plates } = useDriversPlateList();
  const { data: categories } = useVehicleCategories();
  const { data: classes, isLoading: isLoadingClasses } = useListClasses();
  const { mutateAsync, isLoading } = useUpdateVehicle();
  const [selectedClasses, setSelectedClasses] = useState<string[]>(
    vehicle.vehicleClasses?.map(item => item.classId) || [],
  );

  const handleChange = (roleId: string) => {
    setSelectedClasses(prevClasses => {
      if (prevClasses.includes(roleId)) {
        return prevClasses.filter(data => data !== roleId);
      }
      return [...prevClasses, roleId];
    });
  };
  const preloadedValues = {
    licensePlate: vehicle.licensePlate,
    model: vehicle.model,
    vehicleCategoryId: vehicle.vehicleCategoryId,
    vehicleIcon: vehicle.vehicleIcon,
    id: vehicle.id,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: {
    licensePlate?: string;
    model?: string;
    vehicleCategoryId?: string;
    vehicleIcon?: string;
  }) => {
    mutateAsync({
      id: vehicle.id,
      licensePlate: values.licensePlate,
      model: values.model,
      vehicleCategoryId: values.vehicleCategoryId,
      vehicleIcon: values.vehicleIcon,
      classesId: selectedClasses,
    });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <ConfirmModal
        close={closeDirtyFormModal}
        isOpen={isFormDirty}
        description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
        handleConfirmation={() => navigate(-1)}
      />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 40 }}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
          >
            Editando veículo
          </Typography>
          <Controller
            name="model"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                size="small"
                name="model"
                type="text"
                placeholder="Citroen C3"
                label="Modelo do carro"
                onChange={onChange}
                value={value}
                errorMessage={formState.errors.model?.message}
                error={!!formState.errors.model}
              />
            )}
          />
          <>
            {hasTracker && !writeLicensePlate ? (
              <Controller
                name="licensePlate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="licensePlate"
                    type="text"
                    select={hasTracker}
                    label="Placa"
                    onChange={onChange}
                    value={value}
                    errorMessage={formState.errors.licensePlate?.message}
                    error={!!formState.errors.licensePlate}
                  >
                    {vehicle.licensePlate && (
                      <MenuItem value={vehicle.licensePlate}>
                        {vehicle.licensePlate}
                      </MenuItem>
                    )}
                    {plates?.length > 0 &&
                      plates !== 'Não houve resultados!' &&
                      plates.map((item: any, key: any) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={key} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </InputField>
                )}
              />
            ) : (
              <Controller
                name="licensePlate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="licensePlate"
                    type="text"
                    label="Placa"
                    onChange={onChange}
                    value={value}
                    errorMessage={formState.errors.licensePlate?.message}
                    error={!!formState.errors.licensePlate}
                  />
                )}
              />
            )}
          </>
          {hasTracker && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: '15px',
              }}
            >
              <FormControlLabel
                sx={{
                  width: '100%',
                  color: 'gray',
                }}
                control={
                  <Checkbox
                    onChange={() => setWriteLicensePlate(prev => !prev)}
                    checked={writeLicensePlate}
                  />
                }
                label="Cadastrar placa manualmente"
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Controller
              name="vehicleCategoryId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  size="small"
                  name="vehicleCategoryId"
                  type="text"
                  select
                  label="Categoria do veículo"
                  onChange={onChange}
                  value={value}
                  errorMessage={formState.errors.vehicleCategoryId?.message}
                  error={!!formState.errors.vehicleCategoryId}
                >
                  {categories &&
                    categories?.length > 0 &&
                    categories.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.category.toUpperCase()}
                      </MenuItem>
                    ))}
                </InputField>
              )}
            />
            <Controller
              name="vehicleIcon"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  style={{ width: '300px' }}
                  size="small"
                  name="vehicleIcon"
                  select
                  label="Ícone do veículo"
                  onChange={onChange}
                  value={value}
                  error={!!formState.errors.vehicleIcon}
                  errorMessage={formState.errors.vehicleIcon?.message}
                >
                  {possibleIcons &&
                    possibleIcons?.length > 0 &&
                    possibleIcons.map(item => (
                      <MenuItem
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                        key={item.name}
                        value={item.name}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <Typography>{item.name}</Typography>
                          <img
                            style={{
                              display: 'flex',
                              height: '35px',
                              width: '35px',
                            }}
                            alt={item.name}
                            src={item.icon}
                          />
                        </Box>
                      </MenuItem>
                    ))}
                </InputField>
              )}
            />
          </Box>
          <FormGroup>
            {isLoadingClasses ? (
              <Box>Carregando</Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  margin: '10px 0px',
                  flexWrap: 'wrap',
                  gap: '15px',
                  justifyContent: 'center',
                }}
              >
                {classes?.map((vehicleClass: IVehicleClass) => (
                  <Box
                    key={vehicleClass.id}
                    sx={{
                      padding: '4px 15px',
                      borderRadius: '10px',
                      border:
                        theme.palette.mode === 'dark'
                          ? '1px solid #56acee'
                          : '1px solid #0b7ed4',
                      background: selectedClasses.includes(vehicleClass.id)
                        ? '#0b7ed4'
                        : 'transparent',
                      color: selectedClasses.includes(vehicleClass.id)
                        ? 'white'
                        : '#0b7ed4',
                      display: 'flex',
                      gap: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleChange(vehicleClass.id)}
                  >
                    {vehicleClass.name}
                    {selectedClasses.includes(vehicleClass.id) && (
                      <CheckOutlined />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </FormGroup>

          <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Button
              sx={{ width: '200px' }}
              variant="contained"
              color="success"
              type="submit"
            >
              {isLoading ? 'carregando...' : 'Salvar'}
            </Button>
            <Button
              onClick={() => handleGoBack()}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
