import { Link, Table } from '@/components';
import { Visibility, EditSharp, DeleteSharp } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { Companies } from '..';

interface MobileTableProps {
  tableData: Companies[] | undefined;
  toggleEdit: (id: string) => void;
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function MobileTable({
  tableData = [],
  toggleEdit,
  toggleDelete,
  loading,
}: MobileTableProps) {
  return (
    <Table<Companies>
      columns={[
        { title: 'Nome', field: 'corporateName', colSpan: 1 },
        { title: 'Nome Fantasia', field: 'fantasyName', colSpan: 1 },
        { title: 'Ramo', field: 'industry', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id } }) {
            return (
              <>
                <Tooltip title="visualizar">
                  <IconButton onClick={() => toggleEdit(id)}>
                    <Visibility sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>
                <Link to={`./${id}`}>
                  <Tooltip title="Editar">
                    <IconButton>
                      <EditSharp sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Link>

                <Tooltip title="Inativar">
                  <IconButton onClick={() => toggleDelete(id)}>
                    <DeleteSharp sx={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      ]}
      data={tableData.sort((a, b) =>
        a.corporateName.localeCompare(b.corporateName),
      )}
      loading={loading}
    />
  );
}
