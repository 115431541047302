import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { changeTripsStatusResponse } from '../types';

type UseAddAdditionalValueOptions = {
  config?: MutationConfig<typeof updateAdditionalValue>;
};

interface IRequest {
  id: string;
  price: string;
  type: string;
  observation: string;
  urlImage?: File | null;
  hasImageChanged: boolean;
}

export async function updateAdditionalValue(
  data: IRequest,
): Promise<changeTripsStatusResponse> {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return axios.put(`trips/updateAttachedFile/${data.id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const useUpdateAdditionalValue = ({
  config,
}: UseAddAdditionalValueOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('trips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
      queryClient.invalidateQueries('trips');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Atualizado com sucesso.',
      });
    },
    ...config,
    mutationFn: updateAdditionalValue,
  });
};
