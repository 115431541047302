import { useTheme } from '@mui/material';

export const Steps = () => {
  const theme = useTheme();
  const steps = [
    {
      selector: '[data-tut="reactour__automaticRedirectionButton"]',
      content: `Ao habilitar esse botão, as viagens serão automaticamente redirecionadas ao motoristas`,
      style: {
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : '',
        color: theme.palette.mode === 'dark' ? 'black' : '',
      },
    },
    {
      selector: '[data-tut="reactour__slider"]',
      content: `Selecione a distância máxima que o sistema buscará um motorista disponível`,
      style: {
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : '',
        color: theme.palette.mode === 'dark' ? 'black' : '',
      },
    },
    {
      selector: '[data-tut="reactour__ClosestDrivers"]',
      content: `Ao selecionar esse botão, buscará os motoristas com a distância mais próxima`,
      style: {
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : '',
        color: theme.palette.mode === 'dark' ? 'black' : '',
      },
    },
    {
      selector: '[data-tut="reactour__IdleDrivers"]',
      content: `Ao selecionar esse botão, buscará os motoristas mais ociosos`,
      style: {
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : '',
        color: theme.palette.mode === 'dark' ? 'black' : '',
      },
    },
  ];

  return {
    steps,
  };
};
