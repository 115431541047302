import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

interface IFixedPrice {
  id: string;
  addressFrom: string;
  addressTo: string;
  distance: number;
  price: number;
  corporateName: string;
}

// eslint-disable-next-line consistent-return
export async function getFixedPrice(id: string): Promise<IFixedPrice> {
  const response = await axios.get(`priceTable/get/${id}`);
  return response.data.payload;
}

export const useGetFixedPrice = (id: string) => {
  return useQuery({
    queryKey: ['prices', id],
    enabled: !!id,
    queryFn: () => getFixedPrice(id),
  });
};
