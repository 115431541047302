import { Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { DefaultPriceDetail } from './DefaultPriceDetail';
import { TaggedPriceDetail } from './TaggedPriceDetail';
import { TablePriceDetail } from './TablePriceDetail';
import { PriceDetailsPDF } from './PriceDetailsPDF';
import { TaggedPriceDetailPDF } from './TaggedPriceDetailPDF';
import { TablePriceDetailPDF } from './TablePriceDetailPDF';

export interface IPriceDetais {
  id: string;
  price: number;
  pricePerKm: number;
  otherFees: number;
  totalWaitingTime: number;
  distance: number;
  updatedAt: Date;
  createdAt: Date;
  multiply: number;
  initialPrice: number;
  waitingTimePrice: number;
  extraDistance?: number;
  vehicleClassPrice?: number;
  vehicleClassPercentage?: number;
  payment?: string;
  tripId: string;
  priceInTheCellPhone?: number;
  distanceInTheCellPhone?: number;
  estimatedDistance: number;
  companyTablePrice?: number;
  waitingTimeBeforeStart?: number;
  waitingTimeAfterStart?: number;
  pricePerMinute?: number;
  pricePerMinuteBeforeStart?: number;
  tag?: string;
  taggedPrice?: number;
  timeToWaitBeforeStartBillingWhenTheTripIsStarted?: number;
  timeToWaitBeforeStartBillingWhenTheTripIsNotStarted?: number;
  nightAdditional?: number;
  hasNightAdditional?: boolean;
  stopPoints?: any[];
  trip: {
    id: string;
    tripAttachments: {
      fileUrl?: string;
      id: string;
      observation: string;
      price: string;
      type: string;
    }[];
  };
}

interface IProps {
  data: IPriceDetais | null;
}

export const PriceDetails = ({ data }: IProps) => {
  const hasNightAdditional = data?.hasNightAdditional || false;
  const nightAdditional = data?.nightAdditional || 0;
  const vehicleClassPrice = data?.vehicleClassPrice || 0;
  const vehicleClassPercentage = data?.vehicleClassPercentage;
  const initialPrice = data?.initialPrice || 0;
  const distance = data?.distance || 0;
  const pricePerKm = data?.pricePerKm || 0;
  const multiply = data?.multiply || 1;
  const kmPrice = distance * pricePerKm * multiply || 0;
  const totalDistancePrice = (
    kmPrice +
    (hasNightAdditional ? kmPrice * (nightAdditional / 100) : 0) +
    vehicleClassPrice +
    (kmPrice * (vehicleClassPercentage || 0)) / 100
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const taggedPrice = data?.taggedPrice || 0;
  const totalTaggedPrice = (
    taggedPrice +
    (hasNightAdditional ? taggedPrice * (nightAdditional / 100) : 0) +
    vehicleClassPrice +
    (taggedPrice * (vehicleClassPercentage || 0)) / 100
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const extraKmPrice = (data?.extraDistance || 0) * pricePerKm;

  const totalExtraKmPriceValue =
    extraKmPrice +
    (hasNightAdditional ? extraKmPrice * (nightAdditional / 100) : 0) +
    vehicleClassPrice +
    (extraKmPrice * (vehicleClassPercentage || 0)) / 100;

  const totalExtraKmPrice = totalExtraKmPriceValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const priceTablePrice = data?.companyTablePrice || 0;

  const totalTablePrice = (
    totalExtraKmPriceValue +
    priceTablePrice +
    (hasNightAdditional ? priceTablePrice * (nightAdditional / 100) : 0) +
    vehicleClassPrice +
    (priceTablePrice * (vehicleClassPercentage || 0)) / 100
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const waitingTimePrice = data?.waitingTimePrice || 0;
  const totalWaitingTime = data?.totalWaitingTime || 0;
  const formattedPricePerKm = pricePerKm.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const formattedInitialPrice = initialPrice.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const totalWaitingPrice = Number(waitingTimePrice.toFixed(2)).toLocaleString(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
    },
  );
  const va = (data?.otherFees || 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const tripPrice = (data?.price || 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const handlePickPDF = () => {
    if (data?.stopPoints?.length) {
      return (
        <PriceDetailsPDF
          distance={distance}
          formattedInitialPrice={formattedInitialPrice}
          formattedPricePerKm={formattedPricePerKm}
          multiply={multiply}
          hasNightAdditional={hasNightAdditional}
          nightAdditional={nightAdditional}
          otherFees={data?.otherFees || 0}
          totalDistancePrice={totalDistancePrice}
          totalWaitingPrice={totalWaitingPrice}
          totalWaitingTime={totalWaitingTime}
          tripAttachments={data?.trip?.tripAttachments || []}
          tripPrice={tripPrice}
          va={va}
          vehicleClassPercentage={vehicleClassPercentage || 0}
          vehicleClassPrice={vehicleClassPrice}
          waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
          waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
          waitingTimePrice={waitingTimePrice}
        />
      );
    }

    if (
      data?.payment === 'Tabelado' &&
      data?.companyTablePrice &&
      data?.companyTablePrice > 0
    ) {
      return (
        <TablePriceDetailPDF
          companyTablePrice={data?.companyTablePrice || 0}
          formattedInitialPrice={formattedInitialPrice}
          formattedPricePerKm={formattedPricePerKm}
          hasNightAdditional={hasNightAdditional}
          multiply={multiply}
          nightAdditional={nightAdditional}
          otherFees={data?.otherFees || 0}
          totalTablePrice={totalTablePrice}
          totalWaitingPrice={totalWaitingPrice}
          totalWaitingTime={totalWaitingTime}
          tripAttachments={data?.trip?.tripAttachments || []}
          tripPrice={tripPrice}
          totalExtraKmPrice={totalExtraKmPrice}
          va={va}
          extraDistance={data?.extraDistance || 0}
          vehicleClassPercentage={vehicleClassPercentage || 0}
          vehicleClassPrice={vehicleClassPrice}
          waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
          waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
          waitingTimePrice={waitingTimePrice}
        />
      );
    }

    if (data?.payment === 'Tarifado' && data?.tag) {
      return (
        <TaggedPriceDetailPDF
          formattedInitialPrice={formattedInitialPrice}
          hasNightAdditional={hasNightAdditional}
          multiply={multiply}
          nightAdditional={nightAdditional}
          otherFees={data?.otherFees || 0}
          totalTaggedPrice={totalTaggedPrice}
          totalWaitingPrice={totalWaitingPrice}
          totalWaitingTime={totalWaitingTime}
          tripAttachments={data?.trip?.tripAttachments || []}
          tripPrice={tripPrice}
          va={va}
          vehicleClassPercentage={vehicleClassPercentage || 0}
          vehicleClassPrice={vehicleClassPrice}
          waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
          waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
          waitingTimePrice={waitingTimePrice}
          tag={data?.tag}
          taggedPrice={data?.taggedPrice || 0}
        />
      );
    }

    return (
      <PriceDetailsPDF
        distance={distance}
        formattedInitialPrice={formattedInitialPrice}
        formattedPricePerKm={formattedPricePerKm}
        multiply={multiply}
        hasNightAdditional={hasNightAdditional}
        nightAdditional={nightAdditional}
        otherFees={data?.otherFees || 0}
        totalDistancePrice={totalDistancePrice}
        totalWaitingPrice={totalWaitingPrice}
        totalWaitingTime={totalWaitingTime}
        tripAttachments={data?.trip?.tripAttachments || []}
        tripPrice={tripPrice}
        va={va}
        vehicleClassPercentage={vehicleClassPercentage || 0}
        vehicleClassPrice={vehicleClassPrice}
        waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
        waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
        waitingTimePrice={waitingTimePrice}
      />
    );
  };

  const handleGeneratePDF = async () => {
    // const doc = handlePickPDF();
    // const asPdf = pdf();
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, 'document.pdf');
  };

  const detailsRender = () => {
    if (data?.stopPoints?.length) {
      return (
        <DefaultPriceDetail
          distance={distance}
          formattedInitialPrice={formattedInitialPrice}
          formattedPricePerKm={formattedPricePerKm}
          multiply={multiply}
          hasNightAdditional={hasNightAdditional}
          nightAdditional={nightAdditional}
          otherFees={data?.otherFees || 0}
          totalDistancePrice={totalDistancePrice}
          totalWaitingPrice={totalWaitingPrice}
          totalWaitingTime={totalWaitingTime}
          tripAttachments={data?.trip?.tripAttachments || []}
          tripPrice={tripPrice}
          va={va}
          vehicleClassPercentage={vehicleClassPercentage || 0}
          vehicleClassPrice={vehicleClassPrice}
          waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
          waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
          waitingTimePrice={waitingTimePrice}
        />
      );
    }
    switch (data?.payment) {
      case 'Tabelado': {
        if (data?.companyTablePrice && data?.companyTablePrice > 0) {
          return (
            <TablePriceDetail
              companyTablePrice={data?.companyTablePrice || 0}
              formattedInitialPrice={formattedInitialPrice}
              formattedPricePerKm={formattedPricePerKm}
              hasNightAdditional={hasNightAdditional}
              multiply={multiply}
              nightAdditional={nightAdditional}
              otherFees={data?.otherFees || 0}
              totalTablePrice={totalTablePrice}
              totalWaitingPrice={totalWaitingPrice}
              totalWaitingTime={totalWaitingTime}
              tripAttachments={data?.trip?.tripAttachments || []}
              tripPrice={tripPrice}
              totalExtraKmPrice={totalExtraKmPrice}
              va={va}
              extraDistance={data?.extraDistance || 0}
              vehicleClassPercentage={vehicleClassPercentage || 0}
              vehicleClassPrice={vehicleClassPrice}
              waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
              waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
              waitingTimePrice={waitingTimePrice}
            />
          );
        }
        return (
          <>
            <DefaultPriceDetail
              distance={distance}
              formattedInitialPrice={formattedInitialPrice}
              formattedPricePerKm={formattedPricePerKm}
              hasNightAdditional={hasNightAdditional}
              multiply={multiply}
              nightAdditional={nightAdditional}
              otherFees={data?.otherFees || 0}
              totalDistancePrice={totalDistancePrice}
              totalWaitingPrice={totalWaitingPrice}
              totalWaitingTime={totalWaitingTime}
              tripAttachments={data?.trip?.tripAttachments || []}
              tripPrice={tripPrice}
              va={va}
              vehicleClassPercentage={vehicleClassPercentage || 0}
              vehicleClassPrice={vehicleClassPrice}
              waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
              waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
              waitingTimePrice={waitingTimePrice}
            />
            <Typography sx={{ fontWeight: 'bold' }}>
              Não foi possível calcular com base no preço tabelado, então
              utilizou-se o preço por km.
            </Typography>
          </>
        );
      }
      case 'Tarifado': {
        if (data?.tag) {
          return (
            <>
              <TaggedPriceDetail
                formattedInitialPrice={formattedInitialPrice}
                hasNightAdditional={hasNightAdditional}
                multiply={multiply}
                nightAdditional={nightAdditional}
                otherFees={data?.otherFees || 0}
                totalTaggedPrice={totalTaggedPrice}
                totalWaitingPrice={totalWaitingPrice}
                totalWaitingTime={totalWaitingTime}
                tripAttachments={data?.trip?.tripAttachments || []}
                tripPrice={tripPrice}
                va={va}
                vehicleClassPercentage={vehicleClassPercentage || 0}
                vehicleClassPrice={vehicleClassPrice}
                waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
                waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
                waitingTimePrice={waitingTimePrice}
                tag={data?.tag}
                taggedPrice={data?.taggedPrice || 0}
              />
            </>
          );
        }
        return (
          <>
            <DefaultPriceDetail
              distance={distance}
              formattedInitialPrice={formattedInitialPrice}
              formattedPricePerKm={formattedPricePerKm}
              hasNightAdditional={hasNightAdditional}
              multiply={multiply}
              nightAdditional={nightAdditional}
              otherFees={data?.otherFees || 0}
              totalDistancePrice={totalDistancePrice}
              totalWaitingPrice={totalWaitingPrice}
              totalWaitingTime={totalWaitingTime}
              tripAttachments={data?.trip?.tripAttachments || []}
              tripPrice={tripPrice}
              va={va}
              vehicleClassPercentage={vehicleClassPercentage || 0}
              vehicleClassPrice={vehicleClassPrice}
              waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
              waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
              waitingTimePrice={waitingTimePrice}
            />
            <Typography sx={{ fontWeight: 'bold' }}>
              Não foi possível calcular com base no preço tarifado, então
              utilizou-se o preço por km.
            </Typography>
          </>
        );
      }
      default: {
        return (
          <DefaultPriceDetail
            distance={distance}
            formattedInitialPrice={formattedInitialPrice}
            formattedPricePerKm={formattedPricePerKm}
            hasNightAdditional={hasNightAdditional}
            multiply={multiply}
            nightAdditional={nightAdditional}
            otherFees={data?.otherFees || 0}
            totalDistancePrice={totalDistancePrice}
            totalWaitingPrice={totalWaitingPrice}
            totalWaitingTime={totalWaitingTime}
            tripAttachments={data?.trip?.tripAttachments || []}
            tripPrice={tripPrice}
            va={va}
            vehicleClassPercentage={vehicleClassPercentage || 0}
            vehicleClassPrice={vehicleClassPrice}
            waitingTimeAfterStart={data?.waitingTimeAfterStart || 0}
            waitingTimeBeforeStart={data?.waitingTimeBeforeStart || 0}
            waitingTimePrice={waitingTimePrice}
          />
        );
      }
    }
  };

  return <>{detailsRender()}</>;
};
