/* eslint-disable no-nested-ternary */
import {
  Radio,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  useTheme,
  Box,
  FormGroup,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { VscCircleFilled } from 'react-icons/vsc';
import car from '@/assets/img/acceptTripCar.png';
import { format } from 'date-fns';
import { Search } from '@mui/icons-material';
import { useState } from 'react';
import { ISelectDriverDTO } from '../../types';

type IInterface = {
  requestedVehicle?: string;
  data: ISelectDriverDTO[];
  setSelectedDriverId: (id: string) => void;
  onlineDriversId: any[];
  selectedDriverId: string;
};

export const AcceptTripDesktopTable = ({
  data,
  setSelectedDriverId,
  onlineDriversId,
  selectedDriverId,
  requestedVehicle,
}: IInterface) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearch(e.target.value);
  };
  const sortedData = [...data]
    ?.filter(driver =>
      Object.values(driver).some(
        value =>
          typeof value === 'string' &&
          value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    )
    ?.sort((a, b) => {
      if (
        onlineDriversId &&
        onlineDriversId.includes &&
        onlineDriversId.includes(a.id) &&
        (!onlineDriversId.includes || !onlineDriversId.includes(b.id))
      ) {
        return -1;
      }
      if (
        onlineDriversId &&
        onlineDriversId.includes &&
        !onlineDriversId.includes(a.id) &&
        onlineDriversId.includes(b.id)
      ) {
        return 1;
      }
      return 0;
    });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormGroup row>
        <FormControl>
          <OutlinedInput
            margin="dense"
            id="searchTerms"
            name="searchTerms"
            type="search"
            size="small"
            placeholder="Pesquisar..."
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      </FormGroup>
      <Table
        sx={{
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 25px',
            }}
          >
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{ border: 'none' }}>Nome</TableCell>
            <TableCell sx={{ border: 'none' }}>Tipo do veículo</TableCell>
            <TableCell sx={{ border: 'none' }}>Distância</TableCell>
            <TableCell sx={{ border: 'none' }}>Última viagem</TableCell>
            <TableCell sx={{ border: 'none' }}>Status</TableCell>
          </TableRow>
          <TableBody>
            <div
              style={{
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              {sortedData
                ?.filter(driver => driver.licensePlate !== '')
                .map(i => {
                  return (
                    <TableRow
                      onClick={() => {
                        setSelectedDriverId(i.id);
                      }}
                      sx={{
                        border: '1px solid transparent',
                        cursor: 'pointer',
                        '&:hover': {
                          '& td': {
                            transition: 'ease-in-out 0.5s',
                            fontWeight: 'bold',
                            color: '#5C5C5C',
                            background: '#BBD7FF',
                          },
                        },
                        '& td': {
                          marginTop: '5px',
                          fontWeight: 'bold',
                          background:
                            theme.palette.mode === 'dark'
                              ? '#474747'
                              : '#FFFFFF',
                          color:
                            theme.palette.mode === 'dark'
                              ? '#D8D8D8'
                              : '#5C5C5C',
                        },

                        '& td:first-child': {
                          borderTopLeftRadius: '18px',
                          borderBottomLeftRadius: '18px',
                        },
                        '& td:last-child': {
                          borderTopRightRadius: '18px',
                          borderBottomRightRadius: '18px',
                        },
                      }}
                      key={i.id}
                    >
                      <TableCell scope="row">
                        <Radio
                          sx={{ color: '#848484', marginLeft: '-15px' }}
                          checked={selectedDriverId === i.id}
                          value={i.id}
                          name="radio-buttons"
                        />
                      </TableCell>
                      <TableCell>
                        {i.urlImage ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '70px',
                                height: '70px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                position: 'relative',
                              }}
                            >
                              <img
                                src={i.urlImage}
                                alt="foto motorista"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <img
                            style={{ marginRight: '8px' }}
                            alt="Carro"
                            src={car}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>{i.name}</TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        <span
                          style={{
                            color: requestedVehicle
                              ? requestedVehicle === i.vehicleCategoryName
                                ? 'green'
                                : 'red'
                              : '',
                          }}
                        >
                          {i.vehicleCategoryName}
                        </span>
                      </TableCell>
                      <TableCell sx={{ width: '135px' }}>
                        {i.distance >= 0 ? `${i.distance} KM` : ''}
                      </TableCell>
                      <TableCell sx={{ width: '135px' }}>
                        {i.tripFinalDate &&
                          format(
                            new Date(i.tripFinalDate),
                            'dd/MM/yyyy H:mm:ss',
                          )}
                      </TableCell>
                      {onlineDriversId?.includes(i.id) ? (
                        <TableCell>
                          <VscCircleFilled color="green" />
                          Online
                        </TableCell>
                      ) : (
                        <TableCell>
                          <VscCircleFilled color="red" />
                          Offline
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </div>
          </TableBody>
        </TableHead>
      </Table>
    </Box>
  );
};
