import { PageLayout } from '@/components';
import { Paid } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { useCustomTheme } from '@/providers/theme';
import { Statement as StatementComponent } from '../components';

export function Statement() {
  const { subTitle } = useCustomTheme();
  const theme = useTheme();
  return (
    <>
      <PageLayout
        title="Extrato"
        subTitle={subTitle}
        icon={<Paid fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <Box sx={{ background: theme.palette.background.default }}>
          <StatementComponent />
        </Box>
      </PageLayout>
    </>
  );
}
