import { PageLayout } from '@/components';
import { Sell } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { TagListTable } from '../components/TagListTable';

export function TagList() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Tarifas"
        subTitle={subTitle}
        icon={<Sell fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <TagListTable />
      </PageLayout>
    </>
  );
}
