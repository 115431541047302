import { PageLayout } from '@/components';
import { Home } from '@/features/home/routes/home';
import { useAuth } from '@/providers/auth';
import PieChartIcon from '@mui/icons-material/PieChart';
import { useCustomTheme } from '@/providers/theme';
import { DashBoard as DashBoardComponent } from '../index';
import { HomeSector } from '../components/HomeSector';

export function DashBoard() {
  const { subTitle } = useCustomTheme();
  const { user } = useAuth();
  const { accessType } = user.user;

  if (accessType === 'company_admin' || accessType === 'sector_admin') {
    return <HomeSector />;
  }
  return (
    <>
      {(user.user.accessType === 'renter' &&
        user.user.profile.find(data => data.name === 'dashboard')) ||
      user.user.accessType === 'master' ? (
        <PageLayout
          title={subTitle}
          subTitle="Dashboard"
          icon={<PieChartIcon fontSize="large" style={{ color: '#0ebcdf' }} />}
        >
          <DashBoardComponent />
        </PageLayout>
      ) : (
        <Home />
      )}
    </>
  );
}
