import { PageLayout } from '@/components';
import { DirectionsCarFilled } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { ListVehicleClassesComponent } from '../components';

export function ListVehicleClasses() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Classes de veículos"
        subTitle={subTitle}
        icon={
          <DirectionsCarFilled fontSize="large" style={{ color: '#0ebcdf' }} />
        }
      >
        <ListVehicleClassesComponent />
      </PageLayout>
    </>
  );
}
