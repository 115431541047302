import { Form, InputField } from '@/components';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ICreateVehicleClassDTO } from '../types';
import { useRegisterClass } from '../api';

const schema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  initialPrice: z.number({ required_error: 'Campo obrigatório' }),
  additionalFeePercentage: z.number({ required_error: 'Campo obrigatório' }),
});

export const CreateVehicleClassComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync, isLoading } = useRegisterClass();
  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<ICreateVehicleClassDTO, typeof schema>
          id="create-vehicle-class"
          onSubmit={values => {
            mutateAsync(values);
          }}
          schema={schema}
          options={{
            defaultValues: {
              name: '',
              initialPrice: 0,
              additionalFeePercentage: 0,
            },
          }}
        >
          {({ formState, control }) => (
            <>
              <Typography
                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
              >
                Cadastro de classe
              </Typography>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="name"
                      type="text"
                      label="Classe"
                      value={value}
                      onChange={onChange}
                      placeholder="Executivo"
                      error={!!formState.errors.name?.message}
                      errorMessage={formState.errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="initialPrice"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                        inputProps: { min: 0, step: 0.01 },
                      }}
                      size="small"
                      name="initialPrice"
                      type="number"
                      label="Preço adicional"
                      value={Number(value) || null}
                      onChange={e => onChange(Number(e.target.value))}
                      placeholder="0"
                      errorMessage={formState.errors.initialPrice?.message}
                      error={!!formState.errors.initialPrice}
                    />
                  )}
                />
                <Controller
                  name="additionalFeePercentage"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0, step: 0.01 },
                      }}
                      size="small"
                      name="additionalFeePercentage"
                      type="number"
                      label="Porcentagem de taxa adicional"
                      value={Number(value) || null}
                      onChange={e => onChange(Number(e.target.value))}
                      placeholder="0"
                      errorMessage={
                        formState.errors.additionalFeePercentage?.message
                      }
                      error={!!formState.errors.additionalFeePercentage}
                    />
                  )}
                />
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Carregando' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
