import { useUpdateCompanyAutomaticRedirection } from '@/features/companyNew';
import { useAuth } from '@/providers/auth';
import { useNotificationStore } from '@/stores';
import { FormControlLabel, Switch } from '@mui/material';
import { useCallback } from 'react';

type Props = {
  mode: boolean;
  isLoading: boolean;
  openTripsData: number;
};

export const RedirectButton = ({ openTripsData, mode, isLoading }: Props) => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();

  const {
    mutateAsync: mutateRedirectionMode,
    isLoading: isLoadingRedirectionMode,
  } = useUpdateCompanyAutomaticRedirection();

  const handleToggleMode = useCallback(
    data => {
      if (!mode && openTripsData > 0) {
        addNotification({
          type: 'warning',
          title: 'Você ainda possui corridas pendentes',
        });
      } else mutateRedirectionMode(data);
    },
    [addNotification, mutateRedirectionMode, openTripsData, mode],
  );

  return (
    <FormControlLabel
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}
      control={
        <Switch
          disabled={isLoadingRedirectionMode || isLoading}
          checked={mode}
          onChange={() => handleToggleMode(user.user.companyId)}
          name="Busca automática de motorista"
          color="primary"
        />
      }
      label="Busca automática de motorista"
    />
  );
};
