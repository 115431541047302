import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { loadOpenTripsResponse } from '../types';

interface IRequest {
  initialDate?: string;
  finalDate?: string;
  status?: string[];
  type?: 'recurring' | 'requests';
}

// eslint-disable-next-line consistent-return
export async function getSectorNotFinishedTrips({
  finalDate,
  initialDate,
  status,
  type,
}: IRequest): Promise<loadOpenTripsResponse[]> {
  const response = await axios.get(
    `trips/sectorTripsStatus?type=${type}&initialDate=${initialDate}&finalDate=${finalDate}&status=${status}`,
  );
  return response.data.payload;
}

export const useGetSectorNotFinishedTrips = ({
  finalDate,
  initialDate,
  status,
  type,
}: IRequest) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['sector-trips', { finalDate, initialDate, status, type }],
    enabled:
      user?.user?.accessType === 'sector_admin' ||
      user?.user?.accessType === 'company_admin',
    queryFn: () =>
      getSectorNotFinishedTrips({ finalDate, initialDate, status, type }),
  });
};
