import { Form, InputField } from '@/components';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { useDriversPlateList } from '@/features/drivers';
import { useAuth } from '@/providers/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IVehicleClass, useListClasses } from '@/features/vehicleClasses';
import { CheckOutlined } from '@mui/icons-material';

import { possibleIcons } from '@/utils/vehicleIcons';
import { useRegisterVehicle, useVehicleCategories } from '../api';
import { ICreateVehicleDTO } from '../types';

const schema = z.object({
  licensePlate: z.string({ required_error: 'Campo obrigatório' }),
  model: z.string({ required_error: 'Campo obrigatório' }),
  vehicleCategoryId: z.string({ required_error: 'Campo obrigatório' }),
  vehicleIcon: z.string().optional().nullable(),
});

export const CreateVehicleComponent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [writeLicensePlate, setWriteLicensePlate] = useState(false);
  const hasTracker = !!user.user.company.trackerId;
  const { data: plates } = useDriversPlateList();
  const { data: categories } = useVehicleCategories();
  const theme = useTheme();
  const { mutateAsync, isLoading } = useRegisterVehicle();
  const { data: classes, isLoading: isLoadingClasses } = useListClasses();
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);

  const handleChange = (roleId: string) => {
    setSelectedClasses(prevClasses => {
      if (prevClasses.includes(roleId)) {
        return prevClasses.filter(data => data !== roleId);
      }
      return [...prevClasses, roleId];
    });
  };
  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<ICreateVehicleDTO, typeof schema>
          id="create-vehicle"
          onSubmit={values => {
            mutateAsync({ ...values, classesId: selectedClasses });
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
              >
                Cadastro de veículo
              </Typography>
              <Box>
                <InputField
                  size="small"
                  name="model"
                  type="text"
                  label="Modelo do carro"
                  registration={register('model')}
                  placeholder="Citroen C3"
                  error={!!formState.errors.model?.message}
                  errorMessage={formState.errors.model?.message}
                />
                <>
                  {hasTracker && !writeLicensePlate ? (
                    <InputField
                      size="small"
                      name="licensePlate"
                      select
                      label="Placa"
                      registration={register('licensePlate')}
                      error={!!formState.errors.licensePlate}
                      errorMessage={formState.errors.licensePlate?.message}
                    >
                      {plates?.length > 0 &&
                        plates !== 'Não houve resultados!' &&
                        plates.map((item: any, key: any) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <MenuItem key={key} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </InputField>
                  ) : (
                    <InputField
                      size="small"
                      name="licensePlate"
                      label="Placa"
                      registration={register('licensePlate')}
                      error={!!formState.errors.licensePlate}
                      errorMessage={formState.errors.licensePlate?.message}
                    />
                  )}
                </>
                {hasTracker && (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() => setWriteLicensePlate(prev => !prev)}
                          checked={writeLicensePlate}
                        />
                      }
                      label="Cadastrar placa manualmente"
                    />
                  </Box>
                )}
                <Box
                  sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  <InputField
                    size="small"
                    name="vehicleCategoryId"
                    select
                    label="Categoria do veículo"
                    registration={register('vehicleCategoryId')}
                    error={!!formState.errors.vehicleCategoryId}
                    errorMessage={formState.errors.vehicleCategoryId?.message}
                  >
                    {categories &&
                      categories?.length > 0 &&
                      categories.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.category.toUpperCase()}
                        </MenuItem>
                      ))}
                  </InputField>
                  <InputField
                    style={{ width: '300px' }}
                    size="small"
                    name="vehicleIcon"
                    select
                    label="Ícone do veículo"
                    registration={register('vehicleIcon')}
                    error={!!formState.errors.vehicleIcon}
                    errorMessage={formState.errors.vehicleIcon?.message}
                  >
                    {possibleIcons &&
                      possibleIcons?.length > 0 &&
                      possibleIcons.map(item => (
                        <MenuItem
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                          key={item.name}
                          value={item.name}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography>{item.name}</Typography>
                            <img
                              style={{
                                display: 'flex',
                                height: '35px',
                                width: '35px',
                              }}
                              alt={item.name}
                              src={item.icon}
                            />
                          </Box>
                        </MenuItem>
                      ))}
                  </InputField>
                </Box>
                <FormGroup>
                  {isLoadingClasses ? (
                    <Box>Carregando</Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        margin: '10px 0px',
                        flexWrap: 'wrap',
                        gap: '15px',
                        justifyContent: 'center',
                      }}
                    >
                      {classes?.map((vehicleClass: IVehicleClass) => (
                        <Box
                          key={vehicleClass.id}
                          sx={{
                            padding: '4px 15px',
                            borderRadius: '10px',
                            border:
                              theme.palette.mode === 'dark'
                                ? '1px solid #56acee'
                                : '1px solid #0b7ed4',
                            background: selectedClasses.includes(
                              vehicleClass.id,
                            )
                              ? '#0b7ed4'
                              : 'transparent',
                            color: selectedClasses.includes(vehicleClass.id)
                              ? 'white'
                              : '#0b7ed4',
                            display: 'flex',
                            gap: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChange(vehicleClass.id)}
                        >
                          {vehicleClass.name}
                          {selectedClasses.includes(vehicleClass.id) && (
                            <CheckOutlined />
                          )}
                        </Box>
                      ))}
                    </Box>
                  )}
                </FormGroup>
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    sx={{ width: '200px' }}
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    {isLoading ? 'carregando...' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
