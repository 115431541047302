import { Form, InputField } from '@/components';
import { phoneMask } from '@/utils/maskShared';
import {
  Grid,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import * as z from 'zod';
import { useState } from 'react';
import { useCreateSectorAsRenter } from '@/features/sectors';

interface IRequest {
  onSuccess: (sectorId: string) => void;
  companyId: string;
}

export const CreateSectorAsRenter = ({ onSuccess, companyId }: IRequest) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const schema = z.object({
    name: z.string({ required_error: 'Campo obrigatório' }),
    phone: z.string({ required_error: 'Campo obrigatório' }),
    limitOfTrips: z.string().optional(),
    limitOfCosts: z.string().optional(),
    sectorId: z.string().optional(),
  });
  const [hasTripLimit, setHasTripLimit] = useState(false);
  const [hasPriceLimit, setHasPriceLimit] = useState(false);
  const [blockAtLimit, setBlockAtLimit] = useState(false);
  const { isLoading, mutateAsync } = useCreateSectorAsRenter();
  return (
    <Box>
      <Box>
        <Form<any, typeof schema>
          id="create-sector"
          onSubmit={values => {
            mutateAsync(
              {
                ...values,
                blockAtLimit,
                companyId,
              },
              {
                onSuccess: data => {
                  onSuccess(data.id);
                },
              },
            );
          }}
          schema={schema}
        >
          {({ register, formState, setValue }) => (
            <>
              <Grid container>
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="name"
                    type="text"
                    label="Setor"
                    registration={register('name')}
                    error={!!formState.errors.name}
                    errorMessage={formState.errors.name?.message}
                  />
                  <InputField
                    size="small"
                    name="sectorId"
                    type="text"
                    label="ID do setor"
                    registration={register('sectorId')}
                    error={!!formState.errors.sectorId}
                    errorMessage={formState.errors.sectorId?.message}
                  />
                </Grid>
                <Grid sm={6} xs={12} item>
                  <InputField
                    sx={{
                      marginLeft: isMobile ? '0px' : '10px',
                    }}
                    size="small"
                    name="phone"
                    type="text"
                    label="Telefone"
                    registration={register('phone')}
                    inputProps={{ minLength: 11, maxLength: 15 }}
                    error={!!formState.errors.password}
                    errorMessage={formState.errors.password?.message}
                    onChange={(event: any) => {
                      const { value } = event.target;
                      // eslint-disable-next-line no-param-reassign
                      event.target.value = phoneMask(
                        value.replace(/[^\d]+/g, ''),
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <FormControlLabel
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  }}
                  control={
                    <Checkbox
                      onChange={() => {
                        if (hasTripLimit) {
                          setValue('limitOfTrips', '0');
                        }
                        setHasTripLimit(prev => !prev);
                      }}
                      checked={hasTripLimit}
                    />
                  }
                  label="Limite de corridas"
                />
              </Grid>
              {hasTripLimit && (
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="limitOfTrips"
                    type="number"
                    label=""
                    registration={register('limitOfTrips')}
                    error={!!formState.errors.limitOfTrips}
                    errorMessage={formState.errors.limitOfTrips?.message}
                  />
                </Grid>
              )}
              <Grid container>
                <FormControlLabel
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  }}
                  control={
                    <Checkbox
                      onChange={() => {
                        if (hasPriceLimit) {
                          setValue('limitOfCosts', '0');
                        }
                        setHasPriceLimit(prev => !prev);
                      }}
                      checked={hasPriceLimit}
                    />
                  }
                  label="Limite de preço"
                />
              </Grid>
              {hasPriceLimit && (
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="limitOfCosts"
                    type="number"
                    label=""
                    registration={register('limitOfCosts')}
                    error={!!formState.errors.limitOfCosts}
                    errorMessage={formState.errors.limitOfCosts?.message}
                  />
                </Grid>
              )}
              {(hasPriceLimit || hasTripLimit) && (
                <Grid sm={6} xs={12} item>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                    control={
                      <Checkbox
                        onChange={() => setBlockAtLimit(prev => !prev)}
                        checked={blockAtLimit}
                      />
                    }
                    label="Bloquear solicitações ao atingir o limite"
                  />
                </Grid>
              )}
              <Button
                sx={{ marginTop: '10px' }}
                variant="contained"
                color="success"
                type="submit"
              >
                {isLoading ? 'carregando...' : 'Adicionar'}
              </Button>
            </>
          )}
        </Form>
      </Box>
    </Box>
  );
};
