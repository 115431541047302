/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import {
  useMediaQuery,
  useTheme,
  Box,
  FormControl,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Button,
} from '@mui/material';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import { SelectDriver } from '@/features/reports/components/SelectDriverInput';
import { useDrivers } from '@/features/drivers';
import { useOpenTrip } from '@/providers/openTrips';
import { MobileSideBar, Modal } from '@/components';
import { OpenTrip } from '../components/OpenTrip';
import useStyle from '../styles/style';
import { ConfigurationButton } from '../components/ConfigurationButton';
import { SwipeableDrawer } from '../components/SwipeableDrawer';

import automaticRedirectionLottie from '../../../assets/json/automaticRedirectionLottie.json';
import { DateFilter } from '../components/OpenTrip/DateFilter';
import { JoinTrip } from '../components/JoinTrip/JoinTrip';
import { RequestTrip } from '../components/RequestTripAsRenter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusOptions = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Aguardando resposta',
    value: 'requested',
  },
  {
    name: 'Aguardando motorista',
    value: 'pending',
  },
  {
    name: 'Agendado',
    value: 'scheduled',
  },
  {
    name: 'A caminho',
    value: 'accepted',
  },
  {
    name: 'Iniciada',
    value: 'started',
  },
  {
    name: 'Finalizada',
    value: 'finished',
  },
  {
    name: 'Rejeitada',
    value: 'requestRejected',
  },
  {
    name: 'Cancelada',
    value: 'requestCanceled',
  },
];

export function TripRequest() {
  const [openTour, setOpenTour] = useState(false);
  const [isGridOption, setIsGridOption] = useState(true);
  const {
    close: closeJoinTrip,
    toggle: toggleJoinTrip,
    isOpen: isOpenJoinTrip,
  } = useDisclosure();
  const {
    isOpen: isNewTripModalOpen,
    open: openNewTripModal,
    close: closeNewTripModal,
  } = useDisclosure();
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const { data: drivers } = useDrivers();

  const {
    close: closeSettings,
    toggle: toggleSettings,
    isOpen: isSettingsOpen,
  } = useDisclosure();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyle({ matches });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [joinTripsData, setJoinTripsData] = useState<{
    ids: string[];
    usersSharingTrip: {
      userId: string;
      name: string;
    }[];
    adressesFrom: {
      address: string;
      lat: number;
      long: number;
      city: string;
    }[];
    adressesTo: {
      address: string;
      lat: number;
      long: number;
      city: string;
    }[];
    stopPoints: {
      address: string;
      lat: number;
      long: number;
    }[];
  }>({
    ids: [],
    usersSharingTrip: [],
    adressesFrom: [],
    adressesTo: [],
    stopPoints: [],
  });
  const {
    openTripsData,
    initialDate,
    finalDate,
    setFinalDate,
    setInitialDate,
    setCheckedStastus,
    setType,
    type,
    setDriver,
    checkedStatus,
  } = useOpenTrip();

  const handleChange = (event: SelectChangeEvent<typeof checkedStatus>) => {
    const {
      target: { value },
    } = event;

    if (
      value.includes('all') &&
      checkedStatus.includes('all') &&
      Array.isArray(value)
    ) {
      setCheckedStastus(value.filter(item => item !== 'all'));
      return;
    }

    if (value.includes('all')) {
      setCheckedStastus([
        'all',
        'requested',
        'pending',
        'scheduled',
        'accepted',
        'started',
        'finished',
        'requestRejected',
        'requestCanceled',
      ]);
      return;
    }
    setCheckedStastus(typeof value === 'string' ? value.split(',') : value);
  };

  const handleJoinTrips = () => {
    const selectedTrips = openTripsData.filter(trip =>
      selectedRows.has(trip.id),
    );
    const ids = selectedTrips.map(trip => trip.id);
    const passengers = selectedTrips.flatMap(trip =>
      trip.users.map(user => user),
    );
    const points = selectedTrips.flatMap(trip =>
      trip?.stopPoints?.map(data => data),
    );
    const usersSharingTrip = passengers.map(passenger => ({
      userId: passenger.id,
      name: passenger.name,
    }));
    const adressesFrom = selectedTrips.map(trip => {
      const { addressFrom, startLat, startLong, cityOfOrigin } = trip;
      return {
        address: addressFrom,
        lat: parseFloat(startLat),
        long: parseFloat(startLong),
        city: cityOfOrigin,
      };
    });
    const adressesTo = selectedTrips.map(trip => {
      const { addressTo, finalLat, finalLong, cityOfDestination } = trip;
      return {
        address: addressTo,
        lat: parseFloat(finalLat),
        long: parseFloat(finalLong),
        city: cityOfDestination,
      };
    });
    const stopPoints = points?.map(point => {
      return {
        address: point?.address,
        lat: point?.lat,
        long: point?.long,
      };
    });
    setJoinTripsData({
      ids,
      usersSharingTrip,
      adressesFrom,
      adressesTo,
      stopPoints,
    });
    toggleJoinTrip();
  };

  return (
    <>
      {isOpenJoinTrip && (
        <Modal
          title="Juntar corridas"
          dialogContent={
            <JoinTrip
              ids={joinTripsData.ids || []}
              usersSharingTrip={joinTripsData.usersSharingTrip || []}
              close={() => {
                closeJoinTrip();
                setSelectedRows(new Set());
              }}
              adressesFrom={joinTripsData.adressesFrom || []}
              adressesTo={joinTripsData.adressesTo || []}
              stopPoints={joinTripsData.stopPoints || []}
            />
          }
          open={isOpenJoinTrip}
          size="sm"
          onClose={closeJoinTrip}
        />
      )}
      {isNewTripModalOpen && (
        <Modal
          title="Solicitar corrida"
          dialogContent={<RequestTrip />}
          open={isNewTripModalOpen}
          size="sm"
          onClose={closeNewTripModal}
        />
      )}
      {isSettingsOpen && (
        <SwipeableDrawer
          setOpenTour={setOpenTour}
          openTour={openTour}
          isOpen={isSettingsOpen}
          close={closeSettings}
          toggle={toggleSettings}
          data={openTripsData?.filter(trip => trip.type === 'Simples').length}
          title="Configurações das solicitações de viagens"
          animation={
            <Lottie
              animationData={automaticRedirectionLottie}
              style={{ width: '180px' }}
            />
          }
        />
      )}

      <Box>
        {isMobile && (
          <>
            <Box
              sx={{
                zIndex: '999',
                background: theme.palette.background.default,
              }}
            >
              <MobileSideBar />
            </Box>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '0px' : '20px',
            flexDirection: 'column',
          }}
        >
          <Box>
            <FormControlLabel
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              }}
              control={
                <Checkbox
                  onChange={() => setIsGridOption(prev => !prev)}
                  checked={isGridOption}
                />
              }
              label="Modo tabela"
            />
          </Box>
          <FormControl
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              marginTop: isMobile ? '0px' : '10px',
            }}
          >
            <Box>
              <ConfigurationButton
                setIsOpen={setOpenTour}
                toggleSettings={toggleSettings}
              />
            </Box>
            <TextField
              size="small"
              sx={{
                width: isMobile ? '170px' : '250px',
                marginLeft: isMobile ? '10px' : '0',
                marginBottom: isMobile ? '10px' : '0',
              }}
              value={type}
              onChange={e => setType(e.target.value as any)}
              label="Filtrar por categoria"
              type="text"
              select
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="requests">Solicitações</MenuItem>
              <MenuItem value="recurring">Viagem recorrente</MenuItem>
            </TextField>
            <FormControl
              sx={{
                minWidth: 200,
                marginBottom: '10px',
                display: 'flex',
                marginLeft: isMobile ? '0px' : '10px',
                gap: isMobile ? '10px' : '0px',
                flexDirection: 'row',
              }}
            >
              <FormControl sx={{ marginLeft: isMobile ? '10px' : '0px' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  size="small"
                  value={checkedStatus}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      sx={{ width: isMobile ? '170px' : '250px' }}
                      label="Status"
                    />
                  }
                  renderValue={selected => {
                    const selectedStatus = selected as string[];
                    return selectedStatus
                      .map(statusSelected =>
                        statusOptions.find(
                          statusOption => statusOption.value === statusSelected,
                        ),
                      )
                      .map(data => data?.name)
                      .join(', ');
                  }}
                  MenuProps={MenuProps}
                >
                  {statusOptions.map(data => (
                    <MenuItem key={data.value} value={data.value}>
                      <Checkbox
                        checked={checkedStatus.indexOf(data.value) > -1}
                      />
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ marginLeft: isMobile ? '0px' : '10px' }}>
                <SelectDriver drivers={drivers || []} setDriver={setDriver} />
              </Box>
            </FormControl>

            <DateFilter
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              isMobile={isMobile}
            />
            {selectedRows.size >= 2 && (
              <Box
                sx={{
                  height: '40px',
                  background:
                    theme.palette.mode === 'dark' ? '#0f9e27' : '#1CB935',
                  padding: '0px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  borderRadius: '5px',
                  color: 'white',
                  fontWeight: 'medium',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  handleJoinTrips();
                }}
              >
                Juntar corrida
              </Box>
            )}
          </FormControl>
          <Button
            onClick={() => openNewTripModal()}
            sx={{ width: '180px', marginTop: '10px' }}
            variant="contained"
          >
            Solicitar corrida
          </Button>
        </Box>
        <OpenTrip
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          drivers={drivers || []}
          openTripsData={openTripsData}
          isGridOption={isGridOption}
        />
      </Box>
    </>
  );
}
