import { UserValues } from '@/features/users';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
// import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

// AxiosResponse
// CompanyValues

export async function getOperators(): Promise<UserValues[]> {
  const response = await axios.get('users/getAll');
  return response.data.payload;
}

type UseOperatorsOptions = {
  config?: QueryConfig<typeof getOperators>;
};

export const useOperators = ({ config }: UseOperatorsOptions = {}) => {
  return useQuery({
    queryKey: ['operators'],
    queryFn: () => getOperators(),
    ...config,
  });
};
