import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

type UseDeleteDriverOptions = {
  config?: MutationConfig<typeof deleteCompany>;
};

export async function deleteCompany(data: any): Promise<any> {
  return axios.post('prv/api/company/delete', data);
}

export const useDeleteCompany = ({ config }: UseDeleteDriverOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('companies');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('companies', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('companies');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Empresa inativada com sucesso.',
      });
      navigate('/companies');
    },
    ...config,
    mutationFn: deleteCompany,
  });
};
