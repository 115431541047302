import { ConfirmModal, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useRenterFinishesTrip } from '../../api';

const schema = z.object({
  manualInitialOdometer: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  manualFinalOdometer: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracteres'),
  manualStartOdometer: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
});

interface IProps {
  tripId: string;
  manualInitialOdometer?: number;
  manualFinalOdometer?: number;
  manualStartOdometer?: number;
  onSuccess?: () => void;
}

export const FinishTrip = ({
  tripId,
  manualFinalOdometer,
  manualInitialOdometer,
  manualStartOdometer,
  onSuccess,
}: IProps) => {
  const { isOpen, close, open } = useDisclosure();
  const { mutateAsync, isLoading } = useRenterFinishesTrip();
  const preloadedValues = {
    manualFinalOdometer: manualFinalOdometer?.toString(),
    manualInitialOdometer: manualInitialOdometer?.toString(),
    manualStartOdometer: manualStartOdometer?.toString(),
  };

  const { handleSubmit, control, formState, getValues } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: {
    manualInitialOdometer?: number;
    manualFinalOdometer?: number;
    manualStartOdometer?: number;
  }) => {
    mutateAsync(
      {
        id: tripId,
        ...values,
      },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess();
          }
        },
      },
    );
  };
  return (
    <Box>
      <ConfirmModal
        isOpen={isOpen}
        close={close}
        isLoading={isLoading}
        title="Tem certeza que deseja finalizar essa viagem?"
        handleConfirmation={() => {
          onSubmit({
            manualInitialOdometer: Number(
              getValues().manualInitialOdometer || 0,
            ),
            manualFinalOdometer: Number(getValues().manualFinalOdometer || 0),
            manualStartOdometer: Number(getValues().manualStartOdometer || 0),
          });
        }}
      />
      <form onSubmit={handleSubmit(open)} style={{ padding: 40 }}>
        <Controller
          name="manualInitialOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualInitialOdometer"
              type="number"
              placeholder="540"
              label="Odômetro do início do deslocamento ao passageiro"
              onChange={e => onChange(e.target.value)}
              value={value}
              errorMessage={formState.errors.manualInitialOdometer?.message}
              error={!!formState.errors.manualInitialOdometer}
            />
          )}
        />
        <Controller
          name="manualStartOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualStartOdometer"
              type="number"
              placeholder="550"
              label="Odômetro do início da viagem"
              onChange={e => onChange(e.target.value)}
              value={value}
              errorMessage={formState.errors.manualStartOdometer?.message}
              error={!!formState.errors.manualStartOdometer}
            />
          )}
        />

        <Controller
          name="manualFinalOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualFinalOdometer"
              type="number"
              placeholder="560"
              label="Odômetro do final da viagem"
              onChange={e => onChange(e.target.value)}
              value={value}
              errorMessage={formState.errors.manualFinalOdometer?.message}
              error={!!formState.errors.manualFinalOdometer}
            />
          )}
        />

        <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
          <Button variant="contained" color="success" type="submit">
            {isLoading ? 'carregando...' : 'Salvar'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
