import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { MapScreen } from './MapScreen';

export const MapScreenRoutes = () => {
  return (
    <Can permissions={['renter', 'company_admin']}>
      <Routes>
        <Route path="" element={<MapScreen />} />
      </Routes>
    </Can>
  );
};
