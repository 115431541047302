/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  FormGroup,
  Toolbar,
  useMediaQuery,
  TextField,
  useTheme,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import ptLocale from 'date-fns/locale/pt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { useCompanies } from '@/features/companies';
import { useAuth } from '@/providers/auth';
import { useNotificationStore } from '@/stores';
import { useDrivers } from '@/features/drivers';
import { Pdf } from '@/utils/PDF';
import { axios } from '@/lib/axios';
import { useListVehicles } from '@/features/vehicles';
import { CustomSelect } from '@/components/CustomSelect';
import { useReports } from '../api';
import { DesktopTable } from './DesktopTable';
import { HandleOptions } from './HandleOptions';
import { WorkJourneyReportsField } from './WorkJourneyReportsField';
import { SelectReportType } from './SelectReportType';
import { VehiclesReportsField } from './VehiclesReportsField';
import { SelectCompany } from './SelectCompanyInput';
import { SelectDriver } from './SelectDriverInput';
import { Chart, IChatValue } from './Dashboard/Chart';

export interface ChipData {
  key: number;
  field: string;
  title: string;
  colSpan?: number;
  Cell?: (props: any) => JSX.Element;
  CustomHeader?: (props: any) => JSX.Element;
}

export function ReportsListTable() {
  const { addNotification } = useNotificationStore();
  const [defaultDateRange, setDefaultDateRange] = useState<
    'oneDay' | '7days' | '15days' | '30days'
  >('oneDay');
  const [headers, setHeaders] = useState<any>([]);
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const [finalDate, setFinalDate] = useState<any>(new Date());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const [onlyConcluded, setOnlyConcluded] = useState(false);
  const [report, setReport] = useState<any[]>([]);
  const [chartData, setChartData] = useState<IChatValue>({});
  const [
    canceledAndFinishedTripsChartData,
    setCanceledAndFinishedTripsChartData,
  ] = useState<{
    canceled: IChatValue;
    finished: IChatValue;
  }>({
    canceled: {},
    finished: {},
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoadingWorkJourney, setIsLoadingWorkJourney] = useState(false);
  const [isLoadingChartReport, setIsLoadingChartReport] = useState(false);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);

  const { data: drivers } = useDrivers();
  const { data: vehicles } = useListVehicles();
  const { mutateAsync, isLoading } = useReports();

  const [chipData, setChipData] = useState<ChipData[]>([]);

  const [removedChips, setRemovedChips] = useState<ChipData[]>([]);

  useEffect(() => {
    const filteredHeaders = chipData.map((data: ChipData) => {
      return {
        label: data.title,
        key: data.field,
      };
    });

    setHeaders(filteredHeaders);
  }, [chipData]);

  const [reportType, setReportType] = useState<
    | 'trips'
    | 'workJourney'
    | 'vehicleExchange'
    | 'location'
    | 'timeRelation'
    | 'canceledAndFinishedTripsRelation'
    | 'sectorRelation'
    | 'passengersRanking'
    | 'operatorsRanking'
    | 'acceptedTimeRelation'
    | 'vehicleAndTripsRelation'
    | 'driversRanking'
  >('trips');
  const { data: companies } = useCompanies();
  const [company, setCompany] = useState<string | undefined>('');
  const [cityOfOrigin, setCityOfOrigin] = useState('');
  const [cityOfDestination, setCityOfDestination] = useState('');
  const [driver, setDriver] = useState<string | undefined>();
  const [vehicle, setVehicle] = useState<{
    id: string;
    licensePlate: string;
  }>({
    id: '',
    licensePlate: '',
  });
  const listReports = async (reportType: string) => {
    if (initialDate && !finalDate) {
      addNotification({
        type: 'error',
        title: 'Erro',
        message: 'Adicione uma data final.',
      });
      return;
    }

    if (finalDate && !initialDate) {
      addNotification({
        type: 'error',
        title: 'Erro',
        message: 'Adicione uma data inicial.',
      });
      return;
    }
    switch (reportType) {
      case 'trips':
        {
          const companyId =
            user?.user.accessType === 'renter'
              ? company
              : user?.user?.companyId;
          const data = {
            id: companyId,
            driverId: driver || null,
            limit: 200,
            skip: 0,
            requestDate: initialDate ? format(initialDate, 'MM/dd/yyyy') : null,
            tripFinalDate: finalDate ? format(finalDate, 'MM/dd/yyyy') : null,
            sector: user?.user?.sectorName || null,
            onlyStartedTrips: onlyConcluded,
          };

          mutateAsync(data, {
            onSuccess: data => {
              const price = data
                ?.map((trip: any) => trip.tripPrice)
                ?.reduce((a: any, b: any) => parseFloat(a) + parseFloat(b), 0);
              setTotalPrice(price);
              setReport(data);
            },
          });
        }
        break;
      case 'workJourney':
        {
          setIsLoadingWorkJourney(true);
          if (!driver) {
            addNotification({
              type: 'error',
              title: 'Erro',
              message: 'Selecione um motorista',
            });
            return;
          }
          const response = await axios.get(
            `/clockIn/getDriverWorkJourney?driverId=${driver}&startDate=${
              initialDate ? format(initialDate, 'MM/dd/yyyy') : null
            }&finalDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
          );
          setReport(response.data.payload);
          setIsLoadingWorkJourney(false);
        }
        break;
      case 'location':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            if (!cityOfOrigin && !cityOfDestination) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione ao menos uma cidade',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listLocationDashboard/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${
                finalDate ? format(finalDate, 'MM/dd/yyyy') : null
              }&cityOfOrigin=${cityOfOrigin}&cityOfDestination=${cityOfDestination}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'timeRelation':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listTimeRelation/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'vehicleAndTripsRelation':
        {
          setIsLoadingVehicles(true);
          try {
            if (!vehicle.licensePlate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione um veículo',
              });
              return;
            }
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const response = await axios.get(
              `/reports/listVehicleReport/${vehicle.licensePlate}?requestDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&tripFinalDate=${
                finalDate ? format(finalDate, 'MM/dd/yyyy') : null
              }`,
            );
            setReport(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingVehicles(false);
          }
        }
        break;
      case 'acceptedTimeRelation':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listAcceptedTimeRelation/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'passengersRanking':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listPassengersThatRequestMoreTrips/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'operatorsRanking':
        {
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listOperatorsThatRequestMoreTrips/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'driversRanking':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const response = await axios.get(
              `/reports/listDriversThatCompleteMoreTrips/?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'sectorRelation':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/listSectorRelationDashboard/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'canceledAndFinishedTripsRelation':
        {
          setIsLoadingChartReport(true);
          try {
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const companyId =
              user?.user.accessType === 'renter'
                ? company
                : user?.user?.companyId;
            const response = await axios.get(
              `/reports/getDailyCanceledAndFinishedTripsRelation/${companyId}?startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&endDate=${finalDate ? format(finalDate, 'MM/dd/yyyy') : null}`,
            );
            setCanceledAndFinishedTripsChartData(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingChartReport(false);
          }
        }
        break;
      case 'vehicleExchange':
        {
          setIsLoadingVehicles(true);
          try {
            if (!vehicle.id) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione um veículo',
              });
              return;
            }
            if (!initialDate || !finalDate) {
              addNotification({
                type: 'error',
                title: 'Erro',
                message: 'Selecione as datas',
              });
              return;
            }
            const response = await axios.get(
              `/vehicles/getVehicleExchangeReport?vehicleId=${
                vehicle.id
              }&startDate=${
                initialDate ? format(initialDate, 'MM/dd/yyyy') : null
              }&finalDate=${
                finalDate ? format(finalDate, 'MM/dd/yyyy') : null
              }`,
            );
            setReport(response.data.payload);
          } catch (err) {
            console.log(err);
          } finally {
            setIsLoadingVehicles(false);
          }
        }
        break;
      default: {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: 'Adicione tipo válido de relatório.',
        });
      }
    }
  };

  const handleDownload = () => {
    // Criar um novo workbook
    const workbook = new ExcelJS.Workbook();

    // Adicionar uma nova planilha ao workbook
    const worksheet = workbook.addWorksheet('Relatório');

    // Adicionar cabeçalhos à planilha
    const excelHeaders = headers
      .filter((header: any) => header.label !== 'Ações')
      .map((header: any) => header.label);
    worksheet.addRow(excelHeaders);

    // Adicionar dados à planilha
    report.forEach(item => {
      const rowData = headers
        .filter((header: any) => header.label !== 'Ações')
        .map((header: any) => item[header.key]);
      worksheet.addRow(rowData);
    });

    // Salvar o arquivo Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      FileSaver.saveAs(new Blob([buffer]), `Relatório-${Date.now()}.xlsx`);
    });
  };

  return (
    <>
      <SelectReportType
        reportType={reportType}
        onChange={(
          type:
            | 'trips'
            | 'workJourney'
            | 'location'
            | 'vehicleExchange'
            | 'timeRelation'
            | 'sectorRelation'
            | 'canceledAndFinishedTripsRelation'
            | 'acceptedTimeRelation'
            | 'vehicleAndTripsRelation'
            | 'driversRanking'
            | 'operatorsRanking'
            | 'passengersRanking',
        ) => {
          setReportType(type);
          setReport([]);
          setChartData({});
          setCanceledAndFinishedTripsChartData({
            canceled: {},
            finished: {},
          });
        }}
      />
      {(user?.user?.accessType === 'renter' ||
        user?.user?.accessType === 'master') &&
        (reportType === 'trips' ||
          reportType === 'location' ||
          reportType === 'timeRelation' ||
          reportType === 'sectorRelation' ||
          reportType === 'passengersRanking' ||
          reportType === 'operatorsRanking' ||
          reportType === 'acceptedTimeRelation' ||
          reportType === 'canceledAndFinishedTripsRelation') && (
          <Box sx={{ display: 'flex' }}>
            <Toolbar>
              <FormGroup sx={{ gap: 1.5 }} row>
                {(reportType === 'trips' ||
                  reportType === 'location' ||
                  reportType === 'timeRelation' ||
                  reportType === 'sectorRelation' ||
                  reportType === 'passengersRanking' ||
                  reportType === 'operatorsRanking' ||
                  reportType === 'acceptedTimeRelation' ||
                  reportType === 'canceledAndFinishedTripsRelation') && (
                  <SelectCompany
                    companies={
                      companies?.length > 0
                        ? [
                            {
                              id: '',
                              corporateName: 'Todas',
                            },
                            ...companies,
                          ]
                        : []
                    }
                    setCompany={setCompany}
                  />
                )}
                {reportType === 'trips' && (
                  <SelectDriver drivers={drivers || []} setDriver={setDriver} />
                )}
              </FormGroup>
            </Toolbar>
          </Box>
        )}

      {reportType === 'location' && (
        <Box sx={{ display: 'flex' }}>
          <Toolbar>
            <FormGroup sx={{ gap: 1.5 }} row>
              <TextField
                label="Cidade de origem"
                placeholder="Rio de Janeiro"
                value={cityOfOrigin}
                onChange={e => setCityOfOrigin(e.target.value)}
                size="small"
              />
              <TextField
                label="Cidade de destino"
                placeholder="Rio de Janeiro"
                value={cityOfDestination}
                onChange={e => setCityOfDestination(e.target.value)}
                size="small"
              />
            </FormGroup>
          </Toolbar>
        </Box>
      )}

      {reportType === 'workJourney' && (
        <WorkJourneyReportsField
          drivers={drivers || []}
          setDriver={setDriver}
        />
      )}

      {(reportType === 'vehicleExchange' ||
        reportType === 'vehicleAndTripsRelation') && (
        <VehiclesReportsField
          setVehicle={setVehicle}
          vehicles={vehicles || []}
        />
      )}
      <CustomSelect<'oneDay' | '7days' | '15days' | '30days'>
        label="Selecione o intervalo de datas"
        value={defaultDateRange}
        onChange={e => {
          if (e === 'oneDay') {
            setInitialDate(new Date());
            setFinalDate(new Date());
          } else if (e === '7days') {
            setInitialDate(
              new Date(new Date().setDate(new Date().getDate() - 7)),
            );
            setFinalDate(new Date());
          } else if (e === '15days') {
            setInitialDate(
              new Date(new Date().setDate(new Date().getDate() - 15)),
            );
            setFinalDate(new Date());
          } else if (e === '30days') {
            setInitialDate(
              new Date(new Date().setDate(new Date().getDate() - 30)),
            );
            setFinalDate(new Date());
          }
          setDefaultDateRange(e);
        }}
        menuOptions={[
          {
            label: '1 dia',
            value: 'oneDay',
            permissions: [''],
            render: () => {
              return (
                <MenuItem key="oneDay" value="oneDay">
                  1 dia
                </MenuItem>
              );
            },
          },
          {
            label: 'Últimos 7 dias',
            value: '7days',
            permissions: [''],
            render: () => {
              return (
                <MenuItem key="7days" value="7days">
                  Últimos 7 dias
                </MenuItem>
              );
            },
          },
          {
            label: 'Últimos 15 dias',
            value: '15days',
            permissions: [''],
            render: () => {
              return (
                <MenuItem key="15days" value="15days">
                  Últimos 15 dias
                </MenuItem>
              );
            },
          },
          {
            label: 'Últimos 30 dias',
            value: '30days',
            permissions: [''],
            render: () => {
              return (
                <MenuItem key="30days" value="30days">
                  Últimos 30 dias
                </MenuItem>
              );
            },
          },
        ]}
      />

      <Toolbar>
        <FormGroup row>
          <Box sx={{ width: '200px', marginTop: isMobile ? '10px' : '0px' }}>
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data inicial"
                value={initialDate}
                maxDate={finalDate}
                onChange={newValue => {
                  setInitialDate(() => newValue);
                }}
                renderInput={params => (
                  <TextField size="small" {...params} disabled />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              marginLeft: isMobile ? '0px' : '10px',
              marginTop: isMobile ? '10px' : '0px',
              width: '200px',
            }}
          >
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data final"
                value={finalDate}
                minDate={initialDate}
                onChange={newValue => {
                  setFinalDate(() => newValue);
                }}
                renderInput={params => (
                  <TextField size="small" {...params} disabled />
                )}
              />
            </LocalizationProvider>
          </Box>
        </FormGroup>
      </Toolbar>
      <HandleOptions
        selectedDriver={driver}
        chipData={chipData}
        removedChips={removedChips}
        reportType={reportType}
        setChipData={setChipData}
        setRemovedChips={setRemovedChips}
      />

      {reportType === 'trips' && (
        <Toolbar>
          <FormControlLabel
            sx={{
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
            control={
              <Checkbox
                onChange={() => setOnlyConcluded(prev => !prev)}
                checked={onlyConcluded}
              />
            }
            label="Apenas viagens concluídas"
          />
        </Toolbar>
      )}
      <Box sx={{ display: 'flex' }}>
        <Toolbar>
          <Button
            variant="contained"
            type="button"
            onClick={() => listReports(reportType)}
            size={isMobile ? 'small' : 'medium'}
          >
            Gerar
          </Button>
        </Toolbar>
        {report.length > 0 && (
          <>
            <Toolbar>
              <Button
                variant="contained"
                size={isMobile ? 'small' : 'medium'}
                type="button"
                color="error"
                onClick={() => {
                  Pdf({
                    data: report,
                    headers,
                    totalPrice,
                  });
                }}
              >
                Gerar PDF
              </Button>
            </Toolbar>

            <Toolbar>
              <Button
                onClick={handleDownload}
                size={isMobile ? 'small' : 'medium'}
                variant="contained"
                type="button"
                color="success"
              >
                Gerar Excel
              </Button>
            </Toolbar>
          </>
        )}
      </Box>

      {isLoading && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {(reportType === 'trips' ||
        reportType === 'workJourney' ||
        reportType === 'vehicleAndTripsRelation' ||
        reportType === 'vehicleExchange') && (
        <DesktopTable
          chipData={chipData || []}
          tableData={report || []}
          loading={
            isLoading ||
            isLoadingWorkJourney ||
            isLoadingVehicles ||
            isLoadingChartReport
          }
          type={reportType}
        />
      )}
      {reportType === 'location' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            label="Relatório por localidade"
            rangeType="month"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}
      {reportType === 'timeRelation' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            label="Relatório horário da solicitação"
            rangeType="day"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'acceptedTimeRelation' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            label="Relatório horário da corrida"
            rangeType="day"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'sectorRelation' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            chartType="bar"
            label="Relatório por centro de custo"
            rangeType="alternative"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'passengersRanking' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            chartType="bar"
            label="Rank dos passageiros"
            rangeType="alternative"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'operatorsRanking' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            chartType="bar"
            label="Rank dos operadores"
            rangeType="alternative"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'driversRanking' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            chartType="bar"
            label="Rank dos motoristas"
            rangeType="alternative"
            chartValues={chartData}
            startDate={initialDate}
            endDate={finalDate}
          />
        </Box>
      )}

      {reportType === 'canceledAndFinishedTripsRelation' && (
        <Box sx={{ height: '450px', margin: 'auto' }}>
          <Chart
            legend
            chartType="bar"
            startDate={initialDate}
            endDate={finalDate}
            customDataset={[
              {
                label: 'Canceladas',
                data:
                  Object.values(canceledAndFinishedTripsChartData?.canceled) ||
                  [],
                borderColor: '#74FE71',
                backgroundColor: (context: any) => 'red',
                fill: false,
                yAxisID: 'y',
                tension: 0.5,
              },
              {
                label: 'Finalizadas',
                data:
                  Object.values(canceledAndFinishedTripsChartData?.finished) ||
                  [],
                borderColor: '#0F2D56',
                backgroundColor: (context: any) => '#0D3A90',
                fill: false,
                yAxisID: 'y',
                tension: 0.5,
              },
            ]}
            rangeType="month"
            label="Relatório de viagens canceladas e finalizadas"
            chartValues={{}}
          />
        </Box>
      )}
    </>
  );
}
