import { InputField } from '@/components';
import { Button, MenuItem } from '@mui/material';

interface ISelectCostCenter {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  data: { name: string; id: string; sectorId?: string }[];
  value?: string;
  onClickAdd?: () => void;
}

export const SelectCostCenter = ({
  onChange,
  data,
  value,
  onClickAdd,
}: ISelectCostCenter) => {
  return (
    <InputField
      style={{ marginBottom: '0', border: 'none', zIndex: 0 }}
      size="small"
      name="sectorId"
      select
      value={value}
      type="text"
      label="Centro de custo"
      onChange={onChange}
    >
      {data
        ?.sort((a, b) => a?.name.localeCompare(b?.name))
        ?.map(sector => (
          <MenuItem key={sector.id} value={sector.id}>
            {sector.sectorId
              ? `${sector.name} - ${sector.sectorId}`
              : `${sector.name}`}
          </MenuItem>
        ))}
      {onClickAdd && (
        <Button
          onClick={onClickAdd}
          sx={{
            marginLeft: '15px',
            marginTop: '10px',
          }}
          variant="contained"
        >
          Adicionar
        </Button>
      )}
    </InputField>
  );
};
