import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CreateLog } from '@/features/versionLog';

type UseCreateLogOptions = {
  config?: MutationConfig<typeof createLog>;
};

// eslint-disable-next-line consistent-return
export async function createLog(data: CreateLog): Promise<any> {
  const response = await axios.post('logs/create', data);
  return response.data.payload;
}

export const useCreateLog = ({ config }: UseCreateLogOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newLog => {
      await queryClient.cancelQueries('logs');

      const previousLogs = queryClient.getQueryData<CreateLog[]>('logs');

      queryClient.setQueryData('logs', [...(previousLogs || []), newLog]);

      return previousLogs;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('logs', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('logs');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Atualização registrada com sucesso',
      });
      navigate('/logs');
    },
    ...config,
    mutationFn: createLog,
  });
};
