/* eslint-disable no-nested-ternary */

import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import { Speed, GpsOff, GpsNotFixed } from '@mui/icons-material';
import routeLottie from '../../../assets/json/route.json';
import { driversProps } from '../types';

export const CellPhoneInfo = ({
  filteredDriver,
}: {
  filteredDriver: driversProps;
}) => {
  const theme = useTheme();
  return (
    <>
      <Box style={{ padding: '20px' }}>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
          }}
        >
          Informações do celular
        </Typography>
        <Box style={{ height: '300px', marginTop: '30px', display: 'flex' }}>
          <Box
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <Lottie animationData={routeLottie} style={{ flex: 1 }} />
          </Box>
          <Box
            style={{
              flex: 1,
              padding: '20px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                }}
              >
                Hora da atualização
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                }}
              >
                {filteredDriver.lastUpdateInTheCellPhone && (
                  <>{filteredDriver.lastUpdateInTheCellPhone}</>
                )}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '20px',
              }}
            >
              <Tooltip title="Sinal">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {filteredDriver?.isDriverTrackerEnabled ? (
                    <GpsNotFixed />
                  ) : (
                    <GpsOff />
                  )}
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver?.isDriverTrackerEnabled
                      ? 'GPS Ligado'
                      : 'GPS Desligado'}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Velocidade Km/h">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Speed />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      color:
                        theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver?.speedInTheCellPhone?.toFixed(2) || 0} KM
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
