import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Car from '@/assets/img/emptyCar2.png';
import Wheel1 from '@/assets/img/wheel1.png';
import Wheel2 from '@/assets/img/wheel2.png';
import { useNavigate } from 'react-router-dom';
import { MobileSideBar, SideNav } from '@/components';
import { DirectionsCarFilled } from '@mui/icons-material';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useSocket } from '@/providers/socketProvider';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import useStyle from './styles';
import {
  useGetSectorNotFinishedTrips,
  useTripsToBeValidated,
  useValidateTrip,
} from '../../api';
import { TripCard } from '../TripCard';

export const TripsValidation = () => {
  const {
    close: closeDrawer,
    isOpen: isDrawerOpen,
    toggle: toggleDrawer,
  } = useDisclosure();
  const theme = useTheme();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyle({ matches });
  const { data, refetch } = useTripsToBeValidated();
  const { mutateAsync } = useValidateTrip();
  const { socket } = useSocket();
  const [type, setType] = useState<'requests' | 'recurring'>('requests');
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const [finalDate, setFinalDate] = useState<any>(new Date());
  const {
    data: trips,
    isLoading: isLoadingTrips,
    refetch: refetchTrips,
  } = useGetSectorNotFinishedTrips({
    finalDate,
    initialDate,
    status: [],
    type,
  });

  socket
    ?.off('validationCanceledByUser')
    .on('validationCanceledByUser', async () => {
      refetch();
    });

  const [canClickRefechButton, setCanClickRefetchButton] = useState(true);

  const handleClickRefetchTrips = () => {
    if (canClickRefechButton) {
      refetchTrips();
      setCanClickRefetchButton(false);

      setTimeout(() => {
        setCanClickRefetchButton(true);
      }, 20000);
    } else {
      addNotification({
        title: 'Aguarde um pouco para atualizar a lista de viagens',
        type: 'warning',
      });
    }
  };

  return (
    <>
      <Tooltip
        sx={{
          zIndex: 999,
          position: 'absolute',
          top: '20px',
          right: '20px',
          background: '#36B75B',
          padding: '10px',
          '&:hover': {
            background: '#36B75B',
          },
        }}
        title="Viagens Solicitadas"
      >
        <IconButton onClick={() => toggleDrawer()}>
          <DirectionsCarFilled
            sx={{ width: '35px', height: '35px', color: '#D0FFC8' }}
          />
        </IconButton>
      </Tooltip>
      {!!trips?.length && <Box className={classes.pulse} />}
      <Box sx={{ background: theme.palette.background.default }}>
        {matches ? <SideNav /> : <MobileSideBar />}
        <Box sx={{ flexDirection: 'column', justifyContent: 'space-around' }}>
          <Button
            sx={{
              marginLeft: '50px',
              marginTop: '10px',
              zIndex: 999,
            }}
            variant="contained"
            color="success"
            onClick={() => navigate('./request')}
          >
            Solicitar viagem para o passageiro
          </Button>
        </Box>
        {data?.length ? (
          <Box
            sx={{ marginBottom: '5px', marginTop: matches ? '0' : '50px' }}
            className={classes.CardWrapper}
          >
            {data?.map((i: any) => (
              <TripCard
                key={i?.id}
                id={i?.masterId || i?.id}
                finalLat={i?.finalLat}
                finalLong={i?.finalLong}
                status={i?.status}
                cityOfDestination={i?.cityOfDestination}
                cityOfOrigin={i?.cityOfOrigin}
                driver={i?.driver}
                driverId={i?.driverId}
                driverName={i?.driverName}
                drivers={i?.drivers}
                externalCompany={i?.externalCompany}
                isJoinedTrip={i?.isJoinedTrip}
                sector={i?.sector}
                users={i?.users}
                scheduledDate={i?.scheduledDate}
                stopPoints={i?.stopPoints}
                corporateName={i?.corporateName}
                vehicleCategoryName={i?.vehicleCategoryName}
                usersSharingTrip={i?.users}
                addressFrom={i?.addressFrom}
                addressTo={i?.addressTo}
                distance={i?.distance}
                travelTime={i?.travelTime}
                requestDate={i?.requestDate}
                estimatedPrice={i?.estimatedPrice}
                AcceptTripModal={() => {
                  mutateAsync({ id: i?.id, isValidated: true });
                }}
                RejectTripModal={() => {
                  mutateAsync({ id: i?.id, isValidated: false });
                }}
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.appMain}>
            <Box className={classes.imgWrapper}>
              <Box className={classes.carContainer}>
                <img className={classes.car} src={Car} alt="Carro" />
                <img className={classes.wheel1} src={Wheel1} alt="Roda1" />
                <img className={classes.wheel2} src={Wheel2} alt="Roda2" />
              </Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  marginTop: '50px',
                  color: theme.palette.mode === 'dark' ? '#FFF' : 'black',
                }}
                className={classes.text}
              >
                Não há nenhuma viagem para ser validada
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  color: theme.palette.mode === 'dark' ? '#FFF' : 'black',
                }}
                className={classes.text}
              >
                Todas as viagens para serem validadas aparecerão aqui.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
