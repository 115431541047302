import { InputField } from '@/components';
import { useVehicleCategories } from '@/features/vehicles';
import { MenuItem } from '@mui/material';

interface ISelectTypeOfVehicle {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SelectTypeOfVehicle = ({ onChange }: ISelectTypeOfVehicle) => {
  const { data } = useVehicleCategories();
  const formattedData = data?.map(vehicle => ({
    id: vehicle.id,
    name: vehicle.category,
  }));
  return (
    <InputField
      style={{ marginBottom: '0', border: 'none', zIndex: 0 }}
      size="small"
      name="typeOfVehicle"
      select
      type="text"
      label="Selecione o tipo de veículo"
      onChange={onChange}
    >
      {formattedData?.map(vehicle => (
        <MenuItem key={vehicle.id} value={vehicle.id}>
          {vehicle.name}
        </MenuItem>
      ))}
    </InputField>
  );
};
