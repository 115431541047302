import { Box, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Detail } from './Detail';
import { AdditionalValues } from './AdditionalValues';
import { WaitingTimeAdditionalValues } from './WaitingTimeAdditionalValues';

interface IProps {
  formattedInitialPrice: string;
  totalTablePrice: string;
  waitingTimePrice: number;
  totalWaitingTime: number;
  totalWaitingPrice: string;
  tripPrice: string;
  va: string;
  tripAttachments: {
    fileUrl?: string;
    id: string;
    observation: string;
    price: string;
    type: string;
  }[];
  otherFees: number;
  extraDistance?: number;
  formattedPricePerKm: string;
  multiply: number;
  hasNightAdditional: boolean;
  nightAdditional: number;
  vehicleClassPrice: number;
  vehicleClassPercentage: number;
  waitingTimeBeforeStart: number;
  waitingTimeAfterStart: number;
  companyTablePrice: number;
  totalExtraKmPrice: string;
}

export const TablePriceDetail = ({
  formattedInitialPrice,
  totalTablePrice,
  totalWaitingPrice,
  totalWaitingTime,
  tripPrice,
  va,
  waitingTimePrice,
  otherFees,
  tripAttachments,
  multiply,
  nightAdditional,
  hasNightAdditional,
  vehicleClassPercentage,
  vehicleClassPrice,
  waitingTimeAfterStart,
  waitingTimeBeforeStart,
  formattedPricePerKm,
  companyTablePrice,
  extraDistance,
  totalExtraKmPrice,
}: IProps) => {
  return (
    <Box sx={{ marginTop: '-10px' }}>
      <Detail
        bold
        title="Valor inicial fixo"
        description={formattedInitialPrice}
      />
      <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
      <Detail bold title="Preço tabelado" description="" />
      <ul style={{ marginTop: '-12px' }}>
        {multiply === 2 && (
          <>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    width: '50%',
                  }}
                >
                  <Typography>Preço tabelado na ida:</Typography>
                  <ArrowForward style={{ color: '#3394D9' }} />
                </Box>

                <Typography>
                  {(companyTablePrice / 2).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Box>
            </li>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    width: '50%',
                  }}
                >
                  <Typography>Preço tabelado na volta:</Typography>
                  <ArrowBack style={{ color: '#3394D9' }} />
                </Box>

                <Typography>
                  {(companyTablePrice / 2).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Box>
            </li>
          </>
        )}
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={{ width: '50%' }}>Preço tabelado total:</Typography>
            <Typography>
              {companyTablePrice.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Box>
        </li>
        {!!extraDistance && (
          <>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography sx={{ width: '50%' }}>Km excedido:</Typography>
                <Typography>{extraDistance}</Typography>
              </Box>
            </li>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography sx={{ width: '50%' }}>Preço por km:</Typography>
                <Typography>{formattedPricePerKm}</Typography>
              </Box>
            </li>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography sx={{ width: '50%' }}>
                  Preço do km excedido:
                </Typography>
                <Typography>{totalExtraKmPrice}</Typography>
              </Box>
            </li>
          </>
        )}
        {!!nightAdditional && hasNightAdditional && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>Valor noturno:</Typography>
              <Typography>{nightAdditional}%</Typography>
            </Box>
          </li>
        )}
        {!!vehicleClassPrice && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>
                Valor adicional classe do veículo:
              </Typography>
              <Typography>
                {vehicleClassPrice.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Box>
          </li>
        )}
        {!!vehicleClassPercentage && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>
                Porcentagem adicional classe do veículo:
              </Typography>
              <Typography>{vehicleClassPercentage}%</Typography>
            </Box>
          </li>
        )}
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Subtotal (preço tabelado):</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {totalTablePrice}
            </Typography>
          </Box>
        </li>
      </ul>
      <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
      {waitingTimePrice > 0 && (
        <>
          <WaitingTimeAdditionalValues
            totalWaitingPrice={totalWaitingPrice}
            totalWaitingTime={totalWaitingTime}
            waitingTimeAfterStart={waitingTimeAfterStart}
            waitingTimeBeforeStart={waitingTimeBeforeStart}
          />
          <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
        </>
      )}

      {!!otherFees && otherFees > 0 && (
        <>
          <AdditionalValues tripAttachments={tripAttachments} va={va} />
          <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
        </>
      )}
      <Box sx={{ marginTop: '10px' }}>
        <Detail title="Valor final" bold description={tripPrice} />
      </Box>
    </Box>
  );
};
