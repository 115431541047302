import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';
import { IStopPoint } from '../components/TripRequest';

type useCheckPriceOptions = {
  config?: MutationConfig<typeof checkPriceAsRenter>;
};

interface ICheckPrice {
  addressFrom: string;
  addressTo: string;
  stopPoints: IStopPoint[];
  startLat: number;
  startLong: number;
  finalLat: number;
  finalLong: number;
  companyId: string;
}

interface ICheckPriceResponse {
  estimatedDistance: number;
  estimatedTime: number;
  estimatedPrice: number;
}

export async function checkPriceAsRenter({
  addressFrom,
  addressTo,
  stopPoints,
  startLat,
  startLong,
  finalLat,
  finalLong,
  companyId,
}: ICheckPrice): Promise<ICheckPriceResponse> {
  const response = await axios.post(`/trips/checkPriceAsRenter`, {
    addressFrom,
    addressTo,
    stopPoints,
    startLat,
    startLong,
    finalLat,
    finalLong,
    companyId,
  });
  return response.data.payload;
}

export const useCheckPriceAsRenter = ({
  config,
}: useCheckPriceOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: checkPriceAsRenter,
  });
};
