import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  useTheme,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import * as z from 'zod';
import { Controller } from 'react-hook-form';
import {
  Brightness7,
  Brightness4,
  Visibility,
  VisibilityOff,
  SupportAgent,
} from '@mui/icons-material';

import { Form, InputField } from '@/components/Form';
import { useCustomTheme } from '@/providers/theme';
import { useAuth } from '@/providers/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SantaClausLoading } from '@/components/Loading/SantaClausLoading';

const schema = z.object({
  email: z.string(),
  password: z.string(),
  rememberMe: z.boolean().optional(),
});

type LoginValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { signIn, loading, customLoading } = useAuth();
  const { toggleColorMode } = useCustomTheme();
  const [showPassword, setShowPassword] = useState(false);

  function togglePassword() {
    setShowPassword(prev => !prev);
  }

  return (
    <>
      <Form<LoginValues, typeof schema>
        onSubmit={async values => {
          await signIn(values, onSuccess);
        }}
        schema={schema}
      >
        {({ register, formState, control }) => (
          <>
            <InputField
              sx={{ marginBottom: '15px' }}
              type="text"
              name="email"
              label="Login"
              error={!!formState.errors.email}
              errorMessage={formState.errors.email}
              registration={register('email')}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth {...field}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Senha
                  </InputLabel>
                  <OutlinedInput
                    required
                    label="Senha"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    error={!!formState.errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          onMouseDown={e => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                  />
                  {formState.errors.password && (
                    <FormHelperText error>
                      {formState.errors.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Controller
                name="rememberMe"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox size="small" color="primary" {...field} />
                    }
                    label="Lembrar de mim"
                  />
                )}
              />
              <Tooltip title="suporte">
                <IconButton onClick={() => navigate('/support')}>
                  <SupportAgent />
                </IconButton>
              </Tooltip>
            </Box>

            <LoadingButton
              loading={loading || customLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </LoadingButton>
          </>
        )}
      </Form>
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
      </IconButton>
    </>
  );
};
