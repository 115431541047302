/* eslint-disable react/destructuring-assignment */
import { ContentLayout, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { useAuth } from '@/providers/auth';
import { useGetCompanyProfiles } from '@/features/profiles';
import { useRoles, useUpdateCompanyNew } from '../api';
import { Companies } from '../types';

type Props = {
  companiesQuery?: Companies;
  rolesQuery?: any[];
};

export const RenterUpdate = ({ companiesQuery, rolesQuery }: Props) => {
  const [trackerId, setTrackerId] = useState(companiesQuery?.trackerId || '');
  const { data: profiles, isLoading: isLoadingProfiles } =
    useGetCompanyProfiles();
  const { data: roles, isLoading: isLoadingRoles } = useRoles();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
    form: {
      padding: '40px',
    },
  }));
  const classes = useStyles({ isMobile });

  const { mutateAsync, isLoading: loadingUpdate } = useUpdateCompanyNew();

  const preloadedValues = {
    password: '',
    email: companiesQuery?.email,
    corporateName: companiesQuery?.corporateName,
    fantasyName: companiesQuery?.fantasyName,
    industry: companiesQuery?.industry,
    number: companiesQuery?.number,
    registeredNumber: companiesQuery?.registeredNumber,
    phone: companiesQuery?.phone?.toString(),
    personToContact: companiesQuery?.personToContact,
    zipCode: companiesQuery?.zipCode?.toString(),
    address: companiesQuery?.address,
    state: companiesQuery?.state,
    city: companiesQuery?.city,
    neighborhood: companiesQuery?.neighborhood,
    driverFees: companiesQuery?.priceList[0]?.driverFees || '0',
    userEmail: companiesQuery?.user[0]?.email,
    name: companiesQuery?.user[0]?.name,
    userPhone: companiesQuery?.user[0]?.phone,
    individualRegistration: companiesQuery?.user[0]?.individualRegistration,
  };

  const schema = z.object({
    email: z.string({ required_error: 'Campo obrigatório' }).email(),
    password: z.string({ required_error: 'Campo obrigatório' }).optional(),
    corporateName: z.string({ required_error: 'Campo obrigatório' }),
    fantasyName: z.string({ required_error: 'Campo obrigatório' }),
    industry: z.string({ required_error: 'Campo obrigatório' }),
    registeredNumber: z
      .string({ required_error: 'Campo obrigatório' })
      .max(18, 'Máximo 18 caracteres')
      .min(14, 'Minimo 14 caracteres'),
    phone: z
      .string({ required_error: 'Campo obrigatório' })
      .min(9, 'Minimo 9 caracteres')
      .max(15, 'Máximo 15 caracteres'),
    personToContact: z.string(),
    zipCode: z
      .string({ required_error: 'Campo obrigatório' })
      .min(8, 'Minimo 8 caracteres')
      .max(9, 'Máximo 9 caracteres'),
    address: z.string({ required_error: 'Campo obrigatório' }),
    number: z.string().optional(),
    city: z.string({ required_error: 'Campo obrigatório' }),
    state: z.string({ required_error: 'Campo obrigatório' }),
    neighborhood: z.string({ required_error: 'Campo obrigatório' }),
    driverFees: z.string().optional(),
    userEmail: z.string({ required_error: 'Campo obrigatório' }),
    name: z.string({ required_error: 'Campo obrigatório' }),
    userPhone: z
      .string({ required_error: 'Campo obrigatório' })
      .min(9, 'Minimo 9 caracteres')
      .max(15, 'Máximo 15 caracteres'),
    individualRegistration: z
      .string({ required_error: 'Campo obrigatório' })
      .min(11, 'Deve ter pelo menos 11 caracteres'),
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const [profile, setProfile] = useState(
    companiesQuery?.user[0]?.roleProfileId || '',
  );

  const handlePermitions = (role: { name: string; id: string }) => {
    setProfile(role.id);
  };

  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    rolesQuery?.map((role: { id: string }) => role.id) || [],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAlreadySelected = selectedRoles.find(
      data => data === event.target.value,
    );
    if (isAlreadySelected) {
      setSelectedRoles((prevRoles: string[]) =>
        prevRoles.filter(data => data !== event.target.value),
      );
      return;
    }
    setSelectedRoles((prevRoles: string[]) =>
      prevRoles.concat(event.target.value),
    );
  };

  const OnSubmit = (values: any) => {
    const data = {
      ...values,
      trackerId,
      roles: selectedRoles,
      status: true,
      userId: companiesQuery?.user[0].id,
      id: companiesQuery?.id,
      profile,
    };
    mutateAsync({ data });
  };

  return (
    <ContentLayout goBackButton>
      <Box
        sx={{ background: theme.palette.mode === 'dark' ? '#212121' : '#FFF' }}
      >
        <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
          <form className={classes.form} onSubmit={handleSubmit(OnSubmit)}>
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Editando empresa
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="email"
                        type="email"
                        label="Email"
                        onChange={onChange}
                        value={value}
                        placeholder="Email da empresa"
                        errorMessage={formState.errors.email?.message}
                        error={!!formState.errors.email}
                      />
                    )}
                  />

                  <Controller
                    name="corporateName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="corporateName"
                        type="text"
                        label="Razão Social"
                        onChange={onChange}
                        value={value}
                        placeholder="Nome real da empresa"
                        errorMessage={formState.errors.corporateName?.message}
                        error={!!formState.errors.corporateName}
                      />
                    )}
                  />

                  <Controller
                    name="fantasyName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="fantasyName"
                        type="text"
                        label="Nome Fantasia"
                        onChange={onChange}
                        value={value}
                        placeholder="Nome da empresa"
                        errorMessage={formState.errors.fantasyName?.message}
                        error={!!formState.errors.fantasyName}
                      />
                    )}
                  />

                  <Controller
                    name="industry"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="industry"
                        type="text"
                        label="Ramo"
                        onChange={onChange}
                        value={value}
                        placeholder="Ramo de atuação da empresa"
                        errorMessage={formState.errors.industry?.message}
                        error={!!formState.errors.industry}
                      />
                    )}
                  />

                  <Controller
                    name="registeredNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="registeredNumber"
                        type="text"
                        label="CNPJ"
                        onChange={onChange}
                        value={value}
                        placeholder="99.999.999/9999-99"
                        errorMessage={
                          formState.errors.registeredNumber?.message
                        }
                        error={!!formState.errors.registeredNumber}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="phone"
                        type="text"
                        label="Telefone"
                        onChange={onChange}
                        value={value}
                        placeholder="(99) 99999-9999"
                        errorMessage={formState.errors.phone?.message}
                        error={!!formState.errors.phone}
                      />
                    )}
                  />

                  <Controller
                    name="personToContact"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="personToContact"
                        type="text"
                        label="Pessoa para contato"
                        onChange={onChange}
                        value={value}
                        placeholder="Pessoa para contato na Empresa"
                        errorMessage={formState.errors.personToContact?.message}
                        error={!!formState.errors.personToContact}
                      />
                    )}
                  />

                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="personToContact"
                        type="text"
                        label="CEP"
                        onChange={onChange}
                        value={value}
                        placeholder="99999-999"
                        errorMessage={formState.errors.zipCode?.message}
                        error={!!formState.errors.zipCode}
                      />
                    )}
                  />

                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="address"
                        type="text"
                        label="Endereço"
                        onChange={onChange}
                        value={value}
                        placeholder="Logradouro e número da empresa"
                        errorMessage={formState.errors.address?.message}
                        error={!!formState.errors.address}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="city"
                        type="text"
                        label="Cidade"
                        onChange={onChange}
                        value={value}
                        placeholder="Cidade"
                        errorMessage={formState.errors.city?.message}
                        error={!!formState.errors.city}
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="state"
                        type="text"
                        label="Estado"
                        onChange={onChange}
                        value={value}
                        placeholder="Estado"
                        errorMessage={formState.errors.state?.message}
                        error={!!formState.errors.state}
                      />
                    )}
                  />

                  <Controller
                    name="number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="number"
                        type="text"
                        label="Número"
                        onChange={onChange}
                        value={value}
                        placeholder="Número"
                        errorMessage={formState.errors.number?.message}
                        error={!!formState.errors.number}
                      />
                    )}
                  />

                  <Controller
                    name="neighborhood"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="neighborhood"
                        type="text"
                        label="Bairro"
                        onChange={onChange}
                        value={value}
                        placeholder="Bairro"
                        errorMessage={formState.errors.neighborhood?.message}
                        error={!!formState.errors.neighborhood}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Box className={classes.wrapper}>
                    {user.user.accessType === 'master' && (
                      <Controller
                        name="driverFees"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="driverFees"
                            type="number"
                            label="Taxa do motorista"
                            onChange={onChange}
                            value={value}
                            placeholder="0"
                            errorMessage={formState.errors.driverFees?.message}
                            error={!!formState.errors.driverFees}
                          />
                        )}
                      />
                    )}
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? 'white' : 'red',
                        fontSize: '22px',
                      }}
                    >
                      Editando usuário administrador
                    </Typography>

                    <Controller
                      name="userEmail"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="userEmail"
                          type="text"
                          label="Login"
                          onChange={onChange}
                          value={value}
                          placeholder="Login do usuário master"
                          errorMessage={formState.errors.userEmail?.message}
                          error={!!formState.errors.userEmail}
                        />
                      )}
                    />

                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="name"
                          type="text"
                          label="Nome"
                          onChange={onChange}
                          value={value}
                          placeholder="Nome do usuário"
                          errorMessage={formState.errors.name?.message}
                          error={!!formState.errors.name}
                        />
                      )}
                    />

                    <Controller
                      name="userPhone"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="userPhone"
                          type="text"
                          label="Telefone"
                          onChange={onChange}
                          value={value}
                          placeholder="(99) 99999-9999"
                          errorMessage={formState.errors.userPhone?.message}
                          error={!!formState.errors.userPhone}
                        />
                      )}
                    />

                    <Controller
                      name="individualRegistration"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="individualRegistration"
                          type="text"
                          label="CPF"
                          onChange={onChange}
                          value={value}
                          placeholder="999.999.999-99"
                          errorMessage={
                            formState.errors.individualRegistration?.message
                          }
                          error={!!formState.errors.individualRegistration}
                        />
                      )}
                    />

                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="password"
                          type="password"
                          label="Senha"
                          onChange={onChange}
                          value={value}
                          placeholder="Senha"
                          errorMessage={formState.errors.password?.message}
                          error={!!formState.errors.password}
                        />
                      )}
                    />
                  </Box>
                  {user.user.accessType === 'master' && (
                    <>
                      <Box sx={{ margin: '10px' }}>
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? 'white' : 'red',
                            fontSize: '22px',
                          }}
                        >
                          Perfil do usuário administrador
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {isLoadingProfiles ? (
                            <Box>Carregando</Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                marginTop: '10px',
                                flexWrap: 'wrap',
                              }}
                            >
                              {profiles?.map(data => (
                                <FormControlLabel
                                  key={data.id}
                                  control={
                                    <Checkbox
                                      checked={data.id === profile}
                                      onChange={() => handlePermitions(data)}
                                    />
                                  }
                                  label={data.name}
                                />
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Box sx={{ margin: '10px' }}>
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? 'white' : 'red',
                            fontSize: '22px',
                          }}
                        >
                          Permissões da empresa
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {isLoadingRoles ? (
                            <Box>Carregando</Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                marginTop: '10px',
                                flexWrap: 'wrap',
                              }}
                            >
                              {roles
                                ?.filter(role => role.isCompanyPermission)
                                .map(data => (
                                  <FormControlLabel
                                    sx={{ flex: '1' }}
                                    key={data.id}
                                    value={data.id}
                                    control={
                                      <Checkbox
                                        checked={
                                          !!selectedRoles.find(
                                            selectedRole =>
                                              selectedRole === data.id,
                                          )
                                        }
                                        onChange={handleChange}
                                      />
                                    }
                                    label={data.type}
                                  />
                                ))}
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Box sx={{ margin: '10px' }}>
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? 'white' : 'red',
                            fontSize: '22px',
                          }}
                        >
                          Id do Monitoramento
                        </Typography>

                        <TextField
                          sx={{ width: '90px' }}
                          size="small"
                          name="trackerId"
                          type="text"
                          label="Id"
                          onChange={e => setTrackerId(e.target.value)}
                          value={trackerId}
                          placeholder="111"
                        />
                      </Box>
                    </>
                  )}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    sx={{ marginTop: '10px' }}
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={loadingUpdate}
                  >
                    {loadingUpdate ? 'Carregando...' : 'Salvar'}
                  </Button>
                </Box>
              </Grid>
            </>
          </form>
        </Paper>
      </Box>
    </ContentLayout>
  );
};
