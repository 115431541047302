import { axios } from '@/lib/axios';
import { useInfiniteQuery } from 'react-query';
import { IChatMessage } from '../types';

interface IOutput {
  total: number;
  messages: IChatMessage[];
  hasMore: boolean;
}

export async function listMessages({
  renterUserId,
  driverId,
  pageParam = 1,
}: {
  renterUserId: string;
  driverId: string;
  pageParam?: number;
}): Promise<IOutput> {
  const response = await axios.get(
    `chat/listMessages?renterUserId=${renterUserId}&driverId=${driverId}&page=${pageParam}`,
  );
  return response.data.payload;
}

export const useListMessages = ({
  renterUserId,
  driverId,
}: {
  renterUserId: string;
  driverId: string;
}) => {
  return useInfiniteQuery(
    ['messages', renterUserId, driverId],
    ({ pageParam }) =>
      listMessages({
        driverId,
        renterUserId,
        pageParam,
      }),
    {
      getNextPageParam: (lastPage: IOutput, allPages) => {
        const nextPage = allPages.length + 1;
        const hasMore = lastPage?.total >= allPages.length * 15;
        return hasMore ? nextPage : undefined;
      },
      enabled: !!renterUserId && !!driverId,
    },
  );
};
