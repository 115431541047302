import {
  Box,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '@/assets/img/OneLogo.png';
import { MobileSideBar, Modal } from '@/components';
import { useEffect, useState } from 'react';
import robot from '@/assets/json/robot.json';
import robotDark from '@/assets/json/robotDark.json';
import Lottie from 'lottie-react';
import { VersionUpdates } from '@/features/home';
import { useSectorsList } from '@/features/sectors';
import { useNavigate } from 'react-router-dom';
import { useCustomTheme } from '@/providers/theme';
import { ChangePasswordForm } from '@/components/ChangePasswordForm';
import { useUserStore } from '@/stores';
import { storage } from '@/utils/storage';
import { useAuth } from '@/providers/auth';
import { SectorLimitPopup } from './SectorLimitPopup';

export const HomeSector = () => {
  const { user } = useAuth();
  const [needsToChangePassword, setNeedsToChangePassword] = useState(
    user.user.needToChangePassword,
  );
  const { subTitle, homePageLogo } = useCustomTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useSectorsList();
  const [isSectorLimitPopupOpen, setIsSectorLimitPopupOpen] = useState(false);
  const [exceedLimitText, setExceedLimitText] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      background: theme.palette.background.default,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: ({ isDark }: { isDark?: boolean }) => (isDark ? 'white' : 'black'),
    },
  }));
  const classes = useStyles({ isDark });

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      const exceedCostLimit = data.find(
        sector =>
          sector.limitOfCosts &&
          Number(sector.costLimitConsumed) >=
            0.8 * Number(sector.limitOfCosts || 0),
      );

      const exceedRequestLimit = data.find(
        sector =>
          sector.limitOfTrips &&
          Number(sector.tripLimitConsumed) >=
            0.8 * Number(sector.limitOfTrips || 0),
      );

      if (exceedCostLimit) {
        setExceedLimitText(
          exceedCostLimit
            ? `O setor ${exceedCostLimit.name} excedeu 80% do limite de custo`
            : '',
        );
        setIsSectorLimitPopupOpen(true);
      }

      if (exceedRequestLimit) {
        setExceedLimitText(
          exceedRequestLimit
            ? `O setor ${exceedRequestLimit.name} excedeu 80% do limite de solicitações`
            : '',
        );
        setIsSectorLimitPopupOpen(true);
      }
    }
  }, [data, isLoading]);
  return (
    <>
      {needsToChangePassword && (
        <Modal
          dialogContent={
            <ChangePasswordForm
              onSuccess={() => {
                useUserStore.setState({
                  user: {
                    user: {
                      ...user.user,
                      needToChangePassword: false,
                    },
                  },
                });
                const hasLocalStorage = storage.getItem({
                  key: 'user',
                  storageType: 'local',
                });
                if (hasLocalStorage) {
                  storage.setItem({
                    key: 'user',
                    storageType: 'local',
                    values: {
                      user: {
                        ...user.user,
                        needToChangePassword: false,
                      },
                    },
                  });
                }
                const hasSessionStorage = storage.getItem({
                  key: 'user',
                  storageType: 'session',
                });

                if (hasSessionStorage) {
                  storage.setItem({
                    key: 'user',
                    storageType: 'session',
                    values: {
                      user: {
                        ...user.user,
                        needToChangePassword: false,
                      },
                    },
                  });
                }
                setNeedsToChangePassword(false);
              }}
            />
          }
          title="Você precisa alterar sua senha"
          open={needsToChangePassword}
          size="xs"
          onClose={() => {}}
        />
      )}
      <Modal
        open={isSectorLimitPopupOpen}
        onClose={() => setIsSectorLimitPopupOpen(false)}
        size="xs"
        title=""
        dialogContent={
          <SectorLimitPopup
            onClick={() => navigate('/sectors')}
            close={() => setIsSectorLimitPopupOpen(false)}
            text={exceedLimitText}
          />
        }
      />
      {isMobile ? (
        <>
          <Box sx={{ background: theme.palette.background.default }}>
            <MobileSideBar />
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: '15px',
                zIndex: 990,
              }}
            >
              <Tooltip title="Atualizações">
                <IconButton>
                  {theme.palette.mode === 'dark' ? (
                    <Lottie
                      style={{ width: '80px' }}
                      onClick={() => setIsOpen(true)}
                      animationData={robotDark}
                    />
                  ) : (
                    <Lottie
                      style={{ width: '80px' }}
                      onClick={() => setIsOpen(true)}
                      animationData={robot}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {isOpen && (
              <Modal
                title="Atualizações"
                dialogContent={<VersionUpdates />}
                open={isOpen}
                size="lg"
                onClose={close}
              />
            )}
            <Box className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transform: 'translate(0%, -50%)',
                }}
              >
                <img style={{ width: '100px' }} src={Logo} alt="logo" />

                <Typography
                  variant="h1"
                  sx={{ fontSize: '30px', marginTop: '20px' }}
                  className={classes.text}
                >
                  Bem-vindo ao {subTitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ paddingLeft: '80px' }} className={classes.wrapper}>
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '15px',
              zIndex: 990,
            }}
          >
            <Tooltip title="Atualizações">
              <IconButton>
                {theme.palette.mode === 'dark' ? (
                  <Lottie
                    style={{ width: '80px' }}
                    onClick={() => setIsOpen(true)}
                    animationData={robotDark}
                  />
                ) : (
                  <Lottie
                    style={{ width: '80px' }}
                    onClick={() => setIsOpen(true)}
                    animationData={robot}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          {isOpen && (
            <Modal
              title="Atualizações"
              dialogContent={<VersionUpdates />}
              open={isOpen}
              size="sm"
              onClose={close}
            />
          )}
          <img style={{ width: '150px' }} src={homePageLogo} alt="logo" />

          <Typography
            variant="h1"
            sx={{ fontSize: '3rem', marginLeft: '20px' }}
            className={classes.text}
          >
            Bem-vindo ao {subTitle}
          </Typography>
        </Box>
      )}
    </>
  );
};
