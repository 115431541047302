/* eslint-disable consistent-return */
import { loadOpenTripsResponse } from '@/features/tripRequest';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  data: loadOpenTripsResponse[];
  length: number;
  allTrips: loadOpenTripsResponse[];
}

export const getInProgressTrips = async (): Promise<IOutput> => {
  const response = await axios.get('trips/getAllTripsInProgress');
  return response.data.payload;
};

type UseInProgressTripsOptions = {
  config?: QueryConfig<typeof getInProgressTrips>;
};

export const useInProgressTrips = ({
  config,
}: UseInProgressTripsOptions = {}) => {
  return useQuery({
    queryKey: ['allTripsInProgress'],
    queryFn: () => getInProgressTrips(),
    ...config,
  });
};
