import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { IVehicleClass } from '../types';

const listClassesAsCompanyAdmin = async (): Promise<IVehicleClass[]> => {
  const response = await axios.get(
    '/vehicleClasses/listVehicleClassesAsCompanyAdmin',
  );
  return response.data.payload;
};

export const useListClassesAsCompanyAdmin = () => {
  return useQuery({
    queryKey: ['classes'],
    queryFn: listClassesAsCompanyAdmin,
  });
};
