import { Form, InputField } from '@/components';
import {
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  Paper,
  useMediaQuery,
} from '@mui/material';
import * as z from 'zod';
import { useCreatePermissions } from '../api';

export const CreatePermissions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutateAsync } = useCreatePermissions();
  const schema = z.object({
    type: z.string(),
  });

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<any, typeof schema>
          id="create-company"
          onSubmit={values => {
            mutateAsync(values);
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Permissões
              </Typography>
              <Grid container>
                <Grid sm={12} xs={12} item>
                  <InputField
                    size="small"
                    name="version"
                    type="text"
                    label="Permissões"
                    registration={register('type')}
                    error={!!formState.errors.type}
                    errorMessage={formState.errors.type?.message}
                  />
                </Grid>
              </Grid>
              <Button
                sx={{ marginTop: '10px' }}
                variant="contained"
                color="success"
                type="submit"
              >
                Adicionar
              </Button>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
