import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useTheme,
} from '@mui/material';

import { useMemo, useState } from 'react';
import { Search } from '@mui/icons-material';
import { useListVehicles } from '../api';
import { ListVehiclesTable } from './ListVehiclesTable';
import { IVehicle } from '../types';

export function ListVehiclesComponent() {
  const { data, isLoading } = useListVehicles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [onlyInactived, setOnlyInactived] = useState(false);
  const [text, setText] = useState('');

  const filteredData = data?.filter(vehicle => {
    return onlyInactived ? !vehicle.status : vehicle.status;
  });

  const filteredVehicles: IVehicle[] = useMemo(() => {
    const filter = (result: string, vehicles: IVehicle[]) => {
      const filteredData = vehicles?.filter((vehicle: IVehicle) =>
        Object.values(vehicle).some(
          value =>
            typeof value === 'string' &&
            value.toString().toLowerCase().includes(result.toLowerCase()),
        ),
      );

      return filteredData;
    };
    return filter(text, filteredData || []);
  }, [text, filteredData]);

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('/vehicles/create')}
        >
          Cadastrar novo veículo
        </Button>
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              value={text}
              onChange={e => setText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      <Toolbar>
        <FormControlLabel
          sx={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }}
          control={
            <Checkbox
              onChange={() => setOnlyInactived(prev => !prev)}
              checked={onlyInactived}
            />
          }
          label="Apenas veículos inativos"
        />
      </Toolbar>
      <ListVehiclesTable isLoading={isLoading} data={filteredVehicles || []} />
    </>
  );
}
