/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const redirectDriver = async (data: {
  id: string;
  driverId?: string;
  externalCompany?: string;
}) => {
  try {
    const response = await axios.post(
      `appointments/redirectAppointmentToAnotherDriver?id=${data.id}&driverId=${data.driverId}`,
    );
    return response.data.payload;
  } catch (err) {
    console.log(err);
  }
};

export const useRedirectDriver = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('trips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    mutationFn: redirectDriver,
  });
};
