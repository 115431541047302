import { useAuth } from '@/providers/auth';

export const useHasPermission = (
  permission:
    | 'veículos'
    | 'bater ponto'
    | 'renter'
    | 'preço estimado'
    | 'alerta de ignição',
): boolean => {
  const { user } = useAuth();
  const permissions = user?.user?.company.permissions;
  const hasPermission = permissions?.find(r => r.name === permission);
  return !!hasPermission;
};
