/* eslint-disable import/no-duplicates */
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {
  Box,
  FormGroup,
  TextField,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useState } from 'react';
import { AutoCompleteInput, PageLayout } from '@/components';
import { Folder } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { LogsTable } from '../components/LogsTable';
import { useGetUsers } from '../api';

export const Logs = () => {
  const { subTitle } = useCustomTheme();
  const { data: users } = useGetUsers();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const formattedUsers = users?.map((user: { id: string; name: string }) => ({
    id: user.id,
    name: user.name,
  }));
  const [selectClient, setSelectClient] = useState({
    id: '',
    name: '',
  });
  return (
    <>
      <PageLayout
        title="Logs do sistema"
        subTitle={subTitle}
        icon={<Folder fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <Box sx={{ display: 'flex' }}>
          <Toolbar>
            <FormGroup sx={{ gap: 1.5 }} row>
              <AutoCompleteInput
                inputId="select-user"
                label="Selecione cliente"
                onChange={option => {
                  setSelectClient({
                    id: option?.id || '',
                    name: option?.name || '',
                  });
                }}
                options={formattedUsers || []}
                selectedOption={selectClient}
                width={200}
              />
            </FormGroup>
          </Toolbar>
        </Box>
        <Toolbar>
          <FormGroup row>
            <Box sx={{ width: '200px', marginTop: isMobile ? '10px' : '0px' }}>
              <LocalizationProvider
                locale={ptLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Data inicial"
                  value={startDate}
                  onChange={newValue => {
                    setStartDate(() => newValue);
                  }}
                  renderInput={params => (
                    <TextField size="small" {...params} disabled />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                marginLeft: isMobile ? '0px' : '10px',
                marginTop: isMobile ? '10px' : '0px',
                width: '200px',
              }}
            >
              <LocalizationProvider
                locale={ptLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Data final"
                  value={endDate}
                  onChange={newValue => {
                    setEndDate(() => newValue);
                  }}
                  renderInput={params => (
                    <TextField size="small" {...params} disabled />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </FormGroup>
        </Toolbar>
        <LogsTable
          user_id={selectClient.id || undefined}
          endDate={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
          startDate={startDate ? format(startDate, 'MM/dd/yyyy') : ''}
        />
      </PageLayout>
    </>
  );
};
