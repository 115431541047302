import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { ProfileList } from './ProfileList';
import { CreateProfile } from './CreateProfile';
import { UpdateProfile } from './UpdateProfile';

export const ProfileRoutes = (): JSX.Element => {
  return (
    <Can
      roles={['gerenciar perfis']}
      permissions={['renter', 'master', 'company_admin']}
    >
      <Routes>
        <Route path="" element={<ProfileList />} />
        <Route path="/create" element={<CreateProfile />} />
        <Route path=":id" element={<UpdateProfile />} />
      </Routes>
    </Can>
  );
};
