import { ContentLayout } from '@/components';
import { CreateVehicleClassComponent } from '../components/CreateVehicleClass';

export const CreateVehicleClass = () => {
  return (
    <ContentLayout>
      <CreateVehicleClassComponent />
    </ContentLayout>
  );
};
