import { AssignmentInd, People, HourglassBottom } from '@mui/icons-material';

import { useSocket } from '@/providers/socketProvider';
import { useTheme } from '@mui/material';
import {
  useDrivers,
  useInProgressTrips,
  useTripCounter,
  useUsers,
  useUsersWaitingForTrip,
} from '../api';

export const CardDatas = () => {
  const { socket } = useSocket();
  const theme = useTheme();
  const {
    data: usersWaitingForTripData,
    isLoading: isLoadingUsersWaitingForTrip,
    refetch: refetchUsersWaitingForTrip,
  } = useUsersWaitingForTrip();
  const { data: driversData, isLoading: isLoadingDrivers } = useDrivers();
  const { data: usersData, isLoading: isLoadingUsers } = useUsers();
  const { data: tripCounterData, isLoading: isLoadingTripCounterData } =
    useTripCounter();
  const { refetch: refetchInProgressTrip } = useInProgressTrips();

  socket?.off('refetchDashboard')?.on('refetchDashboard', async () => {
    refetchInProgressTrip();
    refetchUsersWaitingForTrip();
  });

  const cardDatas = [
    {
      icon: (
        <People
          sx={{
            fontSize: '30px',
            color: theme.palette.mode === 'light' ? '#5A5A5A' : '#DFDFDF',
          }}
        />
      ),

      title: 'Usuários',
      amount: usersData?.payload?.length || 0,
      percentage: usersData?.payload?.length
        ? `${(
            (100 * usersData?.payload?.actived) /
            usersData?.payload?.length
          ).toFixed(0)}%`
        : '',
      percentageColor:
        (100 * usersData?.payload?.actived) / usersData?.payload?.length >= 50
          ? '#13AE85'
          : 'red',
      percentageDescription: 'usuários ativos',
      isLoading: isLoadingUsers,
    },
    {
      icon: (
        <AssignmentInd
          sx={{
            fontSize: '30px',
            color: theme.palette.mode === 'light' ? '#5A5A5A' : '#DFDFDF',
          }}
        />
      ),

      title: 'Motoristas',
      amount: driversData?.payload.length || 0,
      percentage:
        driversData?.payload?.length > 0
          ? `${(
              (100 * driversData?.payload?.actived) /
              driversData?.payload?.length
            ).toFixed(0)}%`
          : '',
      percentageColor:
        (100 * driversData?.payload?.actived) / driversData?.payload?.length >=
        50
          ? '#13AE85'
          : 'red',
      percentageDescription: 'motoristas ativos',
      isLoading: isLoadingDrivers,
    },
    {
      title: 'Veículos',
      amount: tripCounterData?.totalVehicles,
      percentage:
        tripCounterData?.consumedTrips !== undefined &&
        tripCounterData?.totalTrips !== undefined
          ? `${(
              (Number(tripCounterData.consumedTrips) /
                Number(tripCounterData.totalTrips) || 0) * 100
            ).toFixed(0)}%`
          : '',

      percentageColor: '#4D4D4D',
      percentageDescription: 'Limite de corridas mensais',
      isLoading: isLoadingTripCounterData,
    },
    {
      icon: (
        <HourglassBottom
          sx={{
            fontSize: '30px',
            color: theme.palette.mode === 'light' ? '#5A5A5A' : '#DFDFDF',
          }}
        />
      ),

      title: 'Passageiros em espera',
      amount: usersWaitingForTripData || 0,
      percentage: '',
      percentageColor: '',
      percentageDescription: '',
      isLoading: isLoadingUsersWaitingForTrip,
    },
  ];
  return {
    cardDatas,
    driversData,
    usersData,
  };
};
