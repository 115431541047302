type User = {
  user: {
    permissions?: any;
    accessType?: string;
  };
};

type ValidateUserPermissionsParams = {
  user: User;
  permissions?: any;
};

export function validateUserPermissions({
  user,
  permissions,
}: ValidateUserPermissionsParams): boolean {
  if (permissions && permissions?.length > 0) {
    const hasPermissions = permissions.includes(user?.user?.accessType);

    if (!hasPermissions) {
      return false;
    }
  }

  return true;
}
