import { axios } from '@/lib/axios';

import { useQuery } from 'react-query';

interface IRequest {
  tripId: string;
}

interface IOutput {
  id: string;
  action: string;
  observations: Array<{
    key: string;
    value: string;
  }>;
  user: string;
  createdAt: string;
}

export async function getTripHistory({ tripId }: IRequest): Promise<IOutput[]> {
  const response = await axios.get(`trips/getTripHistory/${tripId}`);

  return response.data.payload;
}

export const useGetTripHistory = ({ tripId }: IRequest) => {
  return useQuery({
    queryKey: ['tripHistory', tripId],
    queryFn: () => getTripHistory({ tripId }),
  });
};
