import { BarChart } from './BarChart';
import { LineChart } from './LineChart';

export type IChatValue = number[] | { [key: string]: number };

interface IRequest {
  label: string;
  gradient?: string[];
  chartValues: IChatValue;
  endDate?: string;
  startDate?: string;
  color?: string;
  chartType?: 'line' | 'bar';
  rangeType?: 'year' | 'month' | 'day' | 'alternative';
  customDataset?: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: any;
    fill: boolean;
    yAxisID: 'y' | 'x';
    tension?: number;
  }[];
  legend?: boolean;
}

export const Chart = ({
  chartValues,
  label,
  color,
  gradient,
  customDataset,
  endDate,
  startDate,
  rangeType = 'year',
  chartType = 'line',
  legend = false,
}: IRequest) => {
  if (chartType === 'bar') {
    return (
      <BarChart
        color={color}
        label={label}
        endDate={endDate}
        startDate={startDate}
        chartValues={chartValues}
        gradients={gradient}
        rangeType={rangeType}
        customDataset={customDataset}
        legend={legend}
      />
    );
  }
  return (
    <LineChart
      color={color}
      label={label}
      endDate={endDate}
      startDate={startDate}
      chartValues={chartValues}
      gradients={gradient}
      rangeType={rangeType}
      customDataset={customDataset}
      legend={legend}
    />
  );
};
