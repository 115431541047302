import * as z from 'zod';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Form, InputField } from '@/components/Form';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreatePriceList } from '../types/index';
import { useSaveCompanyPrice } from '../api/saveCompanyPrice';
import { useCompaniesWithPrice } from '../api';

const schema = z.object({
  corporateName: z.string({ required_error: 'Campo obrigatório' }),
  addressFrom: z.string({ required_error: 'Campo obrigatório' }),
  addressTo: z.string({ required_error: 'Campo obrigatório' }),
  distance: z.string({ required_error: 'Campo obrigatório' }),
  price: z.string({ required_error: 'Campo obrigatório' }),
});

export function CreatePrice() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const { data: companiesWithPriceData } = useCompaniesWithPrice();

  const classes = useStyles({ isMobile });
  const { mutateAsync, isLoading: isLoadingSaveCompanyWithPrice } =
    useSaveCompanyPrice();

  return (
    <Box>
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <Form<CreatePriceList, typeof schema>
          id="save-price"
          onSubmit={values => {
            mutateAsync(values);
          }}
          schema={schema}
        >
          {({ register, formState, control }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                }}
              >
                Cadastrar novo preço
              </Typography>
              <Grid container>
                <Grid md={6} xs={12} item>
                  <InputField
                    size="small"
                    name="corportateName"
                    type="text"
                    select
                    label="Empresa"
                    registration={register('corporateName')}
                    error={!!formState.errors.corporateName}
                    errorMessage={formState.errors.corporateName?.message}
                  >
                    {companiesWithPriceData?.map((option: any) => (
                      <MenuItem key={option.id} value={option.corporateName}>
                        {option.corporateName}
                      </MenuItem>
                    ))}
                  </InputField>
                  <InputField
                    size="small"
                    name="addressFrom"
                    type="text"
                    label="Partida"
                    registration={register('addressFrom')}
                    error={!!formState.errors.addressFrom}
                    errorMessage={formState.errors.addressFrom?.message}
                  />

                  <InputField
                    size="small"
                    name="addressTo"
                    type="text"
                    label="Destino"
                    registration={register('addressTo')}
                    error={!!formState.errors.addressTo}
                    errorMessage={formState.errors.addressTo?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className={classes.wrapper}>
                    <Controller
                      name="distance"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="distance"
                          type="number"
                          label="Distância (Km)"
                          onChange={onChange}
                          value={value}
                          placeholder="2"
                          errorMessage={formState.errors.distance?.message}
                          error={!!formState.errors.distance}
                        />
                      )}
                    />
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="price"
                          type="number"
                          label="Valor cobrado (R$)"
                          onChange={onChange}
                          value={value}
                          placeholder="20"
                          errorMessage={formState.errors.price?.message}
                          error={!!formState.errors.price}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Box sx={{ display: 'flex', margin: '10px', gap: '5px' }}>
                  <Button variant="contained" color="success" type="submit">
                    {isLoadingSaveCompanyWithPrice
                      ? 'carregando...'
                      : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoadingSaveCompanyWithPrice}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Form>
      </Paper>
    </Box>
  );
}
