/* eslint-disable no-nested-ternary */
import { GpsNotFixed, GpsOff, MoreVert, Person } from '@mui/icons-material';

import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useDisclosure } from '@/hooks/useDisclosure';
import { driversProps } from '../types';
import { TrackerInfo } from './TrackerInfo';
import { Modal } from '../../../components/Modal/Modal';
import { CellPhoneInfo } from './CellPhoneInfo';

export const DriversGridBody = ({
  filteredDriver,
  handleClick,
  classes,
}: {
  filteredDriver: driversProps;
  handleClick: (data: typeof filteredDriver) => void;
  classes: any;
}) => {
  const theme = useTheme();
  const {
    open: openTrackerInformation,
    isOpen: isTrackerInformationOpen,
    close: closeTrackerInformation,
  } = useDisclosure();
  const {
    open: openCellPhoneInformation,
    isOpen: isCellPhoneInformationOpen,
    close: closeCellPhoneInformation,
  } = useDisclosure();

  return (
    <>
      <TableBody sx={{ zIndex: 1 }}>
        <>
          <TableRow
            // eslint-disable-next-line react/no-array-index-key
            onClick={() => handleClick(filteredDriver)}
            className={classes.table}
          >
            <TableCell sx={{ width: '20px' }} />
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              {filteredDriver.plate}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              {filteredDriver.name.split(' ')[0]}{' '}
              {filteredDriver.name.split(' ')[1]}
            </TableCell>
            <TableCell
              sx={{
                position: 'relative',
                fontWeight: '350',
                '&:hover .passengers': {
                  display: 'flex',
                },
              }}
            >
              {filteredDriver?.users && (
                <>
                  {Array.from(Array(4).keys()).map((element, i) => {
                    const color =
                      filteredDriver.users &&
                      filteredDriver.users.length >= i + 1
                        ? theme.palette.mode === 'dark'
                          ? '#12be0f'
                          : '#0f990d'
                        : theme.palette.mode === 'dark'
                        ? '#646464'
                        : 'gray';
                    return (
                      <Person
                        fontSize="small"
                        style={{ color }}
                        key={element}
                      />
                    );
                  })}

                  <Box
                    className="passengers"
                    sx={{
                      display: 'none',
                      position: 'absolute',
                      top: '-20px',
                      right: '25px',
                      background:
                        theme.palette.mode === 'dark' ? '#2C2C2C' : '#e7e7e7',
                      color:
                        theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                      padding: '5px',
                      borderRadius: '16px',
                      minWidth: '240px',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {filteredDriver?.users?.map(
                      (user: { id: string; name: string }) => (
                        <>
                          <Typography key={user.id}>{user.name}</Typography>
                        </>
                      ),
                    )}
                  </Box>
                </>
              )}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              {filteredDriver.addressTo
                ? `${filteredDriver.addressTo?.slice(0, 30)}...`
                : ''}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              <Box sx={{ marginLeft: '5px' }}>{filteredDriver.sector}</Box>
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
                '&:hover .fullName': {
                  display: 'block',
                },
                '&:hover .slicedName': {
                  display: 'none',
                },
              }}
            >
              {filteredDriver.corporateName.length >= 10 ? (
                <>
                  <Box className="slicedName">
                    {filteredDriver.corporateName?.slice(0, 10)}...
                  </Box>
                  <Box className="fullName" sx={{ display: 'none' }}>
                    {filteredDriver.corporateName}
                  </Box>
                </>
              ) : (
                <Box>{filteredDriver.corporateName}</Box>
              )}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              <Box
                sx={{
                  background:
                    theme.palette.mode === 'dark' ? '#0f920d' : '#25C322',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  borderRadius: '10px',
                  maxWidth: '120px',
                  color: '#fff',
                }}
              >
                {filteredDriver.estimatedDateToFinishTrip}
              </Box>
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              <Box
                sx={{
                  background:
                    theme.palette.mode === 'dark' ? '#bb6707' : '#DB821A',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  borderRadius: '10px',
                  maxWidth: '120px',
                  color: '#fff',
                }}
              >
                {filteredDriver.timeInTrip}
              </Box>
            </TableCell>

            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '15px',
                }}
              >
                {filteredDriver?.speedInTheCellPhone?.toFixed(2) || 0} KM
              </Box>
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
                width: '150px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  style={{
                    fontSize: '12px',
                    marginLeft: '16px',
                    fontWeight: 'bold',
                    color: filteredDriver?.isDriverTrackerEnabled
                      ? '#0f920d'
                      : '#9A1616',
                  }}
                >
                  {filteredDriver?.isDriverTrackerEnabled ? 'ON' : 'OFF'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              {filteredDriver.lastUpdateInTheCellPhone && (
                <Box sx={{ marginLeft: '10px' }}>
                  {filteredDriver.lastUpdateInTheCellPhone}
                </Box>
              )}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {filteredDriver.driverInProgress ? (
                  <Typography sx={{ fontSize: '10px' }}>🟢</Typography>
                ) : (
                  <Typography sx={{ fontSize: '10px' }}>🔴</Typography>
                )}
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              <Tooltip
                title="Informações do rastreador"
                onClick={() => openTrackerInformation()}
              >
                <MoreVert
                  fontSize="medium"
                  sx={{
                    color:
                      theme.palette.mode === 'dark' ? '#b1b1b1' : '#646464',
                  }}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
          <Modal
            dialogContent={<TrackerInfo filteredDriver={filteredDriver} />}
            open={isTrackerInformationOpen}
            size="sm"
            onClose={closeTrackerInformation}
          />
          <Modal
            dialogContent={<CellPhoneInfo filteredDriver={filteredDriver} />}
            open={isCellPhoneInformationOpen}
            size="sm"
            onClose={closeCellPhoneInformation}
          />
        </>
      </TableBody>
    </>
  );
};
