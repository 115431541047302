import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

const getUsers = async () => {
  const response = await axios.get(`users/getClients`);
  return response.data.payload;
};

export const useGetUsers = () => {
  return useQuery({
    queryKey: ['AllUsers'],
    queryFn: getUsers,
  });
};
