import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { IVehicleClass } from '../types';

interface IRequest {
  id: string;
}

const deleteClass = async ({ id }: IRequest): Promise<IVehicleClass> => {
  const response = await axios.delete(
    `/vehicleClasses/deleteVehicleClass/${id}`,
  );
  return response.data.payload;
};

export const useDeleteClass = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries('classes');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('classes', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('classes');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Classe deletada com sucesso',
      });
    },
    mutationFn: deleteClass,
  });
};
