import { useCan } from '@/hooks/useCan';
import { forwardRef, ReactNode } from 'react';

type CanProps = {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
  restriction?: string;
};

export function Can({
  children,
  permissions,
  restriction,
  roles,
}: CanProps): JSX.Element | null {
  const userCanSeeComponent = useCan({
    permissions,
    restriction,
    roles,
  });

  if (!userCanSeeComponent) {
    return null;
  }

  return <>{children}</>;
}

export const CanWrapper = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ children, permissions, restriction, roles }: CanProps, ref: any) => {
    const userCanSeeComponent = useCan({
      permissions,
    });

    if (!userCanSeeComponent) {
      return null;
    }

    return <div ref={ref}>{children}</div>;
  },
);
