import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

export interface IGetLogsRequest {
  startDate: string;
  endDate: string;
  user_id?: string;
  user_company_id?: string;
}

interface IGetLogsResponse {
  ip: string;
  id: string;
  user_id: string;
  client: string;
  date: string;
  description?: string;
  client_type: string;
  client_version: string;
  corporateName: string;
  device: string;
  os: string;
  resp_time: string;
  status_code: string;
  url: string;
  userName: string;
  userId: string;
}

const getLogs = async ({
  startDate,
  endDate,
  user_id,
  user_company_id,
}: IGetLogsRequest): Promise<IGetLogsResponse[]> => {
  const response = await axios.get(
    `/app-logs/getLogs?startDate=${startDate}&endDate=${endDate}&user_id=${user_id}&user_company_id=${user_company_id}`,
  );
  return response.data.payload;
};

export const useGetLogs = ({
  endDate,
  startDate,
  user_company_id,
  user_id,
}: IGetLogsRequest) => {
  return useQuery({
    enabled: !!endDate && !!startDate,
    queryKey: ['getLogs', endDate, startDate, user_company_id, user_id],
    queryFn: () => getLogs({ endDate, startDate, user_company_id, user_id }),
  });
};
