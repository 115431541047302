import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { AddAddress } from '../AddAddress';
import { FavoriteAdressesList } from '../FavoriteAdressesList';
import { useFindAdresses } from '../../api';
import { IAddressDTO } from '../../types';

interface IRequest {
  onSelectAddress: (data: IAddressDTO) => void;
}

export const SelectFavoriteAddress = ({ onSelectAddress }: IRequest) => {
  const theme = useTheme();
  const { close, isOpen, open } = useDisclosure();
  const { data, isLoading } = useFindAdresses();
  return (
    <Box sx={{ padding: '10px' }}>
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.mode === 'dark' ? '#FFF' : '#808080',
          textAlign: 'center',
        }}
      >
        Favoritos
      </Typography>
      <Button
        sx={{ margin: '10px 0' }}
        size="small"
        variant="contained"
        color="success"
        onClick={() => open()}
      >
        Adicionar favorito
      </Button>
      <Box sx={{ width: '100%', height: '200px', overflowY: 'scroll' }}>
        <FavoriteAdressesList
          onSelectAddress={onSelectAddress}
          data={data || []}
          isLoading={isLoading}
        />
      </Box>
      <Modal
        onClose={() => close()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isOpen}
        size="sm"
        dialogContent={<AddAddress onClose={() => close()} />}
      />
    </Box>
  );
};
