import * as z from 'zod';

import { Button } from '@mui/material';
import { Form, InputField } from '@/components';
import { useCreateCategory } from '../api';

interface IRequest {
  onClose: () => void;
}

const schema = z.object({
  description: z.string().optional(),
  category: z.string({ required_error: 'Campo obrigatório' }),
});
export const CreateCategory = ({ onClose }: IRequest) => {
  const { mutateAsync, isLoading } = useCreateCategory();
  return (
    <>
      <Form<any, typeof schema>
        id="create-category"
        onSubmit={values => {
          mutateAsync(values, {
            onSuccess: () => {
              onClose();
            },
          });
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              size="small"
              name="category"
              type="text"
              label="Categoria"
              registration={register('category')}
              error={!!formState.errors.category}
              errorMessage={formState.errors.category?.message}
            />
            <InputField
              size="small"
              name="description"
              type="text"
              label="Descrição"
              registration={register('description')}
              error={!!formState.errors.description}
              errorMessage={formState.errors.description?.message}
            />

            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              color="success"
              type="submit"
            >
              {isLoading ? 'carregando...' : 'Adicionar'}
            </Button>
          </>
        )}
      </Form>
    </>
  );
};
