/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

export const getUsers = async () => {
  try {
    const response = await axios.get('users/getAllUsers');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

type UseUsersOptions = {
  config?: QueryConfig<typeof getUsers>;
};

export const useUsers = ({ config }: UseUsersOptions = {}) => {
  return useQuery({
    queryKey: ['allUsers'],
    queryFn: () => getUsers(),
    ...config,
  });
};
