/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';

export const getGoogleRequests = async () => {
  try {
    const response = await axios.get('googleRequests/requests-chart');
    return response.data.payload;
  } catch (err) {
    console.log(err);
  }
};

type UseLatestTripsOptions = {
  config?: QueryConfig<typeof getGoogleRequests>;
};

export const UseGetGoogleRequests = ({
  config,
}: UseLatestTripsOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    enabled: user.user.accessType === 'master',
    queryKey: ['google-requests'],
    queryFn: () => getGoogleRequests(),
    ...config,
  });
};
