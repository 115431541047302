import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { IPriceTags } from '../types';

// eslint-disable-next-line consistent-return
export async function getTaggedCompanyPrices(data: {
  companyId: string;
}): Promise<IPriceTags[]> {
  const response = await axios.get(
    `taggedAdresses/findTaggedPrices/${data.companyId}`,
  );
  return response.data.payload;
}

export const useGetTaggedCompanyPrices = ({
  companyId,
}: {
  companyId: string;
}) => {
  return useQuery({
    enabled: !!companyId,
    queryKey: ['tags', companyId],
    queryFn: () => getTaggedCompanyPrices({ companyId }),
  });
};
