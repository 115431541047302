import { ContentLayout, Loading } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetFixedPrice } from '../api';
import { UpdatePriceList } from '../components/UpdatePriceList';

export function UpdatePrice() {
  const { id } = useParams();
  const { data, isLoading } = useGetFixedPrice(id || '');

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }
  return (
    <ContentLayout>
      {data && (
        <UpdatePriceList
          addressFrom={data.addressFrom}
          addressTo={data?.addressTo}
          distance={data?.distance}
          id={data?.id}
          price={data.price}
        />
      )}
    </ContentLayout>
  );
}
