import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

type UseDeleteRecurringTripOptions = {
  config?: MutationConfig<typeof deleteRecurringTrip>;
};

export async function deleteRecurringTrip(id: string): Promise<void> {
  return axios.delete(`recurringTrips/deleteRecurringTrip/${id}`);
}

export const useDeleteRecurringTrip = ({
  config,
}: UseDeleteRecurringTripOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('recurringTrips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('recurringTrips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('recurringTrips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    ...config,
    mutationFn: deleteRecurringTrip,
  });
};
