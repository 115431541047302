import { InputField } from '@/components';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { ITaggedLocation } from '../types';
import { useGetTaggedCompanyPrices, useUpdateTaggedLocation } from '../api';

interface IRequest {
  taggedLocation: ITaggedLocation;
}

const schema = z.object({
  address: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  priceTagId: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
});

export const UpdateTaggedLocation = ({ taggedLocation }: IRequest) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data: taggedPrices } = useGetTaggedCompanyPrices({
    companyId: taggedLocation?.priceTag?.companyId || '',
  });
  const { mutateAsync, isLoading } = useUpdateTaggedLocation();
  const preloadedValues = {
    address: taggedLocation?.address,
    priceTagId: taggedLocation?.priceTagId,
    id: taggedLocation?.id,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: { address: string; priceTagId: string }) => {
    mutateAsync({
      id: taggedLocation.id,
      address: values.address,
      priceTagId: values.priceTagId,
    });
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 40 }}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
          >
            Editando localidade
          </Typography>
          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                size="small"
                name="address"
                type="text"
                placeholder="Santa Cruz"
                label="Bairro"
                onChange={onChange}
                value={value}
                errorMessage={formState.errors.address?.message}
                error={!!formState.errors.address}
              />
            )}
          />
          <Controller
            name="priceTagId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                size="small"
                name="priceTagId"
                select
                type="text"
                label="Tarifa"
                onChange={onChange}
                value={value}
                error={!!formState.errors.priceTagId}
                errorMessage={formState.errors.priceTagId?.message}
              >
                {taggedPrices?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </InputField>
            )}
          />

          <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Button variant="contained" color="success" type="submit">
              {isLoading ? 'carregando...' : 'Salvar'}
            </Button>
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
