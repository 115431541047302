import { ContentLayout } from '@/components';
import { CreateOperatorForm } from '../components';

export function CreateOperators() {
  return (
    <ContentLayout>
      <CreateOperatorForm />
    </ContentLayout>
  );
}
