import { AutoCompleteInput, Modal } from '@/components';
import { AddCustomBreak } from '@/features/clockIn/components/AddCustomBreak';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Add } from '@mui/icons-material';
import { Box, Button, FormGroup, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';

interface IWorkJourneyReportsField {
  drivers: {
    id: string;
    name: string;
  }[];
  setDriver: (driver: string) => void;
}

export const WorkJourneyReportsField = ({
  drivers,
  setDriver,
}: IWorkJourneyReportsField) => {
  const { close, isOpen, open } = useDisclosure();
  const formattedDrivers = drivers?.map(driver => ({
    id: driver.id,
    name: driver.name,
  }));
  const [selectedDriver, setSelectedDriver] = useState<{
    id: string;
    name: string;
  }>(formattedDrivers?.length > 0 ? formattedDrivers[0] : { id: '', name: '' });

  useEffect(() => {
    setDriver(selectedDriver.id);
  }, [selectedDriver, setDriver]);
  return (
    <Box sx={{ display: 'flex' }}>
      <Modal
        onClose={close}
        title="Criar Ponto Personalizado"
        open={isOpen}
        size="sm"
        dialogContent={<AddCustomBreak onSuccess={close} />}
      />
      <Toolbar>
        <FormGroup sx={{ gap: 1.5 }} row>
          <AutoCompleteInput
            inputId="select-driver-work-journey"
            label="Selecione motorista"
            onChange={option => {
              setSelectedDriver({
                id: option?.id || '',
                name: option?.name || '',
              });
              setDriver(option?.id || '');
            }}
            options={formattedDrivers}
            selectedOption={selectedDriver}
            width={200}
          />
          <Button
            sx={{ width: '200px' }}
            onClick={() => open()}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Criar Ponto
          </Button>
        </FormGroup>
      </Toolbar>
    </Box>
  );
};
