import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { GoogleRequests } from './GoogleRequests';

export const GoogleRequestsRoutes = () => {
  return (
    <>
      <Can permissions={['master']}>
        <Routes>
          <Route path="/" element={<GoogleRequests />} />
        </Routes>
      </Can>
    </>
  );
};
