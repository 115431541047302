import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';
import { IAssociateVehicleDTO } from '../types';

interface IOutput {
  isAvailable: boolean;
  notAvailableMessage?: string;
}

export async function checkIfCanBeAssociated(
  data: IAssociateVehicleDTO,
): Promise<IOutput> {
  const response = await axios.post('vehicles/checkIfCanBeAssociated', data);
  return response.data.payload;
}

export const useCheckIfCanBeAssociated = () => {
  return useMutation({
    mutationFn: checkIfCanBeAssociated,
  });
};
