import { useAuth } from '@/providers/auth';
import { useRoutes } from 'react-router-dom';

import { SantaClausLoading } from '@/components/Loading/SantaClausLoading';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = (): JSX.Element => {
  const { isAuthenticated, customLoading } = useAuth();

  const routes = isAuthenticated ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return (
    <>
      {customLoading && <SantaClausLoading isLoading={customLoading} />}
      {element}
    </>
  );
};
