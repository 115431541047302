import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Fallback, SideNav } from '@/components';
import { CompaniesNewRoutes } from '@/features/companyNew';
import { DashBoardRoutes } from '@/features/dashboard';
import { VersionLogRoutes } from '@/features/versionLog';
import { DriversRoutes } from '@/features/drivers';
import { UsersRoutes } from '@/features/users';
import {
  RequestAsAdminRoute,
  TripRequestRoute,
  ValidationRoute,
} from '@/features/tripRequest';
import { MapScreenRoutes } from '@/features/mapScreen';
import { PriceTableRoutes } from '@/features/priceTable';
import { ReportsRoutes } from '@/features/reports';
import { SectorsRoutes } from '@/features/sectors';
import { PermissionsRoutes } from '@/features/permissions';
import { StatementRoutes } from '@/features/statements';
import ChatRobot from '@/assets/img/ChatRobot.png';
import { Logs } from '@/features/logs/routes/Logs';
import { GoogleRequestsRoutes } from '@/features/googleRequests/routes';
import { VehiclesRoutes } from '@/features/vehicles';
import { CategoriesRoutes } from '@/features/categories';
import { ProfileRoutes } from '@/features/profiles';
import { OperatorsRoutes } from '@/features/operators';
import { Badge, Box, Button, Typography } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { ChatContainer } from '@/components/Chat/ChatContainer';
import { useChat } from '@/providers/chatProvider';
import Draggable from 'react-draggable';
import { useAuth } from '@/providers/auth';
import { VehicleClassesRoutes } from '@/features/vehicleClasses';
import { TagRoutes } from '@/features/Tag';

const App = () => {
  const { notCheckedMessages, closeChat, isChatOpen, toggleChat } = useChat();
  const { user } = useAuth();
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <SideNav />
        {/*
                {user?.user?.accessType === 'renter' && (
          <Box
            sx={{
              position: 'absolute',
              right: '20px',
              bottom: '20px',
              zIndex: 999,
            }}
          >
            <Draggable>
              <Button
                onClick={() => toggleChat()}
                variant="contained"
                sx={{
                  display: 'flex',
                  gap: 4,
                  borderRadius: '16px',
                  padding: '12px',
                  width: '130px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  background: '#D9D9D9',
                  height: '30px',
                  position: 'relative',
                  '&:hover': {
                    background: '#D9D9D9',
                  },
                }}
              >
                <Badge badgeContent={notCheckedMessages?.total} color="info">
                  <Chat style={{ color: '#292929' }} />
                </Badge>
                <Typography sx={{ display: 'flex', color: '#292929' }}>
                  C
                  <Typography
                    sx={{ textTransform: 'lowercase', color: '#292929' }}
                  >
                    hat
                  </Typography>
                </Typography>
                <img
                  style={{
                    position: 'absolute',
                    width: '70px',
                    height: '70px',
                    marginBottom: '80px',
                    marginRight: '10px',
                    objectFit: 'cover',
                  }}
                  src={ChatRobot}
                  alt="Chat"
                />
              </Button>
            </Draggable>

            {isChatOpen && (
              <Box
                sx={{
                  position: 'fixed',
                  right: '20px',
                  top: '40px',
                }}
              >
                <ChatContainer
                  notCheckedMessages={notCheckedMessages?.messages || []}
                  onChatClose={() => closeChat()}
                />
              </Box>
            )}
          </Box>
        )}
        */}
        <Outlet />
      </Suspense>
    </>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/companies/*', element: <CompaniesNewRoutes /> },
      { path: '/home/*', element: <DashBoardRoutes /> },
      { path: '/logs/*', element: <VersionLogRoutes /> },
      { path: '/drivers/*', element: <DriversRoutes /> },
      { path: '*', element: <Navigate to="." /> },
      { path: '/users/*', element: <UsersRoutes /> },
      { path: '/profile/*', element: <ProfileRoutes /> },
      { path: '/operators/*', element: <OperatorsRoutes /> },
      { path: '/tripsRequest', element: <TripRequestRoute /> },
      { path: '/requestTripAsAdmin', element: <RequestAsAdminRoute /> },
      { path: '/trips/*', element: <ValidationRoute /> },
      { path: '/mapscreen', element: <MapScreenRoutes /> },
      { path: '/pricetable/*', element: <PriceTableRoutes /> },
      { path: '/reports/*', element: <ReportsRoutes /> },
      { path: '/sectors/*', element: <SectorsRoutes /> },
      { path: '/statements', element: <StatementRoutes /> },
      { path: '/roles', element: <PermissionsRoutes /> },
      { path: '/vehicles/*', element: <VehiclesRoutes /> },
      { path: '/app-logs', element: <Logs /> },
      { path: '/google-requests', element: <GoogleRequestsRoutes /> },
      { path: '/categories/*', element: <CategoriesRoutes /> },
      { path: '/classes/*', element: <VehicleClassesRoutes /> },
      { path: '/tags/*', element: <TagRoutes /> },
    ],
  },
];
