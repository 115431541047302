import { useAuth } from '@/providers/auth';
import { FormGroup, MenuItem, TextField, Toolbar } from '@mui/material';

interface ISelectReportType {
  reportType: string;
  onChange: (
    reportType:
      | 'trips'
      | 'workJourney'
      | 'canceledAndFinishedTripsRelation'
      | 'timeRelation'
      | 'sectorRelation'
      | 'passengersRanking'
      | 'location'
      | 'vehicleAndTripsRelation'
      | 'driversRanking'
      | 'acceptedTimeRelation',
  ) => void;
}

const menuOptions = [
  {
    label: 'Viagens',
    value: 'trips',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="trips" value="trips">
          Viagens
        </MenuItem>
      );
    },
  },
  {
    label: 'Localidade',
    value: 'location',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="location" value="location">
          Localidade
        </MenuItem>
      );
    },
  },
  {
    label: 'Rank dos passageiros',
    value: 'passengersRanking',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="passengersRanking" value="passengersRanking">
          Rank dos passageiros
        </MenuItem>
      );
    },
  },
  {
    label: 'Rank dos operadores',
    value: 'operatorsRanking',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="operatorsRanking" value="operatorsRanking">
          Rank dos operadores
        </MenuItem>
      );
    },
  },
  {
    label: 'Rank dos motoristas',
    value: 'driversRanking',
    permissions: ['renter'],
    render: () => {
      return (
        <MenuItem key="driversRanking" value="driversRanking">
          Rank dos motoristas
        </MenuItem>
      );
    },
  },
  {
    label: 'Data de solicitação',
    value: 'timeRelation',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="timeRelation" value="timeRelation">
          Horário de solicitação
        </MenuItem>
      );
    },
  },
  {
    label: 'Data da corrida',
    value: 'timeRelation',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="acceptedTimeRelation" value="acceptedTimeRelation">
          Horário de corrida
        </MenuItem>
      );
    },
  },
  {
    label: 'Relação por centro de custo',
    value: 'sectorRelation',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem key="sectorRelation" value="sectorRelation">
          Relação por centro de custo
        </MenuItem>
      );
    },
  },
  {
    label: 'Relação de canceladas e finalizadas',
    value: 'canceledAndFinishedTripsRelation',
    permissions: ['renter', 'company_admin', 'sector_admin'],
    render: () => {
      return (
        <MenuItem
          key="canceledAndFinishedTripsRelation"
          value="canceledAndFinishedTripsRelation"
        >
          Relação de canceladas e finalizadas
        </MenuItem>
      );
    },
  },
  {
    label: 'Jornada de trabalho',
    value: 'workJourney',
    permissions: ['renter'],
    roles: ['bater ponto'],
    render: () => {
      return (
        <MenuItem key="workJourney" value="workJourney">
          Jornada de trabalho
        </MenuItem>
      );
    },
  },
  {
    label: 'Relação de veículos e viagens',
    value: 'vehicleAndTripsRelation',
    permissions: ['renter'],
    render: () => {
      return (
        <MenuItem key="vehicleAndTripsRelation" value="vehicleAndTripsRelation">
          Relação de veículos e viagens
        </MenuItem>
      );
    },
  },
  {
    label: 'Veículos',
    value: 'vehicleExchange',
    permissions: ['renter'],
    roles: ['veículos'],
    render: () => {
      return (
        <MenuItem key="vehicleExchange" value="vehicleExchange">
          Veículos
        </MenuItem>
      );
    },
  },
];
export const SelectReportType = ({
  reportType,
  onChange,
}: ISelectReportType) => {
  const { user } = useAuth();
  return (
    <Toolbar>
      <FormGroup row>
        <TextField
          label="Selecionar tipo do relatório"
          select
          size="small"
          value={reportType}
          sx={{
            width: '200px',
          }}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
        >
          {menuOptions
            .filter(data =>
              data.roles
                ? data.permissions.includes(user.user.accessType) &&
                  user.user.company.permissions.find(permission =>
                    data.roles?.includes(permission.name),
                  )
                : data.permissions.includes(user.user.accessType),
            )
            .map(option => option.render())}
        </TextField>
      </FormGroup>
    </Toolbar>
  );
};
