import * as React from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  MenuItem,
  Menu,
} from '@mui/material';
import {
  Business,
  Home,
  People,
  AssignmentInd,
  Map,
  AttachMoney,
  Folder,
  DirectionsCarFilled,
  Settings,
  Brightness7,
  Brightness4,
  Coffee,
  ListAlt,
  PowerSettingsNew,
  Paid,
  Lock,
  AirportShuttleOutlined,
  Accessibility,
  Engineering,
  Person,
} from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { useAuth } from '@/providers/auth';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useStyles } from './styles';
import { RenderNavItens } from './NavItens';
import { Modal } from '../Modal';
import { Profile } from '../Profile';

export function SideNav() {
  const { signOut } = useAuth();
  const theme = useTheme();
  const {
    isOpen: isProfileOpen,
    open: openPrifle,
    close: closeProfile,
  } = useDisclosure();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { toggleColorMode } = useCustomTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { sidebarColor, sidebarLogo } = useCustomTheme();

  return (
    <>
      <div
        className={classes.root}
        style={{
          backgroundImage: sidebarColor,
        }}
      >
        <Box className={classes.logo}>
          <img src={sidebarLogo} width={30} height={30} alt="logo" />
        </Box>
        <div className={classes.iconsWrapper}>
          <RenderNavItens
            navItens={[
              {
                icon: Home,
                label: 'Início',
                path: '/home',
                className: classes.icon,
              },
              {
                icon: DirectionsCarFilled,
                label: 'Viagens',
                path: '/tripsRequest',
                className: classes.icon,
                permissions: ['renter'],
                roles: ['gerenciar viagens'],
              },
              {
                icon: DirectionsCarFilled,
                label: 'Solicitações',
                path: '/requestTripAsAdmin',
                className: classes.icon,
                permissions: ['company_admin', 'sector_admin'],
                roles: ['solicitar viagem'],
              },
              {
                icon: Map,
                label: 'Mapa',
                path: '/mapscreen',
                className: classes.icon,
                permissions: ['renter', 'company_admin'],
                roles: ['mapa'],
              },
              {
                icon: Folder,
                label: 'Relatórios',
                path: '/reports',
                className: classes.icon,
                permissions: ['renter', 'company_admin', 'sector_admin'],
                roles: ['relatorios'],
              },
              {
                icon: ListAlt,
                label: 'Atualizações',
                path: '/logs',
                className: classes.icon,
                permissions: ['master'],
              },
              {
                icon: AssignmentInd,
                label: 'Motoristas',
                path: '/drivers',
                className: classes.icon,
                permissions: ['renter'],
                roles: ['gerenciar motoristas'],
              },
              {
                icon: Coffee,
                label: 'Setores',
                path: '/sectors',
                className: classes.icon,
                permissions: ['company_admin', 'sector_admin'],
                roles: ['gerenciar setores'],
              },
              {
                icon: People,
                label: 'Usuários',
                path: '/users',
                className: classes.icon,
                permissions: ['sector_admin', 'company_admin'],
                roles: ['gerenciar passageiros'],
              },
              {
                icon: Accessibility,
                label: 'Perfis',
                path: '/profile',
                className: classes.icon,
                permissions: ['renter', 'master', 'company_admin'],
                roles: ['gerenciar perfis'],
              },
              {
                icon: Engineering,
                label: 'Operadores',
                path: '/operators',
                className: classes.icon,
                permissions: ['renter', 'company_admin', 'master'],
                roles: ['gerenciar operadores'],
              },
              {
                icon: Business,
                label: 'Empresas',
                path: '/companies',
                className: classes.icon,
                permissions: ['master', 'renter'],
                roles: ['gerenciar clientes'],
              },
              // {
              //   icon: AttachMoney,
              //   label: 'Tabela de preços',
              //   path: '/pricetable',
              //   className: classes.icon,
              //   permissions: ['renter'],
              //   roles: ['gerenciar tabela de preços'],
              // },
              {
                icon: Paid,
                label: 'Extrato',
                path: '/statements',
                className: classes.icon,
                permissions: ['sector_admin', 'company_admin'],
                roles: ['extrato'],
              },
              {
                icon: Lock,
                label: 'Permissões',
                path: '/roles',
                className: classes.icon,
                permissions: ['master'],
              },
              {
                icon: AirportShuttleOutlined,
                label: 'Categorias',
                path: '/categories',
                className: classes.icon,
                permissions: ['master'],
              },
              {
                icon: Folder,
                label: 'Logs da aplicação',
                path: '/app-logs',
                className: classes.icon,
                permissions: ['master'],
              },
              {
                icon: Folder,
                label: 'Chamadas do google',
                path: '/google-requests',
                className: classes.icon,
                permissions: ['master'],
              },
            ]}
          />
        </div>

        <Tooltip className={classes.logout} title="Configurações">
          <IconButton onClick={handleClick}>
            <Settings
              sx={{
                color: 'white',
                '&:hover': {
                  transform: 'rotate(90deg)',
                },
                transform: 'rotate(0deg)',
                transition: 'ease-in-out 0.5s',
              }}
              fontSize="medium"
            />
          </IconButton>
        </Tooltip>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Box>
            <Tooltip title="Perfil" placement="right">
              <MenuItem onClick={openPrifle}>
                <Person fontSize="small" />
              </MenuItem>
            </Tooltip>
            <Tooltip title="tema" placement="right">
              <MenuItem onClick={toggleColorMode}>
                {theme.palette.mode === 'dark' ? (
                  <Brightness7 fontSize="small" />
                ) : (
                  <Brightness4 fontSize="small" />
                )}
              </MenuItem>
            </Tooltip>
            <Tooltip title="logout" placement="right">
              <MenuItem onClick={signOut}>
                <PowerSettingsNew sx={{ color: 'red' }} fontSize="small" />
              </MenuItem>
            </Tooltip>
          </Box>
        </Menu>
      </div>
      {isProfileOpen && (
        <Modal
          open={isProfileOpen}
          onClose={closeProfile}
          size="xs"
          title="Perfil"
          dialogContent={<Profile />}
        />
      )}
    </>
  );
}
