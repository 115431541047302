import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

type UseCompanyOptions = {
  id: string;
  config?: QueryConfig<typeof getCompany>;
};

// AxiosResponse<CompanyValues>

export const getCompany = async ({ id }: { id: string }): Promise<any> => {
  const response = await axios.post('prv/api/company/list', { id });
  return response.data.data[0];
};

export const useCompany = ({ id, config }: UseCompanyOptions) => {
  return useQuery({
    queryKey: ['companies', id],
    queryFn: () => getCompany({ id }),
    ...config,
  });
};
