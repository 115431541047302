import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { saveSector, saveSectorResponse } from '../types';

export async function createSector(
  data: saveSector,
): Promise<saveSectorResponse> {
  return axios.post('/sectors/create', data);
}

export const useCreateSector = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newSector => {
      await queryClient.cancelQueries('sectors');

      const previoussectors = queryClient.getQueryData<any[]>('sectors');

      queryClient.setQueryData('sectors', [
        ...(previoussectors || []),
        newSector,
      ]);

      return previoussectors;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('sectors');
      if (data.data.success === false) {
        addNotification({
          type: 'error',
          title: 'Error',
          message: data.msg,
        });
      }

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Setor criada com sucesso.',
      });
      navigate('/sectors');
    },
    mutationFn: createSector,
  });
};
