import {
  Avatar,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { loadOpenTripsResponse } from '@/features/tripRequest';
import Lottie from 'lottie-react';
import Carousel from 'react-material-ui-carousel';
import { Loading } from '@/components';
import waitingJson from '../../../assets/json/waiting.json';
import pin from '../../../assets/img/AddressIconChecked.png';
import driverDefault from '../../../assets/img/driver-default.png';

export function InProgressTripCard({
  inProgressData,
  isLoading = false,
}: {
  inProgressData: { data: loadOpenTripsResponse[] } | any;
  isLoading?: boolean;
}) {
  function truncateWithEllipsis(str: string, maxLength: number): string {
    if (str?.length > maxLength) {
      return `${str.substring(0, maxLength - 3)}...`;
    }
    return str;
  }
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }
  return (
    <>
      {inProgressData?.data?.length > 0 ? (
        <>
          <Carousel
            indicatorContainerProps={{
              style: {
                overflow: 'hidden',
                maxWidth: '100%',
                height: '0px',
              },
            }}
            stopAutoPlayOnHover
            interval={10000}
            navButtonsAlwaysInvisible
          >
            {inProgressData?.data?.map((i: loadOpenTripsResponse) => (
              <Box
                key={i?.id}
                sx={{
                  flex: 1,
                  padding: '10px 25px',
                  height: isXl ? '300px' : null,
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    color:
                      theme.palette.mode === 'light' ? '#373737' : '#a0a0a0',
                    fontWeight: 'bold',
                  }}
                >
                  Viagens em progresso
                </Typography>
                <Box
                  sx={{
                    flexDirection: isXl ? 'column' : 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: '#7C7C7C',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      Id:
                    </Typography>
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === 'light'
                            ? '#373737'
                            : '#a0a0a0',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {i?.id}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: '#A0C1E2',
                      padding: '8px 12px',
                      fontSize: '14px',
                      borderRadius: '20px',
                      fontWeight: 'bold',
                      color: '#373737',
                    }}
                  >
                    Em corrida
                  </Box>
                </Box>
                <Box
                  sx={{
                    maxHeight: '260px',
                    marginTop: '30px',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color:
                        theme.palette.mode === 'light' ? '#373737' : '#a0a0a0',
                    }}
                  >
                    <img src={pin} alt="pin" style={{ marginRight: '10px' }} />
                    {truncateWithEllipsis(i?.addressFrom, 50)}
                  </Box>

                  <Box
                    sx={{
                      marginTop: '20px',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color:
                        theme.palette.mode === 'light' ? '#373737' : '#a0a0a0',
                    }}
                  >
                    <img src={pin} alt="pin" style={{ marginRight: '10px' }} />
                    {i?.addressTo
                      ? truncateWithEllipsis(i?.addressTo, 50)
                      : 'Motorista à disposição'}
                  </Box>
                </Box>
                <Box
                  sx={{
                    background:
                      theme.palette.mode === 'light' ? '#ececec' : '#4e4e4e',
                    width: '100%',
                    height: '50px',
                    marginTop: isXl ? '100px' : '20px',
                    borderRadius: '50px',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 2px',
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      sx={{ height: '48px', width: '48px' }}
                      src={i?.driver?.user?.urlImage || driverDefault}
                    />
                    <Box
                      sx={{
                        flexDirection: 'column',
                        display: 'flex',
                        marginLeft: '10px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color:
                            theme.palette.mode === 'light'
                              ? '#737373'
                              : '#a0a0a0',
                        }}
                      >
                        Motorista
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color:
                            theme.palette.mode === 'light'
                              ? '#373737'
                              : '#c4c4c4',
                        }}
                      >
                        {truncateWithEllipsis(
                          i?.driver?.user?.name || i?.externalCompany || '',
                          25,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Carousel>
        </>
      ) : (
        <Grid
          sx={{
            padding: '10px 10px',
            borderBottom: `1px solid ${
              theme.palette.mode === 'light' ? '#d6d6d6' : '#424242'
            }`,
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
          }}
        >
          <Lottie animationData={waitingJson} style={{ width: '250px' }} />
          <Typography
            sx={{
              fontWeight: 'bold',
              color:
                theme.palette.mode === 'light'
                  ? 'rgba(51, 51, 51, 1)'
                  : '#DFDFDF',
            }}
          >
            Não há viagens no momento
          </Typography>
        </Grid>
      )}
    </>
  );
}
