/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  route: string;
  count: number;
}

export const getMostCalledRoutes = async (): Promise<IOutput[]> => {
  const response = await axios.get('app-logs/getMostCalledRoutes');
  return response.data.payload;
};

type UseGetMostCalledRoutesOptions = {
  config?: QueryConfig<typeof getMostCalledRoutes>;
};

export const UseGetMostCalledRoutes = ({
  config,
}: UseGetMostCalledRoutesOptions = {}) => {
  return useQuery({
    queryKey: ['mostCalledRoutes'],
    queryFn: () => getMostCalledRoutes(),
    ...config,
  });
};
