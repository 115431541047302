import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { IProfile } from '../types';

export async function getCompanyProfiles(): Promise<IProfile[]> {
  const response = await axios.get('permissions/getCompanyProfiles');
  return response.data.payload;
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getCompanyProfiles>;
};

export const useGetCompanyProfiles = ({ config }: UseCompaniesOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['profiles'],
    enabled:
      user?.user?.accessType === 'renter' ||
      user?.user?.accessType === 'master' ||
      user?.user?.accessType === 'company_admin',
    queryFn: () => getCompanyProfiles(),
    ...config,
  });
};
