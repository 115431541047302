/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Typography,
  Container,
  Paper,
  useMediaQuery,
  Grid,
  Box,
} from '@mui/material';
import { Link } from '@/components/Elements';
import { ReactNode } from 'react';
import { useCustomTheme } from '@/providers/theme';

type LayoutProps = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export function Layout({ children }: LayoutProps): JSX.Element {
  const isMobile = useMediaQuery('(max-width:900px)');
  const {
    authBackgroundColor,
    authBackgroundImage,
    authBackgroundLogo,
    authLogo,
  } = useCustomTheme();

  return (
    <>
      <Grid
        container
        sx={{
          background: isMobile ? '' : authBackgroundColor,
        }}
      >
        <Grid
          item
          md={9}
          sx={{
            backgroundImage: isMobile ? '' : `url(${authBackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                flex: 1,
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
                maxWidth: '1000px',
              }}
            >
              {authBackgroundLogo && (
                <img
                  src={authBackgroundLogo}
                  alt="logo"
                  style={{
                    width: 200,
                    height: 217,
                    objectFit: 'contain',
                    transform: `translate(50%)`,
                  }}
                />
              )}
            </Box>
          )}

          <Container
            component="main"
            maxWidth={isMobile && 'md'}
            sx={{
              display: 'flex',
              flex: 1,
              height: '100%',
              padding: 0,
              alignItems: 'center',
              justifyContent: isMobile ? 'center' : 'flex-end',
              transform: isMobile ? '' : `translate(30%)`,
            }}
          >
            <Paper
              elevation={5}
              sx={{
                width: isMobile ? '100vw' : 450,
                borderRadius: isMobile ? 0 : 5,
                height: isMobile ? '100vh' : 650,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1rem',
              }}
            >
              <img
                style={{
                  alignItems: 'center',
                  backgroundSize: 'contain',
                  width: '208px',
                }}
                src={authLogo}
                alt="Logo"
              />

              {children}
            </Paper>

            {/* <Copyright
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      /> */}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
