import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';
import { Companies, UpdateCompanyNewDTO, SaveCompanyResponse } from '../types';

export type UpdateCompanyData = {
  data: UpdateCompanyNewDTO;
};

export const updateCompany = ({
  data,
}: UpdateCompanyData): Promise<SaveCompanyResponse> => {
  return axios.put(`companies/update/${data.id}`, data);
};

type UseUpdateCompanyOptions = {
  config?: MutationConfig<typeof updateCompany>;
};

export const useUpdateCompanyNew = ({
  config,
}: UseUpdateCompanyOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingCompany => {
      await queryClient.cancelQueries(['companies', updatingCompany?.data.id]);

      const previousCompany = queryClient.getQueryData<Companies>([
        'companies',
        updatingCompany?.data.id,
      ]);

      queryClient.setQueryData(['companies', updatingCompany?.data.id], {
        ...previousCompany,
        ...updatingCompany.data,
        id: updatingCompany.data.id,
      });

      return { previousCompany };
    },
    onError: (_, __, context: any) => {
      if (context?.previousCompany) {
        queryClient.setQueryData(
          ['companies', context.previousCompany.id],
          context.previousCompany,
        );
      }
    },
    onSuccess: (_data, _variables, context) => {
      queryClient.refetchQueries(['companies', context.previousCompany.id]);

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Empresa atualizada com sucesso.',
      });
      navigate('/companies');
    },
    ...config,
    mutationFn: updateCompany,
  });
};
