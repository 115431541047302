import { useAuth } from '@/providers/auth';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { SectorStatement } from './SectorStatement';
import { SelectSector } from './SelectSector';
import { SelectUser } from './SelectUser';
import { UserStatement } from './UserStatement';

export const Statement = () => {
  const { user: userAccess } = useAuth();
  const [user, setUser] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sectorId, setSectorId] = useState('');

  if (userAccess.user.accessType === 'sector_admin') {
    return (
      <>
        <Box
          sx={{
            padding: isMobile ? '0' : '0 40px',
            alignItems: 'center',
            justifyContent: 'start',
            display: 'flex',
            flexDirection: 'column',
            marginTop: isMobile ? '10px' : '0px',
          }}
        >
          <SelectUser user={user} setUser={setUser} />
          <Box>
            <UserStatement userId={user} />
          </Box>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box
        sx={{
          padding: isMobile ? '0' : '0 40px',
          alignItems: 'center',
          justifyContent: 'start',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isMobile ? '10px' : '0px',
        }}
      >
        <SelectSector sectorId={sectorId} setSectorId={setSectorId} />

        <Box>
          <SectorStatement sectorId={sectorId} />
        </Box>
      </Box>
    </>
  );
};
