import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Toolbar,
  useTheme,
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import { Companies, useCompanies } from '@/features/companyNew';
import { DesktopTagTable } from './DesktopTagTable';
import { useGetTaggedCompanyPrices } from '../api';
import { DesktopTaggedAdresses } from './DesktopTaggedAdresses';

export function TagListTable() {
  const [selectedCheckBox, setSelectedCheckBox] = useState<
    'taggedPrices' | 'taggedAdresses'
  >('taggedPrices');
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: companies } = useCompanies();
  const taggedCompanies: Companies[] =
    companies?.filter((company: Companies) => company.payment === 'Tarifado') ||
    [];

  const [selectedCompany, setSelectedCompany] = useState<string>(
    taggedCompanies[0]?.id || '',
  );

  const { data: taggedPrices, isLoading: isLoadingTaggedPrices } =
    useGetTaggedCompanyPrices({
      companyId: selectedCompany,
    });

  const handleCompanyChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const companyId = event.target.value as string;
    setSelectedCompany(companyId);
  };

  return (
    <>
      <Select
        value={selectedCompany}
        onChange={handleCompanyChange}
        style={{ height: '44px', marginLeft: '24px' }}
        displayEmpty
      >
        <MenuItem value="">Selecione a empresa</MenuItem>
        {taggedCompanies?.map(company => (
          <MenuItem key={company.id} value={company.id}>
            {company.fantasyName}
          </MenuItem>
        ))}
      </Select>
      {selectedCompany && (
        <Box sx={{ display: 'flex' }}>
          <Toolbar>
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() => navigate(`/tags/create/${selectedCompany}`)}
            >
              Cadastrar nova tarifa
            </Button>
          </Toolbar>
          <Toolbar>
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() =>
                navigate(`/tags/createLocation/${selectedCompany}`)
              }
            >
              Cadastrar nova localidade
            </Button>
          </Toolbar>
        </Box>
      )}
      <Box sx={{ display: 'flex' }}>
        <FormGroup>
          <FormControlLabel
            sx={{
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              marginLeft: '0.5rem',
            }}
            control={
              <Checkbox
                onChange={() => setSelectedCheckBox('taggedPrices')}
                checked={selectedCheckBox === 'taggedPrices'}
              />
            }
            label="Tarifas"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            sx={{
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              marginLeft: '0.5rem',
            }}
            control={
              <Checkbox
                onChange={() => setSelectedCheckBox('taggedAdresses')}
                checked={selectedCheckBox === 'taggedAdresses'}
              />
            }
            label="Localidade"
          />
        </FormGroup>
      </Box>
      <>
        {selectedCheckBox === 'taggedPrices' ? (
          <DesktopTagTable
            tableData={taggedPrices || []}
            loading={isLoadingTaggedPrices}
          />
        ) : (
          <DesktopTaggedAdresses
            tableData={
              taggedPrices?.flatMap(tag => {
                if (tag?.taggedAdresses && tag?.taggedAdresses?.length > 0) {
                  return tag.taggedAdresses?.map(address => {
                    return {
                      id: address.id,
                      name: tag.name,
                      price: tag.price,
                      priceForDriver: tag.priceForDriver,
                      companyId: tag.companyId,
                      address: address.address,
                      priceTagId: address.priceTagId,
                    };
                  });
                }
                return [];
              }) || []
            }
            loading={isLoadingTaggedPrices}
          />
        )}
      </>
    </>
  );
}
