import { ConfirmModal, SwipeableDrawerComponent } from '@/components';
import { useOpenTrip } from '@/providers/openTrips';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useCompanyAutomaticRedirectionMode } from '@/features/companyNew/api/getCompanyAutomaticRedirectionMode';
import { useAuth } from '@/providers/auth';
import { useNotificationStore } from '@/stores';
import { useDisclosure } from '@/hooks/useDisclosure';
import { DriverDistanceRadiusSlider } from '../DriverDistanceRadiusSlider';
import { RedirectButton } from '../RedirectButton';
import { RedirectionModeFilterOption } from '../RedirectionModeFilterOptions';
import { SettingsTutorial } from '../SettingsTutorial';
import {
  useUpdateAutoRedirectMode,
  useUpdateAutoRedirectSearchRadius,
} from '../../api';

type Props = {
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
  data: any;
  openTour?: boolean;
  setOpenTour?: any;
  title?: string;
  animation?: ReactElement;
};

export const SwipeableDrawer = ({
  isOpen,
  close,
  toggle,
  data,
  openTour,
  setOpenTour,
  title,
  animation,
}: Props) => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const { data: autoRedirectData, isLoading: isLoadingMode } =
    useCompanyAutomaticRedirectionMode({ id: user.user.companyId });
  const { mutateAsync, isLoading } = useUpdateAutoRedirectSearchRadius();
  const {
    mutateAsync: updateAutoRedirectMode,
    isLoading: isUpdatingAutoRedirectMode,
  } = useUpdateAutoRedirectMode();

  const [slideValue, setSlideValue] = useState(15);
  const [mode, setMode] = useState(1);

  const { automaticRedirectionMode, setAutomaticRedirectionMode } =
    useOpenTrip();

  useEffect(() => {
    if (typeof autoRedirectData?.automaticRedirection === 'boolean') {
      setAutomaticRedirectionMode(autoRedirectData?.automaticRedirection);
      setSlideValue(() => {
        if (autoRedirectData.distance) return autoRedirectData.distance;
        return 15;
      });
      setMode(() => {
        if (autoRedirectData.mode) return autoRedirectData.mode;
        return 1;
      });
    }
  }, [autoRedirectData, setAutomaticRedirectionMode]);

  const handleChange = useCallback(
    (event: Event, newValue: any, activeThumb: number) => {
      setSlideValue(newValue);
    },
    [],
  );
  const handleClosestDrivers = useCallback(() => {
    setMode(1);
  }, [setMode]);

  const handleIdleDrivers = useCallback(() => {
    setMode(2);
  }, [setMode]);

  const handleSave = useCallback(
    (mode: number, distance: number) => {
      try {
        mutateAsync({ distance });
        updateAutoRedirectMode({ mode });
        addNotification({
          title: 'Sucesso ao salvar as configurações',
          type: 'success',
        });
      } catch (err) {
        addNotification({
          title: 'Erro ao salvar as configurações',
          type: 'error',
        });
      }
    },
    [addNotification, mutateAsync, updateAutoRedirectMode],
  );
  const options = [
    {
      bg: mode === 1 ? '#7CB9E8' : '',
      color: mode === 1 ? 'white' : '',
      bgHover: mode === 1 ? '#7CB9E8' : '',
      handle: () => handleClosestDrivers(),
      title: 'Mais próximos',
      id: '1',
    },
    {
      bg: mode === 2 ? '#7CB9E8' : '',
      color: mode === 2 ? 'white' : '',
      bgHover: mode === 2 ? '#7CB9E8' : '',
      handle: () => handleIdleDrivers(),
      title: 'Mais ociosos',
      id: '2',
    },
  ];

  const {
    isOpen: isExitConfirmationOpen,
    open: openExitConfirmation,
    close: closeExitConfirmation,
  } = useDisclosure();

  const handleClickExit = useCallback(() => {
    if (
      autoRedirectData?.distance !== slideValue ||
      autoRedirectData?.mode !== mode
    ) {
      openExitConfirmation();
    } else {
      close();
    }
  }, [
    autoRedirectData?.distance,
    autoRedirectData?.mode,
    close,
    mode,
    openExitConfirmation,
    slideValue,
  ]);

  return (
    <>
      <ConfirmModal
        close={closeExitConfirmation}
        isOpen={isExitConfirmationOpen}
        description="Existem informações alteradas não salvas, fechar assim mesmo?"
        handleConfirmation={() => close()}
      />
      {openTour ? (
        <SettingsTutorial
          setOpenTour={setOpenTour}
          openTour={openTour}
          isOpen={isOpen}
          close={() => close()}
          toggle={() => toggle()}
          animation={animation}
        />
      ) : (
        <SwipeableDrawerComponent
          title={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>{title}</Typography>
              <Tooltip arrow title="Ajuda" placement="right">
                <IconButton
                  onClick={() => setOpenTour((prev: boolean) => !prev)}
                >
                  <HelpOutlineIcon sx={{ color: 'orange' }} fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          }
          isOpen={isOpen}
          close={() => {
            close();
          }}
          toggle={() => toggle()}
        >
          <Box
            sx={{
              padding: '10px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {animation}
            </Box>
            <Typography
              sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
            >
              Modo
            </Typography>
            <RedirectButton
              isLoading={isLoadingMode}
              mode={automaticRedirectionMode}
              openTripsData={data}
            />
          </Box>
          {automaticRedirectionMode && (
            <>
              <DriverDistanceRadiusSlider
                title="Raio de busca de motoristas (KM)"
                ariaLabel="Distância máxima do motorista"
                handleChange={handleChange}
                slideValue={slideValue}
                isLoading={isLoading}
              />
              <Box
                sx={{
                  padding: '10px',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
                >
                  Filtro de motoristas
                </Typography>
                <RedirectionModeFilterOption
                  options={options}
                  mode={mode}
                  setMode={setMode}
                  isConfirming={isUpdatingAutoRedirectMode}
                />
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <Button
              onClick={() => handleSave(mode, slideValue)}
              sx={{ width: '120px' }}
              color="success"
              variant="contained"
            >
              Salvar
            </Button>
            <Button
              onClick={() => {
                handleClickExit();
              }}
              sx={{ width: '120px' }}
              variant="contained"
            >
              Fechar
            </Button>
          </Box>
        </SwipeableDrawerComponent>
      )}
    </>
  );
};
