import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { IProfile } from '../types';

export async function getProfileById(id: string): Promise<IProfile> {
  const response = await axios.get(`permissions/getProfileById/${id}`);
  return response.data.payload;
}

export const useGetProfileById = (id: string) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['profiles', id],
    enabled:
      user?.user?.accessType === 'renter' ||
      user?.user?.accessType === 'master' ||
      user?.user?.accessType === 'company_admin',
    queryFn: () => getProfileById(id),
  });
};
