import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { Categories } from './Categories';


export const CategoriesRoutes = () => {
  return (
    <>
      <Can permissions={['master']}>
        <Routes>
          <Route path="/" element={<Categories />} />
        </Routes>
      </Can>
    </>
  );
};
