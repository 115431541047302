import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SaveUserResponse } from '@/features/users';
import { ICreateOperator } from '../types';

export async function createOperator(
  data: ICreateOperator,
): Promise<SaveUserResponse> {
  return axios.post('users/registerOperator', data);
}

export const useCreateOperator = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newProfile => {
      await queryClient.cancelQueries('operators');

      const previousDrivers =
        queryClient.getQueryData<ICreateOperator[]>('operators');

      queryClient.setQueryData('operators', [
        ...(previousDrivers || []),
        newProfile,
      ]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('operators', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('operators');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Operador cadastrado com sucesso.',
      });
      navigate('/operators');
    },
    mutationFn: createOperator,
  });
};
