import Axios from 'axios';
import { useNotificationStore } from '@/stores';
import 'dotenv';
import { API_URL } from '@/config';
import { storage } from '@/utils/storage';
import { AuthTokenError } from './errors/AuthTokenError';

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

export const axios = Axios.create({
  baseURL: API_URL,
});

function getToken() {
  const localToken = storage.getItem({
    key: 'token',
    storageType: 'local',
  });
  const sessionToken = storage.getItem({
    key: 'token',
    storageType: 'session',
  });

  if (localToken) {
    return localToken.token;
  }

  if (sessionToken) {
    return sessionToken.token;
  }

  return null;
}

function getRefreshToken() {
  const localRefreshToken = storage.getItem({
    key: 'refreshToken',
    storageType: 'local',
  });
  const sessionRefreshToken = storage.getItem({
    key: 'refreshToken',
    storageType: 'session',
  });

  if (localRefreshToken) {
    return {
      refreshToken: localRefreshToken,
      type: 'local',
    };
  }

  if (sessionRefreshToken) {
    return {
      refreshToken: sessionRefreshToken,
      type: 'session',
    };
  }

  return { refreshToken: null, type: null };
}

axios.interceptors.request.use(
  config => {
    const token = getToken();

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);
axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    if (error?.response?.status === 401) {
      if (error.response.data?.errorCode === 'session expired') {
        setTimeout(() => {
          storage.clearItem({ key: 'refreshToken', storageType: 'local' });
          storage.clearItem({ key: 'token', storageType: 'local' });
          storage.clearItem({ key: 'user', storageType: 'local' });
          storage.clearItem({ key: 'refreshToken', storageType: 'session' });
          storage.clearItem({ key: 'token', storageType: 'session' });
          storage.clearItem({ key: 'user', storageType: 'session' });

          window.location.href = '/';
        }, 5000);

        useNotificationStore.getState().addNotification({
          title: 'Erro',
          type: 'error',
          message: 'Sessão encerrada, faça login novamente.',
        });

        return Promise.reject(new AuthTokenError());
      }
      if (error.response.data?.errorCode === 'token expired') {
        const { refreshToken, type } = getRefreshToken();

        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          axios
            .post(`session/refresh-token`, {
              refresh_token: refreshToken,
            })
            .then(response => {
              const {
                token,
                user,
                refreshToken: newRefreshToken,
              } = response.data;

              if (type === 'session') {
                storage.setItem({
                  key: 'refreshToken',
                  storageType: 'session',
                  values: newRefreshToken,
                });
                storage.setItem({
                  key: 'user',
                  storageType: 'session',
                  values: { user },
                });
                storage.setItem({
                  key: 'token',
                  storageType: 'session',
                  values: { token },
                });
              }

              if (type === 'local') {
                storage.setItem({
                  key: 'refreshToken',
                  storageType: 'local',
                  values: newRefreshToken,
                });
                storage.setItem({
                  key: 'user',
                  storageType: 'local',
                  values: { user },
                });
                storage.setItem({
                  key: 'token',
                  storageType: 'local',
                  values: { token },
                });
              }

              axios.defaults.headers.Authorization = `Bearer ${token}`;
              failedRequestsQueue.forEach(request => request.onSuccess(token));
              failedRequestsQueue = [];
            })
            // eslint-disable-next-line no-shadow
            .catch(error => {
              failedRequestsQueue.forEach(request => request.onFailure(error));
              failedRequestsQueue = [];
              useNotificationStore.getState().addNotification({
                title: 'Erro',
                type: 'error',
                message:
                  'Houve um problema na validação das suas credenciais, você será deslogado.',
              });

              setTimeout(() => {
                storage.clearItem({
                  key: 'refreshToken',
                  storageType: 'local',
                });
                storage.clearItem({ key: 'token', storageType: 'local' });
                storage.clearItem({ key: 'user', storageType: 'local' });
                storage.clearItem({
                  key: 'refreshToken',
                  storageType: 'session',
                });
                storage.clearItem({ key: 'token', storageType: 'session' });
                storage.clearItem({ key: 'user', storageType: 'session' });

                window.location.href = '/';
              }, 5000);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: any) => {
              originalConfig.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalConfig));
            },
            // eslint-disable-next-line no-shadow
            onFailure: (error: any) => {
              reject(error);
            },
          });
        });
      }

      setTimeout(() => {
        storage.clearItem({ key: 'refreshToken', storageType: 'local' });
        storage.clearItem({ key: 'token', storageType: 'local' });
        storage.clearItem({ key: 'user', storageType: 'local' });
        storage.clearItem({ key: 'refreshToken', storageType: 'session' });
        storage.clearItem({ key: 'token', storageType: 'session' });
        storage.clearItem({ key: 'user', storageType: 'session' });

        window.location.href = '/';
      }, 5000);

      useNotificationStore.getState().addNotification({
        title: 'Erro',
        type: 'error',
        message:
          'Houve um problema na validação das suas credenciais, você será deslogado.',
      });

      return Promise.reject(new AuthTokenError());
    }

    if (error.message === 'Network Error') {
      useNotificationStore.getState().addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Parece que há um problema de conexão.',
      });
    }
    if (error) {
      const message = error.response?.data?.message || error.message;
      useNotificationStore
        .getState()
        .addNotification({ type: 'error', title: 'Erro', message });
    }
    return Promise.reject(error);
  },
);

export const instance = Axios.create({});
delete instance.defaults.headers.common.Authorization;
