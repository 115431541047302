/* eslint-disable import/no-duplicates */
import { PageLayout } from '@/components';
import { Folder } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import ptLocale from 'date-fns/locale/pt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  FormGroup,
  TextField,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import { useCustomTheme } from '@/providers/theme';
import { RequestsTable } from '../components/RequestsTable';

export const GoogleRequests = () => {
  const { subTitle } = useCustomTheme();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  return (
    <PageLayout
      title="Chamadas do Google"
      subTitle={subTitle}
      icon={<Folder fontSize="large" style={{ color: '#0ebcdf' }} />}
    >
      <Toolbar>
        <FormGroup row>
          <Box sx={{ width: '200px', marginTop: isMobile ? '10px' : '0px' }}>
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data inicial"
                value={startDate}
                onChange={newValue => {
                  setStartDate(() => newValue);
                }}
                renderInput={params => (
                  <TextField size="small" {...params} disabled />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              marginLeft: isMobile ? '0px' : '10px',
              marginTop: isMobile ? '10px' : '0px',
              width: '200px',
            }}
          >
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data final"
                value={endDate}
                onChange={newValue => {
                  setEndDate(() => newValue);
                }}
                renderInput={params => (
                  <TextField size="small" {...params} disabled />
                )}
              />
            </LocalizationProvider>
          </Box>
        </FormGroup>
      </Toolbar>
      <RequestsTable
        endDate={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
        startDate={startDate ? format(startDate, 'MM/dd/yyyy') : ''}
      />
    </PageLayout>
  );
};
