import { Link, Table } from '@/components';
import {
  Visibility,
  EditSharp,
  DeleteSharp,
  LocalAtm,
  ChangeCircle,
} from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { useDeleteUser } from '../api';
import { UserValues } from '../types';

interface MobileTableProps {
  tableData: UserValues[];
  toggleEdit: (id: string) => void;
  toggleDelete: (id: string) => void;
  toggleVoucher: (id: string) => void;
  loading: boolean;
}

export function MobileTable({
  tableData = [],
  toggleEdit,
  toggleDelete,
  toggleVoucher,
  loading,
}: MobileTableProps) {
  const { mutateAsync } = useDeleteUser();

  const activeUser = (id: string) => {
    mutateAsync({ id });
  };

  return (
    <Table<UserValues>
      columns={[
        { title: 'Nome', field: 'name', colSpan: 2 },
        { title: 'Setor', field: 'sectorName', colSpan: 2 },
        { title: 'Tipo de acesso', field: 'accessType', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id, accessType, status } }) {
            return (
              <>
                <Tooltip title="Visualizar">
                  <IconButton onClick={() => toggleEdit(id)}>
                    <Visibility sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>
                {accessType === 'user' && (
                  <Tooltip title="Voucher">
                    <IconButton onClick={() => toggleVoucher(id)}>
                      <LocalAtm sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                )}
                {status ? (
                  <>
                    <Link to={`./${id}`}>
                      <Tooltip title="Editar">
                        <IconButton>
                          <EditSharp sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Inativar">
                      <IconButton onClick={() => toggleDelete(id)}>
                        <DeleteSharp sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Reativar">
                      <IconButton onClick={() => activeUser(id)}>
                        <ChangeCircle sx={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData?.sort((a, b) => a?.name?.localeCompare(b?.name))}
      loading={loading}
    />
  );
}
