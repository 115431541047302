import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { companyWithPriceResponse } from '../types';

// AxiosResponse
// CompanyValues

export async function getCompaniesWithPrice(
  companyId?: any,
): Promise<companyWithPriceResponse[]> {
  const response = await axios.get('companies/getCompaniesWithPriceTable');
  return response.data.payload;
}

type UseCompaniesPriceOptions = {
  config?: QueryConfig<typeof getCompaniesWithPrice>;
};

export const useCompaniesWithPrice = ({
  config,
}: UseCompaniesPriceOptions = {}) => {
  return useQuery({
    queryKey: ['companiesPrice'],
    queryFn: () => getCompaniesWithPrice(),
    ...config,
  });
};
