import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { RejectTripProps, RejectTripResponse } from '../types';

type UseRejectTripRequestOptions = {
  config?: MutationConfig<typeof rejectTripRequest>;
};

export async function rejectTripRequest(
  data: RejectTripProps,
): Promise<RejectTripResponse> {
  return axios.patch(`trips/refuse/${data.tripId}`, data);
}

export const useRejectTrip = ({ config }: UseRejectTripRequestOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async rejectedTrip => {
      await queryClient.cancelQueries('trips');
      await queryClient.cancelQueries('scheduledTrips');

      const previoustrips = queryClient.getQueryData<any[]>('trips');
      const previousscheduledTrips =
        queryClient.getQueryData<any[]>('scheduledTrips');

      queryClient.setQueryData('trips', [
        previoustrips?.filter(trip => trip !== rejectedTrip),
      ]);

      queryClient.setQueryData('scheduledTrips', [
        previousscheduledTrips?.filter(trip => trip !== rejectedTrip),
      ]);

      return previoustrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
      queryClient.invalidateQueries('scheduledTrips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Viagem recusada',
      });
    },
    ...config,
    mutationFn: rejectTripRequest,
  });
};
