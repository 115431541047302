import { ContentLayout } from '@/components';
import { Can } from '@/components/Can';
import { CreateProfileForm } from '../components/CreateProfile';

export function CreateProfile() {
  return (
    <Can roles={['gerenciar perfis']}>
      <ContentLayout>
        <CreateProfileForm />
      </ContentLayout>
    </Can>
  );
}
