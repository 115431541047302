import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { DriversValues } from '../types';

// AxiosResponse
// CompanyValues

export async function getDrivers(): Promise<DriversValues[] | any> {
  const response = await axios.get('drivers/getAll');
  return response.data.payload;
}

type UseDriverOptions = {
  config?: QueryConfig<typeof getDrivers>;
};

export const useDrivers = ({ config }: UseDriverOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['drivers'],
    enabled: user?.user?.accessType === 'renter',
    queryFn: () => getDrivers(),
    ...config,
  });
};
