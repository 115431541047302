import { ContentLayout } from '@/components';
import { CreateDriver as CreateDriverForm } from '../components/CreateDriver';

export function CreateDriver() {
  return (
    <ContentLayout>
      <CreateDriverForm />
    </ContentLayout>
  );
}
