/* eslint-disable no-param-reassign */
import * as z from 'zod';
import {
  Box,
  Button,
  FormGroup,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Form, InputField } from '@/components/Form';
import { useState } from 'react';
import { useRoles } from '@/features/companyNew';
import { CheckOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCreateCompanyProfile } from '../api';

const schema = z.object({
  name: z.string({ required_error: 'Campo obrigatório' }),
});

export function CreateProfileForm() {
  const navigate = useNavigate();
  const { data: roles, isLoading: isLoadingRoles } = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { mutateAsync, isLoading } = useCreateCompanyProfile();

  const handleChange = (roleId: string) => {
    setSelectedRoles(prevRoles => {
      if (prevRoles.includes(roleId)) {
        return prevRoles.filter(data => data !== roleId);
      }
      return [...prevRoles, roleId];
    });
  };

  const theme = useTheme();
  return (
    <Paper
      sx={{
        maxWidth: '600px',
        margin: 'auto',
        padding: '15px',
        borderRadius: '20px',
      }}
    >
      <Box>
        <Form<{ name: string }, typeof schema>
          id="create-profile"
          onSubmit={values => {
            mutateAsync({
              name: values.name,
              roles: selectedRoles,
            });
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : '#515151',
                  fontSize: '22px',
                  textAlign: 'center',
                  fontWeight: 'medium',
                }}
              >
                Cadastro de perfil
              </Typography>
              <Box>
                <Box>
                  <InputField
                    sx={{ width: '100%' }}
                    size="small"
                    name="name"
                    type="text"
                    label="Nome"
                    registration={register('name')}
                    error={!!formState.errors.name}
                    errorMessage={formState.errors.name?.message}
                    placeholder="administrativo"
                  />
                </Box>
                <FormGroup>
                  {isLoadingRoles ? (
                    <Box>Carregando</Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        margin: '10px 0px',
                        flexWrap: 'wrap',
                        gap: '15px',
                        justifyContent: 'center',
                      }}
                    >
                      {roles
                        ?.filter(role => !role.isCompanyPermission)
                        ?.map((role: any) => (
                          <Box
                            key={role.id}
                            sx={{
                              padding: '4px 15px',
                              borderRadius: '10px',
                              border:
                                theme.palette.mode === 'dark'
                                  ? '1px solid #56acee'
                                  : '1px solid #0b7ed4',
                              background: selectedRoles.includes(role.id)
                                ? '#0b7ed4'
                                : 'transparent',
                              color: selectedRoles.includes(role.id)
                                ? 'white'
                                : '#0b7ed4',
                              display: 'flex',
                              gap: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleChange(role.id)}
                          >
                            {role.type}
                            {selectedRoles.includes(role.id) && (
                              <CheckOutlined />
                            )}
                          </Box>
                        ))}
                    </Box>
                  )}
                </FormGroup>
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    sx={{
                      fontWeight: 'medium',
                    }}
                    color="success"
                    variant="contained"
                    type="submit"
                  >
                    {isLoading ? 'Carregando...' : 'Salvar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
}
