import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { getCompaniesPrice } from '../types';

// eslint-disable-next-line consistent-return
export async function getCompanyPrices(data: getCompaniesPrice): Promise<any> {
  const response = await axios.get(`priceTable/getAll/${data.companyId}`);
  return response.data.payload;
}

export const useCompaniesPrice = (companyId: string | undefined) => {
  return useQuery({
    queryKey: ['prices', companyId],
    enabled: !!companyId,
    queryFn: () => getCompanyPrices({ companyId }),
  });
};
