import * as z from 'zod';
import { InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { ICategory } from '../types';
import { useEditCategory } from '../api';

const schema = z.object({
  description: z.string().optional(),
  category: z.string({ required_error: 'Campo obrigatório' }),
});

type IRequest = {
  category: ICategory;
  onClose: () => void;
};

export function EditCategory({ category, onClose }: IRequest) {
  const { mutateAsync, isLoading: loadingUpdate } = useEditCategory();

  const preloadedValues = {
    id: category?.id,
    category: category?.category,
    description: category?.description || '',
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const OnSubmit = (values: ICategory) => {
    mutateAsync(
      { ...values, id: category.id },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
      <>
        <Controller
          name="category"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="category"
              type="text"
              label="Categoria"
              onChange={onChange}
              value={value}
              errorMessage={formState.errors.category?.message}
              error={!!formState.errors.category}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="description"
              type="text"
              label="Descrição"
              onChange={onChange}
              value={value}
              errorMessage={formState.errors.description?.message}
              error={!!formState.errors.description}
            />
          )}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            sx={{ marginTop: '10px' }}
            variant="contained"
            color="success"
            type="submit"
            disabled={loadingUpdate}
          >
            {loadingUpdate ? 'Carregando...' : 'Salvar'}
          </Button>
        </Box>
      </>
    </form>
  );
}
