import * as z from 'zod';
import { ConfirmModal, ContentLayout, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  TextField,
} from '@mui/material';
import { Filter, DeleteOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import ptLocale from 'date-fns/locale/pt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider, TimePicker } from '@mui/lab';
import { useAuth } from '@/providers/auth';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useUpdateDriver, useDriversPlateList } from '../api';
import { DriversValues } from '../types';

const schema = z.object({
  email: z.string({ required_error: 'Campo obrigatório' }),
  password: z.string().optional(),
  name: z.string({ required_error: 'Campo obrigatório' }),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(9, 'Minimo 9 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  driverLicenseCategory: z
    .string({ required_error: 'Campo obrigatório' })
    .max(2, 'Máximo 2 caracteres'),
  individualRegistration: z
    .string({ required_error: 'Campo obrigatório' })
    .max(14, 'Máximo 14 caracteres'),
  driverLicense: z
    .string({ required_error: 'Campo obrigatório' })
    .max(11, 'Máximo 11 caracteres'),
  driverLicenseValidate: z
    .string({ required_error: 'Campo obrigatório' })
    .max(10, 'Máximo 10 caracteres'),
});

type Props = {
  driversQuery: DriversValues;
};

function addSecondsToDate(seconds: number) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const newDate = new Date(currentDate.getTime() + seconds * 1000);
  return newDate;
}

export function Driver({ driversQuery }: Props) {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: plateList } = useDriversPlateList();
  const vehiclePermission = !!user.user.company.permissions.find(
    data => data.name === 'veículos',
  );
  const { mutateAsync, isLoading: loadingUpdate } = useUpdateDriver();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [urlImage, setUrlImage] = useState(driversQuery?.urlImage);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [workLoad, setWorkLoad] = useState<Date | null>(
    driversQuery.workLoad ? addSecondsToDate(driversQuery?.workLoad) : null,
  );
  const [lunchInterval, setLunchInterval] = useState<Date | null>(
    driversQuery.lunchInterval
      ? addSecondsToDate(driversQuery?.lunchInterval)
      : null,
  );
  const [startJourney, setStartJourney] = useState<Date | null>(
    driversQuery.startJourney
      ? addSecondsToDate(driversQuery?.startJourney)
      : null,
  );

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const preloadedValues = {
    password: '',
    email: driversQuery?.email,
    name: driversQuery?.name,
    phone: driversQuery?.phone,
    driverLicenseCategory: driversQuery?.driverLicenseCategory,
    individualRegistration: driversQuery?.individualRegistration?.toString(),
    driverLicense: driversQuery?.driverLicense?.toString(),
    licensePlate: driversQuery?.licensePlate,
    driverLicenseValidate: driversQuery?.driverLicenseValidate,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const OnSubmit = (values: any) => {
    let workLoadInSeconds;
    if (workLoad) {
      const hours = workLoad.getHours();
      const minutes = workLoad.getMinutes();

      workLoadInSeconds = hours * 3600 + minutes * 60;
    }
    let lunchIntervalInSeconds;
    if (lunchInterval) {
      const hours = lunchInterval.getHours();
      const minutes = lunchInterval.getMinutes();

      lunchIntervalInSeconds = hours * 3600 + minutes * 60;
    }
    let startJourneyInSeconds;
    if (startJourney) {
      const hours = startJourney.getHours();
      const minutes = startJourney.getMinutes();
      startJourneyInSeconds = hours * 3600 + minutes * 60;
    }
    const data = {
      ...values,
      id: driversQuery.id,
      status: 'true',
      urlImage: selectedFile,
      hasImageChanged: !!(urlImage !== driversQuery.urlImage),
      workLoad: workLoadInSeconds,
      lunchInterval: lunchIntervalInSeconds,
      startJourney: startJourneyInSeconds,
    };

    mutateAsync({ data });
  };

  const [showAddImageBox, setShowAddImageBox] = useState(true);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const previewURL = URL.createObjectURL(file);
      setUrlImage(previewURL);
      setSelectedFile(file);
      setShowAddImageBox(false);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteDriverImage = async () => {
    setUrlImage('');
    setSelectedFile(null);
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentLayout>
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <ConfirmModal
          close={closeDirtyFormModal}
          isOpen={isFormDirty}
          description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
          handleConfirmation={() => navigate(-1)}
        />
        <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
          <>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'red',
              }}
            >
              Editando motorista
            </Typography>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="email"
                      type="text"
                      label="Login"
                      onChange={onChange}
                      value={value}
                      placeholder="Login do motorista"
                      errorMessage={formState.errors.email?.message}
                      error={!!formState.errors.email}
                    />
                  )}
                />

                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="name"
                      type="text"
                      label="Nome"
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.name?.message}
                      error={!!formState.errors.name}
                    />
                  )}
                />
                <Controller
                  name="individualRegistration"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="individualRegistration"
                      type="text"
                      label="CPF"
                      onChange={onChange}
                      inputProps={{ minLength: 11, maxLength: 14 }}
                      value={value}
                      errorMessage={
                        formState.errors.individualRegistration?.message
                      }
                      error={!!formState.errors.individualRegistration}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="phone"
                      type="text"
                      label="Telefone"
                      inputProps={{ maxLength: 15 }}
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.phone?.message}
                      error={!!formState.errors.phone}
                    />
                  )}
                />
                <Controller
                  name="driverLicense"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="driverLicense"
                      type="text"
                      label="CNH"
                      inputProps={{ minLength: 9, maxLength: 11 }}
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.driverLicense?.message}
                      error={!!formState.errors.driverLicense}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box className={classes.wrapper}>
                  <Controller
                    name="driverLicenseValidate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="driverLicenseValidate"
                        type="text"
                        label="Validade CNH"
                        inputProps={{ maxLength: 10 }}
                        onChange={onChange}
                        value={value}
                        errorMessage={
                          formState.errors.driverLicenseValidate?.message
                        }
                        error={!!formState.errors.driverLicenseValidate}
                      />
                    )}
                  />

                  <Controller
                    name="driverLicenseCategory"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="driverLicenseCategory"
                        type="text"
                        label="Categoria CNH"
                        onChange={onChange}
                        value={value}
                        errorMessage={
                          formState.errors.driverLicenseCategory?.message
                        }
                        error={!!formState.errors.driverLicenseCategory}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="password"
                        type="password"
                        label="Senha"
                        onChange={onChange}
                        value={value}
                        errorMessage={formState.errors.password?.message}
                        error={!!formState.errors.password}
                      />
                    )}
                  />
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                    dateFormats={{
                      hours24h: 'HH:mm',
                    }}
                  >
                    <TimePicker
                      label="Carga horária (opcional)"
                      value={workLoad}
                      onChange={newValue => {
                        if (newValue) setWorkLoad(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          sx={{ zIndex: 0, marginTop: '15px' }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                    dateFormats={{
                      hours24h: 'HH:mm',
                    }}
                  >
                    <TimePicker
                      label="Intervalo de almoço (opcional)"
                      value={lunchInterval}
                      onChange={newValue => {
                        if (newValue) setLunchInterval(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          sx={{ zIndex: 0, marginTop: '15px' }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                    dateFormats={{
                      hours24h: 'HH:mm',
                    }}
                  >
                    <TimePicker
                      label="Início da jornada (opcional)"
                      value={startJourney}
                      onChange={newValue => {
                        if (newValue) setStartJourney(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          sx={{ zIndex: 0, marginTop: '15px' }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {!vehiclePermission && (
                    <>
                      <Controller
                        name="licensePlate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="licensePlate"
                            select
                            type="text"
                            label="Placa"
                            onChange={onChange}
                            value={value}
                            errorMessage={
                              formState.errors.licensePlate?.message
                            }
                            error={!!formState.errors.licensePlate}
                          >
                            <MenuItem value="">Livre</MenuItem>
                            {driversQuery.licensePlate && (
                              <MenuItem value={driversQuery.licensePlate}>
                                {driversQuery.licensePlate}
                              </MenuItem>
                            )}
                            {plateList?.length > 0 &&
                              plateList !== 'Não houve resultados!' &&
                              plateList.map((item: any, key: any) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={key} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </InputField>
                        )}
                      />
                    </>
                  )}
                </Box>
                {urlImage ? (
                  <Box
                    sx={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      marginTop: '5px',
                      position: 'relative',
                    }}
                  >
                    <form
                      action="/drivers/create"
                      method="POST"
                      encType="multipart/form-data"
                    >
                      <input
                        name="driverImage"
                        type="file"
                        accept=".jpeg, .jpg, .png"
                        onChange={handleFileInput}
                        ref={fileInputRef}
                        style={{
                          display: 'none',
                        }}
                      />
                    </form>
                    <Box
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? '#3d9b6c' : '#76eeb2',
                        position: 'absolute',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bottom: '-10px',
                        transform: 'translateX(-35px)',
                        border:
                          theme.palette.mode === 'dark'
                            ? '4px solid #292929'
                            : '4px solid #fff',
                        cursor: 'pointer',
                      }}
                      onClick={handleDivClick}
                    >
                      <Filter style={{ fontSize: '20px' }} />
                    </Box>
                    {!selectedFile && (
                      <Box
                        sx={{
                          background:
                            theme.palette.mode === 'dark'
                              ? '#b44141'
                              : '#e46464',
                          position: 'absolute',
                          borderRadius: '50%',
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bottom: '-10px',
                          transform: 'translateX(35px)',
                          border:
                            theme.palette.mode === 'dark'
                              ? '4px solid #292929'
                              : '4px solid #fff',
                          cursor: 'pointer',
                        }}
                        onClick={handleDeleteDriverImage}
                      >
                        <DeleteOutline style={{ fontSize: '20px' }} />
                      </Box>
                    )}
                    <Box
                      sx={{
                        width: '118px',
                        height: '118px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 0px 10px 0px #0000004c',
                      }}
                    >
                      <img
                        src={urlImage}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '5px',
                        position: 'relative',
                        height: '118px',
                      }}
                    >
                      <Box
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: showAddImageBox ? 'flex' : 'none',
                          marginTop: '5px',
                          height: '118px',
                          borderRadius: '10px',
                          border: '1px dotted gray',
                          flexDirection: 'column',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }}
                        onClick={handleDivClick}
                      >
                        <form
                          action="/drivers/create"
                          method="POST"
                          encType="multipart/form-data"
                        >
                          <input
                            name="driverImage"
                            type="file"
                            accept=".jpeg, .jpg, .png"
                            onChange={handleFileInput}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          />
                        </form>

                        <Filter style={{ color: 'gray' }} />
                        <Typography>Adicionar imagem</Typography>
                      </Box>
                      {urlImage && (
                        <Box
                          sx={{
                            background: 'white',
                            width: '118px',
                            height: '118px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            position: 'relative',
                            boxShadow: '0px 0px 10px 0px #0000004c',
                          }}
                          onClick={handleDivClick}
                        >
                          <Filter
                            style={{ position: 'absolute', color: '#fff' }}
                          />
                          <img
                            src={urlImage}
                            alt="Preview"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Grid>

              <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={loadingUpdate}
                >
                  {loadingUpdate ? 'Carregando' : 'Salvar'}
                </Button>
                <Button
                  onClick={() => handleGoBack()}
                  variant="contained"
                  color="primary"
                  disabled={loadingUpdate}
                >
                  Voltar
                </Button>
              </Box>
            </Grid>
          </>
        </form>
      </Paper>
    </ContentLayout>
  );
}
