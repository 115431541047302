import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import ptLocale from 'date-fns/locale/pt';

interface IProps {
  selectedRecurringTripDays: {
    id: number;
    name: string;
  }[];
  setSelectedRecurringTripDays: (days: { id: number; name: string }[]) => void;
  date: Date;
  setScheduledDate: (date: Date) => void;
}

const days = [
  {
    id: 0,
    name: 'Dom',
  },
  {
    id: 1,
    name: 'Seg',
  },
  {
    id: 2,
    name: 'Ter',
  },
  {
    id: 3,
    name: 'Qua',
  },
  {
    id: 4,
    name: 'Qui',
  },
  {
    id: 5,
    name: 'Sex',
  },
  {
    id: 6,
    name: 'Sab',
  },
];

export const RecurringTripDays = ({
  selectedRecurringTripDays,
  setSelectedRecurringTripDays,
  date,
  setScheduledDate,
}: IProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography fontSize={16} color="#696969">
        Repetir
      </Typography>
      <Typography fontSize={14} sx={{ color: theme.palette.primary.main }}>
        {selectedRecurringTripDays.length === 7
          ? 'Todos os dias'
          : selectedRecurringTripDays.map(day => day.name).join('- ')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {days.map(day => {
          const isSelected = selectedRecurringTripDays.find(
            selectedDay => selectedDay.id === day.id,
          );
          return (
            <button
              key={day.id}
              onClick={() => {
                if (isSelected) {
                  setSelectedRecurringTripDays(
                    selectedRecurringTripDays.filter(
                      selectedDay => selectedDay.id !== day.id,
                    ),
                  );
                } else {
                  setSelectedRecurringTripDays([
                    ...selectedRecurringTripDays,
                    day,
                  ]);
                }
              }}
              type="button"
              style={{
                cursor: 'pointer',
                width: '33px',
                height: '33px',
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                background: isSelected ? '#3482AE' : '#FFF',
                border: `1px solid ${isSelected ? '#3482AE' : '#A1A1A1'}`,
              }}
            >
              <Typography color={isSelected ? '#FFF' : '#696969'} fontSize={12}>
                {day.name}
              </Typography>
            </button>
          );
        })}
      </Box>
      <LocalizationProvider
        locale={ptLocale}
        dateAdapter={AdapterDateFns}
        dateFormats={{
          hours24h: 'HH:mm',
        }}
      >
        <TimePicker
          label="Horário"
          value={date}
          onChange={newValue => {
            if (newValue) setScheduledDate(newValue);
          }}
          renderInput={params => (
            <TextField
              sx={{ zIndex: 0, marginTop: '15px' }}
              size="small"
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
