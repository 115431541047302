import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteProps, deletePropsResponse } from '../types';

type UseDeleteCompanyPriceOptions = {
  config?: MutationConfig<typeof deleteCompanyPrice>;
};

export async function deleteCompanyPrice(
  data: deleteProps,
): Promise<deletePropsResponse> {
  return axios.delete(`priceTable/delete/${data.id}`);
}

export const useDeleteCompanyPrice = ({
  config,
}: UseDeleteCompanyPriceOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('prices');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('prices', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('prices');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Preço tabelado deletado com sucesso.',
      });
      navigate('/pricetable');
    },
    ...config,
    mutationFn: deleteCompanyPrice,
  });
};
