/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ConfirmModal, Modal, Table } from '@/components';
import { IconButton, Tooltip } from '@mui/material';
import {
  ChangeCircle,
  DeleteSharp,
  DirectionsCarFilled,
  EditSharp,
  NoTransfer,
} from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IVehicle } from '../types';
import { AssociateDriverToVehicle } from './AssociateDriverToVehicle';
import { useDisassociateDriver, useUpdateVehicleStatus } from '../api';

interface IRequest {
  data: IVehicle[];
  isLoading: boolean;
}

export const ListVehiclesTable = ({ data, isLoading = false }: IRequest) => {
  const navigate = useNavigate();
  const { mutateAsync: disassociateVehicle, isLoading: isDisassociating } =
    useDisassociateDriver();
  const { mutateAsync: updateVehicleStatus, isLoading: updatingVehicleStatus } =
    useUpdateVehicleStatus();
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | null>(null);
  const [selectedVehicleToDisassociate, setSelectedVehicleToDisassociate] =
    useState({
      vehicleId: '',
      driverId: '',
    });
  const [selectedVehicleToRemoved, setSelectedVehicleToRemoved] = useState({
    vehicleId: '',
  });
  const handleAssociateVehicle = (vehicle: IVehicle) => {
    setSelectedVehicle(vehicle);
  };
  return (
    <>
      <Table
        columns={[
          { title: 'Placa', field: 'licensePlate', colSpan: 2 },
          { title: 'Modelo', field: 'model', colSpan: 2 },
          { title: 'Categoria', field: 'vehicleCategoryName', colSpan: 2 },
          { title: 'Motorista', field: 'driverName', colSpan: 2 },
          {
            title: 'Ações',
            field: 'id',
            colSpan: 2,
            Cell: ({ entry: { driverName, ...rest } }) => {
              return (
                <>
                  {rest.status ? (
                    <>
                      {driverName !== 'Sem motorista' ? (
                        <Tooltip title="Desassociar motorista">
                          <IconButton
                            onClick={() => {
                              setSelectedVehicleToDisassociate({
                                vehicleId: rest.id,
                                driverId: rest.driverId,
                              });
                            }}
                          >
                            <NoTransfer />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Associar motorista">
                          <IconButton
                            onClick={() =>
                              handleAssociateVehicle({ ...rest, driverName })
                            }
                          >
                            <DirectionsCarFilled />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Inativar veículo">
                        <IconButton
                          disabled={updatingVehicleStatus}
                          onClick={() => {
                            setSelectedVehicleToRemoved({
                              vehicleId: rest.id,
                            });
                          }}
                        >
                          <DeleteSharp sx={{ color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar veículo">
                        <IconButton
                          disabled={updatingVehicleStatus}
                          onClick={() => {
                            navigate(`/vehicles/update/${rest.id}`);
                          }}
                        >
                          <EditSharp sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip title="Ativar veículo">
                      <IconButton
                        disabled={updatingVehicleStatus}
                        onClick={() => {
                          updateVehicleStatus(rest.id);
                        }}
                      >
                        <ChangeCircle sx={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              );
            },
          },
        ]}
        data={
          data.sort((a, b) => a.licensePlate.localeCompare(b.licensePlate)) ||
          []
        }
        loading={isLoading}
      />
      {selectedVehicle && (
        <Modal
          dialogContent={
            <AssociateDriverToVehicle
              onClose={() => setSelectedVehicle(null)}
              vehicle={selectedVehicle}
            />
          }
          title="Associar motorista"
          size="xs"
          onClose={() => setSelectedVehicle(null)}
          open={!!selectedVehicle}
        />
      )}
      <ConfirmModal
        isLoading={isDisassociating}
        title="Desassociar motorista"
        close={() =>
          setSelectedVehicleToDisassociate({
            driverId: '',
            vehicleId: '',
          })
        }
        handleConfirmation={() => {
          disassociateVehicle(
            {
              driverId: selectedVehicleToDisassociate.driverId,
              vehicleId: selectedVehicleToDisassociate.vehicleId,
            },
            {
              onSuccess: () => {
                setSelectedVehicleToDisassociate({
                  driverId: '',
                  vehicleId: '',
                });
              },
            },
          );
        }}
        isOpen={
          selectedVehicleToDisassociate.vehicleId !== '' &&
          selectedVehicleToDisassociate.driverId !== ''
        }
      />
      <ConfirmModal
        isLoading={updatingVehicleStatus}
        title="Tem certeza que desejar inativar o veículo?"
        close={() =>
          setSelectedVehicleToRemoved({
            vehicleId: '',
          })
        }
        handleConfirmation={() => {
          updateVehicleStatus(selectedVehicleToRemoved.vehicleId, {
            onSuccess: () => {
              setSelectedVehicleToRemoved({
                vehicleId: '',
              });
            },
          });
        }}
        isOpen={selectedVehicleToRemoved.vehicleId !== ''}
      />
    </>
  );
};
