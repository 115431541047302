import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  id: string;
  category: string;
  description?: string;
}

// eslint-disable-next-line consistent-return
export async function getVehicleCategories(): Promise<IOutput[]> {
  const response = await axios.get('vehicleCategories/listCategories');
  return response.data.payload;
}

type UseVehicleCategoriesOptions = {
  config?: QueryConfig<typeof getVehicleCategories>;
};

export const useVehicleCategories = ({
  config,
}: UseVehicleCategoriesOptions = {}) => {
  return useQuery({
    queryKey: ['vehicleCategories'],
    queryFn: () => getVehicleCategories(),
    ...config,
  });
};
