import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { TagList } from './TagList';
import { CreateTagRoute } from './CreateTag';
import { UpdateTagRoute } from './UpdateTag';
import { CreateTaggedLocationRoute } from './CreateTaggedLocation';
import { UpdateTaggedLocationRoute } from './UpdateTaggedLocation';

export const TagRoutes = () => {
  return (
    <>
      <Can permissions={['renter']}>
        <Routes>
          <Route path="/" element={<TagList />} />
          <Route path="/create/:companyId" element={<CreateTagRoute />} />
          <Route
            path="/createLocation/:companyId"
            element={<CreateTaggedLocationRoute />}
          />
          <Route
            path="/updateLocation/:id"
            element={<UpdateTaggedLocationRoute />}
          />
          <Route path="/:id" element={<UpdateTagRoute />} />
        </Routes>
      </Can>
    </>
  );
};
