import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { UpdateTag } from '../components/UpdateTag';
import { useGetTaggedPrice } from '../api';

export function UpdateTagRoute() {
  const { id } = useParams();

  const { data: tag, isLoading } = useGetTaggedPrice({ id: id || '' });
  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {tag && <UpdateTag tag={tag} />}
    </>
  );
}
