import { InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdateFinishedTrip } from '../../api';

const schema = z.object({
  manualInitialOdometer: z.number({ required_error: 'Campo obrigatório' }),
  manualFinalOdometer: z.number({ required_error: 'Campo obrigatório' }),
  manualStartOdometer: z.number({ required_error: 'Campo obrigatório' }),
});

interface IProps {
  tripId: string;
  manualInitialOdometer?: number;
  manualFinalOdometer?: number;
  manualStartOdometer?: number;
  onSuccess?: () => void;
}

export const EditFinishedTrip = ({
  tripId,
  manualFinalOdometer,
  manualInitialOdometer,
  manualStartOdometer,
  onSuccess,
}: IProps) => {
  const { mutateAsync, isLoading } = useUpdateFinishedTrip();
  const preloadedValues = {
    manualFinalOdometer,
    manualInitialOdometer,
    manualStartOdometer,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: {
    manualInitialOdometer?: number;
    manualFinalOdometer?: number;
    manualStartOdometer?: number;
  }) => {
    mutateAsync(
      {
        tripId,
        ...values,
      },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess();
          }
        },
      },
    );
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 40 }}>
        <Controller
          name="manualInitialOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualInitialOdometer"
              type="number"
              placeholder="540"
              label="Odômetro do início do deslocamento ao passageiro"
              onChange={e => onChange(Number(e.target.value) || 0)}
              value={value}
              errorMessage={formState.errors.manualInitialOdometer?.message}
              error={!!formState.errors.manualInitialOdometer}
            />
          )}
        />
        <Controller
          name="manualStartOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualStartOdometer"
              type="number"
              placeholder="550"
              label="Odômetro do início da viagem"
              onChange={e => onChange(Number(e.target.value) || 0)}
              value={value}
              errorMessage={formState.errors.manualStartOdometer?.message}
              error={!!formState.errors.manualStartOdometer}
            />
          )}
        />

        <Controller
          name="manualFinalOdometer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputField
              size="small"
              name="manualFinalOdometer"
              type="number"
              placeholder="560"
              label="Odômetro do final da viagem"
              onChange={e => onChange(Number(e.target.value) || 0)}
              value={value}
              errorMessage={formState.errors.manualFinalOdometer?.message}
              error={!!formState.errors.manualFinalOdometer}
            />
          )}
        />

        <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
          <Button variant="contained" color="success" type="submit">
            {isLoading ? 'carregando...' : 'Salvar'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
