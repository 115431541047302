import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { listSectorsResponse } from '../types';

export async function getSectorsByCompany(
  id: string,
): Promise<listSectorsResponse[]> {
  const response = await axios.get(`/sectors/getSectorsByCompany/${id}`);
  return response.data.payload;
}

export const useSectorsByCompany = (id: string) => {
  return useQuery({
    queryKey: ['sectorsByCompany', id],
    queryFn: () => getSectorsByCompany(id),
    enabled: !!id,
  });
};
