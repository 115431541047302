import { convertToTimeFormat } from '@/utils/formatTime';
import { Box, Typography } from '@mui/material';
import { Detail } from './Detail';

interface IProps {
  totalWaitingTime: number;
  waitingTimeBeforeStart: number;
  waitingTimeAfterStart: number;
  totalWaitingPrice: string;
}

export const WaitingTimeAdditionalValues = ({
  totalWaitingPrice,
  totalWaitingTime,
  waitingTimeAfterStart,
  waitingTimeBeforeStart,
}: IProps) => {
  return (
    <>
      <Detail bold title="Tempo parado" description="" />
      <ul>
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={{ width: '50%' }}>
              Tempo esperado antes de iniciar
            </Typography>
            <Typography>
              {convertToTimeFormat(waitingTimeBeforeStart || 0)}
            </Typography>
          </Box>
        </li>

        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={{ width: '50%' }}>
              Tempo esperado depois de iniciar
            </Typography>
            <Typography>
              {convertToTimeFormat(waitingTimeAfterStart || 0)}
            </Typography>
          </Box>
        </li>

        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={{ width: '50%' }}>Tempo esperado total</Typography>
            <Typography>
              {convertToTimeFormat(totalWaitingTime || 0)}
            </Typography>
          </Box>
        </li>

        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Subtotal (Tempo parado)</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {totalWaitingPrice}
            </Typography>
          </Box>
        </li>
      </ul>
    </>
  );
};
