import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';
import { listSectorsResponse } from '../types';

interface IRequest {
  id: string;
  name: string;
  phone: string;
  status: number;
  limitOfTrips?: number;
  limitOfCosts?: number;
  companyId: string;
}

export async function createSectorAsRenter(
  data: IRequest,
): Promise<listSectorsResponse> {
  const response = await axios.post('/sectors/createAsRenter', data);
  return response.data.payload;
}

export const useCreateSectorAsRenter = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newSector => {
      await queryClient.cancelQueries('sectorsByCompany');

      const previoussectors =
        queryClient.getQueryData<any[]>('sectorsByCompany');

      queryClient.setQueryData('sectorsByCompany', [
        ...(previoussectors || []),
        newSector,
      ]);

      return previoussectors;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('sectorsByCompany');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Setor criada com sucesso.',
      });
    },
    mutationFn: createSectorAsRenter,
  });
};
