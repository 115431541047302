import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';

interface IProps {
  id: string;
  message?: string;
  driverAvaliation: number;
  confirmation: boolean;
}

export const managerEvaluatesTrip = async (data: IProps): Promise<void> => {
  await axios.put('userAnswers/manager-update', data);
};

export const useManagerEvaluatesTrip = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(['tripsToEvaluate']);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tripsToEvaluate']);
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    mutationFn: managerEvaluatesTrip,
  });
};
