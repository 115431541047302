import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface IRequest {
  ids: string[];
  addressFrom: string;
  addressTo: string;
  stopPoints: {
    order: number;
    lat: number;
    long: number;
    address: string;
    userId: string;
  }[];
  startLat: number;
  finalLat: number;
  startLong: number;
  finalLong: number;
  observation?: string;
  cityOfDestination?: string;
  cityOfOrigin?: string;
  scheduledDate?: Date;
  usersSharingTrip: {
    userId: string;
  }[];
}

export async function joinTrips(data: IRequest): Promise<void> {
  const response = await axios.post('/trips/joinendTrip', data);
  return response.data.payload;
}

export const useJoinTrips = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async joinedTrip => {
      await queryClient.cancelQueries('trips');
      const previoustrips = queryClient.getQueryData<any[]>('trips');

      queryClient.setQueryData('trips', [
        previoustrips?.filter(trip => trip !== joinedTrip),
      ]);

      return previoustrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('trips');
    },
    mutationFn: joinTrips,
  });
};
