/* eslint-disable import/no-duplicates */
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import { parse } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import { useState } from 'react';
import { useUpdateAppointmentDate } from '../../api';

interface IProps {
  scheduledDate: string;
  tripId: string;
  onSuccess: () => void;
}

export const EditTrip = ({ scheduledDate, tripId, onSuccess }: IProps) => {
  const formattedDate = parse(scheduledDate, 'dd/MM/yyyy HH:mm', new Date());
  const { mutateAsync, isLoading } = useUpdateAppointmentDate();
  const [date, setDate] = useState(formattedDate);
  return (
    <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Data inicial"
          value={date}
          minDate={new Date()}
          onChange={newValue => {
            if (newValue) {
              setDate(newValue);
            }
          }}
          renderInput={params => (
            <TextField sx={{ zIndex: 0 }} size="small" {...params} />
          )}
        />
      </LocalizationProvider>
      <LoadingButton
        onClick={() => {
          if (date) {
            mutateAsync(
              {
                tripId,
                newScheduledDate: new Date(date),
              },
              {
                onSuccess: () => {
                  onSuccess();
                },
              },
            );
          }
        }}
        loading={isLoading}
        disabled={isLoading || !scheduledDate}
        sx={{ marginTop: '10px' }}
        size="small"
        variant="contained"
        color="primary"
      >
        Salvar
      </LoadingButton>
    </Box>
  );
};
