import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { listUserResponse } from '../types';

interface IRequest {
  companyId: string;
}

export async function getUsersByCompany({
  companyId,
}: IRequest): Promise<listUserResponse[]> {
  const response = await axios.get(`users/getUsersByCompany/${companyId}`);
  return response.data.payload;
}
export const useUsersByCompany = ({ companyId }: IRequest) => {
  return useQuery({
    queryKey: ['usersByCompany', companyId],
    queryFn: () => getUsersByCompany({ companyId }),
  });
};
