import {
  Box,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowCircleDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

interface ContentLayoutProps {
  children: React.ReactNode;
  goBackButton?: boolean;
}

export function ContentLayout({ children, goBackButton }: ContentLayoutProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyles = makeStyles((theme: Theme) => ({
    mobile: {
      height: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '100%' : '100vh',
      width: '100%',
      background: theme.palette.background.default,
      paddingLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '40px',
    },
  }));
  const classes = useStyles({ isMobile });
  return (
    <Box className={classes.mobile}>
      {goBackButton && (
        <Tooltip arrow title="Voltar" placement="right">
          <IconButton>
            <ArrowCircleDown
              fontSize="large"
              sx={{ transform: 'rotate(90deg)' }}
              onClick={() => navigate(-1)}
            />
          </IconButton>
        </Tooltip>
      )}
      {children}
    </Box>
  );
}
