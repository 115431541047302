/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  finishedRecurringTrips: number;
  finishedTrips: number;
  canceledTrips: number;
  totalTrips: number;
  consumedTrips?: number;
  totalVehicles: number;
}

export const getTripCounter = async (): Promise<IOutput> => {
  const response = await axios.get('trips/tripCounterForDashboard');
  return response.data.payload;
};

type UseTripCounter = {
  config?: QueryConfig<typeof getTripCounter>;
};

export const useTripCounter = ({ config }: UseTripCounter = {}) => {
  return useQuery({
    queryKey: ['tripCounter'],
    queryFn: () => getTripCounter(),
    ...config,
  });
};
