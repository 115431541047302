import {
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTableRecords } from '@/stores';
import { useState } from 'react';
import { Modal } from '@/components';
import { useGetCompanyProfiles } from '../api';
import { ProfileList } from './ProfileList';
import { IProfile } from '../types';
import { DeleteProfile } from './DeleteProfile';

export const ProfileContainer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, isLoading } = useGetCompanyProfiles();
  const [dataId, setDataId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const close = () => {
    setOpenDeleteModal(false);
    setDataId('');
  };
  const handleDeleteToggle = (id: string) => {
    setOpenDeleteModal(true);
    setDataId(id);
  };

  const handleSearch = (e: any) => {
    useTableRecords.setState({
      filterFn: {
        fn: (items: IProfile[]) => {
          if (e.target.value === '') return items;

          try {
            return items.filter((x: IProfile) =>
              x.name.toLowerCase().includes(e.target.value.toLowerCase()),
            );
          } catch (err) {
            console.log(err);
          }

          return items;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('./create')}
          size={isMobile ? 'small' : 'medium'}
        >
          Novo Perfil
        </Button>
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      <ProfileList
        tableData={data || []}
        toggleDelete={handleDeleteToggle}
        loading={isLoading}
      />
      {openDeleteModal && (
        <Modal
          title="Inativar perfil"
          dialogContent={<DeleteProfile close={close} id={dataId} />}
          open={!!openDeleteModal}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
};
