import { Box, Button, Typography, useTheme } from '@mui/material';

interface IAddress {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  city: string;
  onSelectAddress: (data: {
    name: string;
    address: string;
    latitude: number;
    longitude: number;
    id: string;
    city: string;
  }) => void;
}

export const FavoriteAddress = ({
  address,
  id,
  latitude,
  longitude,
  name,
  city,
  onSelectAddress,
}: IAddress) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#232323' : '#ACACAC',
        margin: '10px 0',
        borderRadius: '5px',
      }}
    >
      <Button
        onClick={() => {
          onSelectAddress({ address, id, latitude, longitude, name, city });
        }}
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
      >
        <Typography
          sx={{
            color: theme.palette.mode === 'dark' ? '#7D8B8C' : '#FFF',
            fontSize: '14px',
          }}
        >
          {name}
        </Typography>
      </Button>
    </Box>
  );
};
