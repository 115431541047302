import { Box, Button, Typography } from '@mui/material';

interface AcceptTripConfirmationProps {
  message: string;
  confirm: () => void;
  close: () => void;
}

export const AcceptTripConfirmation = ({
  message,
  confirm,
  close,
}: AcceptTripConfirmationProps) => {
  return (
    <Box>
      <Typography>{message}</Typography>
      <Typography>Deseja enviar mesmo assim?</Typography>
      <Box sx={{ display: 'flex', gap: 2, marginTop: '10px' }}>
        <Button variant="contained" color="error" onClick={() => confirm()}>
          Confirmar
        </Button>
        <Button variant="contained" color="success" onClick={() => close()}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};
