import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { listSectorsResponse } from '../types';

export async function getSector({
  id,
}: {
  id: string;
}): Promise<listSectorsResponse> {
  const response = await axios.get(`/sectors/get/${id}`);
  return response.data.payload;
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getSector>;
  id: string;
};

export const useSector = ({ id, config }: UseCompaniesOptions) => {
  return useQuery({
    queryKey: ['sectors', id],
    queryFn: () => getSector({ id }),
    ...config,
  });
};
