import { useAuth } from '@/providers/auth';
import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCompany, useCompanyRoles } from '../api';
import { CompanyUpdate } from '../routes/Company';
import { RenterUpdate } from '../routes/Renter';

export function UpdateCompany() {
  const { user } = useAuth();
  const dataParams = useParams() as unknown as Record<string, any>;
  const id = dataParams.companyId;
  const { data: companiesQuery, isLoading } = useCompany({ id });
  const { data: roles, isLoading: isLoadingRoles } = useCompanyRoles({ id });
  return (
    <>
      {isLoading || isLoadingRoles ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading || isLoadingRoles}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {user.user.accessType === 'master' ? (
            <RenterUpdate companiesQuery={companiesQuery} rolesQuery={roles} />
          ) : (
            <CompanyUpdate companiesQuery={companiesQuery} rolesQuery={roles} />
          )}
        </>
      )}
    </>
  );
}
