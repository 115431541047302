import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useNotificationStore } from '@/stores';
import { useQuery } from 'react-query';
import { ILastPositionsOutput } from '../types';
// AxiosResponse
// CompanyValues

export async function getLastPositions(
  companyId?: string,
): Promise<ILastPositionsOutput[]> {
  const response = await axios.get('drivers/listDriversLastPositions');

  return response.data.payload;
}

type UseLastPositionsOptions = {
  config?: QueryConfig<typeof getLastPositions>;
};

export const useLastPositions = ({ config }: UseLastPositionsOptions = {}) => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  return useQuery({
    onError: data => {
      if (window.location.pathname.includes('mapscreen')) {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: 'Não foi possível carregar localização dos motoristas!',
        });
      }
      if (window.location.pathname.includes('driver')) {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: 'Não foi possível atualizar as placas disponíveis!',
        });
      }
    },
    onSuccess: data => {
      if (!data && window.location.pathname.includes('mapscreen')) {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: 'Não foi possível carregar localização dos motoristas!',
        });
      }
      if (!data && window.location.pathname.includes('driver')) {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: 'Não foi possível atualizar as placas disponíveis!',
        });
      }
    },
    enabled:
      user?.user?.accessType === 'renter' ||
      user?.user?.accessType === 'company_admin',
    queryKey: ['lastPositions'],
    queryFn: () => getLastPositions(),
    ...config,
  });
};
