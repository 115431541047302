import { ContentLayout, Loading } from '@/components';
import { Can } from '@/components/Can';
import { useParams } from 'react-router-dom';
import { useGetProfileById } from '../api';
import { UpdateProfileForm } from '../components';

export function UpdateProfile() {
  const dataParams = useParams() as unknown as Record<string, any>;
  const { id } = dataParams;
  const { data, isLoading } = useGetProfileById(id);
  return (
    <Can roles={['gerenciar perfis']}>
      <ContentLayout>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            {data && (
              <UpdateProfileForm
                id={data.id}
                name={data.name}
                rolesHasPermissions={data?.rolesHasPermissions || []}
              />
            )}
          </>
        )}
      </ContentLayout>
    </Can>
  );
}
