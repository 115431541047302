import { ContentLayout, PageLayout } from '@/components';
import { ListAlt } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { CreateVersionLog as CreateVersionLogComponent } from '../components/CreateVersionLog';

export function CreateVersionLog() {
  const { subTitle } = useCustomTheme();

  return (
    <PageLayout
      title="Logs"
      subTitle={subTitle}
      icon={<ListAlt fontSize="large" style={{ color: '#0ebcdf' }} />}
    >
      <ContentLayout>
        <CreateVersionLogComponent />
      </ContentLayout>
    </PageLayout>
  );
}
