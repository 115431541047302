import * as z from 'zod';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Box, Button, Typography } from '@mui/material';
import { Form, InputField } from '@/components';
import { useAddFavoriteAddress } from '../../api';
import { PlacesAutoComplete } from '../TripRequest/PlacesAutoComplete';

interface IRequest {
  onClose: () => void;
}

interface IForm {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  city: string;
}

const schema = z.object({
  name: z.string({ required_error: 'Campo obrigatório' }),
  address: z.string({ required_error: 'Campo obrigatório' }),
  city: z.string().optional(),
  latitude: z.number({ required_error: 'Campo obrigatório' }),
  longitude: z.number({ required_error: 'Campo obrigatório' }),
});
export const AddAddress = ({ onClose }: IRequest) => {
  const { mutateAsync, isLoading } = useAddFavoriteAddress();
  const handleAddressFrom = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        setValue('city', city?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('address', val.label);
        setValue('latitude', lat);
        setValue('longitude', lng);
      });
  };

  const cleanAddressFrom = (setValue: any) => {
    setValue('address', null);
    setValue('latitude', null);
    setValue('longitude', null);
    setValue('city', null);
  };

  return (
    <>
      <Typography variant="h5" sx={{ color: '#FFF', textAlign: 'center' }}>
        Adicionar Favorito
      </Typography>
      <Form<any, typeof schema>
        id="add-favorite-address"
        onSubmit={(values: IForm) => {
          mutateAsync(values, {
            onSuccess: () => {
              onClose();
            },
          });
        }}
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            <InputField
              size="small"
              name="name"
              type="text"
              label="Nome"
              registration={register('name')}
              error={!!formState.errors.name}
              errorMessage={formState.errors.name?.message}
            />
            <Box sx={{ width: '100%', marginTop: '15px' }}>
              <PlacesAutoComplete
                handleAddress={handleAddressFrom}
                setValue={setValue}
                placeholder="Endereço"
                clean={cleanAddressFrom}
              />
            </Box>

            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              color="success"
              type="submit"
            >
              {isLoading ? 'carregando...' : 'Adicionar'}
            </Button>
          </>
        )}
      </Form>
    </>
  );
};
