import { ContentLayout } from '@/components';
import { CreateUser as CreateUseForm } from '../components/CreateUser';

export function CreateUser() {
  return (
    <ContentLayout>
      <CreateUseForm />
    </ContentLayout>
  );
}
