import { Typography, Box, Button } from '@mui/material';
import { useDeleteUser } from '../api';

type props = {
  id: string;
  close: () => void;
};

export function DeleteUser({ id, close }: props) {
  const { mutateAsync } = useDeleteUser();

  const handleDelete = () => {
    mutateAsync({ id });
    close();
  };

  return (
    <>
      <Box>
        <Typography sx={{ textAlign: 'center' }}>
          Tem certeza que deseja inativar?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleDelete()}
            sx={{ margin: '10px' }}
            variant="contained"
            color="error"
          >
            Sim
          </Button>
          <Button
            onClick={close}
            sx={{ margin: '10px' }}
            variant="contained"
            color="success"
          >
            Não
          </Button>
        </Box>
      </Box>
    </>
  );
}
