import { ContentLayout } from '@/components';
import { CreateSectors } from '../components/CreateSectors';

export function CreateSector() {
  return (
    <ContentLayout>
      <CreateSectors />
    </ContentLayout>
  );
}
