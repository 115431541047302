import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

type UseDeleteCompanyOptions = {
  config?: MutationConfig<typeof deleteCompany>;
};

export async function deleteCompany(data: { id: string }): Promise<any> {
  return axios.put(`companies/changeCompanyStatus/${data.id}`);
}

export const useDeleteCompany = ({ config }: UseDeleteCompanyOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('companies');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('companies', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('companies');
      if (data.data.payload.status === false) {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Empresa inativada com sucesso.',
        });
      } else {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Empresa reativada com sucesso.',
        });
      }
      navigate('/companies');
    },
    ...config,
    mutationFn: deleteCompany,
  });
};
