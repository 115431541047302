import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { OperatorsList } from './OperatorsList';
import { UpdateOperator } from './UpdateOperator';
import { CreateOperators } from './CreateOperator';

export const OperatorsRoutes = (): JSX.Element => {
  return (
    <Can
      roles={['gerenciar operadores']}
      permissions={['renter', 'company_admin', 'master']}
    >
      <Routes>
        <Route path="" element={<OperatorsList />} />
        <Route path="/create" element={<CreateOperators />} />
        <Route path=":id" element={<UpdateOperator />} />
      </Routes>
    </Can>
  );
};
