import { Modal, PageLayout, Table } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { AirportShuttleOutlined, Edit } from '@mui/icons-material';
import { Button, IconButton, Toolbar, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useCustomTheme } from '@/providers/theme';
import { CreateCategory } from '../components';
import { useVehicleCategories } from '../api';
import { ICategory } from '../types';
import { EditCategory } from '../components/EditCategory';

export const Categories = () => {
  const { subTitle } = useCustomTheme();
  const { close, isOpen, open } = useDisclosure();
  const { data, isLoading } = useVehicleCategories();
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null,
  );
  return (
    <PageLayout
      title="Categorias"
      subTitle={subTitle}
      icon={
        <AirportShuttleOutlined fontSize="large" style={{ color: '#0ebcdf' }} />
      }
    >
      <Toolbar>
        <Button color="success" variant="contained" onClick={() => open()}>
          Adicionar
        </Button>
      </Toolbar>
      <Table
        loading={isLoading}
        data={data || []}
        columns={[
          {
            field: 'category',
            title: 'Categoria',
          },
          {
            field: 'description',
            title: 'Descrição',
          },
          {
            field: 'id',
            title: 'Ações',
            Cell: ({ entry }) => (
              <Tooltip title="Editar">
                <IconButton onClick={() => setSelectedCategory(entry)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            ),
          },
        ]}
      />
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={close}
          size="xs"
          title="Criar categoria"
          dialogContent={<CreateCategory onClose={() => close()} />}
        />
      )}
      {selectedCategory && (
        <Modal
          open={!!selectedCategory}
          onClose={() => setSelectedCategory(null)}
          size="xs"
          title="Editar categoria"
          dialogContent={
            <EditCategory
              category={selectedCategory}
              onClose={() => setSelectedCategory(null)}
            />
          }
        />
      )}
    </PageLayout>
  );
};
