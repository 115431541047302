import { Box, Button } from '@mui/material';
import * as z from 'zod';
import { useChangeUserPassword } from '@/features/users';
import { Form, InputField } from '../Form';

const schema = z.object({
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  newPassword: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  confirmNewPassword: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
});

interface IRequest {
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const ChangePasswordForm = ({ onCancel, onSuccess }: IRequest) => {
  const { mutateAsync: changePassword, isLoading: isLoadingNewPassword } =
    useChangeUserPassword();

  return (
    <Form<
      {
        password: string;
        newPassword: string;
        confirmNewPassword: string;
      },
      typeof schema
    >
      style={{
        padding: '5px',
      }}
      id="change-password"
      onSubmit={values => {
        changePassword(values, {
          onSuccess: () => {
            if (onSuccess) {
              onSuccess();
            }
          },
        });
      }}
      schema={schema}
    >
      {({ register, formState }) => {
        return (
          <>
            <InputField
              size="small"
              name="password"
              type="password"
              label="Senha"
              registration={register('password')}
              error={!!formState.errors.password}
              errorMessage={formState.errors.password?.message}
              placeholder="Senha atual"
            />
            <InputField
              size="small"
              name="newPassword"
              type="password"
              label="Nova senha"
              registration={register('newPassword')}
              error={!!formState.errors.newPassword}
              errorMessage={formState.errors.newPassword?.message}
              placeholder="Nova senha"
            />
            <InputField
              size="small"
              name="confirmNewPassword"
              type="password"
              label="Confirmar nova senha"
              registration={register('confirmNewPassword')}
              error={!!formState.errors.confirmNewPassword}
              errorMessage={formState.errors.confirmNewPassword?.message}
              placeholder="Confirmar nova senha"
            />

            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button
                disabled={isLoadingNewPassword}
                type="submit"
                variant="contained"
                color="success"
              >
                {isLoadingNewPassword ? 'Alterando...' : 'Salvar'}
              </Button>
              {onCancel && (
                <Button
                  onClick={() => onCancel()}
                  disabled={isLoadingNewPassword}
                  variant="contained"
                  color="error"
                >
                  Cancelar
                </Button>
              )}
            </Box>
          </>
        );
      }}
    </Form>
  );
};
