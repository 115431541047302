import * as z from 'zod';
import { ConfirmModal, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import { listUserResponse } from '@/features/users';
import { useGetCompanyProfiles } from '@/features/profiles';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useUpdateOperator } from '../api/updateOperator';

const schema = z.object({
  email: z.string({ required_error: 'Campo obrigatório' }),
  password: z.string().optional(),
  roleProfileId: z.string({ required_error: 'Campo obrigatório' }),
  name: z.string({ required_error: 'Campo obrigatório' }),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(9, 'Minimo 9 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z
    .string({ required_error: 'Campo obrigatório' })
    .max(14, 'Máximo 14 caracteres'),
});

type Props = {
  usersQuery?: listUserResponse;
};

export function UpdateOperatorForm({ usersQuery }: Props) {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { mutateAsync, isLoading: loadingUpdate } = useUpdateOperator();
  const { data: profiles } = useGetCompanyProfiles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const preloadedValues = {
    password: '',
    email: usersQuery?.email,
    name: usersQuery?.name,
    phone: usersQuery?.phone,
    individualRegistration: usersQuery?.individualRegistration,
    roleProfileId: usersQuery?.roleProfileId,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const OnSubmit = (values: any) => {
    const data = {
      ...values,
      status: true,
      companyId: usersQuery?.companyId,
      id: usersQuery?.id,
    };

    mutateAsync({ data });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <ConfirmModal
        close={closeDirtyFormModal}
        isOpen={isFormDirty}
        description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
        handleConfirmation={() => navigate(-1)}
      />
      <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
        <>
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? 'white' : 'red',
              fontSize: '22px',
            }}
          >
            Editando operador
          </Typography>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="email"
                    type="text"
                    label="Login"
                    onChange={onChange}
                    value={value}
                    placeholder="Login do operador"
                    errorMessage={formState.errors.email?.message}
                    error={!!formState.errors.email}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="name"
                    type="text"
                    label="Nome"
                    onChange={onChange}
                    value={value}
                    errorMessage={formState.errors.name?.message}
                    error={!!formState.errors.name}
                  />
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="phone"
                    type="text"
                    label="Telefone"
                    inputProps={{ minLength: 8, maxLength: 15 }}
                    onChange={onChange}
                    value={value}
                    errorMessage={formState.errors.phone?.message}
                    error={!!formState.errors.phone}
                  />
                )}
              />

              <Controller
                name="individualRegistration"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    size="small"
                    name="individualRegistration"
                    type="text"
                    label="CPF"
                    inputProps={{ minLength: 11, maxLength: 14 }}
                    onChange={onChange}
                    value={value}
                    errorMessage={
                      formState.errors.individualRegistration?.message
                    }
                    error={!!formState.errors.individualRegistration}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Box className={classes.wrapper}>
                <Controller
                  name="roleProfileId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="roleProfileId"
                      onChange={onChange}
                      value={value}
                      type="text"
                      select
                      label="Perfil"
                      error={!!formState.errors?.roleProfileId}
                      errorMessage={formState.errors?.roleProfileId?.message}
                    >
                      {profiles?.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </InputField>
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="password"
                      type="password"
                      label="Senha"
                      onChange={onChange}
                      value={value}
                      errorMessage={formState.errors.password?.message}
                      error={!!formState.errors.password}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                disabled={loadingUpdate}
              >
                {loadingUpdate ? 'Carregando...' : 'Salvar'}
              </Button>
              <Button
                onClick={() => handleGoBack()}
                variant="contained"
                color="primary"
                disabled={loadingUpdate}
              >
                Voltar
              </Button>
            </Box>
          </Grid>
        </>
      </form>
    </Paper>
  );
}
