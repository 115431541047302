import {
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import { useUser } from '../api';

type props = {
  id: string;
};

export function ViewUser({ id }: props) {
  const { data: usersQuery, isLoading } = useUser({ id });
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          <Grid container>
            <Grid item>
              {usersQuery?.urlImage && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '180px',
                  }}
                >
                  <Box
                    sx={{
                      width: '140px',
                      height: '140px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      position: 'relative',
                      boxShadow: '0px 0px 10px 0px #0000004c',
                    }}
                  >
                    <img
                      src={usersQuery?.urlImage}
                      alt="foto usuário"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  textAlign: 'center',
                  fontSize: '20px',
                  marginBottom: '10px',
                  fontWeight: 'medium',
                }}
              >
                {usersQuery?.name}
              </Typography>
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Login"
                value={usersQuery?.email}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Matrícula"
                value={usersQuery?.registration}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Telefone"
                value={usersQuery?.phone}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="CPF"
                value={usersQuery?.individualRegistration}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Tipo de acesso"
                value={
                  usersQuery?.accessType === 'sector_admin'
                    ? 'Chefe de setor'
                    : 'Passageiro'
                }
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Setor"
                value={usersQuery?.sector?.name}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
