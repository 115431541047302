import { ContentLayout, Loading } from '@/components';
import { Can } from '@/components/Can';
import { useParams } from 'react-router-dom';
import { UpdateOperatorForm } from '../components/UpdateOperator';
import { useOperator } from '../api/getOperator';

export function UpdateOperator() {
  const dataParams = useParams() as unknown as Record<string, any>;
  const { id } = dataParams;
  const { data, isLoading } = useOperator({ id });

  return (
    <Can roles={['gerenciar operadores']}>
      <ContentLayout>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>{data && <UpdateOperatorForm usersQuery={data} />}</>
        )}
      </ContentLayout>
    </Can>
  );
}
