import { Table } from '@/components';
import { Pdf } from '@/utils/PDF';
import { Box, Button, Toolbar } from '@mui/material';
import { CSVLink } from 'react-csv';
import { IGetLogsRequest, useGetLogs } from '../../api';

export const LogsTable = ({
  endDate,
  startDate,
  user_company_id,
  user_id,
}: IGetLogsRequest) => {
  const { data, isLoading } = useGetLogs({
    endDate,
    startDate,
    user_company_id,
    user_id,
  });
  const headers = [
    {
      title: 'Data',
      field: 'date',
      colSpan: 2,
      label: 'Data',
      key: 'date',
    },
    {
      title: 'Empresa',
      field: 'corporateName',
      colSpan: 2,
      label: 'Empresa',
      key: 'corporateName',
    },
    {
      title: 'Cliente',
      field: 'userName',
      colSpan: 2,
      label: 'Cliente',
      key: 'userName',
    },
    { title: 'IP', field: 'ip', colSpan: 2, label: 'IP', key: 'ip' },
    { title: 'Rota', field: 'url', colSpan: 2, label: 'Rota', key: 'url' },
    {
      title: 'Status',
      field: 'status_code',
      colSpan: 2,
      label: 'Status',
      key: 'status_code',
    },
    {
      title: 'Tempo de resposta',
      field: 'resp_time',
      colSpan: 2,
      label: 'Tempo de resposta',
      key: 'resp_time',
    },
    {
      title: 'Descrição',
      field: 'description',
      colSpan: 2,
      label: 'Descrição',
      key: 'description',
    },
    {
      title: 'Dispositivo',
      field: 'device',
      colSpan: 2,
      label: 'Dispositivo',
      key: 'device',
    },
    { title: 'Sistema', field: 'os', colSpan: 2, label: 'Sistema', key: 'os' },
    {
      title: 'Navegador',
      field: 'client',
      colSpan: 2,
      label: 'Navegador',
      key: 'client',
    },
  ];
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {!!data && (
          <>
            <Toolbar>
              <Button
                variant="contained"
                type="button"
                color="error"
                onClick={() => {
                  Pdf({
                    data,
                    headers,
                  });
                }}
              >
                Gerar PDF
              </Button>
            </Toolbar>

            <Toolbar>
              <CSVLink
                style={{ textDecoration: 'none' }}
                headers={headers}
                filename={`Relatório-logs-${Date.now()}`}
                data={data}
              >
                <Button variant="contained" type="button" color="success">
                  Gerar Excel
                </Button>
              </CSVLink>
            </Toolbar>
          </>
        )}
      </Box>
      <Table
        columns={[
          { title: 'Data', field: 'date', colSpan: 2 },
          { title: 'Empresa', field: 'corporateName', colSpan: 2 },
          { title: 'Cliente', field: 'userName', colSpan: 2 },
          { title: 'IP', field: 'ip', colSpan: 2 },
          { title: 'Rota', field: 'url', colSpan: 2 },
          { title: 'Status', field: 'status_code', colSpan: 2 },
          { title: 'Tempo de resposta', field: 'resp_time', colSpan: 2 },
          { title: 'Descrição', field: 'description', colSpan: 2 },
          { title: 'Dispositivo', field: 'device', colSpan: 2 },
          { title: 'Sistema', field: 'os', colSpan: 2 },
          { title: 'Navegador', field: 'client', colSpan: 2 },
        ]}
        data={data || []}
        loading={isLoading}
      />
    </>
  );
};
