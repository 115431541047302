import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { queryClient } from '../../../lib/react-query';

interface IRequest {
  driverId: string;
}

export const checkMessages = (data: IRequest) => {
  return axios.put(`chat/renterChecksMessages/${data.driverId}`, data);
};
export const useCheckMessages = () => {
  const { user } = useAuth();
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        'messages',
        variables.driverId,
        user?.user?.id,
      ]);
      queryClient.invalidateQueries(['notCheckedMessages']);
    },
    mutationFn: checkMessages,
  });
};
