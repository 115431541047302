/* eslint-disable no-nested-ternary */
import { useMapConfig } from '@/providers/mapConfig';
import { useTable } from '@/utils/useTable';
import {
  Search,
  AspectRatio,
  CloseFullscreen,
  FullscreenExit,
  DirectionsCar,
  Room,
  Business,
  PieChartOutline,
  Schedule,
  SettingsRemote,
  AccountCircle,
  Settings,
  Warning,
  Speed,
  GpsNotFixed,
} from '@mui/icons-material';
import {
  Table as MuiTable,
  TableCell,
  TableHead,
  TableRow,
  Box,
  FormGroup,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Theme,
  useMediaQuery,
  useTheme,
  Tooltip,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  Badge,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { SettingsButton } from '@/components/SettingsButton';
import { useTableRecords } from '@/stores';
import { driversProps } from '../types';
import { DriversGridBody } from './DriversGridBody';

type props = {
  map: any;
  mode: string;
  expandedHandler: () => void;
  hideHandler: () => void;
  defaultHandler: () => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cellphoneTracker: boolean;
  setCellphoneTracker: (e: boolean) => void;
  result: string;
  drivers: driversProps[];
  openSettings: () => void;
  hasTracker: boolean;
  openWarnings: () => void;
  numberOfWarnings: number;
  hasAlertRole: boolean;
};

export const DriversGrid = ({
  defaultHandler,
  hideHandler,
  mode,
  expandedHandler,
  handleSearch,
  cellphoneTracker,
  setCellphoneTracker,
  result,
  drivers,
  map,
  openSettings,
  hasTracker,
  openWarnings,
  numberOfWarnings,
  hasAlertRole,
}: props) => {
  const { zoomMap } = useMapConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [pagesIndex, setPagesIndex] = useState<any>(null);

  const handleClick = (driver: driversProps) => {
    zoomMap(driver.latitude, driver.longitude, map);
  };

  const [onlyProgress, setOnlyProgress] = useState(false);
  const [filteredDriversInProgress, setFilteredDriversInProgress] = useState<
    driversProps[]
  >([]);

  useEffect(() => {
    if (onlyProgress) {
      const filteredValues = drivers?.filter(
        (driver: driversProps) => driver.driverInProgress === true,
      );
      setFilteredDriversInProgress(filteredValues);
    } else {
      setFilteredDriversInProgress(drivers);
    }
  }, [drivers, onlyProgress]);

  useEffect(() => {
    if (mode === 'expanded') {
      setPagesIndex(2);
    } else {
      setPagesIndex(null);
    }
  }, [mode]);

  const filteredDrivers: driversProps[] = useMemo(() => {
    const filter = (result: string, drivers: driversProps[]) => {
      const filteredDrivers = drivers?.filter((driver: driversProps) =>
        Object.values(driver).some(
          value =>
            typeof value === 'string' &&
            value.toString().toLowerCase().includes(result.toLowerCase()),
        ),
      );

      return filteredDrivers;
    };
    return filter(result, drivers);
  }, [result, drivers]);

  const useStyles = makeStyles((theme: Theme) => ({
    table: {
      lineHeight: 0.8,
      fontSize: 10,

      '& thead th': {
        fontWeight: '500',
        fontSize: isMobile ? '12px' : '0.8rem',
        padding: mode === 'hidden' ? '0px' : '4px',
      },

      '& tbody td': {
        fontSize: isMobile ? '14px' : '0.8rem',
        padding: mode === 'hidden' ? '0px' : '3px',
      },

      '& tbody tr:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#121214' : '#fffbf2',
        cursor: 'pointer',
        transition: '0.5s',
      },
    },
    root: {
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          color: theme.palette.mode === 'dark' ? '#fff' : 'black',
        },
      },
    },
    tableRow: {
      overflow: 'hidden',
    },
    formGroup: {
      background:
        theme.palette.mode === 'dark'
          ? '#212121'
          : 'linear-gradient(180deg, #F5F5F5 0%, #DFDFDF 100%)',
      boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.3)',
    },
  }));

  const classes = useStyles();
  const { filterFn } = useTableRecords();
  const { TblPagination, recordsAfterPagingAndSorting } = useTable(
    onlyProgress ? filteredDriversInProgress : filteredDrivers,
    null,
    filterFn,
    pagesIndex,
  );
  const dataAfterFiltering: driversProps[] = recordsAfterPagingAndSorting();

  return (
    <>
      <Box>
        <FormGroup
          className={classes.formGroup}
          row
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            height: '35px',
            alignItems: 'center',
            paddingLeft: '10px',
            justifyContent: 'space-between',
            overflowY: 'hidden',
          }}
        >
          {mode === 'hidden' ? (
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? '#363636'
                      : 'linear-gradient(180deg, #E9E9E9 0%, #A5A5A5 100%)',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  display: 'flex',
                  width: '150px',
                  height: '30px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  marginRight: '20px',
                }}
              >
                <Box
                  style={{
                    height: '100%',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Tooltip onClick={expandedHandler} title="Expandir Tabela">
                    <AspectRatio
                      sx={{
                        cursor: 'pointer',
                        fontSize: isMobile ? '20' : '30px',
                        color:
                          theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  style={{
                    height: '100%',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderLeft: '2px solid #868686',
                    borderRight: '2px solid #868686',
                  }}
                >
                  <Tooltip onClick={defaultHandler} title="Padrão">
                    <FullscreenExit
                      sx={{
                        cursor: 'pointer',
                        fontSize: isMobile ? '20' : '30px',
                        color:
                          theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  style={{
                    height: '100%',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Tooltip onClick={hideHandler} title="Esconder Tabela">
                    <CloseFullscreen
                      sx={{
                        cursor: 'pointer',
                        fontSize: isMobile ? '20' : '30px',
                        color:
                          theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              <SettingsButton handleClick={() => openSettings()} />
            </Box>
          ) : (
            <>
              <Box sx={{ gap: 1, display: 'flex' }}>
                <FormControl
                  className={classes.root}
                  sx={{ flex: 0.2, minWidth: '170px' }}
                >
                  <OutlinedInput
                    sx={{
                      height: '30px',
                      paddingLeft: '10px',
                      width: isMobile ? '55%' : '100%',
                      outline: 'none',
                      border: 'none',
                      background:
                        theme.palette.mode === 'dark' ? '#363636' : '#fff',
                    }}
                    margin="dense"
                    id="searchTerms"
                    name="searchTerms"
                    type="search"
                    size="small"
                    value={result}
                    placeholder="Pesquisar..."
                    onChange={handleSearch}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? '#bdbdbd'
                              : '#666666',
                        }}
                      >
                        <Search />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {hasAlertRole && (
                  <Badge color="primary" badgeContent={numberOfWarnings}>
                    <Tooltip title="Avisos">
                      <IconButton onClick={() => openWarnings()}>
                        <Warning />
                      </IconButton>
                    </Tooltip>
                  </Badge>
                )}
              </Box>
              {!isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {hasTracker && (
                    <FormControlLabel
                      sx={{
                        color:
                          theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                      }}
                      control={
                        <>
                          <Checkbox
                            onChange={() =>
                              setCellphoneTracker(!cellphoneTracker)
                            }
                            checked={cellphoneTracker}
                          />
                        </>
                      }
                      label={
                        <Typography variant="body2">
                          Posição do celular
                        </Typography>
                      }
                    />
                  )}
                  <FormControlLabel
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                      width: '250px',
                    }}
                    control={
                      <>
                        <Checkbox
                          onChange={() => setOnlyProgress(prev => !prev)}
                          checked={onlyProgress}
                        />
                      </>
                    }
                    label={
                      <Typography variant="body2">Somente em viagem</Typography>
                    }
                  />

                  <Box
                    style={{
                      background:
                        theme.palette.mode === 'dark'
                          ? '#363636'
                          : 'linear-gradient(180deg, #E9E9E9 0%, #A5A5A5 100%)',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      display: 'flex',
                      maxWidth: '150px',
                      width: '100%',
                      height: '75%',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      marginRight: '20px',
                    }}
                  >
                    <Box
                      style={{
                        height: '100%',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Tooltip
                        onClick={expandedHandler}
                        title="Expandir Tabela"
                      >
                        <AspectRatio
                          sx={{
                            cursor: 'pointer',
                            fontSize: isMobile ? '20' : '30px',
                            color:
                              theme.palette.mode === 'dark'
                                ? '#bdbdbd'
                                : 'black',
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      style={{
                        height: '100%',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        borderLeft: '2px solid #868686',
                        borderRight: '2px solid #868686',
                      }}
                    >
                      <Tooltip onClick={defaultHandler} title="Padrão">
                        <FullscreenExit
                          sx={{
                            cursor: 'pointer',
                            fontSize: isMobile ? '20' : '30px',
                            color:
                              theme.palette.mode === 'dark'
                                ? '#bdbdbd'
                                : 'black',
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      style={{
                        height: '100%',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Tooltip onClick={hideHandler} title="Esconder Tabela">
                        <CloseFullscreen
                          sx={{
                            cursor: 'pointer',
                            fontSize: isMobile ? '20' : '30px',
                            color:
                              theme.palette.mode === 'dark'
                                ? '#bdbdbd'
                                : 'black',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>

                  <Tooltip
                    sx={{
                      width: '52px',
                      height: '30px',
                      marginRight: '10px',
                      background:
                        theme.palette.mode === 'dark'
                          ? '#363636'
                          : 'linear-gradient(180deg, #E9E9E9 0%, #A5A5A5 100%)',
                      borderRadius: '10px',
                    }}
                    title="Configurações"
                  >
                    <IconButton onClick={() => openSettings()}>
                      <Settings
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
                          '&:hover': {
                            transform: 'rotate(90deg)',
                          },
                          transform: 'rotate(0deg)',
                          transition: 'ease-in-out 0.5s',
                        }}
                        fontSize="medium"
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </FormGroup>

        <MuiTable
          className={classes.table}
          size="small"
          sx={{ background: 'rgba(255, 255, 255, 0.08)' }}
        >
          <TableHead>
            <TableRow
              sx={{
                background:
                  theme.palette.mode === 'dark' ? '#272727' : '#F1F1F1',
                overflowY: 'hidden',
              }}
            >
              <TableCell />
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <DirectionsCar
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Placa
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <AccountCircle
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Motorista
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <AccountCircle
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Passageiro(s)
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Room
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Destino
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    marginRight: '15px',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <PieChartOutline
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Setor
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Business
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Empresa
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Schedule
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Horário previsto
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Schedule
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Tempo em viagem
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Speed
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Velocidade
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <GpsNotFixed
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  GPS
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <Schedule
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Última atualização
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#bdbdbd' : '#3E3E3E',
                  }}
                >
                  <DirectionsCar
                    fontSize="small"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                    }}
                  />
                  Em viagem
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontWeight: 'bold',
                    color:
                      theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                  }}
                >
                  <Tooltip title="Rastreador dispositivo">
                    <SettingsRemote
                      fontSize="small"
                      sx={{
                        color:
                          theme.palette.mode === 'dark' ? '#34A0FF' : '#1C74C0',
                      }}
                    />
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          {onlyProgress ? (
            <>
              {dataAfterFiltering
                ?.filter(driver => driver.driverInProgress)
                ?.map(data => (
                  <DriversGridBody
                    key={data.id}
                    filteredDriver={data}
                    handleClick={handleClick}
                    classes={classes}
                  />
                ))}
            </>
          ) : (
            <>
              {dataAfterFiltering?.map(data => (
                <DriversGridBody
                  key={data.id}
                  filteredDriver={data}
                  handleClick={handleClick}
                  classes={classes}
                />
              ))}
            </>
          )}
        </MuiTable>
      </Box>
      <Paper
        sx={{
          background: theme.palette.mode === 'dark' ? '#2b2b2b' : '#F1F1F1',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: '40px',
          alignItems: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <TblPagination />
      </Paper>
    </>
  );
};
