/* eslint-disable import/no-duplicates */
import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import ptLocale from 'date-fns/locale/pt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import { useAddWorkJourney } from '../api';

export const AddWorkJourney = ({
  closeModal,
  selectedDriver,
}: {
  closeModal: () => void;
  selectedDriver?: string;
}) => {
  const { mutateAsync, isLoading } = useAddWorkJourney();
  const { addNotification } = useNotificationStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [startedAt, setStartedAt] = useState<any>(null);
  const [enteredLunchAt, setEnteredLunchAt] = useState<any>(null);
  const [exitedLunchAt, setExitedLunchAt] = useState<any>(null);
  const [finishedAt, setFinishedAt] = useState<any>(null);
  const handleSubmit = () => {
    if (!selectedDriver) {
      addNotification({
        type: 'error',
        title: 'Selecione um motorista',
      });
      return;
    }
    if (!startedAt || !enteredLunchAt || !exitedLunchAt || !finishedAt) {
      addNotification({
        type: 'error',
        title: 'Preencha todos os campos',
      });
      return;
    }
    mutateAsync(
      {
        enteredLunchAt,
        exitedLunchAt,
        finishedAt,
        startedAt,
        userId: selectedDriver,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Data de entrada"
            value={startedAt}
            onChange={newValue => {
              setStartedAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Entrada para almoço"
            value={enteredLunchAt}
            onChange={newValue => {
              setEnteredLunchAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Retorno do almoço"
            value={exitedLunchAt}
            onChange={newValue => {
              setExitedLunchAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Saída"
            value={finishedAt}
            onChange={newValue => {
              setFinishedAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Button
        disabled={isLoading}
        onClick={() => handleSubmit()}
        sx={{ marginTop: '10px' }}
        variant="contained"
        color="success"
      >
        Salvar
      </Button>
    </Box>
  );
};
