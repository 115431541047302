import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MobileSideBar, Modal } from '@/components';
import { useState } from 'react';
import { Refresh } from '@mui/icons-material';
import { useNotificationStore } from '@/stores';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useSocket } from '@/providers/socketProvider';
import { useGetSectorNotFinishedTrips } from '../../api';
import { EvaluateTrip } from './EvaluateTrip';
import { CompanyTripsGrid } from './CompanyTripsGrid';
import { DateFilter } from '../OpenTrip/DateFilter';
import { TripRequest } from '../TripRequest';

const statusOptions = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Aguardando validação',
    value: 'waitingValidation',
  },
  {
    name: 'Aguardando resposta',
    value: 'requested',
  },
  {
    name: 'Aguardando motorista',
    value: 'pending',
  },
  {
    name: 'Agendado',
    value: 'scheduled',
  },
  {
    name: 'A caminho',
    value: 'accepted',
  },
  {
    name: 'Iniciada',
    value: 'started',
  },
  {
    name: 'Finalizada',
    value: 'finished',
  },
  {
    name: 'Rejeitada',
    value: 'requestRejected',
  },
  {
    name: 'Cancelada',
    value: 'requestCanceled',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SectorTrips = () => {
  const { addNotification } = useNotificationStore();

  const [canClickRefechButton, setCanClickRefetchButton] = useState(true);
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const { socket } = useSocket();
  const [finalDate, setFinalDate] = useState<any>(new Date());

  const handleClickRefetchTrips = () => {
    if (canClickRefechButton) {
      refetchTrips();
      setCanClickRefetchButton(false);

      setTimeout(() => {
        setCanClickRefetchButton(true);
      }, 20000);
    } else {
      addNotification({
        title: 'Aguarde um pouco para atualizar a lista de viagens',
        type: 'warning',
      });
    }
  };

  const [checkedStatus, setCheckedStatus] = useState<string[]>([
    'requested',
    'pending',
    'accepted',
    'started',
    'scheduled',
    'waitingValidation',
  ]);

  const handleChange = (event: SelectChangeEvent<typeof checkedStatus>) => {
    const {
      target: { value },
    } = event;

    if (
      value.includes('all') &&
      checkedStatus.includes('all') &&
      Array.isArray(value)
    ) {
      setCheckedStatus(value.filter(item => item !== 'all'));
      return;
    }

    if (value.includes('all')) {
      setCheckedStatus([
        'all',
        'requested',
        'pending',
        'scheduled',
        'accepted',
        'started',
        'finished',
        'requestRejected',
        'requestCanceled',
        'waitingValidation',
      ]);
      return;
    }
    setCheckedStatus(typeof value === 'string' ? value.split(',') : value);
  };
  const { close, isOpen, open } = useDisclosure();
  const [type, setType] = useState<'requests' | 'recurring'>('requests');
  const { data: trips, refetch: refetchTrips } = useGetSectorNotFinishedTrips({
    finalDate,
    initialDate,
    status: checkedStatus,
    type,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tripToAvaliate, setTripToAvaliate] = useState<string | null>(null);

  socket?.off('tripToValidation').on('tripToValidation', async () => {
    refetchTrips();
  });
  socket
    ?.off('validationCanceledByUser')
    .on('validationCanceledByUser', async () => {
      refetchTrips();
    });

  return (
    <Box>
      {isMobile && (
        <>
          <Box
            sx={{
              zIndex: '999',
              background: theme.palette.background.default,
            }}
          >
            <MobileSideBar />
          </Box>
        </>
      )}
      <>
        {isOpen && (
          <Modal
            title="Solicitar corrida"
            dialogContent={<TripRequest />}
            open={isOpen}
            size="sm"
            onClose={close}
          />
        )}
        <Box
          sx={{
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '0px' : '20px',
          }}
        >
          <IconButton onClick={() => handleClickRefetchTrips()}>
            <Refresh />
          </IconButton>
          <FormControl
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              marginTop: isMobile ? '0px' : '10px',
            }}
          >
            <Box>
              <TextField
                size="small"
                sx={{
                  width: isMobile ? '170px' : '250px',
                  marginLeft: isMobile ? '10px' : '0',
                  marginBottom: isMobile ? '10px' : '0',
                }}
                value={type}
                onChange={e => setType(e.target.value as any)}
                label="Filtrar por categoria"
                type="text"
                select
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value="requests">Solicitações</MenuItem>
                <MenuItem value="recurring">Viagem recorrente</MenuItem>
              </TextField>
            </Box>
            <FormControl sx={{ marginLeft: '10px' }}>
              <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                size="small"
                value={checkedStatus}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    sx={{ width: isMobile ? '170px' : '250px' }}
                    label="Status"
                  />
                }
                renderValue={selected => {
                  const selectedStatus = selected as string[];
                  return selectedStatus
                    .map(statusSelected =>
                      statusOptions.find(
                        statusOption => statusOption.value === statusSelected,
                      ),
                    )
                    .map(data => data?.name)
                    .join(', ');
                }}
                MenuProps={MenuProps}
              >
                {statusOptions.map(data => (
                  <MenuItem key={data.value} value={data.value}>
                    <Checkbox
                      checked={checkedStatus.indexOf(data.value) > -1}
                    />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DateFilter
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              isMobile={isMobile}
            />
          </FormControl>
          <Button onClick={() => open()} variant="contained">
            Solicitar corrida
          </Button>
          <Box
            sx={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            {tripToAvaliate && (
              <Modal
                dialogContent={
                  <EvaluateTrip
                    onSuccess={() => {
                      setTripToAvaliate(null);
                      refetchTrips();
                    }}
                    id={tripToAvaliate}
                  />
                }
                title="Avaliação"
                open={!!tripToAvaliate}
                size="xs"
                onClose={() => setTripToAvaliate(null)}
              />
            )}
          </Box>
        </Box>
        <CompanyTripsGrid
          setTripToAvaliate={setTripToAvaliate}
          openTripsData={trips || []}
        />
      </>
    </Box>
  );
};
