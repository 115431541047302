import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';

// eslint-disable-next-line consistent-return
export async function getVersionLogs({
  version,
}: {
  version: number;
}): Promise<any[] | any> {
  try {
    const response = await axios.get(`logs/getAll/${version}`);
    return response.data.payload;
  } catch (err) {
    console.log(err);
  }
}

export const useVersionLogs = ({ version }: { version: number }) => {
  return useQuery({
    queryKey: ['logs'],
    queryFn: () => getVersionLogs({ version }),
  });
};
