import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { AutoRedirectSettingsDTO } from '../types';

type UseCompanyOptions = {
  id: string;
  config?: QueryConfig<typeof getCompanyAutomaticRedirectionMode>;
};

export async function getCompanyAutomaticRedirectionMode({
  id,
}: {
  id: string;
}): Promise<AutoRedirectSettingsDTO> {
  const response = await axios.get(
    `autoRedirectSettings/getAutomaticRedirectionSettings/${id}`,
  );
  return response.data.payload;
}

export const useCompanyAutomaticRedirectionMode = ({
  id,
  config,
}: UseCompanyOptions) => {
  return useQuery({
    queryKey: ['automaticRedirectionMode', id],
    queryFn: () => getCompanyAutomaticRedirectionMode({ id }),
    ...config,
  });
};
