import { ContentLayout } from '@/components';
import { useAuth } from '@/providers/auth';
import { CreateCompanyNewForm } from '../components/CreateCompanyNew';
import { CreateRenter as CreateRenterForm } from '../components/CreateRenter';

export function CreateCompany() {
  const { user } = useAuth();
  return (
    <ContentLayout>
      {user.user.accessType === 'master' ? (
        <CreateRenterForm />
      ) : (
        <CreateCompanyNewForm />
      )}
    </ContentLayout>
  );
}
