import { AutoCompleteInput } from '@/components';
import { useEffect, useState } from 'react';

interface ISelectTripReportsFields {
  companies: {
    id: string;
    corporateName: string;
  }[];
  setCompany: (company: string) => void;
}

export const SelectCompany = ({
  companies,
  setCompany,
}: ISelectTripReportsFields) => {
  const formattedCompanies = companies?.map(company => ({
    id: company.id,
    name: company.corporateName,
  }));

  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    name: string;
  }>(
    formattedCompanies?.length > 0
      ? formattedCompanies[0]
      : { id: '', name: '' },
  );
  useEffect(() => {
    setCompany(selectedCompany.id);
  }, [selectedCompany, setCompany]);
  return (
    <AutoCompleteInput
      inputId="select-company"
      label="Selecione empresa"
      onChange={option => {
        setSelectedCompany({
          id: option?.id || '',
          name: option?.name || '',
        });
        setCompany(option?.id || '');
      }}
      options={formattedCompanies || []}
      selectedOption={selectedCompany}
      width={200}
    />
  );
};
