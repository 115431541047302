import {
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCompany } from '../api';

type props = {
  id: string;
};

export function ViewCompany({ id }: props) {
  const { data: companiesQuery, isLoading } = useCompany({ id });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  return (
    <>
      {isLoading && !companiesQuery ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                }}
              >
                Empresa
              </Typography>
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Email"
                value={companiesQuery?.email}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Empresa"
                value={companiesQuery?.corporateName}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Nome Fantasia"
                value={companiesQuery?.fantasyName}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Ramo"
                value={companiesQuery?.industry}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="CNPJ"
                value={companiesQuery?.registeredNumber}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Telefone"
                value={companiesQuery?.phone}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Pessoa para contato"
                value={companiesQuery?.personToContact}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="CEP"
                value={companiesQuery?.zipCode}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Endereço"
                value={companiesQuery?.address}
              />

              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Número"
                value={companiesQuery?.number}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Cidade"
                value={companiesQuery?.city}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Bairro"
                value={companiesQuery?.neighborhood}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Preço inicial"
                value={companiesQuery?.priceList[0].initialPrice}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Preço por km"
                value={companiesQuery?.priceList[0].pricePerKm}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Método de pagamento"
                value={companiesQuery?.payment}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.wrapper}>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  }}
                >
                  Usuário administrador
                </Typography>
                <TextField
                  size="small"
                  sx={{ width: '100%', marginTop: '10px' }}
                  disabled
                  label="Login"
                  value={companiesQuery?.user[0]?.email}
                />
                <TextField
                  size="small"
                  sx={{ width: '100%', marginTop: '10px' }}
                  disabled
                  label="Nome"
                  value={companiesQuery?.user[0]?.name}
                />
                <TextField
                  size="small"
                  sx={{ width: '100%', marginTop: '10px' }}
                  disabled
                  label="Telefone"
                  value={companiesQuery?.user[0]?.phone}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
