/* eslint-disable no-useless-return */
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CreatePriceList, createPriceListResponse } from '../types';

type UseCreateCompanyOptions = {
  config?: MutationConfig<typeof saveCompanyPrice>;
};

// eslint-disable-next-line consistent-return
export async function saveCompanyPrice(
  data: CreatePriceList,
): Promise<createPriceListResponse> {
  return axios.post('priceTable/create', data);
}

export const useSaveCompanyPrice = ({
  config,
}: UseCreateCompanyOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCompanyPrice => {
      await queryClient.cancelQueries('prices');

      const previousCompaniesPrice =
        queryClient.getQueryData<CreatePriceList[]>('prices');

      queryClient.setQueryData('prices', [
        ...(previousCompaniesPrice || []),
        newCompanyPrice,
      ]);

      return previousCompaniesPrice;
    },
    onError: (error: AxiosError, _, context) => {
      queryClient.setQueryData('prices', context);
    },
    onSuccess: data => {
      queryClient.invalidateQueries('prices');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'O novo preço tabelado foi cadastrado.',
      });
      navigate('/companies');
    },
    ...config,
    mutationFn: saveCompanyPrice,
  });
};
