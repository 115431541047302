/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  userId: string;
  city: string;
}

export const findAdresses = async (): Promise<IOutput[]> => {
  const response = await axios.get('favoriteAdresses/findAdresses');
  return response.data.payload;
};

type UseFindAdressesOptions = {
  config?: QueryConfig<typeof findAdresses>;
};

export const useFindAdresses = ({ config }: UseFindAdressesOptions = {}) => {
  return useQuery({
    queryKey: ['favoriteAdresses'],
    queryFn: () => findAdresses(),
    ...config,
  });
};
