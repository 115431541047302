import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { IRecurringTrip } from '../types';
import { IStopPoint } from '../components/TripRequest';

type UseRecurringTripsRequestOptions = {
  config?: MutationConfig<typeof saveRecurringTrips>;
};

interface IRequest {
  addressFrom: string;
  addressTo: string;
  finalLat: number;
  finalLong: number;
  startLat: number;
  startLong: number;
  days: {
    day: number;
  }[];
  minute: number;
  hour: number;
  cityOfDestination: string;
  cityOfOrigin: string;
  userIds: string[];
  stopPoints?: IStopPoint[];
}

export async function saveRecurringTrips(
  data: IRequest,
): Promise<IRecurringTrip> {
  const response = await axios.post(
    '/recurringTrips/createRecurringTrip',
    data,
  );
  return response.data.payload;
}

export const useSaveRecurringTrips = ({
  config,
}: UseRecurringTripsRequestOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async recurringTrip => {
      await queryClient.cancelQueries('recurringTrips');

      const previoustrips = queryClient.getQueryData<any[]>('recurringTrips');

      queryClient.setQueryData('recurringTrips', [
        previoustrips?.filter(trip => trip !== recurringTrip),
      ]);

      return previoustrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('recurringTrips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('recurringTrips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Viagem recorrente salva com sucesso',
      });
    },
    ...config,
    mutationFn: saveRecurringTrips,
  });
};
