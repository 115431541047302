import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { IStopPoint } from '../components/TripRequest';

export const sendTripAsRenter = (data: {
  startLat: number;
  startLong: number;
  finalLat?: number;
  finalLong?: number;
  userIds: string[];
  addressFrom: string;
  addressTo?: string;
  cityOfDestination?: string;
  cityOfOrigin?: string;
  scheduledDate?: Date | null;
  scheduledReturnDate?: Date | null;
  stopPoints?: IStopPoint[];
  requestedVehicleClassId?: string;
  sectorId?: string;
  observation?: string;
  companyId: string;
}): Promise<any> => {
  return axios.post(`trips/sendAsRenter`, data);
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof sendTripAsRenter>;
};

export const useSendTripAsRenter = ({
  config,
}: UseUpdateCompaniesOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
      queryClient.invalidateQueries('trips');
    },
    mutationFn: sendTripAsRenter,
  });
};
