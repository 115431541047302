/* eslint-disable no-nested-ternary */

import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import {
  Bolt,
  VpnKey,
  BatteryChargingFull,
  SignalCellular2Bar,
  Sensors,
  Speed,
} from '@mui/icons-material';
import { format } from 'date-fns';
import routeLottie from '../../../assets/json/route.json';
import { driversProps } from '../types';

export const TrackerInfo = ({
  filteredDriver,
}: {
  filteredDriver: driversProps;
}) => {
  const theme = useTheme();

  if (!filteredDriver.date) {
    return (
      <>
        <Typography>Sem rastreador</Typography>
      </>
    );
  }
  return (
    <>
      <Box style={{ padding: '20px' }}>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
          }}
        >
          Informações do rastreador
        </Typography>
        <Box style={{ height: '300px', marginTop: '30px', display: 'flex' }}>
          <Box
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <Lottie animationData={routeLottie} style={{ flex: 1 }} />
          </Box>
          <Box
            style={{
              flex: 1,
              padding: '20px',
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                }}
              >
                Hora da atualização
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                }}
              >
                {filteredDriver.date && (
                  <>
                    {format(
                      new Date(filteredDriver.date),
                      'dd/MM/yyyy H:mm:ss',
                    )}
                  </>
                )}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Tooltip title="Ignição">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <VpnKey />
                  <Typography style={{ fontSize: '12px', textAlign: 'center' }}>
                    {filteredDriver.ignition === 1 ? (
                      <Typography sx={{ fontSize: '10px' }}>🟢</Typography>
                    ) : (
                      <Typography sx={{ fontSize: '10px' }}>🔴</Typography>
                    )}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Bateria">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <BatteryChargingFull />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      color: '#25C322',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver.bateryPercent >= 66 ? (
                      <span style={{ color: '#48BD7E' }}>
                        {filteredDriver.bateryPercent || 0} %
                      </span>
                    ) : filteredDriver.bateryPercent >= 33 &&
                      filteredDriver.bateryPercent < 66 ? (
                      <span style={{ color: 'orange' }}>
                        {filteredDriver.bateryPercent || 0} %
                      </span>
                    ) : (
                      <span
                        style={{
                          color:
                            theme.palette.mode === 'dark' ? '#f02e2e' : 'red',
                        }}
                      >
                        {filteredDriver.bateryPercent || 0} %
                      </span>
                    )}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Voltagem">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Bolt />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      color:
                        theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver.voltage
                      ? `${filteredDriver.voltage} V`
                      : '0'}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Tooltip title="Sinal">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <SignalCellular2Bar />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver?.signal >= 66 ? (
                      <span style={{ color: '#48BD7E' }}>
                        {filteredDriver.signal || 0} %
                      </span>
                    ) : filteredDriver?.signal >= 33 &&
                      filteredDriver?.signal < 66 ? (
                      <span style={{ color: 'orange' }}>
                        {filteredDriver.signal || 0} %
                      </span>
                    ) : (
                      <span style={{ color: 'red' }}>
                        {filteredDriver.signal || 0} %
                      </span>
                    )}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Satélite">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Sensors />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      color: '#25C322',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver?.satellite > 0 ? (
                      <span style={{ color: '#48BD7E' }}>
                        {filteredDriver.satellite} W
                      </span>
                    ) : (
                      <span style={{ color: 'red' }}>
                        {filteredDriver.satellite || 0} W
                      </span>
                    )}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Velocidade Km/h">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Speed />
                  <Typography
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      color:
                        theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                      fontWeight: 'bold',
                    }}
                  >
                    {filteredDriver.speed} KM
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
