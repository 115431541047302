import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { IStatements } from '../types';

export async function getStatements({
  sectorId,
}: {
  sectorId: string;
}): Promise<IStatements[]> {
  const response = await axios.get(`vouchers/getSectorStatements/${sectorId}`);
  return response.data.payload;
}

type UseStatementsOptions = {
  config?: QueryConfig<typeof getStatements>;
  sectorId: string;
};

export const useStatements = ({ sectorId, config }: UseStatementsOptions) => {
  return useQuery({
    queryKey: ['statements', sectorId],
    enabled: sectorId !== undefined && sectorId !== '',
    queryFn: () => getStatements({ sectorId }),
    ...config,
  });
};
