import { Loading } from '@/components';
import { useSectorsList } from '@/features/sectors';
import { MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';

type IProps = {
  sectorId: string;
  setSectorId: (e: string) => void;
};

export const SelectSector = ({ sectorId, setSectorId }: IProps) => {
  const { data: sectorsList, isLoading: loadingSectorList } = useSectorsList();

  useEffect(() => {
    if (!loadingSectorList && sectorsList && sectorsList?.length > 0) {
      setSectorId(sectorsList[0]?.id);
    }
  }, [loadingSectorList, sectorsList, setSectorId]);

  if (loadingSectorList) {
    return <Loading isLoading={loadingSectorList} />;
  }
  return (
    <TextField
      sx={{ width: '200px', color: '#FFF', marginTop: '20px' }}
      select
      onChange={(e: any) => setSectorId(e.target.value)}
      placeholder="Selecione o setor"
      label="Setor"
      value={sectorId}
    >
      {sectorsList?.map((sector: any) => {
        // eslint-disable-next-line consistent-return
        return (
          <MenuItem key={sector.id} value={sector.id}>
            {sector.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
