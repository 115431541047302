import { useState } from 'react';
import { useAuth } from '@/providers/auth';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Business,
  Home,
  People,
  AssignmentInd,
  Map,
  AttachMoney,
  Folder,
  DirectionsCarFilled,
  Settings,
  Brightness7,
  Brightness4,
  Coffee,
  PowerSettingsNew,
  ListAlt,
  Paid,
  Lock,
  AirportShuttleOutlined,
  Accessibility,
  Engineering,
  Person,
} from '@mui/icons-material';
import {
  IconButton,
  SwipeableDrawer,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import { useCustomTheme } from '@/providers/theme';
import { useDisclosure } from '@/hooks/useDisclosure';
import useStyles from './styles';
import { RenderNavItens } from '../Sidebar/NavItens';
import { Modal } from '../Modal';
import { Profile } from '../Profile';

export function MobileSideBar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { signOut } = useAuth();
  const { toggleColorMode, sidebarColor, sidebarLogo } = useCustomTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    isOpen: isProfileOpen,
    open: openPrifle,
    close: closeProfile,
  } = useDisclosure();
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.toggleBar}>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <div
            className={classes.root}
            style={{
              backgroundImage: sidebarColor,
            }}
          >
            <Box className={classes.logo}>
              <img src={sidebarLogo} width={40} height={40} alt="logo" />
            </Box>
            <div className={classes.iconsWrapper}>
              <RenderNavItens
                navItens={[
                  {
                    icon: Home,
                    label: 'Início',
                    path: '/home',
                    className: classes.icon,
                  },
                  {
                    icon: DirectionsCarFilled,
                    label: 'Viagens',
                    path: '/tripsRequest',
                    className: classes.icon,
                    permissions: ['renter'],
                    roles: ['gerenciar viagens'],
                  },
                  {
                    icon: DirectionsCarFilled,
                    label: 'Solicitações',
                    path: '/requestTripAsAdmin',
                    className: classes.icon,
                    permissions: ['company_admin', 'sector_admin'],
                    roles: ['solicitar viagem'],
                  },
                  {
                    icon: Map,
                    label: 'Mapa',
                    path: '/mapscreen',
                    className: classes.icon,
                    permissions: ['renter', 'company_admin'],
                    roles: ['mapa'],
                  },
                  {
                    icon: Folder,
                    label: 'Relatórios',
                    path: '/reports',
                    className: classes.icon,
                    permissions: ['renter', 'company_admin', 'sector_admin'],
                    roles: ['relatorios'],
                  },
                  {
                    icon: ListAlt,
                    label: 'Atualizações',
                    path: '/logs',
                    className: classes.icon,
                    permissions: ['master'],
                  },
                  {
                    icon: AssignmentInd,
                    label: 'Motoristas',
                    path: '/drivers',
                    className: classes.icon,
                    permissions: ['renter'],
                    roles: ['gerenciar motoristas'],
                  },
                  {
                    icon: Coffee,
                    label: 'Setores',
                    path: '/sectors',
                    className: classes.icon,
                    permissions: ['company_admin', 'sector_admin'],
                    roles: ['gerenciar setores'],
                  },
                  {
                    icon: People,
                    label: 'Usuários',
                    path: '/users',
                    className: classes.icon,
                    permissions: ['sector_admin', 'company_admin'],
                    roles: ['gerenciar passageiros'],
                  },
                  {
                    icon: Accessibility,
                    label: 'Perfis',
                    path: '/profile',
                    className: classes.icon,
                    permissions: ['renter', 'master', 'company_admin'],
                    roles: ['gerenciar perfis'],
                  },
                  {
                    icon: Engineering,
                    label: 'Operadores',
                    path: '/operators',
                    className: classes.icon,
                    permissions: ['renter', 'company_admin', 'master'],
                    roles: ['gerenciar operadores'],
                  },
                  {
                    icon: Business,
                    label: 'Empresas',
                    path: '/companies',
                    className: classes.icon,
                    permissions: ['master', 'renter'],
                    roles: ['gerenciar clientes'],
                  },
                  {
                    icon: AttachMoney,
                    label: 'Tabela de preços',
                    path: '/pricetable',
                    className: classes.icon,
                    permissions: ['renter'],
                    roles: ['gerenciar tabela de preços'],
                  },
                  {
                    icon: Paid,
                    label: 'Extrato',
                    path: '/statements',
                    className: classes.icon,
                    permissions: ['sector_admin', 'company_admin'],
                    roles: ['extrato'],
                  },
                  {
                    icon: Lock,
                    label: 'Permissões',
                    path: '/roles',
                    className: classes.icon,
                    permissions: ['master'],
                  },
                  {
                    icon: AirportShuttleOutlined,
                    label: 'Categorias',
                    path: '/categories',
                    className: classes.icon,
                    permissions: ['master'],
                  },
                  {
                    icon: Folder,
                    label: 'Logs da aplicação',
                    path: '/app-logs',
                    className: classes.icon,
                    permissions: ['master'],
                  },
                  {
                    icon: Folder,
                    label: 'Chamadas do google',
                    path: '/google-requests',
                    className: classes.icon,
                    permissions: ['master'],
                  },
                ]}
              />
            </div>
            <Tooltip title="Configurações">
              <IconButton onClick={handleClick}>
                <Settings sx={{ color: 'white' }} fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <Tooltip title="Perfil">
                <MenuItem onClick={openPrifle}>
                  <Person fontSize="small" />
                </MenuItem>
              </Tooltip>
              <Tooltip title="tema">
                <MenuItem onClick={toggleColorMode}>
                  {theme.palette.mode === 'dark' ? (
                    <Brightness7 fontSize="small" />
                  ) : (
                    <Brightness4 fontSize="small" />
                  )}
                </MenuItem>
              </Tooltip>
              <Tooltip title="logout">
                <MenuItem onClick={signOut}>
                  <PowerSettingsNew sx={{ color: 'red' }} fontSize="small" />
                </MenuItem>
              </Tooltip>
            </Menu>
          </div>
        </SwipeableDrawer>
        {isProfileOpen && (
          <Modal
            open={isProfileOpen}
            onClose={closeProfile}
            size="xs"
            title="Perfil"
            dialogContent={<Profile />}
          />
        )}
      </div>
    </>
  );
}
