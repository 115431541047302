import { Component, ReactElement, ReactNode } from 'react';
import {
  Box,
  useTheme,
  SwipeableDrawer as Drawer,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

type Props = {
  title: string | ReactElement<Component>;
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
};

export const SwipeableDrawerComponent = ({
  title,
  children,
  isOpen,
  close,
  toggle,
}: Props) => {
  const theme = useTheme();
  return (
    <Drawer open={isOpen} onClose={() => close()} onOpen={() => toggle()}>
      <Box
        sx={{
          borderBottomLeftRadius: '12px',
          borderTopLeftRadius: '12px',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default,
          height: '100%',
          display: 'flex',
          position: 'fixed',
          width: '350px',
          right: 0,
        }}
      >
        <Box
          sx={{
            borderBottom: '1px solid gray',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              textAlign: 'start',
              padding: '10px',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <Tooltip title="Fechar">
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};
