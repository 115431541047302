import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export async function undoJoinedTrip(data: { id: string }): Promise<void> {
  return axios.patch(`trips/undoJoinedTrip/${data.id}`, data);
}

export const useUndoJoinedTrip = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async rejectedTrip => {
      await queryClient.cancelQueries('trips');
      const previousscheduledTrips = queryClient.getQueryData<any[]>('trips');
      queryClient.setQueryData('trips', [
        previousscheduledTrips?.filter(trip => trip !== rejectedTrip),
      ]);

      return previousscheduledTrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    mutationFn: undoJoinedTrip,
  });
};
