/* eslint-disable react/no-array-index-key */
import { Form, InputField } from '@/components';
import { Star } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { useManagerEvaluatesTrip } from '../../api';

interface IProps {
  id: string;
  onSuccess: () => void;
}

const schema = z.object({
  message: z.string().max(255).optional().nullable(),
  confirmation: z.boolean({
    required_error: 'Confirmação é obrigatória',
  }),
  driverAvaliation: z
    .number({
      required_error: 'Avaliação é obrigatória',
    })
    .min(1)
    .max(5),
});

type FormValues = {
  message: string;
  driverAvaliation: number;
  confirmation: boolean;
};

export const EvaluateTrip = ({ id, onSuccess }: IProps) => {
  const { mutateAsync, isLoading } = useManagerEvaluatesTrip();
  const [stars, setStars] = useState(0);
  const handleStars = (
    driverAvaliation: number,
    setValue: UseFormSetValue<FormValues>,
  ) => {
    if (stars === driverAvaliation) {
      setStars(0);
      setValue('driverAvaliation', 0);
      return;
    }
    setStars(driverAvaliation);
    setValue('driverAvaliation', driverAvaliation);
  };
  return (
    <Form<FormValues, typeof schema>
      onSubmit={async values => {
        mutateAsync(
          {
            id,
            ...values,
          },
          {
            onSuccess: () => {
              onSuccess();
            },
          },
        );
      }}
      schema={schema}
    >
      {({ register, formState, setValue, getValues, watch }) => {
        watch('confirmation');
        return (
          <>
            <Box>
              {Array.from({ length: 5 }).map((_, index) => (
                <IconButton
                  onClick={() => handleStars(index + 1, setValue)}
                  key={index}
                >
                  <Star
                    sx={{
                      color: index < stars ? '#FFD700' : '#E8E8E8',
                      fontSize: '40px',
                    }}
                  />
                </IconButton>
              ))}
              <Typography fontSize={12} color="red">
                {formState.errors.driverAvaliation?.message}
              </Typography>
            </Box>
            <InputField
              size="small"
              name="message"
              type="text"
              label="Mensagem"
              placeholder=""
              registration={register('message')}
              error={!!formState.errors.message}
              errorMessage={formState.errors.message?.message}
            />
            <Typography fontSize="12">
              A viagem foi encerrada no destino?
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setValue('confirmation', true)}
                    checked={getValues('confirmation') === true}
                  />
                }
                label="Sim"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setValue('confirmation', false)}
                    checked={getValues('confirmation') === false}
                  />
                }
                label="Não"
              />
            </Box>
            <Typography fontSize={12} color="red">
              {formState.errors.confirmation?.message}
            </Typography>
            <LoadingButton
              sx={{ marginTop: '10px' }}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="success"
            >
              Salvar
            </LoadingButton>
          </>
        );
      }}
    </Form>
  );
};
