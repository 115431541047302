import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ICreateVehicleClassDTO, IVehicleClass } from '../types';

export async function registerClass(
  data: ICreateVehicleClassDTO,
): Promise<IVehicleClass> {
  return axios.post('vehicleClasses/createVehicleClass', data);
}

export const useRegisterClass = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newDriver => {
      await queryClient.cancelQueries('classes');

      const previousDrivers =
        queryClient.getQueryData<IVehicleClass[]>('classes');

      queryClient.setQueryData('classes', [
        ...(previousDrivers || []),
        newDriver,
      ]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('classes', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('classes');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Classe cadastrada com sucesso.',
      });
      navigate(-1);
    },
    mutationFn: registerClass,
  });
};
