import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';

export const startExternalCompany = (data: { id: string }): Promise<void> => {
  return axios.patch(`trips/startExternalTrip/${data.id}`, data);
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof startExternalCompany>;
};

export const useStartExternalCompany = ({
  config,
}: UseUpdateCompaniesOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    onMutate: () => {
      queryClient.cancelQueries('trips');
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
      queryClient.invalidateQueries('trips');
    },
    mutationFn: startExternalCompany,
  });
};
