import { driversProps } from '@/features/mapScreen';
import { storage } from '@/utils/storage';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface MapConfigProviderProps {
  children: ReactNode;
}

interface MapConfigContextData {
  setMapTheme: (theme: any) => void;
  mapTheme: string;
  mapMarker: string;
  setMapMarker: (marker: any) => void;
  markerColor: string;
  setMarkerColor: (color: any) => void;
  renderMarkers: (
    driver: driversProps[],
    onlyInProgress: boolean,
    map: any,
    setClickedDriver: any,
  ) => void;
  clearMarkers: () => void;
  clearClusters: () => void;
  zoomMap: (lat: number, lng: number, map: any) => void;
  markers: any[];
  map: {
    current: google.maps.Map;
  };
  maps: {
    current: typeof google.maps;
  };
}

const MapConfigContext = createContext({} as MapConfigContextData);

const MapConfigProvider = ({
  children,
}: MapConfigProviderProps): JSX.Element => {
  const map: any = useRef();
  const maps: any = useRef();
  const getMode = storage.getItem({ key: 'mapTheme', storageType: 'local' });

  const [mapTheme, setMapTheme] = useState<'light' | 'dark' | 'system'>(
    getMode?.mapTheme || 'system',
  );

  const getMarkerColor = storage.getItem({
    key: 'markerColor',
    storageType: 'local',
  });

  const getMarker = storage.getItem({
    key: 'mapMarker',
    storageType: 'local',
  });

  const [mapMarker, setMapMarker] = useState<'arrow' | 'car'>(
    getMarker?.mapMarker || 'car',
  );

  const [markerColor, setMarkerColor] = useState<any>(
    getMarkerColor?.markerColor || 'gray',
  );

  useEffect(() => {
    storage.setItem({
      key: 'mapTheme',
      storageType: 'local',
      values: { mapTheme },
    });

    storage.setItem({
      key: 'mapMarker',
      storageType: 'local',
      values: { mapMarker },
    });

    storage.setItem({
      key: 'markerColor',
      storageType: 'local',
      values: { markerColor },
    });
  }, [mapTheme, mapMarker, markerColor]);

  const [renderMap, setRenderMap] = useState(null);
  const zoomMap = (lat: number, lgn: number, map?: any) => {
    const latLon = new maps.current.LatLng(lat, lgn);
    map.current.setCenter(latLon);
    map.current.setZoom(18);
  };
  const [markers, setMarkers] = useState<any>([]);

  const prevMarkersRef: any = useRef([]);

  const prevClusterRef: any = useRef([]);

  function clearMarkers() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prevMarkersRef.current.length; i++) {
      prevMarkersRef.current[i].marker.setMap(null);
    }
    prevMarkersRef.current.length = 0;
  }
  function clearClusters() {
    if (prevClusterRef.current.length) prevClusterRef.current[0].setMap(null);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prevClusterRef.current[0]?.markers?.length; i++) {
      prevClusterRef.current[0].markers[i].setMap(null);
    }
    prevClusterRef.current.length = 0;
  }

  type markers = {
    id: string;
    marker: google.maps.Marker;
  };

  const updateMarkers = useCallback(
    (
      driver: driversProps,
      map: any,
      icon: {
        url: string;
        rotation: number;
      },
      setClickedDriver: (driver: driversProps) => void,
    ) => {
      const index = markers?.findIndex(
        (marker: { id: string }) => marker.id === driver.id,
      );
      const marker = markers[index];

      if (marker) {
        if (
          marker.lat !== driver.latitude &&
          marker.lng !== driver.longitude
        ) {
          const filterMarkers: any[] = markers.filter(
            (data: any) => data.id !== driver.id,
          );
          setMarkers([...filterMarkers, { ...marker }]);
        }
        return marker;
      }
      const newMarker = {
        url: icon.url,
        rotation: driver.direction,
        lat: driver.latitude,
        lng: driver.longitude,
        onClick: () => {},
        map: map.current,
      };
      setMarkers((prev: any) => [
        ...prev,
        { ...newMarker, id: driver.id },
      ]);

      return newMarker;
    },
    [markers],
  );

  const renderMarkers = useCallback(
    (
      drivers: driversProps[],
      onlyInProgress: boolean,
      map: any,
      setClickedDriver: (driver: driversProps) => void,
    ) => {
      setRenderMap(map.current);
      drivers?.map((item: driversProps) => {
        const svgMarker = {
          url: 'https://www.shutterstock.com/image-vector/waze-icon-location-gps-symbol-260nw-1795112224.jpg',
          rotation: item.direction || 0,
          scaledSize: 1,
        };
        updateMarkers(item, map, svgMarker, setClickedDriver);
        return svgMarker;
      });
    },
    [updateMarkers],
  );

  return (
    <MapConfigContext.Provider
      value={{
        mapTheme,
        setMapTheme,
        mapMarker,
        setMapMarker,
        markerColor,
        setMarkerColor,
        renderMarkers,
        clearMarkers,
        clearClusters,
        zoomMap,
        markers,
        map,
        maps,
      }}
    >
      {children}
    </MapConfigContext.Provider>
  );
};

function useMapConfig(): MapConfigContextData {
  const context = useContext(MapConfigContext);

  if (!context) {
    throw new Error('useMapConfig must be used with a useMapConfigProvider.');
  }

  return context;
}

export { MapConfigProvider, useMapConfig };
