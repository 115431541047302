import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Companies,
  CreateCompanyNewDTO,
  SaveCompanyResponse,
} from '@/features/companyNew/types';

export async function createCompany(
  data: CreateCompanyNewDTO,
): Promise<SaveCompanyResponse> {
  const response = await axios.post('companies/create', data);
  return response.data.payload;
}

export const useCreateMaster = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCompanyNew => {
      await queryClient.cancelQueries('companies');

      const previousCompanyNew =
        queryClient.getQueryData<Companies[]>('companies');

      queryClient.setQueryData('companies', [
        ...(previousCompanyNew || []),
        newCompanyNew,
      ]);

      return previousCompanyNew;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('companies', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('companies');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Locatária criada com sucesso.',
      });
      navigate('/companies');
    },
    mutationFn: createCompany,
  });
};
