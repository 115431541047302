import { Box, Button, Typography } from '@mui/material';
import { AutoCompleteInput, ConfirmModal } from '@/components';
import { useState } from 'react';
import { useDrivers } from '@/features/drivers';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IVehicle } from '../types';
import { useAssociateDriverToVehicle, useCheckIfCanBeAssociated } from '../api';

interface IRequest {
  vehicle: IVehicle;
  onClose: () => void;
}

export const AssociateDriverToVehicle = ({ vehicle, onClose }: IRequest) => {
  const { mutateAsync: associate, isLoading: isAssociating } =
    useAssociateDriverToVehicle();
  const { mutateAsync: check, isLoading: isChecking } =
    useCheckIfCanBeAssociated();
  const { close, isOpen, open } = useDisclosure();
  const [notAvailableDescription, setNotAvailableDescription] = useState('');
  const { data: drivers } = useDrivers();
  const formattedDrivers = drivers?.map(
    (driver: { id: string; name: string }) => ({
      id: driver.id,
      name: driver.name,
    }),
  );
  const [selectedDriver, setSelectedDriver] = useState<{
    id: string;
    name: string;
  }>(formattedDrivers?.length > 0 ? formattedDrivers[0] : { id: '', name: '' });

  const handleAssociate = () => {
    check(
      { driverId: selectedDriver.id, vehicleId: vehicle.id },
      {
        onSuccess: data => {
          if (data.isAvailable) {
            associate(
              {
                driverId: selectedDriver.id,
                vehicleId: vehicle.id,
              },
              {
                onSuccess: () => {
                  onClose();
                },
              },
            );
          } else {
            setNotAvailableDescription(data?.notAvailableMessage || '');
            open();
          }
        },
      },
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <AutoCompleteInput
          inputId="select-driver-vehicle"
          label="Selecione motorista"
          onChange={option => {
            setSelectedDriver({
              id: option?.id || '',
              name: option?.name || '',
            });
          }}
          options={formattedDrivers}
          selectedOption={selectedDriver}
          width={200}
        />
        <Box>
          <Typography>{vehicle.model}</Typography>
          <Typography>{vehicle.licensePlate}</Typography>
          <Button
            onClick={() => {
              handleAssociate();
            }}
            disabled={isAssociating || isChecking}
            color="success"
            variant="contained"
          >
            Associar
          </Button>
        </Box>
      </Box>
      <ConfirmModal
        isLoading={isAssociating}
        close={close}
        handleConfirmation={() => {
          associate(
            {
              driverId: selectedDriver.id,
              vehicleId: vehicle.id,
            },
            {
              onSuccess: () => {
                onClose();
              },
            },
          );
        }}
        description={notAvailableDescription}
        isOpen={isOpen}
      />
    </>
  );
};
