import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { IPriceTags } from '../types';

// eslint-disable-next-line consistent-return
export async function getTaggedPrice(data: {
  id: string;
}): Promise<IPriceTags> {
  const response = await axios.get(`taggedAdresses/findTaggedPrice/${data.id}`);
  return response.data.payload;
}

export const useGetTaggedPrice = ({ id }: { id: string }) => {
  return useQuery({
    enabled: !!id,
    queryKey: ['tags', id],
    queryFn: () => getTaggedPrice({ id }),
  });
};
