import {
  Radio,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  useTheme,
  Box,
  FormGroup,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { VscCircleFilled } from 'react-icons/vsc';
import { Search } from '@mui/icons-material';
import { useState } from 'react';
import { ISelectDriverDTO } from '../../types';

type IInterface = {
  data: ISelectDriverDTO[];
  setSelectedDriverId: (id: string) => void;
  onlineDriversId: any[];
  selectedDriverId: string;
};

export const AcceptTripMobileTable = ({
  data,
  setSelectedDriverId,
  onlineDriversId,
  selectedDriverId,
}: IInterface) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearch(e.target.value);
  };
  const sortedData = [...data]
    ?.filter(driver =>
      Object.values(driver).some(
        value =>
          typeof value === 'string' &&
          value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    )
    ?.sort((a, b) => {
      if (
        onlineDriversId &&
        onlineDriversId.includes &&
        onlineDriversId.includes(a.id) &&
        (!onlineDriversId.includes || !onlineDriversId.includes(b.id))
      ) {
        return -1;
      }
      if (
        onlineDriversId &&
        onlineDriversId.includes &&
        !onlineDriversId.includes(a.id) &&
        onlineDriversId.includes(b.id)
      ) {
        return 1;
      }
      return 0;
    });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormGroup row>
        <FormControl>
          <OutlinedInput
            margin="dense"
            id="searchTerms"
            name="searchTerms"
            type="search"
            size="small"
            placeholder="Pesquisar..."
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      </FormGroup>
      <Table
        sx={{
          tableLayout: 'fixed',
          overflowX: 'auto',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 'none', width: '10px' }} />
            <TableCell sx={{ border: 'none' }}>Nome</TableCell>
            <TableCell sx={{ border: 'none' }}>Distância</TableCell>
            <TableCell sx={{ border: 'none' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData
            ?.filter(driver => driver.licensePlate !== '')
            .map(i => {
              return (
                <TableRow
                  onClick={() => {
                    setSelectedDriverId(i.id);
                  }}
                  sx={{
                    border: '1px solid transparent',
                    cursor: 'pointer',
                    '&:hover': {
                      '& td': {
                        transition: 'ease-in-out 0.5s',
                        fontWeight: 'bold',
                        color: '#5C5C5C',
                        background: '#BBD7FF',
                      },
                    },
                    '& td': {
                      marginTop: '5px',
                      fontWeight: 'bold',
                      background:
                        theme.palette.mode === 'dark' ? '#474747' : '#FFFFFF',
                      color:
                        theme.palette.mode === 'dark' ? '#D8D8D8' : '#5C5C5C',
                    },

                    '& td:first-child': {
                      borderTopLeftRadius: '18px',
                      borderBottomLeftRadius: '18px',
                    },
                    '& td:last-child': {
                      borderTopRightRadius: '18px',
                      borderBottomRightRadius: '18px',
                    },
                  }}
                  key={i.id}
                >
                  <TableCell scope="row">
                    <Radio
                      sx={{ color: '#848484', marginLeft: '-15px' }}
                      checked={selectedDriverId === i.id}
                      value={i.id}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>
                    {typeof i.distance === 'number' ? i.distance : 0} Km
                  </TableCell>
                  {onlineDriversId?.includes(i.id) ? (
                    <TableCell>
                      <VscCircleFilled color="green" />
                      Online
                    </TableCell>
                  ) : (
                    <TableCell>
                      <VscCircleFilled color="red" />
                      Offline
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );
};
