import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CreatePermissions } from '@/features/permissions';

type UseCreatePermissionsOptions = {
  config?: MutationConfig<typeof createPermissions>;
};

// eslint-disable-next-line consistent-return
export async function createPermissions(data: CreatePermissions): Promise<any> {
  const response = await axios.post('permissions/create', data);
  return response.data.payload;
}

export const useCreatePermissions = ({
  config,
}: UseCreatePermissionsOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newPermissions => {
      await queryClient.cancelQueries('permissions');

      const previousPermissions =
        queryClient.getQueryData<CreatePermissions[]>('permissions');

      queryClient.setQueryData('permissions', [
        ...(previousPermissions || []),
        newPermissions,
      ]);

      return previousPermissions;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('permissions', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('permissions');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Atualização registrada com sucesso',
      });
      navigate('/permissions');
    },
    ...config,
    mutationFn: createPermissions,
  });
};
