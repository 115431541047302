import { PageLayout } from '@/components';
import { AttachMoney } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { CompaniesPriceListTable } from '../components/CompaniesPriceListTable';

export function CompaniesList() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Tabela de preços"
        subTitle={subTitle}
        icon={<AttachMoney fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <CompaniesPriceListTable />
      </PageLayout>
    </>
  );
}
