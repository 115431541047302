import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteSectorProps, deleteSectorResponse } from '../types';

type UseCreateCompanyOptions = {
  config?: MutationConfig<typeof deleteSector>;
};

export async function deleteSector(
  data: deleteSectorProps,
): Promise<deleteSectorResponse> {
  return axios.delete(`/sectors/delete/${data.id}`);
}

export const useDeleteSector = ({ config }: UseCreateCompanyOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (error: AxiosError) => {
      addNotification({
        type: 'error',
        title: 'Erro',
        message: error.response?.data?.message || 'Erro ao deletar setor.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sectors');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Setor deletado com sucesso.',
      });
      navigate('/sectors');
    },
    ...config,
    mutationFn: deleteSector,
  });
};
