/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  useMediaQuery,
  Box,
  Button,
  Typography,
  useTheme,
  Paper,
  Tooltip,
  IconButton,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import userImage from '@/assets/img/usuario.png';
import mapDarkTheme from '@/assets/img/tripCardDarkTheme.png';
import mapLightheme from '@/assets/img/tripCardLightTheme.png';
import { RiPinDistanceFill, RiCarFill } from 'react-icons/ri';
import { BsCash } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { useOpenTrip } from '@/providers/openTrips';
import { DriversValues, IDriver } from '@/features/drivers';
import {
  ChangeCircle,
  CheckCircleOutline,
  ContentCopy,
  PlayCircleOutline,
  Replay,
  SportsScore,
} from '@mui/icons-material';
import { useState } from 'react';
import AddressCard from '../AddressCard';
import { RedirectDriver } from '../RedirectDriver/RedirectDriver';
import {
  useFinishExternalCompany,
  useMarkExternalTripAsAccepted,
  useStartExternalCompany,
  useUndoJoinedTrip,
} from '../../api';
import { useUndoExternalCompany } from '../../api/undoExternalCompany';

type ITripCard = {
  id: string;
  corporateName: string;
  scheduledDate?: any;
  addressFrom: string;
  addressTo: string;
  distance: string;
  travelTime: string;
  requestDate: string;
  driverId?: string;
  driver?: IDriver;
  driverName?: string;
  cityOfOrigin?: string;
  cityOfDestination?: string;
  users?: [
    {
      id: string;
      companyId: string;
      sectorId: string;
      sectorName: string;
      name: string;
      email: string;
      password: string;
      individualRegistration: string;
      accessType: string;
      status: boolean;
      phone: string;
      createdAt: string;
      updatedAt: string;
    },
  ];
  sector?: {
    id: string;
    name: string;
    sectorId?: string;
  };
  vehicleCategoryName?: string;
  status: string;
  stopPoints?: {
    id: string;
    address: string;
    lat: number;
    long: number;
    order: number;
  }[];
  usersSharingTrip?: {
    id: string;
    name: string;
    urlImage?: string;
    sector?: {
      id: string;
      name: string;
    };
  }[];
  estimatedPrice?: number;
  isJoinedTrip?: boolean;
  drivers?: DriversValues[];
  externalCompany?: string;
  finalLat: string;
  finalLong: string;
  AcceptTripModal: () => void;
  RejectTripModal: () => void;
};

export const TripCard = ({
  corporateName,
  addressFrom,
  scheduledDate,
  addressTo,
  distance,
  requestDate,
  travelTime,
  AcceptTripModal,
  RejectTripModal,
  estimatedPrice,
  stopPoints,
  usersSharingTrip,
  vehicleCategoryName,
  status,
  driver,
  driverId,
  drivers,
  id,
  cityOfDestination,
  cityOfOrigin,
  sector,
  users,
  finalLat,
  finalLong,
  driverName,
  externalCompany = '',
  isJoinedTrip = false,
}: ITripCard) => {
  const statusText: any = {
    requested: 'Aguardando resposta',
    pending: 'Aguardando motorista',
    scheduled: 'Agendado',
    accepted: 'A caminho',
    started: 'Iniciada',
    finished: 'Finalizada',
    requestRejected: 'Rejeitada',
    requestCanceled: 'Cancelada',
  };
  const { automaticRedirectionMode } = useOpenTrip();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const useStyles = makeStyles(() => ({
    wrapper: {
      marginTop: '20px',
      width: '300px',
      borderRadius: '20px 20px',
      background: ({ theme, matches }: { theme?: any; matches?: boolean }) =>
        theme.palette.mode === 'dark' ? '#2C2C2C' : '#F3F3F3',
      marginLeft: ({ matches }: { matches?: boolean; theme?: any }) =>
        matches ? '20px' : '0px',
    },
    photoImage: { width: '266px', height: '113px' },
  }));

  const travelTimeInSeconds = parseFloat(travelTime) * 60;

  const hours = Math.floor(travelTimeInSeconds / 3600);

  const formattedHour =
    hours === 1 ? `${hours} hora` : hours > 1 ? `${hours} horas` : '';

  const minutes = Math.floor(
    (travelTimeInSeconds - Math.floor(travelTimeInSeconds / 3600) * 3600) / 60,
  );

  const formattedMinute =
    minutes === 1
      ? `${minutes} minuto`
      : minutes > 1
      ? `${minutes} minutos`
      : '';

  const formattedTravelTime = `${formattedHour} ${formattedMinute}`;
  const { mutateAsync: undoJoinedTrip, isLoading: isLoadingUndoJoinedTrip } =
    useUndoJoinedTrip();
  const {
    mutateAsync: undoExternalCompany,
    isLoading: isCancelingExternalCompany,
  } = useUndoExternalCompany();
  const {
    mutateAsync: startExternalCompany,
    isLoading: isStartingExternalCompany,
  } = useStartExternalCompany();
  const {
    mutateAsync: markExternalTripAsAccepted,
    isLoading: isAcceptingExternalTrip,
  } = useMarkExternalTripAsAccepted();
  const { mutateAsync: finishExternalCompany, isLoading: isFinishing } =
    useFinishExternalCompany();
  const classes = useStyles({ matches, theme });
  const [showCopyToast, setShowCopyToast] = useState(false);

  const handleCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };

  return (
    <>
      <Paper className={classes.wrapper}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            variant="subtitle1"
          >
            {corporateName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              variant="subtitle2"
            >
              id: {id}
            </Typography>
          </Box>
          <Box>
            <img
              alt="mapa"
              src={theme.palette.mode === 'dark' ? mapDarkTheme : mapLightheme}
            />
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', padding: '15px' }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {usersSharingTrip?.map(user => (
                <Box
                  key={user.id}
                  sx={{
                    display: 'flex',
                    marginRight: '10px',
                    position: 'relative',
                    '&:hover .moreInfo': {
                      display: 'flex',
                    },
                  }}
                >
                  <Box sx={{ width: '25px', height: '25px' }}>
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50px',
                      }}
                      alt="foto do usuário"
                      src={user?.urlImage || userImage}
                    />
                  </Box>
                  <Typography
                    sx={{
                      marginLeft: '5.7px',
                      color:
                        theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                      fontWeight: 'bold',
                    }}
                    variant="subtitle1"
                  >
                    {user.name.split(' ')[0]}
                  </Typography>
                  <Paper
                    className="moreInfo"
                    sx={{
                      zIndex: 1,
                      position: 'absolute',
                      display: 'none',
                      top: '-10px',
                      transform: 'translate(-50%, -50%)',
                      background:
                        theme.palette.mode === 'dark' ? '#2C2C2C' : '#F3F3F3',
                      width: '200px',
                      flexDirection: 'column',
                      padding: '5px',
                      borderRadius: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color:
                        theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                    }}
                  >
                    <img
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50px',
                      }}
                      alt="foto do usuário"
                      src={user?.urlImage || userImage}
                    />
                    <Typography>Nome: {user.name}</Typography>
                    <Typography>Setor: {user?.sector?.name}</Typography>
                  </Paper>
                </Box>
              ))}
            </Box>
            {driverId && (
              <Box
                sx={{
                  marginLeft: '10px',
                  width: matches ? '110px' : '90px',
                }}
              >
                {status === 'pending' || status === 'scheduled' ? (
                  <RedirectDriver
                    drivers={drivers || []}
                    driverId={driverId}
                    id={id}
                  />
                ) : (
                  <>
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                        fontWeight: 'bold',
                      }}
                      variant="subtitle1"
                    >
                      {driverName}
                    </Typography>
                  </>
                )}
              </Box>
            )}
            {externalCompany && (
              <Box
                sx={{
                  marginLeft: '10px',
                  width: matches ? '110px' : '90px',
                }}
              >
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                    fontWeight: 'bold',
                  }}
                  variant="subtitle1"
                >
                  {externalCompany}
                </Typography>
              </Box>
            )}
            <Box>
              <AddressCard
                stopPoints={stopPoints}
                addressFrom={addressFrom}
                addressTo={addressTo}
              />
            </Box>
            {!!distance && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    color:
                      theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                    fontWeight: '700',
                    fontFamily: 'Inter',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BsCash />
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        Preço estimado:
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        R$ {estimatedPrice?.toFixed(2) || '0.00'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    color:
                      theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                    fontWeight: '700',
                    fontFamily: 'Inter',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RiPinDistanceFill />
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        Distância:
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        {parseFloat(distance).toFixed(0)} km
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color:
                      theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                    fontWeight: '700',
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FiClock />
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontWeight: 'bold',
                          fontSize: '12px',
                        }}
                        variant="subtitle1"
                      >
                        Tempo:
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: '5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        {formattedTravelTime}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {(!addressTo || addressTo === 'Motorista à disposição') && (
              <Typography
                sx={{
                  marginLeft: '5px',
                  fontSize: '12px',
                  color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                  fontWeight: '700',
                  fontFamily: 'Inter',
                }}
                variant="subtitle1"
              >
                Motorista à disposição
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                fontWeight: '700',
                fontFamily: 'Inter',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineCalendar />
                <Box
                  sx={{
                    display: 'flex',
                    marginLeft: '10px',
                    marginTop: '5px',
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                    variant="subtitle1"
                  >
                    {scheduledDate ? 'Agendamento' : 'Solicitação'}
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                    variant="subtitle1"
                  >
                    {scheduledDate || requestDate || null}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {vehicleCategoryName && (
              <Box
                sx={{
                  display: 'flex',
                  color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                  fontWeight: '700',
                  fontFamily: 'Inter',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <RiCarFill />
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: '10px',
                      marginTop: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        marginLeft: '5px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                      variant="subtitle1"
                    >
                      Veículo solicitado:
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: '5px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                      variant="subtitle1"
                    >
                      {vehicleCategoryName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              {!automaticRedirectionMode || scheduledDate ? (
                <>
                  {status === 'requested' || status === 'waitingValidation' ? (
                    <>
                      <Button
                        onClick={() => AcceptTripModal()}
                        sx={{
                          background: '#2ABC4A',
                          borderRadius: '8px',
                          color: '#FFFFFF',
                          '&:hover': {
                            background: '#2ABC4A',
                            opacity: '0.8',
                            transition: 'ease-in-out 0.2s',
                          },
                        }}
                        variant="contained"
                      >
                        Aceitar
                      </Button>
                      <Button
                        onClick={() => RejectTripModal()}
                        sx={{
                          background: '#E14242',
                          borderRadius: '8px',
                          color: '#FFFFFF',
                          '&:hover': {
                            background: '#E14242',
                            opacity: '0.8',
                            transition: 'ease-in-out 0.2s',
                          },
                        }}
                        variant="contained"
                      >
                        Recusar
                      </Button>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? '#D8D8D8'
                              : '#7A7A7A',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                        variant="subtitle1"
                      >
                        {statusText[status]}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <Tooltip
                          title="Copiar para whatsapp"
                          sx={{ padding: '0px' }}
                        >
                          <IconButton
                            onClick={() => {
                              const contentToCopy = `*${
                                !scheduledDate ? '--:--' : scheduledDate
                              } ${cityOfOrigin} X ${cityOfDestination}*\n
                              *Endereço inicial - ${addressFrom}*\n
                              ${
                                stopPoints && stopPoints?.length > 0
                                  ? `
                              *Pontos de parada:*\n
                               ${stopPoints
                                 ?.map(
                                   ({ address }, i) =>
                                     `*${i + 1} - ${address}*`,
                                 )
                                 ?.join('\n ')}\n
                                `
                                  : ''
                              }
                              *Endereço final - ${
                                addressTo || 'Motorista à disposição'
                              }*\n
                              *${users
                                ?.map(user => user.name.split(' ')[0])
                                .join(', ')} - ${users
                                ?.map(user => user.phone)
                                .join(', ')}*\n*CC: ${sector?.name}/${
                                sector?.sectorId || ''
                              }*`;
                              handleCopyToClipboard(contentToCopy);
                            }}
                          >
                            <ContentCopy
                              sx={{
                                fontSize: 20,
                                color:
                                  theme.palette.mode === 'dark'
                                    ? '#FFF'
                                    : '#1d1d1d',
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        {status === 'requested' && isJoinedTrip && (
                          <Tooltip
                            title="Desfazer compartilhamento"
                            sx={{ padding: '0px' }}
                          >
                            <IconButton
                              disabled={isLoadingUndoJoinedTrip}
                              onClick={() => {
                                undoJoinedTrip({ id });
                              }}
                            >
                              <ChangeCircle
                                sx={{
                                  color:
                                    theme.palette.mode === 'dark'
                                      ? '#FFF'
                                      : '#1d1d1d',
                                  fontSize: 20,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {externalCompany && (
                          <>
                            {(status === 'pending' ||
                              status === 'scheduled' ||
                              status === 'accepted') && (
                              <Tooltip title="Desfazer">
                                <IconButton
                                  disabled={isCancelingExternalCompany}
                                  onClick={() => {
                                    undoExternalCompany({ id });
                                  }}
                                >
                                  <Replay sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {status === 'scheduled' && (
                              <Tooltip title="Motorista a caminho">
                                <IconButton
                                  disabled={isAcceptingExternalTrip}
                                  onClick={() => {
                                    markExternalTripAsAccepted({
                                      id,
                                    });
                                  }}
                                >
                                  <SportsScore sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {status === 'accepted' && (
                              <Tooltip title="Iniciar">
                                <IconButton
                                  disabled={isStartingExternalCompany}
                                  onClick={() => {
                                    startExternalCompany({ id });
                                  }}
                                >
                                  <PlayCircleOutline sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {status === 'started' && (
                              <Tooltip title="Finalizar">
                                <IconButton
                                  disabled={isFinishing}
                                  onClick={() => {
                                    finishExternalCompany({
                                      id,
                                      lat: parseFloat(finalLat) || 0,
                                      long: parseFloat(finalLong) || 0,
                                      manualFinalOdometer: 0,
                                    });
                                  }}
                                >
                                  <CheckCircleOutline sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box>
                  <Typography>Buscando motorista</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={showCopyToast}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setShowCopyToast(false)}
          message="Copiado para área de transferência"
        />
      </Paper>
    </>
  );
};
