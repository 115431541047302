import {
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { listUserResponse } from '@/features/users';
import { useAuth } from '@/providers/auth';
import { useTableRecords } from '@/stores';
import { useState } from 'react';
import { Modal } from '@/components';
import { OperatorsList } from './OperatorsList';
import { ViewOperator } from './ViewOperator';
import { DeleteOperator } from './DeleteOperator';
import { useChangeOperatorStatus } from '../api/changeOperatorStatus';
import { useOperators } from '../api/getOperators';

export const OperatorsContainer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, isLoading } = useOperators();
  const [dataId, setDataId] = useState('');
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { mutateAsync: changeOperatorStatus, isLoading: isChangingStatus } =
    useChangeOperatorStatus();

  const close = () => {
    setOpenViewModal(false);
    setOpenDeleteModal(false);
    setDataId('');
  };
  const handleViewToggle = (id: string) => {
    setDataId(id);
    setOpenViewModal(true);
    setOpenDeleteModal(false);
  };
  const handleDeleteToggle = (id: string) => {
    setDataId(id);
    setOpenViewModal(false);
    setOpenDeleteModal(true);
  };

  const handleSearch = (e: any) => {
    useTableRecords.setState({
      filterFn: {
        fn: (items: listUserResponse[]) => {
          if (e.target.value === '') return items;

          try {
            return items.filter((x: listUserResponse) =>
              x.name.toLowerCase().includes(e.target.value.toLowerCase()),
            );
          } catch (err) {
            console.log(err);
          }

          return items;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('./create')}
          size={isMobile ? 'small' : 'medium'}
        >
          Novo Operador
        </Button>
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      <OperatorsList
        tableData={
          data
            ?.filter(
              (operator: any) =>
                operator.accessType === user.user.accessType &&
                operator.id !== user.user.id,
            )
            .sort((a, b) => a?.name?.localeCompare(b?.name)) || []
        }
        toggleView={handleViewToggle}
        toggleDelete={handleDeleteToggle}
        loading={isLoading}
        changeOperatorStatus={changeOperatorStatus}
        isChangingStatus={isChangingStatus}
      />

      {openViewModal && (
        <Modal
          dialogContent={<ViewOperator id={dataId} />}
          open={!!openViewModal}
          size="lg"
          onClose={close}
        />
      )}
      {openDeleteModal && (
        <Modal
          title="Inativar operador"
          dialogContent={<DeleteOperator close={close} id={dataId} />}
          open={!!openDeleteModal}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
};
