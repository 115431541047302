import { Box, Skeleton } from '@mui/material';
import { FavoriteAddress } from '../FavoriteAddress';
import { IAddressDTO } from '../../types';

interface IFavoriteAdressesList {
  isLoading: boolean;
  data: IAddressDTO[];
  onSelectAddress: (data: IAddressDTO) => void;
}

export const FavoriteAdressesList = ({
  data,
  isLoading,
  onSelectAddress,
}: IFavoriteAdressesList) => {
  return (
    <Box>
      {isLoading && (
        <Skeleton
          sx={{ borderRadius: '16px' }}
          variant="rectangular"
          width={340}
          height={140}
        />
      )}
      {data?.map(address => (
        <FavoriteAddress
          key={address.id}
          onSelectAddress={onSelectAddress}
          address={address.address}
          latitude={address.latitude}
          longitude={address.longitude}
          name={address.name}
          city={address.city}
          id={address.id}
        />
      ))}
    </Box>
  );
};
