import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { ITaggedLocation } from '../types';

// eslint-disable-next-line consistent-return
export async function getTaggedLocation(data: {
  id: string;
}): Promise<ITaggedLocation> {
  const response = await axios.get(
    `taggedAdresses/findTaggedLocation/${data.id}`,
  );
  return response.data.payload;
}

export const useGetTaggedLocation = ({ id }: { id: string }) => {
  return useQuery({
    enabled: !!id,
    queryKey: ['tags', id],
    queryFn: () => getTaggedLocation({ id }),
  });
};
