import { AutoCompleteInput, ISelectedOption } from '../AutoCompleteInput';

interface IProps {
  label?: string;
  options: ISelectedOption[];
  selectedOptionId: string;
  setSelectedOption: (option: string) => void;
}

export const SelectAutoComplete = ({
  options,
  selectedOptionId,
  setSelectedOption,
  label = 'Selecione',
}: IProps) => {
  const selectedOption = options?.find(
    option => option.id === selectedOptionId,
  ) || { id: '', name: '' };

  return (
    <AutoCompleteInput
      inputId={`select-${label}`}
      label={label}
      onChange={option => {
        setSelectedOption(option?.id || '');
      }}
      options={options || []}
      selectedOption={selectedOption}
      width={200}
    />
  );
};
