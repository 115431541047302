import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';
import { IUpdateVoucher } from '../types';

export async function updateVoucher(data: IUpdateVoucher): Promise<any> {
  return axios.put(`vouchers/updateUserVoucher/${data.userId}`, data);
}

export const useUpdateVoucher = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingUser => {
      await queryClient.cancelQueries(['users', updatingUser?.userId]);

      const previousUser = queryClient.getQueryData<IUpdateVoucher>([
        'users',
        updatingUser?.userId,
      ]);

      queryClient.setQueryData(['users', updatingUser?.userId], {
        ...previousUser,
        ...updatingUser,
        userId: updatingUser?.userId,
      });

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(
          ['users', context.previousUser.data?.data.id],
          context.previousUser,
        );
      }
    },
    onSuccess: (data, _variables, context) => {
      queryClient.refetchQueries(['users', context.previousUser.id]);
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Voucher atualizado.',
      });
    },
    mutationFn: updateVoucher,
  });
};
