import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';
import { IStopPoint } from '../components/TripRequest';

type useCheckPriceOptions = {
  config?: MutationConfig<typeof checkPrice>;
};

interface ICheckPrice {
  addressFrom: string;
  addressTo: string;
  stopPoints: IStopPoint[];
  startLat: number;
  startLong: number;
  finalLat: number;
  finalLong: number;
  vehicleClassId?: string;
}

interface ICheckPriceResponse {
  estimatedDistance: number;
  estimatedTime: number;
  estimatedPrice: number;
}

export async function checkPrice({
  addressFrom,
  addressTo,
  stopPoints,
  startLat,
  startLong,
  finalLat,
  finalLong,
  vehicleClassId,
}: ICheckPrice): Promise<ICheckPriceResponse> {
  const response = await axios.post(`/trips/checkPrice`, {
    addressFrom,
    addressTo,
    stopPoints,
    startLat,
    startLong,
    finalLat,
    finalLong,
    vehicleClassId,
  });
  return response.data.payload;
}

export const useCheckPrice = ({ config }: useCheckPriceOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: checkPrice,
  });
};
