/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

ChartJS.register(CategoryScale, LinearScale, BarElement);

export const options = {
  responsive: true,
  scales: {
    x: {
      ticks: {
        color: 'white',
        borderColor: 'white',
        tickColor: 'white',
        backdropColor: 'white',
        textStrokeColor: 'white',
      },
    },
    y: {
      ticks: {
        color: 'white',
        borderColor: 'white',
        tickColor: 'white',
        backdropColor: 'white',
        textStrokeColor: 'white',
      },
    },
  },
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        color: 'white',
      },
    },
    title: {
      display: true,
    },
  },
};

export function BarChart({
  type,
  chartData,
}: {
  type: string;
  chartData?: any;
}) {
  const [data, setData] = useState<any>();

  const labels = useMemo(() => {
    const label = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    return label;
  }, []);

  const fakeData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => '0'),
          backgroundColor: 'white',
          borderColor: 'white',
        },
        {
          label: '',
          data: labels.map(() => '0'),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
  }, [labels]);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    } else {
      setData(fakeData);
    }
  }, [chartData, fakeData]);

  return (
    <>
      {data && (
        <>
          {type === 'line' ? (
            <Line options={options} data={data} />
          ) : type === 'bar' ? (
            <Bar options={options} data={data} />
          ) : (
            <Doughnut options={options} data={data} />
          )}
        </>
      )}
    </>
  );
}
