import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { IAssociateVehicleDTO, IVehicle } from '../types';

export async function disassociateDriver(
  data: IAssociateVehicleDTO,
): Promise<IVehicle> {
  return axios.patch('vehicles/disassociate', data);
}

export const useDisassociateDriver = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries('vehicles');
      await queryClient.cancelQueries('drivers');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('vehicles', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('vehicles');
      queryClient.invalidateQueries('drivers');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Motorista desassociado com sucesso!',
      });
    },
    mutationFn: disassociateDriver,
  });
};
