import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';

interface IRequest {
  id: string;
  manualFinalOdometer?: number;
  manualInitialOdometer?: number;
  manualStartOdometer?: number;
}

export const renterFinishesTrip = (data: IRequest): Promise<void> => {
  return axios.patch(`trips/renterFinishes/${data.id}`, data);
};

type UseFinishTripOptions = {
  config?: MutationConfig<typeof renterFinishesTrip>;
};

export const useRenterFinishesTrip = ({
  config,
}: UseFinishTripOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
      queryClient.invalidateQueries('trips');
    },
    mutationFn: renterFinishesTrip,
  });
};
