import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { loadOpenTripsResponse } from '@/features/tripRequest/types';
import { useAuth } from '@/providers/auth';

// eslint-disable-next-line consistent-return
export async function getTripsOnHold(
  companyId?: string,
): Promise<loadOpenTripsResponse[]> {
  const response = await axios.get('trips/getTripsOnHold');
  return response.data.payload;
}

type UseTripsOnHoldOptions = {
  config?: QueryConfig<typeof getTripsOnHold>;
};

export const useTripsOnHold = ({ config }: UseTripsOnHoldOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['tripsOnHold'],
    enabled: user?.user?.accessType === 'renter',
    queryFn: () => getTripsOnHold(),
    ...config,
  });
};
