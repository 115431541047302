import { Table } from '@/components';
import { IWorkJourney } from '../api';

interface DesktopTableProps {
  tableData: IWorkJourney[] | undefined;
  loading: boolean;
  columns: {
    title: string;
    field: any;
    colSpan?: number;
    Cell?: ({ entry }: { entry: IWorkJourney }) => React.ReactElement;
    CustomHeader?: () => React.ReactElement;
  }[];
}

export function ClockInTable({
  tableData = [],
  loading,
  columns,
}: DesktopTableProps) {
  return (
    <Table<IWorkJourney> columns={columns} data={tableData} loading={loading} />
  );
}
