import { AlertSVG } from '@/components/Svgs';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';

interface IProps {
  close: () => void;
  text: string;
  onClick: () => void;
}

export const SectorLimitPopup = ({ close, text, onClick }: IProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AlertSVG />
      <Tooltip
        sx={{ position: 'absolute', top: '10px', right: '10px' }}
        title="Fechar"
        placement="top"
      >
        <IconButton onClick={() => close()}>
          <Close />
        </IconButton>
      </Tooltip>
      <Box sx={{ mt: 2, display: 'flex', gap: 1, maxWidth: '80%' }}>
        <Typography
          color="#BB1E1E"
          fontWeight="bold"
          fontSize="18px"
          sx={{ textTransform: 'uppercase' }}
        >
          Atenção:
        </Typography>
        <Typography fontWeight="bold" fontSize="18px">
          {text}
        </Typography>
      </Box>
      <Button onClick={() => onClick()} sx={{ mt: 2 }} variant="contained">
        Gerenciar
      </Button>
    </Box>
  );
};
