import { useUserStore } from '@/stores/user';
import { storage } from '@/utils/storage';
import { AuthUser } from '../types';

export function logoutFn() {
  useUserStore.setState({ user: {} as AuthUser });
  storage.clearItem({ key: 'user', storageType: 'local' });
  storage.clearItem({ key: 'user', storageType: 'session' });
  storage.clearItem({ key: 'token', storageType: 'local' });
  storage.clearItem({ key: 'token', storageType: 'session' });
  storage.clearItem({ key: 'refreshToken', storageType: 'local' });
  storage.clearItem({ key: 'refreshToken', storageType: 'session' });

  window.location.assign(window.location.origin as unknown as string);
}
