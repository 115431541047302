import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { storage } from '@/utils/storage';

type IConfigurationGridButton = {
  toggleSettings: () => void;
  setIsOpen: (mode: boolean) => void;
};

export const ConfigurationButton = ({
  toggleSettings,
  setIsOpen,
}: IConfigurationGridButton) => {
  const hasUserSeenTheTutorial = storage.getItem({
    key: 'tutorial',
    storageType: 'local',
  });
  const handleSettings = () => {
    if (!hasUserSeenTheTutorial?.option) {
      setIsOpen(true);
    }
    toggleSettings();
    storage.setItem({
      key: 'tutorial',
      storageType: 'local',
      values: { option: true },
    });
  };
  return (
    <>
      <Tooltip arrow title="Configurações" placement="right">
        <IconButton>
          <SettingsIcon
            fontSize="large"
            sx={{
              '&:hover': {
                transform: 'rotate(90deg)',
              },
              transform: 'rotate(0deg)',
              transition: 'ease-in-out 0.5s',
            }}
            onClick={() => handleSettings()}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};
