/* eslint-disable no-param-reassign */
import { useState } from 'react';
import * as z from 'zod';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '@/utils/maskShared';

import { Form, InputField } from '@/components/Form';
import { useCreateMaster, useRoles } from '@/features/companyNew/api';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { useAuth } from '@/providers/auth';
import { Controller } from 'react-hook-form';
import { useGetCompanyProfiles } from '@/features/profiles';
import { CreateCompanyNewDTO } from '../types';

const schema = z.object({
  corporateName: z.string(),

  email: z.string().email('Email inválido'),
  password: z
    .string()
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),

  fantasyName: z.string(),
  industry: z.string(),
  registeredNumber: z
    .string()

    .max(18, 'Máximo 18 caracteres')
    .min(18, 'Minimo 18 caracteres'),

  personToContact: z.string(),
  phone: z
    .string()
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  zipCode: z.string().max(9, 'Máximo 9 caracteres'),
  address: z.string(),
  number: z.string(),
  city: z.string(),
  state: z.string(),
  neighborhood: z.string(),
  driverFees: z.string().optional(),
  userEmail: z.string(),
  name: z.string(),
  userPhone: z
    .string()
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z
    .string()
    .min(11, 'Deve ter pelo menos 11 caracteres'),
});

export function CreateRenter() {
  const [trackerId, setTrackerId] = useState('');
  const { data: profiles, isLoading: isLoadingProfiles } =
    useGetCompanyProfiles();
  const { data: roles, isLoading: isLoadingRoles } = useRoles();
  const { mutateAsync, isLoading: loadingAdd } = useCreateMaster();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const [profile, setProfile] = useState('');

  const handlePermitions = (role: { name: string; id: string }) => {
    setProfile(role.id);
  };

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAlreadySelected = selectedRoles.find(
      data => data === event.target.value,
    );
    if (isAlreadySelected) {
      setSelectedRoles((prevRoles: string[]) =>
        prevRoles.filter(data => data !== event.target.value),
      );
      return;
    }
    setSelectedRoles((prevRoles: string[]) =>
      prevRoles.concat(event.target.value),
    );
  };

  return (
    <Box
      sx={{ background: theme.palette.mode === 'dark' ? '#212121' : '#FFF' }}
    >
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <Box>
          <Form<CreateCompanyNewDTO, typeof schema>
            id="create-companyNew"
            onSubmit={values => {
              const data = {
                ...values,
                status: true,
                profile,
                roles: selectedRoles,
                trackerId,
              };
              mutateAsync(data);
            }}
            schema={schema}
          >
            {({ register, formState, control }) => (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'red',
                    fontSize: '22px',
                  }}
                >
                  {user.user.accessType === 'master' && 'Cadastro da locatária'}
                </Typography>
                <Grid container>
                  <Grid sm={6} xs={12} item>
                    <InputField
                      size="small"
                      name="email"
                      type="email"
                      label="Email"
                      registration={register('email')}
                      error={!!formState.errors.email}
                      errorMessage={formState.errors.email?.message}
                      placeholder="Email"
                    />

                    <InputField
                      size="small"
                      name="corporateName"
                      type="text"
                      label="Razão Social"
                      registration={register('corporateName')}
                      error={!!formState.errors.corporateName?.message}
                      errorMessage={formState.errors.corporateName?.message}
                      placeholder="Nome real"
                    />

                    <InputField
                      size="small"
                      name="fantasyName"
                      type="text"
                      label="Nome Fantasia"
                      registration={register('fantasyName')}
                      placeholder="Nome"
                      error={!!formState.errors.fantasyName}
                      errorMessage={formState.errors.fantasyName?.message}
                    />
                    <InputField
                      size="small"
                      name="industry"
                      type="text"
                      label="Ramo"
                      placeholder="Ramo de atuação"
                      registration={register('industry')}
                      error={!!formState.errors.industry}
                      errorMessage={formState.errors.industry?.message}
                    />
                    <InputField
                      size="small"
                      name="registeredNumber"
                      type="text"
                      label="CNPJ"
                      placeholder="99.999.999/9999-99"
                      inputProps={{ minLength: 18, maxLength: 18 }}
                      registration={register('registeredNumber')}
                      error={!!formState.errors.registeredNumber}
                      errorMessage={formState.errors.registeredNumber?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = cnpjMask(value);
                      }}
                    />
                    <InputField
                      size="small"
                      name="personToContact"
                      type="text"
                      label="Pessoa para contato"
                      placeholder="Pessoa para contato"
                      registration={register('personToContact')}
                      error={!!formState.errors.personToContact}
                      errorMessage={formState.errors.personToContact?.message}
                    />
                    <InputField
                      size="small"
                      name="phone"
                      type="text"
                      label="Telefone"
                      placeholder="(99) 99999-9999"
                      registration={register('phone')}
                      inputProps={{ minLength: 14, maxLength: 15 }}
                      error={!!formState.errors.phone}
                      errorMessage={formState.errors.phone?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = phoneMask(
                          value.replace(/[^\d]+/g, ''),
                        );
                      }}
                    />
                    <InputField
                      size="small"
                      name="zipCode"
                      type="text"
                      label="CEP"
                      placeholder="99999-999"
                      inputProps={{ minLength: 9, maxLength: 9 }}
                      registration={register('zipCode')}
                      error={!!formState.errors.zipCode}
                      errorMessage={formState.errors.zipCode?.message}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = cepMask(
                          value.replace(/(\d{5})(\d)/, '$1-$2'),
                        );
                      }}
                    />

                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="address"
                          type="text"
                          label="Endereço"
                          onChange={onChange}
                          value={value}
                          placeholder="Endereço"
                          errorMessage={formState.errors.address?.message}
                          error={!!formState.errors.address}
                        />
                      )}
                    />

                    <InputField
                      size="small"
                      name="number"
                      type="text"
                      label="Número"
                      placeholder="Número"
                      registration={register('number')}
                      error={!!formState.errors.number}
                      errorMessage={formState.errors.number?.message}
                    />

                    <Controller
                      name="city"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="city"
                          type="text"
                          label="Cidade"
                          onChange={onChange}
                          value={value}
                          placeholder="Cidade"
                          errorMessage={formState.errors.city?.message}
                          error={!!formState.errors.city}
                        />
                      )}
                    />

                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="state"
                          type="text"
                          label="Estado"
                          onChange={onChange}
                          value={value}
                          placeholder="Estado"
                          errorMessage={formState.errors.state?.message}
                          error={!!formState.errors.state}
                        />
                      )}
                    />

                    <Controller
                      name="neighborhood"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="neighborhood"
                          type="text"
                          label="Bairro"
                          onChange={onChange}
                          value={value}
                          placeholder="Bairro"
                          errorMessage={formState.errors.neighborhood?.message}
                          error={!!formState.errors.neighborhood}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Box className={classes.wrapper}>
                      <>
                        {user.user.accessType === 'master' && (
                          <Controller
                            name="driverFees"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputField
                                size="small"
                                name="driverFees"
                                type="number"
                                label="Taxa do motorista"
                                onChange={onChange}
                                value={value}
                                placeholder="0"
                                errorMessage={
                                  formState.errors.driverFees?.message
                                }
                                error={!!formState.errors.driverFees}
                              />
                            )}
                          />
                        )}
                      </>

                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? 'white' : 'red',
                          fontSize: '22px',
                        }}
                      >
                        Cadastro do usuário administrador
                      </Typography>
                      <InputField
                        size="small"
                        name="userEmail"
                        type="text"
                        label="Login"
                        placeholder="Login do usuário"
                        registration={register('userEmail')}
                        error={!!formState.errors.userEmail}
                        errorMessage={formState.errors.userEmail?.message}
                      />
                      <InputField
                        size="small"
                        name="password"
                        type="password"
                        label="Senha"
                        registration={register('password')}
                        error={!!formState.errors.password}
                        errorMessage={formState.errors.password?.message}
                        placeholder="Senha para acesso"
                      />
                      <InputField
                        size="small"
                        name="name"
                        type="text"
                        label="Nome"
                        placeholder="Nome do usuário"
                        registration={register('name')}
                        error={!!formState.errors.name}
                        errorMessage={formState.errors.name?.message}
                      />
                      <InputField
                        size="small"
                        name="userPhone"
                        type="text"
                        label="Telefone"
                        placeholder="(99) 99999-9999"
                        registration={register('userPhone')}
                        inputProps={{ minLength: 14, maxLength: 15 }}
                        error={!!formState.errors.userPhone}
                        errorMessage={formState.errors.userPhone?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = phoneMask(
                            value.replace(/[^\d]+/g, ''),
                          );
                        }}
                      />
                      <InputField
                        size="small"
                        name="individualRegistration"
                        type="text"
                        label="CPF"
                        placeholder="999.999.999-99"
                        registration={register('individualRegistration')}
                        error={!!formState.errors.individualRegistration}
                        errorMessage={
                          formState.errors.individualRegistration?.message
                        }
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = cpfMask(value);
                        }}
                      />
                    </Box>
                    {user.user.accessType === 'master' && (
                      <>
                        <Box sx={{ margin: '10px' }}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'white' : 'red',
                              fontSize: '22px',
                            }}
                          >
                            Permissões
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {isLoadingProfiles ? (
                              <Box>Carregando</Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  marginTop: '10px',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {profiles?.map(data => (
                                  <FormControlLabel
                                    sx={{ flex: '1' }}
                                    key={data.id}
                                    control={
                                      <Checkbox
                                        checked={profile === data.id}
                                        onChange={() => handlePermitions(data)}
                                      />
                                    }
                                    label={data.name}
                                  />
                                ))}
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ margin: '10px' }}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'white' : 'red',
                              fontSize: '22px',
                            }}
                          >
                            Permissões da empresa
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {isLoadingRoles ? (
                              <Box>Carregando</Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  marginTop: '10px',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {roles
                                  ?.filter(role => role.isCompanyPermission)
                                  .map(data => (
                                    <FormControlLabel
                                      sx={{ flex: '1' }}
                                      key={data.id}
                                      value={data.id}
                                      control={
                                        <Checkbox
                                          checked={
                                            !!selectedRoles.find(
                                              selectedRole =>
                                                selectedRole === data.id,
                                            )
                                          }
                                          onChange={handleChange}
                                        />
                                      }
                                      label={data.type}
                                    />
                                  ))}
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ margin: '10px' }}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'white' : 'red',
                              fontSize: '22px',
                            }}
                          >
                            Id do Monitoramento
                          </Typography>
                          <TextField
                            sx={{ width: '90px' }}
                            size="small"
                            name="trackerId"
                            type="text"
                            label="Id"
                            onChange={e => setTrackerId(e.target.value)}
                            value={trackerId}
                            placeholder="111"
                          />
                        </Box>
                      </>
                    )}
                  </Grid>

                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      sx={{ marginTop: '10px' }}
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={loadingAdd}
                    >
                      {loadingAdd ? 'Carregando...' : 'Adicionar'}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Form>
        </Box>
      </Paper>
    </Box>
  );
}
