import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Modal } from '@/components';
import { useState, useEffect } from 'react';
import { useTableRecords } from '@/stores';
import { useDisclosure } from '@/hooks/useDisclosure';
import {
  useCompanies,
  ViewCompany,
  DeleteCompany,
  MobileTable,
  DesktopTable,
} from '@/features/companyNew';
import { Companies } from '../types';

export function CompaniesListTable() {
  const navigate = useNavigate();
  const { close, toggle, isOpen } = useDisclosure();
  const [dataId, setDataId] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [onlyInactived, setOnlyInactived] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState<Companies[]>([]);

  const { isLoading, data } = useCompanies();
  const handleSearch = (e: any) => {
    useTableRecords.setState({
      filterFn: {
        fn: (items: Companies[]) => {
          if (e.target.value === '') return items;

          try {
            return items.filter((x: Companies) =>
              x.corporateName
                .toLowerCase()
                .includes(e.target.value.toLowerCase()),
            );
          } catch (err) {
            console.log(err);
          }
          return items;
        },
      },
    });
  };

  useEffect(() => {
    if (onlyInactived) {
      const filteredValues = data?.filter(
        (company: Companies) => company.status === false,
      );
      setFilteredCompanies(filteredValues);
    } else {
      const filteredValues = data?.filter(
        (company: Companies) => company.status === true,
      );
      setFilteredCompanies(filteredValues);
    }
  }, [onlyInactived, data]);

  const companyArray: Companies[] = filteredCompanies;

  const handleEditToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenEditModal(true);
    setOpenDeleteModal(false);
  };

  const handleDeleteToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenEditModal(false);
    setOpenDeleteModal(true);
  };

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('./create')}
          size={isMobile ? 'small' : 'medium'}
        >
          Nova empresa
        </Button>
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      <Toolbar>
        <FormControlLabel
          sx={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }}
          control={
            <Checkbox
              onChange={() => setOnlyInactived(prev => !prev)}
              checked={onlyInactived}
            />
          }
          label="Apenas empresas inativas"
        />
      </Toolbar>

      {isMobile ? (
        <MobileTable
          tableData={companyArray}
          toggleEdit={handleEditToggle}
          toggleDelete={handleDeleteToggle}
          loading={isLoading}
        />
      ) : (
        <DesktopTable
          tableData={companyArray}
          toggleEdit={handleEditToggle}
          toggleDelete={handleDeleteToggle}
          loading={isLoading}
        />
      )}

      {isOpen && openEditModal && (
        <Modal
          dialogContent={<ViewCompany id={dataId} />}
          title="Vizualização"
          open={isOpen}
          size="lg"
          onClose={close}
        />
      )}

      {isOpen && openDeleteModal && (
        <Modal
          title="Inativar empresa"
          dialogContent={<DeleteCompany close={close} id={dataId} />}
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
}
