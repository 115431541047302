import { InputField } from '@/components';
import { IVehicleClass } from '@/features/vehicleClasses';
import { MenuItem } from '@mui/material';

interface ISelectTypeOfVehicle {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classes: IVehicleClass[];
}

export const SelectClassOfVehicle = ({
  onChange,
  classes,
}: ISelectTypeOfVehicle) => {
  return (
    <InputField
      style={{ marginBottom: '0', border: 'none', zIndex: 0 }}
      size="small"
      name="vehicleClassId"
      select
      type="text"
      label="Selecione a classe do veículo (opcional)"
      onChange={onChange}
    >
      {classes?.map(vehicleClass => (
        <MenuItem key={vehicleClass.id} value={vehicleClass.id}>
          {vehicleClass.name}
        </MenuItem>
      ))}
      <MenuItem value={undefined}>Não informar</MenuItem>
    </InputField>
  );
};
