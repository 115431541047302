import { useAuth } from '@/providers/auth';
import { Box, Button, Typography } from '@mui/material';
import * as z from 'zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useChangeUserPassword } from '@/features/users';
import { Form, InputField } from '../Form';

const schema = z.object({
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  newPassword: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  confirmNewPassword: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
});

export const Profile = () => {
  const { user } = useAuth();
  const {
    isOpen: isNewPasswordOpen,
    open: openNewPassword,
    close: closeNewPassword,
  } = useDisclosure();
  const { mutateAsync: changePassword, isLoading: isLoadingNewPassword } =
    useChangeUserPassword();
  const formatCPF = (cpf: string) => {
    const cpfClean = cpf.replace(/\D/g, '');
    return cpfClean.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatPhone = (phone: string) => {
    const phoneClean = phone.replace(/\D/g, '');
    if (phoneClean.length === 11) {
      return phoneClean.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    return phoneClean.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px' }}>Nome:</Typography>{' '}
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {user.user.name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px' }}>Login:</Typography>{' '}
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {user.user.email}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px' }}>Telefone:</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {formatPhone(user.user.phone)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px' }}>CPF:</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {formatCPF(user.user.individualRegistration)}
          </Typography>
        </Box>
        <Button
          sx={{ width: '150px' }}
          onClick={openNewPassword}
          variant="contained"
        >
          Alterar senha
        </Button>
        {isNewPasswordOpen && (
          <Form<
            {
              password: string;
              newPassword: string;
              confirmNewPassword: string;
            },
            typeof schema
          >
            style={{
              padding: '5px',
            }}
            id="change-password"
            onSubmit={values => {
              changePassword(values, {
                onSuccess: () => {
                  closeNewPassword();
                },
              });
            }}
            schema={schema}
          >
            {({ register, formState }) => {
              return (
                <>
                  <InputField
                    size="small"
                    name="password"
                    type="password"
                    label="Senha"
                    registration={register('password')}
                    error={!!formState.errors.password}
                    errorMessage={formState.errors.password?.message}
                    placeholder="Senha atual"
                  />
                  <InputField
                    size="small"
                    name="newPassword"
                    type="password"
                    label="Nova senha"
                    registration={register('newPassword')}
                    error={!!formState.errors.newPassword}
                    errorMessage={formState.errors.newPassword?.message}
                    placeholder="Nova senha"
                  />
                  <InputField
                    size="small"
                    name="confirmNewPassword"
                    type="password"
                    label="Confirmar nova senha"
                    registration={register('confirmNewPassword')}
                    error={!!formState.errors.confirmNewPassword}
                    errorMessage={formState.errors.confirmNewPassword?.message}
                    placeholder="Confirmar nova senha"
                  />

                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Button
                      disabled={isLoadingNewPassword}
                      type="submit"
                      variant="contained"
                      color="success"
                    >
                      {isLoadingNewPassword ? 'Alterando...' : 'Salvar'}
                    </Button>
                    <Button
                      onClick={() => closeNewPassword()}
                      disabled={isLoadingNewPassword}
                      variant="contained"
                      color="error"
                    >
                      Cancelar
                    </Button>
                  </Box>
                </>
              );
            }}
          </Form>
        )}
      </Box>
    </Box>
  );
};
