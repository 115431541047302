import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { saveSector, saveSectorResponse } from '../types';

export async function updateSector(
  data: Partial<saveSector>,
): Promise<saveSectorResponse> {
  return axios.put(`/sectors/update/${data.id}`, data);
}

export const useUpdateSector = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async sector => {
      await queryClient.cancelQueries('sectors');

      const previoussectors = queryClient.getQueryData<any[]>('sectors');

      queryClient.setQueryData('sectors', [...(previoussectors || []), sector]);

      return previoussectors;
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sectors');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Setor atualizado com sucesso.',
      });
      navigate('/sectors');
    },
    mutationFn: updateSector,
  });
};
