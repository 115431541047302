import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { UpdateSector } from './Sector';
import { useSector } from '../api';

export function UpdateSectorRoute() {
  const dataParams = useParams() as unknown as Record<string, any>;
  const id = dataParams.sectorId;
  const { data: sectorQuery, isLoading } = useSector({ id });
  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <UpdateSector sectorQuery={sectorQuery} />
      )}
    </>
  );
}
