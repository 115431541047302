import { InputField } from '@/components';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { IPriceTags } from '../types';
import { useUpdateTag } from '../api';

interface IRequest {
  tag: IPriceTags;
}

const schema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  price: z.number({ required_error: 'Campo obrigatório' }),
  priceForDriver: z.number({ required_error: 'Campo obrigatório' }),
});

export const UpdateTag = ({ tag }: IRequest) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync, isLoading } = useUpdateTag();
  const preloadedValues = {
    name: tag?.name,
    price: tag?.price,
    priceForDriver: tag?.priceForDriver,
    companyId: tag?.companyId,
    id: tag?.id,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: {
    name: string;
    price: number;
    priceForDriver?: number;
  }) => {
    mutateAsync({
      id: tag.id,
      name: values.name,
      price: values.price || 0,
      priceForDriver: values.priceForDriver,
      companyId: tag.companyId,
    });
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 40 }}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
          >
            Editando tarifa
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                size="small"
                name="name"
                type="text"
                placeholder="A"
                label="Tarifa"
                onChange={onChange}
                value={value}
                errorMessage={formState.errors.name?.message}
                error={!!formState.errors.name}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 0.01 },
                }}
                size="small"
                name="price"
                type="number"
                placeholder="0"
                label="Preço"
                onChange={e => onChange(Number(e.target.value))}
                value={Number(value) || null}
                errorMessage={formState.errors.price?.message}
                error={!!formState.errors.price}
              />
            )}
          />
          <Controller
            name="priceForDriver"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 0.01 },
                }}
                size="small"
                name="priceForDriver"
                type="number"
                placeholder="0"
                label="Preço para o motorista"
                onChange={e => onChange(Number(e.target.value))}
                value={Number(value) || null}
                errorMessage={formState.errors.priceForDriver?.message}
                error={!!formState.errors.priceForDriver}
              />
            )}
          />

          <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Button variant="contained" color="success" type="submit">
              {isLoading ? 'carregando...' : 'Salvar'}
            </Button>
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
