import { Link, Table } from '@/components';
import { DeleteSharp, EditSharp } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { useAuth } from '@/providers/auth';
import { listSectorsResponse } from '..';

interface DesktopTableProps {
  tableData: listSectorsResponse[];
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function DesktopTable({
  tableData = [],
  toggleDelete,
  loading,
}: DesktopTableProps) {
  const { user } = useAuth();
  const { accessType } = user.user;
  return (
    <Table<listSectorsResponse>
      columns={[
        { title: 'ID do setor', field: 'sectorId' },
        { title: 'Setor', field: 'name' },
        { title: 'Telefone', field: 'phone' },
        { title: 'Limite de viagens', field: 'tripLimitConsumedFormatted' },
        { title: 'Limite de custo', field: 'costLimitConsumedFormatted' },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id } }) {
            return (
              <>
                <Link to={`./${id}`}>
                  <Tooltip title="Editar">
                    <IconButton>
                      <EditSharp sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Link>
                {accessType === 'company_admin' && (
                  <Tooltip title="Excluir">
                    <IconButton onClick={() => toggleDelete(id)}>
                      <DeleteSharp sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData?.sort((a, b) => a?.name?.localeCompare(b?.name))}
      loading={loading}
    />
  );
}
