import { Form, InputField } from '@/components';
import { phoneMask } from '@/utils/maskShared';
import {
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  Paper,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import * as z from 'zod';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateSector } from '..';

export const CreateSectors = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const schema = z.object({
    name: z.string({ required_error: 'Campo obrigatório' }),
    phone: z.string({ required_error: 'Campo obrigatório' }),
    limitOfTrips: z.string().optional(),
    limitOfCosts: z.string().optional(),
    sectorId: z.string().optional(),
  });
  const [hasTripLimit, setHasTripLimit] = useState(false);
  const [hasPriceLimit, setHasPriceLimit] = useState(false);
  const [blockAtLimit, setBlockAtLimit] = useState(false);
  const { isLoading, mutateAsync } = useCreateSector();
  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<any, typeof schema>
          id="create-sector"
          onSubmit={values => {
            mutateAsync({
              ...values,
              blockAtLimit,
            });
          }}
          schema={schema}
        >
          {({ register, formState, setValue }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Novo setor
              </Typography>
              <Grid container>
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="name"
                    type="text"
                    label="Setor"
                    registration={register('name')}
                    error={!!formState.errors.name}
                    errorMessage={formState.errors.name?.message}
                  />
                  <InputField
                    size="small"
                    name="sectorId"
                    type="text"
                    label="ID do setor"
                    registration={register('sectorId')}
                    error={!!formState.errors.sectorId}
                    errorMessage={formState.errors.sectorId?.message}
                  />
                </Grid>
                <Grid sm={6} xs={12} item>
                  <InputField
                    sx={{
                      marginLeft: isMobile ? '0px' : '10px',
                    }}
                    size="small"
                    name="phone"
                    type="text"
                    label="Telefone"
                    registration={register('phone')}
                    inputProps={{ minLength: 11, maxLength: 15 }}
                    error={!!formState.errors.password}
                    errorMessage={formState.errors.password?.message}
                    onChange={(event: any) => {
                      const { value } = event.target;
                      // eslint-disable-next-line no-param-reassign
                      event.target.value = phoneMask(
                        value.replace(/[^\d]+/g, ''),
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <FormControlLabel
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  }}
                  control={
                    <Checkbox
                      onChange={() => {
                        if (hasTripLimit) {
                          setValue('limitOfTrips', '0');
                        }
                        setHasTripLimit(prev => !prev);
                      }}
                      checked={hasTripLimit}
                    />
                  }
                  label="Limite de corridas"
                />
              </Grid>
              {hasTripLimit && (
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="limitOfTrips"
                    type="number"
                    label=""
                    registration={register('limitOfTrips')}
                    error={!!formState.errors.limitOfTrips}
                    errorMessage={formState.errors.limitOfTrips?.message}
                  />
                </Grid>
              )}
              <Grid container>
                <FormControlLabel
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  }}
                  control={
                    <Checkbox
                      onChange={() => {
                        if (hasPriceLimit) {
                          setValue('limitOfCosts', '0');
                        }
                        setHasPriceLimit(prev => !prev);
                      }}
                      checked={hasPriceLimit}
                    />
                  }
                  label="Limite de preço"
                />
              </Grid>
              {hasPriceLimit && (
                <Grid sm={6} xs={12} item>
                  <InputField
                    size="small"
                    name="limitOfCosts"
                    type="number"
                    label=""
                    registration={register('limitOfCosts')}
                    error={!!formState.errors.limitOfCosts}
                    errorMessage={formState.errors.limitOfCosts?.message}
                  />
                </Grid>
              )}
              {(hasPriceLimit || hasTripLimit) && (
                <Grid sm={6} xs={12} item>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                    control={
                      <Checkbox
                        onChange={() => setBlockAtLimit(prev => !prev)}
                        checked={blockAtLimit}
                      />
                    }
                    label="Bloquear solicitações ao atingir o limite"
                  />
                </Grid>
              )}
              <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                <Button variant="contained" color="success" type="submit">
                  {isLoading ? 'carregando...' : 'Adicionar'}
                </Button>
                <Button
                  onClick={() => navigate(-1)}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Voltar
                </Button>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
