import { SwipeableDrawerComponent } from '@/components';
import { Box, Typography } from '@mui/material';
import { driversProps } from '../types';
import { DriversTable } from './DriversTable';
import { MarkerConfigurations } from './MarkerConfiguration';
import { ThemeConfigurations } from './ThemeConfigurations';
import { TrafficLayerConfiguration } from './TrafficLayerConfiguration';

interface Props {
  map: any;
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
  trafficMap: () => void;
  transitMap: () => void;
  ciclistMap: () => void;
  drivers: driversProps[];
  setClickedDriver: any;
}

export const SwipeableDrawer = ({
  isOpen,
  close,
  toggle,
  trafficMap,
  transitMap,
  ciclistMap,
  setClickedDriver,
  drivers,
  map,
}: Props) => {
  return (
    <SwipeableDrawerComponent
      title="Configurações"
      isOpen={isOpen}
      close={close}
      toggle={toggle}
    >
      <>
        <Box
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <Typography
            sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
          >
            Modo
          </Typography>
          <ThemeConfigurations />
        </Box>

        <Box
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <Typography
            sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
          >
            Camadas de trânsito
          </Typography>
          <Box
            sx={{
              borderRadius: '7px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid gray',
            }}
          >
            <TrafficLayerConfiguration
              ciclistMap={ciclistMap}
              trafficMap={trafficMap}
              transitMap={transitMap}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <Typography
            sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
          >
            Ícone
          </Typography>
          <MarkerConfigurations />
        </Box>

        <DriversTable
          map={map}
          close={close}
          setClickedDriver={setClickedDriver}
          drivers={drivers}
        />
      </>
    </SwipeableDrawerComponent>
  );
};
