import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { ISelectDriverDTO, UseDriverDistanceOptions } from '../types';

// eslint-disable-next-line consistent-return
export async function getDriverDistance(
  data: UseDriverDistanceOptions,
): Promise<ISelectDriverDTO[]> {
  const response = await axios.get(`drivers/driversDistance/${data.tripId}`);
  return response.data.payload;
}

export const useDriverDistance = ({ tripId }: UseDriverDistanceOptions) => {
  return useQuery({
    onError: error => {
      return [];
    },
    queryKey: ['driverDistance', tripId],
    queryFn: () => getDriverDistance({ tripId }),
  });
};
