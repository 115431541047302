import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CompaniesList } from './CompaniesList';
import { CreateCompany } from './CreateCompanyNew';
import { UpdateCompany } from '../components/UpdateCompany';

export const CompaniesNewRoutes = (): JSX.Element => {
  return (
    <Can permissions={['renter', 'master']}>
      <Routes>
        <Route path="/" element={<CompaniesList />} />
        <Route path="/create" element={<CreateCompany />} />
        <Route path="/:companyId" element={<UpdateCompany />} />
      </Routes>
    </Can>
  );
};
