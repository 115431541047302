import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  toggleBar: {},
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1c2841',
    height: '100%',
    display: 'flex',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 56,
    padding: 5,
    marginTop: '15px',
  },

  icon: {
    color: 'white',
    marginTop: '2px',
  },
  iconsWrapper: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '15px',
    flex: 1,
    color: 'white',

    '& li': {
      borderBottom: '1px solid white',
      cursor: 'pointer',
    },
  },
}));
