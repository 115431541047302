import { Autocomplete, Box, Button, createFilterOptions } from '@mui/material';
import React from 'react';

export interface ISelectedOption {
  id: string;
  name: string;
}

interface IAutoCompleteInput {
  inputId: string;
  selectedOption: ISelectedOption;
  onChange: (newValue: ISelectedOption | null) => void;
  options: ISelectedOption[];
  label: string;
  width?: number | string;
  onAdd?: () => void;
}

const filter = createFilterOptions<ISelectedOption>();

export const CustomAutoCompleteInput = ({
  inputId,
  selectedOption,
  onChange,
  options,
  label,
  onAdd,
  width = 300,
}: IAutoCompleteInput) => {
  return (
    <Autocomplete
      value={selectedOption}
      onChange={(event, newValue: any) => {
        onChange(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={inputId}
      options={options}
      noOptionsText={
        <>
          {onAdd && (
            <Button
              sx={{ marginLeft: '15px', marginTop: '10px' }}
              variant="contained"
              onClick={() => onAdd()}
            >
              Adicionar
            </Button>
          )}
        </>
      }
      getOptionLabel={option => {
        if (option.name === undefined) {
          return '';
        }
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.name;
        }
        return option.name;
      }}
      renderOption={(props, option) => (
        <>
          <React.Fragment key={option.id}>
            <li {...props}>{option.name}</li>
            {option === options[options.length - 1] && onAdd && (
              <Button
                sx={{ marginLeft: '15px', marginTop: '10px' }}
                variant="contained"
                onClick={() => onAdd()}
              >
                Adicionar
              </Button>
            )}
          </React.Fragment>
        </>
      )}
      sx={{ width }}
      freeSolo={false}
      renderInput={params => (
        <div ref={params.InputProps.ref}>
          <input
            placeholder={label}
            style={{
              width,
              padding: '12px',
              borderRadius: '5px',
              color: '#808080',
              borderWidth: '1px',
              border: '1px solid #C0C0C0',
            }}
            type="text"
            {...params.inputProps}
          />
        </div>
      )}
    />
  );
};
