import { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Fallback } from '@/components/Elements';
import { lazyImport } from '@/utils/lazyImport';

const { AuthRoutes } = lazyImport(
  () => import('@/features/auth'),
  'AuthRoutes',
);

const { SupportRoutes } = lazyImport(
  () => import('@/features/support'),
  'SupportRoutes',
);

const { TripVisualizationRoutes } = lazyImport(
  () => import('@/features/tripVisualization'),
  'TripVisualizationRoutes',
);

const App = () => {
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <AuthRoutes /> },
      { path: '/support/*', element: <SupportRoutes /> },
      { path: '/tripVisualization/*', element: <TripVisualizationRoutes /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
