import { Form, InputField } from '@/components';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Paper,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import * as z from 'zod';
import { useCreateLog } from '../api';

export const CreateVersionLog = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutateAsync, isLoading } = useCreateLog();
  const schema = z.object({
    type: z.number(),
    version: z.string({ required_error: 'Campo obrigatório' }),
    description: z.string({ required_error: 'Campo obrigatório' }),
  });

  const options = [
    {
      type: 'Novas Atualizações',
      value: 1,
    },
    {
      type: 'Correção de Bugs',
      value: 2,
    },
  ];

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<any, typeof schema>
          id="create-log"
          onSubmit={values => {
            mutateAsync(values);
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Logs
              </Typography>
              <Grid container>
                <Grid sm={9} xs={12} item>
                  <InputField
                    size="small"
                    name="type"
                    select
                    label="Tipo"
                    registration={register('type')}
                    error={!!formState.errors.type}
                    errorMessage={formState.errors.type?.message}
                  >
                    {options.map((item: any, key: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={key} value={item.value}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </InputField>
                </Grid>
                <Grid
                  sx={{
                    marginLeft: isMobile ? '0px' : '10px',
                  }}
                  sm={2}
                  xs={12}
                  item
                >
                  <InputField
                    size="small"
                    name="version"
                    type="text"
                    label="Versão"
                    registration={register('version')}
                    error={!!formState.errors.version}
                    errorMessage={formState.errors.version?.message}
                  />
                </Grid>
                <Grid sm={12} xs={12} item>
                  <InputField
                    size="small"
                    name="phone"
                    type="text"
                    label="Descrição"
                    registration={register('description')}
                    error={!!formState.errors.description}
                    errorMessage={formState.errors.description?.message}
                  />
                </Grid>
              </Grid>

              <LoadingButton
                disabled={isLoading}
                sx={{ marginTop: '10px' }}
                variant="contained"
                color="success"
                type="submit"
              >
                Adicionar
              </LoadingButton>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
