import { AutoCompleteInput } from '@/components';
import { Box, FormGroup, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';

interface IWorkJourneyReportsField {
  vehicles: {
    id: string;
    model: string;
    licensePlate: string;
  }[];
  setVehicle: (Vehicle: { id: string; licensePlate: string }) => void;
}

export const VehiclesReportsField = ({
  vehicles,
  setVehicle,
}: IWorkJourneyReportsField) => {
  const formattedVehicles = vehicles?.map(vehicle => ({
    id: vehicle.id,
    name: vehicle.licensePlate,
  }));
  const [selectedVehicle, setSelectedVehicle] = useState<{
    id: string;
    name: string;
  }>(
    formattedVehicles?.length > 0 ? formattedVehicles[0] : { id: '', name: '' },
  );

  useEffect(() => {
    setVehicle({
      id: selectedVehicle.id,
      licensePlate: selectedVehicle.name,
    });
  }, [selectedVehicle, setVehicle]);
  return (
    <Box sx={{ display: 'flex' }}>
      <Toolbar>
        <FormGroup sx={{ gap: 1.5 }} row>
          <AutoCompleteInput
            inputId="select-Vehicle-work-journey"
            label="Selecione a placa"
            onChange={option => {
              setSelectedVehicle({
                id: option?.id || '',
                name: option?.name || '',
              });
              setVehicle({
                id: option?.id || '',
                licensePlate: option?.name || '',
              });
            }}
            options={formattedVehicles}
            selectedOption={selectedVehicle}
            width={200}
          />
        </FormGroup>
      </Toolbar>
    </Box>
  );
};
