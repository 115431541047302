import { Link, Table } from '@/components';

import {
  Visibility,
  EditSharp,
  DeleteSharp,
  ChangeCircle,
} from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import userPhoto from '@/assets/img/usuario.png';
import { useChangeDriverStatus } from '../api';
import { DriversValues } from '../types';

interface DesktopTableProps {
  tableData: DriversValues[];
  toggleEdit: (id: string) => void;
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function DesktopTable({
  tableData = [],
  toggleEdit,
  toggleDelete,
  loading,
}: DesktopTableProps) {
  const { mutateAsync } = useChangeDriverStatus();

  const activeUser = (id: string) => {
    mutateAsync({ id });
  };

  return (
    <Table<DriversValues>
      columns={[
        {
          title: 'Foto',
          field: 'urlImage',
          Cell({ entry: { urlImage, name } }) {
            return (
              <>
                {urlImage ? (
                  <img
                    src={urlImage}
                    alt={`Foto do usuário ${name}`}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <img
                    src={userPhoto}
                    alt="Foto padrão"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </>
            );
          },
        },
        { title: 'Nome', field: 'name', colSpan: 2 },
        { title: 'Placa', field: 'licensePlate', colSpan: 2 },
        { title: 'Categoria CNH', field: 'driverLicenseCategory', colSpan: 2 },
        { title: 'Telefone', field: 'phone', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id, status } }) {
            return (
              <>
                <Tooltip title="visualizar">
                  <IconButton onClick={() => toggleEdit(id)}>
                    <Visibility sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>
                {status ? (
                  <>
                    <Link to={`./${id}`}>
                      <Tooltip title="Editar">
                        <IconButton>
                          <EditSharp sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip title="Inativar">
                      <IconButton onClick={() => toggleDelete(id)}>
                        <DeleteSharp sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Reativar">
                    <IconButton onClick={() => activeUser(id)}>
                      <ChangeCircle sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData?.sort((a, b) => a?.name?.localeCompare(b.name))}
      loading={loading}
    />
  );
}
