import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useRedirectDriver } from '../../api/redirectDriver';

interface IRedirectDriver {
  id: string;
  driverId: string;
  drivers: {
    id: string;
    name: string;
    licensePlate: string;
  }[];
}

export const RedirectDriver = ({ id, driverId, drivers }: IRedirectDriver) => {
  const [selectedDriverId, setSelectedDriverId] = useState(driverId || '');
  const { mutateAsync: redirectDriver, isLoading } = useRedirectDriver();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedDriverId(event.target.value);
    redirectDriver({
      id,
      driverId: event.target.value,
    });
  };
  return (
    <>
      <FormControl
        sx={{
          m: 1,
          minWidth: isMobile ? 80 : 100,
          maxWidth: '200px',
          width: isMobile ? '80px' : '150px',
        }}
      >
        <InputLabel id="demo-simple-select-autowidth-label">
          Motorista
        </InputLabel>
        <Select
          disabled={isLoading}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={selectedDriverId || ''}
          onChange={handleChange}
          autoWidth
          label="Motorista"
          sx={{
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {!driverId ? (
            <MenuItem value="">
              <em />
            </MenuItem>
          ) : (
            <MenuItem value="">
              <Button
                onClick={() => {
                  redirectDriver({
                    id,
                    driverId: '',
                  });
                }}
              >
                Remover
              </Button>
            </MenuItem>
          )}
          {drivers
            .filter(data => data.licensePlate !== '')
            ?.map(driver => (
              <MenuItem
                key={driver.id}
                sx={{ maxWidth: '200px', width: '100%' }}
                value={driver.id}
              >
                {driver.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
