import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';
import { CompanyValues, UpdateCompanyDTO, SaveCompanyResponse } from '../types';

export type UpdateCompanyData = {
  data: UpdateCompanyDTO;
  id: string;
};

export const updateCompany = ({
  data,
  id,
}: UpdateCompanyData): Promise<SaveCompanyResponse> => {
  return axios.post('prv/api/company/saveCompanyAndMaster', { ...data, id });
  // return axios.put(`prv/api/company/save/${id}`, data);
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof updateCompany>;
};

export const useUpdateCompany = ({
  config,
}: UseUpdateCompaniesOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingCompany => {
      await queryClient.cancelQueries(['companies', updatingCompany?.id]);

      const previousCompanies = queryClient.getQueryData<CompanyValues>([
        'companies',
        updatingCompany?.id,
      ]);

      queryClient.setQueryData(['companies', updatingCompany?.id], {
        ...previousCompanies,
        ...updatingCompany.data,
        id: updatingCompany.id,
      });

      return { previousCompanies };
    },
    onError: (_, __, context: any) => {
      if (context?.previousCompanies) {
        queryClient.setQueryData(
          ['companies', context.previousCompanies.id],
          context.previousCompanies,
        );
      }
    },
    onSuccess: data => {
      queryClient.refetchQueries(['companies', data.data.id]);
      if (data?.data?.success === false) {
        addNotification({
          type: 'error',
          title: 'Erro',
          message: Object.entries(data?.data?.msg)[0][1],
        });
      } else {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Empresa atualizada com sucesso.',
        });
        navigate('/companies');
      }
    },
    ...config,
    mutationFn: updateCompany,
  });
};
