import { Button } from '@mui/material';
import { ConfigButtonProps } from './types';

export const ConfigButton = ({
  icon,
  bg,
  color,
  bgHover,
  handle,
  isLoading,
  children,
}: ConfigButtonProps) => {
  return (
    <Button
      disabled={isLoading}
      startIcon={icon}
      sx={{
        borderRight: '1px solid gray',
        padding: '10px',
        flex: '1',
        textAlign: 'center',
        borderRadius: 0,
        backgroundColor: bg,
        color,
        '&:hover': {
          background: bgHover,
        },
      }}
      onClick={handle}
    >
      {children}
    </Button>
  );
};
