import { ContentLayout } from '@/components';
import { SectorTrips } from '../components/SectorTrips';

export function SendTrip({ goBackButton = true }: { goBackButton?: boolean }) {
  return (
    <ContentLayout goBackButton={goBackButton}>
      <SectorTrips />
    </ContentLayout>
  );
}
