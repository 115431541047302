/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ConfirmModal, Table } from '@/components';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteSharp, EditSharp } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteClass } from '../api';
import { IVehicleClass } from '../types';

interface IRequest {
  data: IVehicleClass[];
  isLoading: boolean;
}

export const ListVehiclesClassesTable = ({
  data,
  isLoading = false,
}: IRequest) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading: isDeleting } = useDeleteClass();
  const [selectedClassToRemoved, setSelectedClassToRemoved] = useState({
    id: '',
  });

  return (
    <>
      <Table<IVehicleClass>
        columns={[
          { title: 'Nome', field: 'name', colSpan: 2 },
          { title: 'Preço inicial', field: 'initialPrice', colSpan: 2 },
          {
            title: 'Porcentagem extra',
            field: 'additionalFeePercentage',
            colSpan: 2,
          },
          {
            title: 'Ações',
            field: 'id',
            colSpan: 2,
            Cell: ({ entry: { ...rest } }) => {
              return (
                <>
                  <Tooltip title="Deletar a classe">
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => {
                        setSelectedClassToRemoved({
                          id: rest.id,
                        });
                      }}
                    >
                      <DeleteSharp sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar a classe">
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => {
                        navigate(`/classes/update/${rest.id}`);
                      }}
                    >
                      <EditSharp sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ]}
        data={data || []}
        loading={isLoading}
      />
      <ConfirmModal
        isLoading={isDeleting}
        title="Tem certeza que desejar deletar a classe?"
        close={() =>
          setSelectedClassToRemoved({
            id: '',
          })
        }
        handleConfirmation={() => {
          mutateAsync(
            { id: selectedClassToRemoved.id },
            {
              onSuccess: () => {
                setSelectedClassToRemoved({
                  id: '',
                });
              },
            },
          );
        }}
        isOpen={selectedClassToRemoved.id !== ''}
      />
    </>
  );
};
