import { Typography, Box, Button } from '@mui/material';
import { useDeleteCompany } from '../api';

type props = {
  id: string;
  close: () => void;
};

export function DeleteCompany({ id, close }: props) {
  // const { data: companiesQuery, isLoading } = useCompany({ id });
  const { mutateAsync } = useDeleteCompany();

  const handleDelete = (id: any) => {
    mutateAsync({ id });
    close();
  };

  return (
    <>
      <Box>
        <Typography sx={{ textAlign: 'center' }}>
          Tem certeza que deseja inativar essa empresa?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleDelete(id)}
            sx={{ margin: '10px' }}
            variant="contained"
            color="success"
          >
            Sim
          </Button>
          <Button
            onClick={close}
            sx={{ margin: '10px' }}
            variant="contained"
            color="error"
          >
            Não
          </Button>
        </Box>
      </Box>
    </>
  );
}
