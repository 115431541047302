import ambulance from '@/assets/vehicleIcons/ambulance.png';
import blackCar from '@/assets/vehicleIcons/blackCar.png';
import blueCar from '@/assets/vehicleIcons/blueCar.png';
import redCar from '@/assets/vehicleIcons/redCar.png';
import whitePickup from '@/assets/vehicleIcons/whitePickup.png';
import yellowCar from '@/assets/vehicleIcons/yellowCar.png';
import greenCar from '@/assets/vehicleIcons/greenCar.png';
import blueBus from '@/assets/vehicleIcons/blueBus.png';
import van from '@/assets/vehicleIcons/van.png';
import taxi from '@/assets/vehicleIcons/taxi.png';
import police from '@/assets/vehicleIcons/police.png';
import schoolBus from '@/assets/vehicleIcons/schoolBus.png';
import motocycle from '@/assets/vehicleIcons/motocycle.png';
import yellowMotocycle from '@/assets/vehicleIcons/yellowMotocycle.png';
import jeep from '@/assets/vehicleIcons/jeep.png';

export const possibleIcons = [
  {
    name: 'Carro',
    icon: blackCar,
  },
  {
    name: 'Carro azul',
    icon: blueCar,
  },
  {
    name: 'Carro Amarelo',
    icon: yellowCar,
  },
  {
    name: 'Carro vermelho',
    icon: redCar,
  },
  {
    name: 'Carro verde',
    icon: greenCar,
  },
  {
    name: 'Carro branco',
    icon: whitePickup,
  },
  {
    name: 'Jeep',
    icon: jeep,
  },
  {
    name: 'Moto',
    icon: motocycle,
  },
  {
    name: 'Moto amarela',
    icon: yellowMotocycle,
  },
  {
    name: 'Van',
    icon: van,
  },
  {
    name: 'Ônibus',
    icon: blueBus,
  },
  {
    name: 'Ônibus escolar',
    icon: schoolBus,
  },
  {
    name: 'Taxi',
    icon: taxi,
  },
  {
    name: 'Polícia',
    icon: police,
  },
  {
    name: 'Ambulância',
    icon: ambulance,
  },
];
