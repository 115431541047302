import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

const addCustomBresk = async ({ type }: { type: string }): Promise<void> => {
  await axios.post('/clockIn/addCustomClickIn', { type });
};

export const useAddCustomBreak = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('workjourney');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workjourney');
      addNotification({
        type: 'success',
        title: 'Ponto adicionado',
      });
    },
    mutationFn: addCustomBresk,
  });
};
