import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CreateSector } from './CreateSector';
import { SectorsList } from './SectorsList';
import { UpdateSectorRoute } from './UpdateSector';

export const SectorsRoutes = () => {
  return (
    <>
      <Can permissions={['company_admin', 'sector_admin']}>
        <Routes>
          <Route path="/" element={<SectorsList />} />
          <Route path="/create" element={<CreateSector />} />
          <Route path=":sectorId" element={<UpdateSectorRoute />} />
        </Routes>
      </Can>
    </>
  );
};
