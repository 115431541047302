import { Link, Table } from '@/components';

import {
  Visibility,
  EditSharp,
  DeleteSharp,
  ChangeCircle,
} from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { UserValues } from '@/features/users';

interface IProps {
  tableData: UserValues[];
  toggleView: (id: string) => void;
  toggleDelete: (id: string) => void;
  changeOperatorStatus: (data: { id: string }) => void;
  loading: boolean;
  isChangingStatus: boolean;
}

export function OperatorsList({
  tableData = [],
  toggleView,
  toggleDelete,
  loading,
  changeOperatorStatus,
  isChangingStatus,
}: IProps) {
  return (
    <Table<UserValues>
      columns={[
        { title: 'Nome', field: 'name', colSpan: 2 },
        { title: 'Telefone', field: 'phone', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id, status } }) {
            return (
              <>
                <Tooltip title="Visualizar">
                  <IconButton onClick={() => toggleView(id)}>
                    <Visibility sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>
                {status ? (
                  <>
                    <Link to={`./${id}`}>
                      <Tooltip title="Editar">
                        <IconButton>
                          <EditSharp sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Inativar">
                      <IconButton
                        disabled={isChangingStatus}
                        onClick={() => toggleDelete(id)}
                      >
                        <DeleteSharp sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Reativar">
                      <IconButton
                        disabled={isChangingStatus}
                        onClick={() => changeOperatorStatus({ id })}
                      >
                        <ChangeCircle sx={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData}
      loading={loading}
    />
  );
}
