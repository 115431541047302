import { useSectorsByCompany } from '@/features/sectors';
import { SelectCostCenter } from '../TripRequest/SelectCostCenter';

interface IRequest {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedCompany: string;
  value: string;
  onClickAdd?: () => void;
}

export const SelectSector = ({
  onChange,
  selectedCompany,
  value,
  onClickAdd,
}: IRequest) => {
  const { data: sectorsList } = useSectorsByCompany(selectedCompany);

  return (
    <SelectCostCenter
      value={value}
      onChange={onChange}
      data={sectorsList || []}
      onClickAdd={onClickAdd}
    />
  );
};
