import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { IAttachment } from '../types';

interface IRequest {
  id: string;
}

const getAttachmentsByTripId = async ({
  id,
}: IRequest): Promise<IAttachment[]> => {
  const response = await axios.get(`/trips/getAttachments/${id}`);
  return response.data.payload;
};

export const useGetAttachmentsByTripId = ({ id }: IRequest) => {
  return useQuery({
    queryKey: ['attachments', id],
    queryFn: () => getAttachmentsByTripId({ id }),
  });
};
