import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ICategory } from '../types';

interface IRequest {
    category: string;
    description?: string;
    id: string;
}

export async function editCategory(
  data: IRequest,
): Promise<ICategory> {
  const response = await axios.put(`vehicleCategories/edit/${data.id}`, data);
  return response.data.payload;
}

export const useEditCategory = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCompanyNew => {
      await queryClient.cancelQueries('vehicleCategories');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('vehicleCategories', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('vehicleCategories');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Categoria editada com sucesso.',
      });
    },
    mutationFn: editCategory,
  });
};
