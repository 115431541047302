import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

export const updateAutomaticRedirection = async (id: string) => {
  const response = await axios.patch(
    'autoRedirectSettings/updateAutomaticRedirection',
  );
  return response.data.payload;
};

export const useUpdateCompanyAutomaticRedirection = () => {
  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries(['automaticRedirectionMode', data]);

      const previousMode = queryClient.getQueryData<any>([
        'automaticRedirectionMode',
        data,
      ]);

      queryClient.setQueryData(['automaticRedirectionMode', data], {
        ...previousMode,
      });

      return { previousMode };
    },

    onSuccess: (_data, _variables, context) => {
      queryClient.refetchQueries(['automaticRedirectionMode', _variables]);
    },

    mutationFn: updateAutomaticRedirection,
  });
};
