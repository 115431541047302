import { ContentLayout } from '@/components';

import { CreateVehicleComponent } from '../components/CreateVehicle';

export const CreateVehicle = () => {
  return (
    <ContentLayout>
      <CreateVehicleComponent />
    </ContentLayout>
  );
};
