import { useAuth } from '@/providers/auth';
import { FormGroup, TextField, Toolbar } from '@mui/material';

interface IProps<Entry> {
  value: Entry;
  onChange: (value: Entry) => void;
  label: string;
  menuOptions: {
    label: string;
    value: Entry;
    permissions?: string[];
    roles?: string[];
    render: () => JSX.Element;
  }[];
}

export const CustomSelect = <Entry extends {}>({
  value,
  onChange,
  menuOptions,
  label,
}: IProps<Entry>) => {
  const { user } = useAuth();
  return (
    <Toolbar>
      <FormGroup row>
        <TextField
          label={label}
          select
          size="small"
          value={value}
          sx={{
            width: '200px',
          }}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
        >
          {menuOptions
            .filter(data =>
              data.roles
                ? user?.user?.company?.permissions?.find(permission =>
                    data.roles?.includes(permission.name),
                  ) &&
                  data.permissions?.some(permission =>
                    user?.user.accessType.includes(permission),
                  )
                : data.permissions?.some(permission =>
                    user?.user.accessType.includes(permission),
                  ),
            )
            .map(option => option.render())}
        </TextField>
      </FormGroup>
    </Toolbar>
  );
};
