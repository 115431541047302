import { Box, FormGroup, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import ptLocale from 'date-fns/locale/pt';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface IProps {
  initialDate: Date;
  setInitialDate: Dispatch<SetStateAction<Date | null>>;
  finalDate: Date;
  setFinalDate: Dispatch<SetStateAction<Date | null>>;
  isMobile: boolean;
}

export function DateFilter({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  isMobile,
}: IProps) {
  return (
    <FormGroup
      sx={{
        marginBottom: '10px',
        marginLeft: '10px',
        gap: isMobile ? '10px' : '0px',
      }}
      row
    >
      <Box
        sx={{
          width: isMobile ? '170px' : '200px',
          marginTop: isMobile ? '10px' : '0px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data inicial"
            value={initialDate}
            onChange={(newValue: Date | null) => {
              setInitialDate(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: isMobile ? '10px' : '0px',
          width: isMobile ? '170px' : '200px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data final"
            value={finalDate}
            onChange={(newValue: Date | null) => {
              setFinalDate(newValue);
            }}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </FormGroup>
  );
}
