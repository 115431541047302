import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';

// AxiosResponse
// CompanyValues

export async function getCompanies(companyId?: string): Promise<any> {
  const response = await axios.get('companies/getAll');
  return response.data.payload;
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getCompanies>;
};

export const useCompanies = ({ config }: UseCompaniesOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['companies'],
    enabled:
      user?.user.accessType === 'renter' || user?.user.accessType === 'master',
    queryFn: () => getCompanies(),
    ...config,
  });
};
