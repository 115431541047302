import { Form, InputField } from '@/components';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';
import { useAddCustomBreak } from '../api';

const schema = z.object({
  type: z.string({ required_error: 'Campo obrigatório' }),
});

export const AddCustomBreak = ({ onSuccess }: { onSuccess: () => void }) => {
  const { mutateAsync, isLoading } = useAddCustomBreak();
  return (
    <Form<{ type: string }, typeof schema>
      id="custom-break"
      onSubmit={values => {
        mutateAsync(values, {
          onSuccess: () => {
            onSuccess();
          },
        });
      }}
      schema={schema}
    >
      {({ register, formState }) => (
        <>
          <InputField
            size="small"
            name="type"
            type="text"
            label="Ponto Personalizado"
            registration={register('type')}
            error={!!formState.errors.type}
            errorMessage={formState.errors.type?.message}
          />
          <LoadingButton
            variant="contained"
            color="success"
            loading={isLoading}
            type="submit"
          >
            Salvar
          </LoadingButton>
        </>
      )}
    </Form>
  );
};
