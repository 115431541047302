import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ICategory } from '../types';

interface IRequest {
    category: string;
    description?: string;
}

export async function createCategory(
  data: IRequest,
): Promise<ICategory> {
  const response = await axios.post('vehicleCategories', data);
  return response.data.payload;
}

export const useCreateCategory = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCategory => {
      await queryClient.cancelQueries('vehicleCategories');

      const previousCategories =
        queryClient.getQueryData<ICategory[]>('vehicleCategories');

      queryClient.setQueryData('vehicleCategories', [
        ...(previousCategories || []),
        newCategory,
      ]);

      return previousCategories;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('vehicleCategories', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('vehicleCategories');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Categoria criada com sucesso.',
      });
    },
    mutationFn: createCategory,
  });
};
