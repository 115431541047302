import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { Can } from '@/components/Can';

Chart.register(...registerables);

export const DashBoard = ({ data }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ background: theme.palette.background.default, height: '100vh' }}>
      <Grid
        sx={{
          paddingLeft: isMobile ? '0px' : '80px',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
        container
      >
        {data.map((info: any) => (
          <Can key={info.title} permissions={info.permissions}>
            <Grid
              sx={{
                margin: '20px',
                width: '500px',
                flexBasis: '25%',
              }}
              item
              md={2}
              sm={12}
            >
              <Paper sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Card
                  sx={{
                    padding: '20px',
                    background:
                      'rgba(0, 0, 0, 0) linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) repeat scroll 0% 0%',
                  }}
                >
                  {info.icon}
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgb(123, 128, 154)',
                      fontWeight: '300',
                      fontSize: '1rem',
                    }}
                  >
                    {info.title}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '25px', color: 'rgb(52, 71, 103)' }}
                  >
                    <strong>{info.value}</strong>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Can>
        ))}
      </Grid>
    </Box>
  );
};
