import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';

// AxiosResponse
// CompanyValues

// eslint-disable-next-line consistent-return
export async function getDriversPlateList(): Promise<any> {
  try {
    const response = await axios.get('drivers/platelist');
    return response.data.payload;
  } catch (err) {
    console.log(err);
  }
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getDriversPlateList>;
};

export const useDriversPlateList = ({ config }: UseCompaniesOptions = {}) => {
  const { user } = useAuth();
  const tracker = user.user.company.trackerId;
  return useQuery({
    enabled: !!tracker,
    queryKey: ['driversPlateList'],
    queryFn: () => getDriversPlateList(),
    ...config,
  });
};
