import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { UpdateDriver } from '../components/UpdateDriver';
import { CreateDriver } from './CreateDriver';
import { DriversList } from './DriversList';

export const DriversRoutes = (): JSX.Element => {
  return (
    <Can permissions={['renter']}>
      <Routes>
        <Route path="" element={<DriversList />} />
        <Route path="/create" element={<CreateDriver />} />
        <Route path=":driverId" element={<UpdateDriver />} />
      </Routes>
    </Can>
  );
};
