import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { AcceptTripProps, AcceptTripResponse } from '../types';

type UseAcceptTripRequestOptions = {
  config?: MutationConfig<typeof acceptTripRequest>;
};

export async function acceptTripRequest(
  data: AcceptTripProps,
): Promise<AcceptTripResponse> {
  const response = await axios.post('/trips/acceptTrip', data);
  return response.data.payload;
}

export const useAcceptTrip = ({ config }: UseAcceptTripRequestOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async acceptedTrip => {
      await queryClient.cancelQueries('trips');
      await queryClient.cancelQueries('scheduledTrips');

      const previoustrips = queryClient.getQueryData<any[]>('trips');

      queryClient.setQueryData('trips', [
        previoustrips?.filter(trip => trip !== acceptedTrip),
      ]);

      queryClient.setQueryData('scheduledTrips', [
        previoustrips?.filter(trip => trip !== acceptedTrip),
      ]);

      return previoustrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('trips');
      queryClient.invalidateQueries('scheduledTrips');
    },
    ...config,
    mutationFn: acceptTripRequest,
  });
};
