import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IProfile, IUpdateProfile } from '../types';

export async function updateProfile(data: IUpdateProfile): Promise<IProfile> {
  return axios.put(`permissions/updateRoleProfile/${data.id}`, data);
}

export const useUpdateProfile = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newProfile => {
      await queryClient.cancelQueries('profiles');

      const previousDrivers =
        queryClient.getQueryData<IUpdateProfile[]>('profiles');

      queryClient.setQueryData('profiles', [
        ...(previousDrivers || []),
        newProfile,
      ]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('profiles', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('profiles');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Perfil atualizado com sucesso.',
      });
      navigate('/profile');
    },
    mutationFn: updateProfile,
  });
};
