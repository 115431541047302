import { ContentLayout, PageLayout } from '@/components';
import LockIcon from '@mui/icons-material/Lock';
import { useCustomTheme } from '@/providers/theme';
import { CreatePermissions as CreatePermissionsComponent } from '../components/CreatePermissions';

export function CreatePermissions() {
  const { subTitle } = useCustomTheme();
  return (
    <PageLayout
      title="Permissões"
      subTitle={subTitle}
      icon={<LockIcon fontSize="large" style={{ color: '#0ebcdf' }} />}
    >
      <ContentLayout>
        <CreatePermissionsComponent />
      </ContentLayout>
    </PageLayout>
  );
}
