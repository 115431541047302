/* eslint-disable no-underscore-dangle */
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import Logo from '@/assets/img/logo.png';
import * as z from 'zod';
import { useState } from 'react';
import { InputField, Loading } from '@/components';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateVoucher, useUser } from '../api';
import { CreateVoucher } from './CreateVoucher';

export const Voucher = ({ id }: { id: string }) => {
  const { data: user, isLoading: isLoadingVoucher } = useUser({ id });
  const [mode, setMode] = useState<'add' | 'withdraw'>('add');

  const { mutateAsync, isLoading: isLoadingNewVoucher } = useUpdateVoucher();

  const schema = z.object({
    value: z.string().optional(),
    mode: z.string().optional(),
  });

  const { handleSubmit, control, formState } = useForm({
    resolver: schema && zodResolver(schema),
  });

  const vouchers = [
    {
      value: 1,
      title: 'Ilimitado',
    },
    {
      value: 2,
      title: 'Limitado',
    },
    {
      value: 3,
      title: 'Passar pelo chefe de setor',
    },
  ];

  const OnSubmit = (values: any) => {
    if (!user) return;
    mutateAsync({
      userId: user.id,
      mode,
      value: parseFloat(values.value),
    });
  };

  if (isLoadingVoucher || !user) {
    return <Loading isLoading={isLoadingVoucher} />;
  }

  if (user?.voucher === null) {
    return <CreateVoucher user={user} vouchers={vouchers} />;
  }

  return (
    <form
      onSubmit={handleSubmit(OnSubmit)}
      style={{ display: 'flex', gap: '16px' }}
    >
      <Box
        sx={{
          width: '600px',
          height: '200px',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
          background:
            'linear-gradient(105.31deg, #02F7F1 0.9%, #2998FA 118.4%, #0D8EFF 205.99%)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          justifyContent: 'space-between',
          padding: '10px',
          alignItems: 'start',
          color: '#FFF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Box>
            <img src={Logo} alt="logo" />
            <Typography sx={{ fontWeight: 'bold' }}>Voucher card</Typography>
          </Box>

          <Typography sx={{ fontWeight: 'bold', fontSize: '22px' }}>
            {user.voucher.status === 2
              ? `R$ ${user?.voucher.value.toFixed(2).replace('.', ',')}`
              : 'Ilimitado'}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
            Nome
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            {user?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <Typography>Configurações</Typography>
        <Box
          sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <TextField
            style={{ width: '200px' }}
            size="small"
            type="text"
            select
            label="Tipo de Voucher"
            value={user.voucher.status}
            disabled={isLoadingNewVoucher}
            onChange={e =>
              mutateAsync({
                userId: user.id,
                status: parseFloat(e.target.value),
              })
            }
          >
            {vouchers.map(voucher => (
              <MenuItem key={voucher.value} value={voucher.value}>
                {voucher.title}
              </MenuItem>
            ))}
          </TextField>
          {user.voucher.status === 2 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '200px',
              }}
            >
              <Controller
                name="value"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    InputProps={{
                      inputProps: { min: 0, step: 0.01 },
                    }}
                    size="small"
                    name="value"
                    type="number"
                    label="Valor"
                    onChange={onChange}
                    value={value}
                    placeholder="20"
                    errorMessage={formState.errors.value?.message}
                    error={!!formState.errors.value}
                  />
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Button
                  sx={{ width: '100px' }}
                  variant="contained"
                  color="success"
                  disabled={isLoadingNewVoucher}
                  onClick={() => setMode('add')}
                  type="submit"
                >
                  Adicionar
                </Button>
                <Button
                  sx={{ width: '100px', marginLeft: '5px' }}
                  variant="contained"
                  color="error"
                  disabled={isLoadingNewVoucher}
                  onClick={() => setMode('withdraw')}
                  type="submit"
                >
                  Retirar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
