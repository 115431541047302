import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { IStatements } from '../types';

export async function getUserStatements({
  userId,
}: {
  userId: string;
}): Promise<IStatements[]> {
  const response = await axios.get(`vouchers/getStatement/${userId}`);
  return response.data.payload;
}

type UseStatementsOptions = {
  config?: QueryConfig<typeof getUserStatements>;
  userId: string;
};

export const useUserStatements = ({ userId, config }: UseStatementsOptions) => {
  return useQuery({
    enabled: userId !== undefined && userId !== '',
    queryKey: ['statements', userId],
    queryFn: () => getUserStatements({ userId }),
    ...config,
  });
};
