import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface IRequest {
  id: string;
  email?: string;
  password?: string;
  name: string;
  urlImage?: string;
  phone: string;
  registration?: string;
  roleProfileId: string;
  thirdPartyCompany?: string;
  individualRegistration: string;
  sectorId: any;
  sectorName: string;
  willHaveAccess?: boolean;
  accessType: string | number;
  status: boolean;
  voucher?: number;
  sector?: {
    id: string;
    name: string;
  };
}

interface IOutput {
  accessType: string;
  companyId: string;
  email: string;
  id: string;
  individualRegistration: string;
  name: string;
  phone: string;
  sectorId: string;
  sectorName: string;
  status: boolean;
}

export async function createPassenger(data: IRequest): Promise<IOutput> {
  const response = await axios.post('users/register', data);
  return response.data.payload;
}

export const useCreatePassenger = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newUser => {
      await queryClient.cancelQueries('users');

      const previousUsers = queryClient.getQueryData<IOutput[]>('users');

      queryClient.setQueryData('users', [...(previousUsers || []), newUser]);

      return previousUsers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('users', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Usuário salvo com sucesso.',
      });
    },
    mutationFn: createPassenger,
  });
};
