import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { loadOpenTripsResponse } from '@/features/tripRequest/types';

interface IRequest {
  initialDate: Date;
  finalDate: Date;
  type?: 'simple' | 'appointment' | 'recurring' | '';
  status?: string[];
  driverId?: string;
}

// eslint-disable-next-line consistent-return
export async function getOpenTrips(
  data: IRequest,
): Promise<loadOpenTripsResponse[]> {
  const response = await axios.get(
    `trips/listTripsWithFilter?initialDate=${data.initialDate}&finalDate=${data.finalDate}&type=${data.type}&status=${data.status}&driverId=${data.driverId}`,
  );
  return response.data.payload;
}

export const useOpenTrips = (data: IRequest) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['trips', data],
    enabled: user?.user?.accessType === 'renter',
    queryFn: () => getOpenTrips(data),
  });
};
