import { storage } from '@/utils/storage';
import { Add } from '@mui/icons-material';
import { Box, Chip, Paper } from '@mui/material';

interface ChipData {
  key: number;
  field: string;
  title: string;
  Cell?: (props: any) => JSX.Element;
}

type Props = {
  chipData: ChipData[];
  setChipData: (chips: any) => void;
  removedChips: ChipData[];
  setRemovedChips: (chips: any) => void;
  storegeKey: string;
};

export const ReportsOptions = ({
  chipData,
  setChipData,
  removedChips,
  setRemovedChips,
  storegeKey,
}: Props) => {
  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips: ChipData[]) => {
      const filteredChips = chips.filter(
        (chip: ChipData) => chip.key !== chipToDelete.key,
      );
      storage.setItem({
        key: storegeKey,
        values: filteredChips,
        storageType: 'local',
      });

      return filteredChips;
    });
    setRemovedChips((chips: ChipData[]) => {
      storage.setItem({
        key: `${storegeKey}Removed`,
        values: [...chips, chipToDelete],
        storageType: 'local',
      });
      return [...chips, chipToDelete];
    });
  };

  const handleAdd = (data: ChipData) => {
    setRemovedChips((chips: ChipData[]) => {
      const filteredChips = chips.filter(
        (chip: ChipData) => chip.key !== data.key,
      );
      storage.setItem({
        key: `${storegeKey}Removed`,
        values: filteredChips,
        storageType: 'local',
      });
      return filteredChips;
    });

    setChipData((chips: any) => {
      storage.setItem({
        key: storegeKey,
        values: [...chips, data],
        storageType: 'local',
      });
      return [...chips, data];
    });
  };
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        <Box sx={{ width: '100%' }}>
          {removedChips.length > 0 && (
            <Box sx={{ borderBottom: '1px solid gray' }}>
              {removedChips.map((data: any) => (
                <Chip
                  onDelete={() => handleAdd(data)}
                  key={data.key}
                  sx={{ margin: '10px', cursor: 'pointer' }}
                  label={data.title}
                  deleteIcon={<Add />}
                />
              ))}
            </Box>
          )}
        </Box>

        {chipData.map((data: ChipData) => (
          <Box key={data.key}>
            <Chip
              sx={{ margin: '10px' }}
              label={data.title}
              onDelete={handleDelete(data)}
            />
          </Box>
        ))}
      </Paper>
    </>
  );
};
