import { ConfirmModal, Link, Table } from '@/components';
import { DeleteSharp, EditSharp } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { useState } from 'react';
import { useDeleteTaggedLocation } from '../api';

interface IProps {
  id: string;
  name: string;
  price: number;
  priceForDriver?: number;
  companyId: string;
  createdAt?: Date;
  updatedAt?: Date;
  address: string;
  priceTagId: string;
}

interface DesktopTagTableProps {
  tableData?: IProps[];
  loading: boolean;
}

export function DesktopTaggedAdresses({
  tableData = [],
  loading,
}: DesktopTagTableProps) {
  const [selectedTagToDelete, setSelectedTagToDelete] = useState<null | string>(
    null,
  );
  const { mutateAsync: deleteTag, isLoading: isDeletingTag } =
    useDeleteTaggedLocation();
  return (
    <>
      <Table<IProps>
        columns={[
          { title: 'Endereço', field: 'address' },
          { title: 'Tarifa', field: 'name' },
          { title: 'Valor da tarifa (R$)', field: 'price' },
          {
            title: 'Valor da tarifa para o motorista (R$)',
            field: 'priceForDriver',
          },
          {
            title: 'Ações',
            field: 'id',
            Cell({ entry: { id } }) {
              return (
                <>
                  <Link to={`/tags/updateLocation/${id}`}>
                    <Tooltip title="Editar">
                      <IconButton>
                        <EditSharp sx={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <Tooltip title="Excluir">
                    <IconButton onClick={() => setSelectedTagToDelete(id)}>
                      <DeleteSharp sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ]}
        data={tableData.sort((a, b) => a.name.localeCompare(b.name))}
        loading={loading || isDeletingTag}
      />

      <ConfirmModal
        isOpen={!!selectedTagToDelete}
        close={() => setSelectedTagToDelete(null)}
        title="Tem certeza que deseja deletar o local?"
        handleConfirmation={() => {
          if (selectedTagToDelete) {
            deleteTag(
              { id: selectedTagToDelete },
              {
                onSuccess: () => setSelectedTagToDelete(null),
              },
            );
          }
        }}
      />
    </>
  );
}
