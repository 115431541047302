import { useUser } from '@/features/users';
import {
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

type props = {
  id: string;
};

export function ViewOperator({ id }: props) {
  const { data: usersQuery, isLoading } = useUser({ id });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? 'white' : 'red',
            }}
          >
            Usuário
          </Typography>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Login"
                value={usersQuery?.email}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Nome"
                value={usersQuery?.name}
              />
              <TextField
                size="small"
                sx={{ width: '100%', marginTop: '10px' }}
                disabled
                label="Telefone"
                value={usersQuery?.phone}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.wrapper}>
                <TextField
                  size="small"
                  sx={{ width: '100%', marginTop: '10px' }}
                  disabled
                  label="CPF"
                  value={usersQuery?.individualRegistration}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
