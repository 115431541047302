import { axios } from "@/lib/axios"
import { queryClient } from "@/lib/react-query"
import { useMutation } from "react-query"

const removeWarning = async (plate: string): Promise<void> => {
    await axios.delete(`lastPositions/remove/${plate}`)
}

export const useRemoveWarning = () => {
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries('warnings');
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries('warnings');
        },
        mutationFn: removeWarning
    })
}