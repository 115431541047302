import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

export async function getRoles(id?: string): Promise<
  {
    id: string;
    type: string;
    isCompanyPermission: boolean;
  }[]
> {
  const response = await axios.get('permissions/getAll');
  return response.data.payload;
}

type UseRolesOptions = {
  config?: QueryConfig<typeof getRoles>;
};

export const useRoles = ({ config }: UseRolesOptions = {}) => {
  return useQuery({
    queryKey: ['permissions'],
    queryFn: () => getRoles(),
    ...config,
  });
};
