import { Loading } from '@/components';
import { useUserStatements } from '../api';
import { StatementTableContainer } from './StatementTableContainer';

export const UserStatement = ({ userId }: { userId: string }) => {
  const { data, isLoading } = useUserStatements({ userId });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }
  return <StatementTableContainer data={data} />;
};
