import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CompanyValues, CreateCompanyDTO, SaveCompanyResponse } from '..';

type UseCreateCompanyOptions = {
  config?: MutationConfig<typeof createCompanyAndMaster>;
};

export async function createCompanyAndMaster(
  data: CreateCompanyDTO,
): Promise<SaveCompanyResponse> {
  return axios.post('prv/api/company/saveCompanyAndMaster', data);
}

export const useCreateCompany = ({ config }: UseCreateCompanyOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCompany => {
      await queryClient.cancelQueries('companies');

      const previousCompanies =
        queryClient.getQueryData<CompanyValues[]>('companies');

      queryClient.setQueryData('companies', [
        ...(previousCompanies || []),
        newCompany,
      ]);

      return previousCompanies;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('companies', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('companies');
      if (data.data.success === false) {
        addNotification({
          type: 'error',
          title: 'Error',
          message: Object.entries(data?.data?.msg)[0][1],
        });
      } else {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Empresa criada com sucesso.',
        });
        navigate('/companies');
      }
    },
    ...config,
    mutationFn: createCompanyAndMaster,
  });
};
