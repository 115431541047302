import { ConfigButton } from '@/components';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useUpdateAutoRedirectMode } from '../../api';

type Props = {
  mode: number;
  setMode: (mode: number) => void;
  options: {
    bg: string;
    color: string;
    bgHover: string;
    handle: () => void;
    title: string;
    id: string;
  }[];
  isConfirming?: boolean;
};

export const RedirectionModeFilterOption = ({
  mode,
  setMode,
  options,
  isConfirming,
}: Props) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: '7px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid gray',
        }}
      >
        {options.map(option => (
          <ConfigButton
            isLoading={isConfirming}
            handle={option.handle}
            bg={option.bg}
            bgHover={option.bgHover}
            color={option.color}
            key={option.id}
          >
            {option.title}
          </ConfigButton>
        ))}
      </Box>
    </>
  );
};
