import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CreateVehicleClass } from './CreateVehicleClass';
import { ListVehicleClasses } from './ListVehicleClasses';
import { UpdateVehicleClass } from './UpdateVehicleClass';

export const VehicleClassesRoutes = () => {
  return (
    <>
      <Can restriction="veículos" permissions={['renter']}>
        <Routes>
          <Route path="/" element={<ListVehicleClasses />} />
          <Route path="/create" element={<CreateVehicleClass />} />
          <Route path="/update/:id" element={<UpdateVehicleClass />} />
        </Routes>
      </Can>
    </>
  );
};
