import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';
import { useNotificationStore } from '@/stores';
import { queryClient } from '@/lib/react-query';
import { AxiosError } from 'axios';
import { IPriceTags } from '../types';

// eslint-disable-next-line consistent-return
export async function deleteTaggedLocation(data: {
  id: string;
}): Promise<IPriceTags> {
  const response = await axios.delete(
    `taggedAdresses/deleteTaggedAddress/${data.id}`,
  );
  return response.data.payload;
}

export const useDeleteTaggedLocation = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries('tags');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('tags', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('tags');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Localidade deletada com sucesso',
      });
    },
    mutationFn: deleteTaggedLocation,
  });
};
