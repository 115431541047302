/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { driversProps } from '../types';
import { DriversGrid } from './DriversGrid';

type IGridContainer = {
  mode: string;
  setMode: (e: string) => void;
  map: any;
  drivers: driversProps[];
  cellphoneTracker: boolean;
  setCellphoneTracker: (e: boolean) => void;
  setClickedDriver: (e: any) => void;
  openSettings: () => void;
  hasTracker: boolean;
  openWarnings: () => void;
  numberOfWarnings: number;
  hasAlertRole: boolean;
};

export const GridContainer = ({
  setMode,
  mode,
  drivers,
  map,
  setClickedDriver,
  openSettings,
  cellphoneTracker,
  setCellphoneTracker,
  hasTracker,
  openWarnings,
  numberOfWarnings,
  hasAlertRole,
}: IGridContainer) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [result, setResult] = useState('');
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResult(e.target.value);
  };

  const expandedHandler = () => {
    setMode('expanded');
  };

  const defaultHandler = () => {
    setMode('default');
  };

  const hideHandler = () => {
    setMode('hidden');
    setClickedDriver(null);
  };
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          height: mode === 'expanded' ? '90vh' : mode === 'default' ? 250 : 50,
          right: 0,
          left: isMobile ? -10 : 40,
          transition: '0.5s ease-in-out',
          background: theme.palette.background.default,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height:
              mode === 'expanded' ? '90vh' : mode === 'default' ? 250 : 50,
            overflowY: 'scroll',
          }}
        >
          {drivers && (
            <DriversGrid
              hasAlertRole={hasAlertRole}
              map={map}
              numberOfWarnings={numberOfWarnings}
              openWarnings={openWarnings}
              openSettings={openSettings}
              mode={mode}
              expandedHandler={expandedHandler}
              defaultHandler={defaultHandler}
              hideHandler={hideHandler}
              handleSearch={handleSearch}
              cellphoneTracker={cellphoneTracker}
              setCellphoneTracker={setCellphoneTracker}
              result={result}
              hasTracker={hasTracker}
              drivers={drivers}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
