import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export const updateAutoRedirectMode = ({
  mode,
}: {
  mode: number;
}): Promise<any> => {
  return axios.patch(`autoRedirectSettings/updateAutoRedirectMode`, {
    mode,
  });
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof updateAutoRedirectMode>;
};

export const useUpdateAutoRedirectMode = ({
  config,
}: UseUpdateCompaniesOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateAutoRedirectMode,
  });
};
