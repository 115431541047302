import { PageLayout } from '@/components';
import { AttachMoney, Business, Sell } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { CompaniesPriceListTable } from '@/features/priceTable/components/CompaniesPriceListTable';
import create from 'zustand';
import { useAuth } from '@/providers/auth';
import { TagListTable } from '@/features/Tag/components/TagListTable';
import { CompaniesListTable } from '../components/CompaniesListTable';

interface PageState {
  selectedPage: string;
  setSelectedPage: (page: string) => void;
}

const usePageStore = create<PageState>(set => ({
  selectedPage: 'companies',
  setSelectedPage: (page: string) => set({ selectedPage: page }),
}));

export function CompaniesList() {
  const { subTitle } = useCustomTheme();
  const { user } = useAuth();
  const { selectedPage, setSelectedPage } = usePageStore();

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedPage(event.target.value);
  };

  function getIcon() {
    if (selectedPage === 'companies') {
      return <Business fontSize="large" style={{ color: '#0ebcdf' }} />;
    }
    if (selectedPage === 'pricetable') {
      return <AttachMoney fontSize="large" style={{ color: '#0ebcdf' }} />;
    }
    if (selectedPage === 'tags') {
      return <Sell fontSize="large" style={{ color: '#0ebcdf' }} />;
    }
    return <Business fontSize="large" style={{ color: '#0ebcdf' }} />;
  }

  const title: any = {
    companies: 'Empresas',
    pricetable: 'Preço Tabelado',
    tags: 'Tarifas',
  };
  return (
    <>
      <PageLayout
        title={title[selectedPage] || 'Empresas'}
        subTitle={subTitle}
        icon={getIcon()}
      >
        <Toolbar>
          <Select
            value={selectedPage}
            onChange={handleSelectChange}
            style={{ height: '44px' }}
          >
            <MenuItem value="companies">Empresas</MenuItem>
            {user.user.accessType !== 'master' && (
              <MenuItem value="pricetable">Preço Tabelado</MenuItem>
            )}
            <MenuItem value="tags">Tarifas</MenuItem>
          </Select>
        </Toolbar>
        {selectedPage === 'companies' && <CompaniesListTable />}
        {selectedPage === 'pricetable' && <CompaniesPriceListTable />}
        {selectedPage === 'tags' && <TagListTable />}
      </PageLayout>
    </>
  );
}
