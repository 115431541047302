import { useState } from 'react';
import { Button } from '@mui/material';
import { RecurringTripDays } from '../RecurringTripDays';
import { IRecurringTripTableProps } from '../RecurringTripsList';
import { useUpdateRecurringTrips } from '../../api';

const days = [
  {
    id: 0,
    name: 'Dom',
  },
  {
    id: 1,
    name: 'Seg',
  },
  {
    id: 2,
    name: 'Ter',
  },
  {
    id: 3,
    name: 'Qua',
  },
  {
    id: 4,
    name: 'Qui',
  },
  {
    id: 5,
    name: 'Sex',
  },
  {
    id: 6,
    name: 'Sab',
  },
];

interface IProps {
  recurringDays: number[];
  recurringTime: string;
  id: string;
  close: () => void;
}

export const EditRecurringTrip = ({
  recurringDays,
  recurringTime,
  id,
  close,
}: IProps) => {
  const { mutateAsync, isLoading } = useUpdateRecurringTrips();
  const minute = parseInt(recurringTime?.split(':')[1], 10);
  const hour = parseInt(recurringTime?.split(':')[0], 10);
  const initialDate = new Date();
  initialDate.setHours(hour);
  initialDate.setMinutes(minute);
  const [date, setDate] = useState(initialDate);
  const [selectedRecurringTripDays, setSelectedRecurringTripDays] = useState<
    {
      id: number;
      name: string;
    }[]
  >(recurringDays.map(day => ({ id: day, name: days[day].name })));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(
      {
        id,
        hour: date.getHours(),
        minute: date.getMinutes(),
        recurringTripDays: selectedRecurringTripDays.map(day => ({
          day: day.id,
        })),
      },
      {
        onSuccess() {
          close();
        },
      },
    );
  };

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      onSubmit={handleSubmit}
    >
      <RecurringTripDays
        date={date}
        setScheduledDate={(date: Date) => setDate(date)}
        selectedRecurringTripDays={selectedRecurringTripDays}
        setSelectedRecurringTripDays={setSelectedRecurringTripDays}
      />
      <Button
        disabled={isLoading}
        sx={{ width: '200px' }}
        type="submit"
        variant="contained"
      >
        Salvar
      </Button>
    </form>
  );
};
