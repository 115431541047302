import { Box, Typography, useTheme } from '@mui/material';
import addressIconChecked from '@/assets/img/AddressIconChecked.png';
import addressIconNotChecked from '@/assets/img/AddressIconNotChecked.png';

type Props = {
  addressFrom: string;
  addressTo: string;
  stopPoints?: {
    id: string;
    address: string;
    lat: number;
    long: number;
    order: number;
  }[];
};

const AddressCard = ({ addressFrom, addressTo, stopPoints }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <div>
        <Typography
          sx={{
            fontSize: 11,
            display: 'flex',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
            '& .fullAddress': {
              display: 'none',
            },

            '&:hover .fullAddress': {
              transition: 'ease-in-out 0.2s',
              display: 'block',
            },

            '&:hover .parcialAddress': {
              transition: 'ease-in-out 0.2s',
              display: 'none',
            },
          }}
        >
          <img
            style={{ width: '20px', height: '20px', marginRight: '5px' }}
            src={addressIconChecked}
            alt="checked"
          />
          <span className="fullAddress">{addressFrom}</span>
          {addressFrom?.length > 40 ? (
            <span className="parcialAddress">
              {addressFrom?.slice(0, 40)}...
            </span>
          ) : (
            <span className="parcialAddress">{addressFrom}</span>
          )}
        </Typography>
        {stopPoints
          ?.sort((a, b) => a.order - b.order)
          .map(stopPoint => (
            <Typography
              key={stopPoint.id}
              sx={{
                marginTop: '10px',
                fontSize: 11,
                fontFamily: theme.typography.fontFamily,
                display: 'flex',
                color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
                '& .fullAddress': {
                  display: 'none',
                },

                '&:hover .fullAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'block',
                },

                '&:hover .parcialAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'none',
                },
              }}
            >
              <img
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
                src={addressIconNotChecked}
                alt="notChecked"
              />
              <span className="fullAddress">{stopPoint.address}</span>
              {stopPoint.address?.length > 40 ? (
                <span className="parcialAddress">
                  {stopPoint.address?.slice(0, 40)}...
                </span>
              ) : (
                <span className="parcialAddress">{stopPoint.address}</span>
              )}
            </Typography>
          ))}
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: 11,
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            color: theme.palette.mode === 'dark' ? '#D8D8D8' : '#7A7A7A',
            '& .fullAddress': {
              display: 'none',
            },

            '&:hover .fullAddress': {
              transition: 'ease-in-out 0.2s',
              display: 'block',
            },

            '&:hover .parcialAddress': {
              transition: 'ease-in-out 0.2s',
              display: 'none',
            },
          }}
        >
          {addressTo && addressTo !== 'Motorista à disposição' && (
            <>
              <img
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
                src={addressIconChecked}
                alt="notChecked"
              />
              <span className="fullAddress">{addressTo}</span>
              {addressTo?.length > 40 ? (
                <span className="parcialAddress">
                  {addressTo?.slice(0, 40)}...
                </span>
              ) : (
                <span className="parcialAddress">{addressTo}</span>
              )}
            </>
          )}
        </Typography>
      </div>
    </Box>
  );
};

export default AddressCard;
