import { Box, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Detail } from './Detail';
import { AdditionalValues } from './AdditionalValues';
import { WaitingTimeAdditionalValues } from './WaitingTimeAdditionalValues';

interface IProps {
  distance: number;
  formattedInitialPrice: string;
  formattedPricePerKm: string;
  totalDistancePrice: string;
  waitingTimePrice: number;
  totalWaitingTime: number;
  totalWaitingPrice: string;
  tripPrice: string;
  va: string;
  tripAttachments: {
    fileUrl?: string;
    id: string;
    observation: string;
    price: string;
    type: string;
  }[];
  otherFees: number;
  multiply: number;
  hasNightAdditional: boolean;
  nightAdditional: number;
  vehicleClassPrice: number;
  vehicleClassPercentage: number;
  waitingTimeBeforeStart: number;
  waitingTimeAfterStart: number;
}

export const DefaultPriceDetail = ({
  distance,
  formattedInitialPrice,
  formattedPricePerKm,
  totalDistancePrice,
  totalWaitingPrice,
  totalWaitingTime,
  tripPrice,
  va,
  waitingTimePrice,
  otherFees,
  tripAttachments,
  multiply,
  nightAdditional,
  hasNightAdditional,
  vehicleClassPercentage,
  vehicleClassPrice,
  waitingTimeAfterStart,
  waitingTimeBeforeStart,
}: IProps) => {
  return (
    <Box sx={{ marginTop: '-10px' }}>
      <Detail
        bold
        title="Valor inicial fixo"
        description={formattedInitialPrice}
      />
      <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />

      <Detail bold title="Tabela de cobrança de KM" description="" />

      <ul style={{ marginTop: '-12px' }}>
        {multiply === 2 ? (
          <>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    width: '50%',
                  }}
                >
                  <Typography>Distância percorrida na ida (km):</Typography>
                  <ArrowForward style={{ color: '#3394D9' }} />
                </Box>
                <Typography>{distance.toFixed(2)}km</Typography>
              </Box>
            </li>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    width: '50%',
                  }}
                >
                  <Typography>Distância percorrida na volta (km):</Typography>
                  <ArrowBack style={{ color: '#3394D9' }} />
                </Box>
                <Typography>{distance.toFixed(2)}km</Typography>
              </Box>
            </li>
            <li>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography sx={{ width: '50%' }}>
                  Distância total (km):
                </Typography>
                <Typography>{(distance * 2).toFixed(2)}km</Typography>
              </Box>
            </li>
          </>
        ) : (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>
                Distância total (km):
              </Typography>
              <Typography>{distance.toFixed(2)}km</Typography>
            </Box>
          </li>
        )}
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={{ width: '50%' }}>Valor por km:</Typography>
            <Typography>{formattedPricePerKm}</Typography>
          </Box>
        </li>

        {!!nightAdditional && hasNightAdditional && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>Valor noturno:</Typography>
              <Typography>{nightAdditional}%</Typography>
            </Box>
          </li>
        )}
        {!!vehicleClassPrice && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>
                Valor adicional classe do veículo:
              </Typography>
              <Typography>
                {vehicleClassPrice.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Box>
          </li>
        )}
        {!!vehicleClassPercentage && (
          <li>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ width: '50%' }}>
                Porcentagem adicional classe do veículo:
              </Typography>
              <Typography>{vehicleClassPercentage}%</Typography>
            </Box>
          </li>
        )}
        <li>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Subtotal (Distância):</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {totalDistancePrice}
            </Typography>
          </Box>
        </li>
      </ul>
      <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
      {waitingTimePrice > 0 && (
        <>
          <WaitingTimeAdditionalValues
            totalWaitingPrice={totalWaitingPrice}
            totalWaitingTime={totalWaitingTime}
            waitingTimeAfterStart={waitingTimeAfterStart}
            waitingTimeBeforeStart={waitingTimeBeforeStart}
          />
          <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
        </>
      )}

      {!!otherFees && otherFees > 0 && (
        <>
          <AdditionalValues tripAttachments={tripAttachments} va={va} />
          <Box sx={{ width: '100%', height: '1px', background: 'gray' }} />
        </>
      )}
      <Box sx={{ marginTop: '10px' }}>
        <Detail title="Valor final" bold description={tripPrice} />
      </Box>
    </Box>
  );
};
