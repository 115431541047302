import { PageLayout } from '@/components';
import { AssignmentInd } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import create from 'zustand';
import { ListVehiclesComponent } from '@/features/vehicles';
import { ListVehicleClassesComponent } from '@/features/vehicleClasses';
import { DriversListTable } from '../components/DriversListTable';

interface PageState {
  selectedPage: string;
  setSelectedPage: (page: string) => void;
}

const usePageStore = create<PageState>(set => ({
  selectedPage: 'drivers',
  setSelectedPage: (page: string) => set({ selectedPage: page }),
}));

export function DriversList() {
  const { subTitle } = useCustomTheme();
  const { selectedPage, setSelectedPage } = usePageStore();

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedPage(event.target.value);
  };

  const title: any = {
    drivers: 'Motoristas',
    vehicle_classes: 'Classe de veículos',
    vehicles: 'Veículos',
  };

  return (
    <>
      <PageLayout
        title={title[selectedPage] || 'Motoristas'}
        subTitle={subTitle}
        icon={<AssignmentInd fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <Toolbar>
          <Select
            value={selectedPage}
            onChange={handleSelectChange}
            style={{ height: '44px' }}
          >
            <MenuItem value="drivers">Motoristas</MenuItem>
            <MenuItem value="vehicle_classes">Classe de veículos</MenuItem>
            <MenuItem value="vehicles">Veículos</MenuItem>
          </Select>
        </Toolbar>
        {selectedPage === 'drivers' && <DriversListTable />}
        {selectedPage === 'vehicles' && <ListVehiclesComponent />}
        {selectedPage === 'vehicle_classes' && <ListVehicleClassesComponent />}
      </PageLayout>
    </>
  );
}
