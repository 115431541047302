import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    zIndex: 999,
    height: '100%',
    position: 'fixed',
    flexDirection: 'column',
    borderRadius: 0,
    backgroundColor: '#1c2841',
    display: ({ isMobile }: { isMobile?: boolean }) =>
      isMobile ? 'none' : 'flex',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 56,
    padding: 5,
    marginTop: '15px',
    marginRight: '1px',
  },
  iconsWrapper: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '15px',
    flex: 1,
  },

  icon: {
    color: 'white',
    marginTop: '2px',
  },
  logout: {},
}));
