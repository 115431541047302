import { axios } from '@/lib/axios';

import { useUserStore } from '@/stores';
import { storage } from '@/utils/storage';
import { LoginCredentialsDTO, LoginResponse } from '../types';

export async function loginWithEmailAndPassword({
  email,
  password,
}: LoginCredentialsDTO): Promise<LoginResponse> {
  const response = await axios.post('session/desktop', { email, password });

  return response.data.payload;
}

export async function loginFn(data: LoginCredentialsDTO, addNotification: any) {
  const response = await loginWithEmailAndPassword(data);
  const { user } = response;
  if (response.user) {
    if (data.rememberMe === true) {
      useUserStore.setState({ user });
      storage.setItem({ key: 'user', storageType: 'local', values: { user } });
      storage.setItem({
        key: 'token',
        storageType: 'local',
        values: { token: response.token },
      });

      storage.setItem({
        key: 'refreshToken',
        storageType: 'local',
        values: response.refreshToken,
      });

      storage.setItem({
        key: 'refreshToken',
        storageType: 'session',
        values: response.refreshToken,
      });
    }
    useUserStore.setState({ user });
    storage.setItem({
      key: 'user',
      storageType: 'session',
      values: { user },
    });

    storage.setItem({
      key: 'token',
      storageType: 'session',
      values: { token: response.token },
    });

    storage.setItem({
      key: 'refreshToken',
      storageType: 'session',
      values: response.refreshToken,
    });
  } else {
    addNotification({
      type: 'error',
      title: 'Erro',
      message: 'Erro de autenticação',
    });
  }

  return response;
}
