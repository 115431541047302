import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { DriversValues } from '../types';

type UseDriverOptions = {
  id: string;
  config?: QueryConfig<typeof getDriver>;
};

// AxiosResponse<CompanyValues>

export const getDriver = async ({
  id,
}: {
  id: string;
}): Promise<DriversValues | any> => {
  const response = await axios.get(`drivers/get/${id}`);
  return response.data.payload;
};

export const useDriver = ({ id, config }: UseDriverOptions) => {
  return useQuery({
    queryKey: ['drivers', id],
    queryFn: () => getDriver({ id }),
    ...config,
  });
};
