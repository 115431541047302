import { PageLayout } from '@/components';
import { Folder } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { ReportsListTable } from '../components/ReportsListTable';

export function ReportsList() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Relatórios"
        subTitle={subTitle}
        icon={<Folder fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <ReportsListTable />
      </PageLayout>
    </>
  );
}
