import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { IRecurringTrip } from '../types';

interface IRequest {
  tripId: string;
  manualInitialOdometer?: number;
  manualFinalOdometer?: number;
  manualStartOdometer?: number;
}

export async function updateFinishedTrip(
  data: IRequest,
): Promise<IRecurringTrip> {
  const response = await axios.put(
    `/trips/updateFinishedTrip/${data.tripId}`,
    data,
  );
  return response.data.payload;
}

export const useUpdateFinishedTrip = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('trips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Viagem alterada com sucesso',
      });
    },
    mutationFn: updateFinishedTrip,
  });
};
