import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';
import { listSectorsResponse } from '../types';

interface IRequest {
  id: string;
  name: string;
  phone: string;
  status: number;
  limitOfTrips?: number;
  limitOfCosts?: number;
}

export async function createSectorInTripRequest(
  data: IRequest,
): Promise<listSectorsResponse> {
  const response = await axios.post('/sectors/create', data);
  return response.data.payload;
}

export const useCreateSectorInTripRequest = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newSector => {
      await queryClient.cancelQueries('sectors');

      const previoussectors = queryClient.getQueryData<any[]>('sectors');

      queryClient.setQueryData('sectors', [
        ...(previoussectors || []),
        newSector,
      ]);

      return previoussectors;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('sectors');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Setor criada com sucesso.',
      });
    },
    mutationFn: createSectorInTripRequest,
  });
};
