import { Link, Table } from '@/components';

import { EditSharp, DeleteSharp } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { IProfile } from '../types';

interface IProps {
  tableData: IProfile[];
  toggleDelete: (id: string) => void;
  loading: boolean;
}

export function ProfileList({ tableData = [], toggleDelete, loading }: IProps) {
  return (
    <Table<IProfile>
      columns={[
        { title: 'Nome', field: 'name', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id } }) {
            return (
              <>
                <Link to={`./${id}`}>
                  <Tooltip title="Editar">
                    <IconButton>
                      <EditSharp sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title="Inativar">
                  <IconButton onClick={() => toggleDelete(id)}>
                    <DeleteSharp sx={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      ]}
      data={tableData?.sort((a, b) => a?.name?.localeCompare(b?.name))}
      loading={loading}
    />
  );
}
