import { nanoid } from 'nanoid';
import create from 'zustand';

export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: any;
  handler?: () => void;
};

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationsStore>(set => ({
  notifications: [],
  addNotification: notification =>
    set(state => ({
      notifications: [
        ...state.notifications,
        { id: nanoid(), ...notification },
      ],
    })),
  dismissNotification: id =>
    set(state => ({
      notifications: state.notifications.filter(
        notification => notification.id !== id,
      ),
    })),
}));
