import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import Logo from '@/assets/img/logo.png';
import { useState } from 'react';
import { useCreateVoucher } from '../api';
import { listUserResponse } from '../types';

export const CreateVoucher = ({
  user,
  vouchers,
}: {
  user: listUserResponse;
  vouchers: { value: number; title: string }[];
}) => {
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucher, setVoucher] = useState(1);

  const handleSetVoucher = (value: number) => {
    setVoucher(value);
    setVoucherValue(0);
  };

  const handleVoucherValue = (value: number) => {
    if (value < 0) return;
    setVoucherValue(value);
  };

  const { mutateAsync, isLoading } = useCreateVoucher();
  const handleSave = ({
    userId,
    value,
    status,
  }: {
    userId: string;
    value: number;
    status: number;
  }) => {
    mutateAsync({
      userId,
      value,
      status,
    });
  };
  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <Box
        sx={{
          width: '600px',
          height: '200px',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
          background:
            'linear-gradient(105.31deg, #02F7F1 0.9%, #2998FA 118.4%, #0D8EFF 205.99%)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          justifyContent: 'space-between',
          padding: '10px',
          alignItems: 'start',
          color: '#FFF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Box>
            <img src={Logo} alt="logo" />
            <Typography sx={{ fontWeight: 'bold' }}>Voucher card</Typography>
          </Box>

          <Typography sx={{ fontWeight: 'bold', fontSize: '22px' }}>
            {voucher === 2 ? `R$ ${voucherValue || 0}` : 'Ilimitado'}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
            Nome
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            {user?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <Typography>Configurações</Typography>
        <Box
          sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <TextField
            style={{ width: '200px' }}
            size="small"
            type="text"
            select
            label="Tipo de Voucher"
            value={voucher}
            onChange={e => handleSetVoucher(parseFloat(e.target.value))}
          >
            {vouchers.map(voucher => (
              <MenuItem key={voucher.value} value={voucher.value}>
                {voucher.title}
              </MenuItem>
            ))}
          </TextField>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
            }}
          >
            {voucher === 2 && (
              <TextField
                placeholder="R$ 20,00"
                sx={{ marginTop: '10px' }}
                size="small"
                type="number"
                value={voucherValue}
                onChange={e => handleVoucherValue(parseFloat(e.target.value))}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                sx={{ width: '100%' }}
                variant="contained"
                color="success"
                disabled={isLoading}
                onClick={() =>
                  handleSave({
                    userId: user.id,
                    value: voucherValue,
                    status: voucher,
                  })
                }
              >
                {isLoading ? 'Carregando...' : 'Salvar'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
