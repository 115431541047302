import { ContentLayout } from '@/components';
import { CreatePrice as CreatePriceForm } from '../components/CreatePriceList';

export function CreatePrice() {
  return (
    <ContentLayout>
      <CreatePriceForm />
    </ContentLayout>
  );
}
