/* eslint-disable no-nested-ternary */
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Modal } from '@/components';
import { VersionUpdates } from '@/features/home';
import robot from '@/assets/json/robot.json';
import robotDark from '@/assets/json/robotDark.json';
import Lottie from 'lottie-react';
import { Info } from '@mui/icons-material';
import { useAuth } from '@/providers/auth';
import { useDisclosure } from '@/hooks/useDisclosure';

import { LineChart } from '@/features/reports/components/Dashboard/LineChart';
import { BarChart } from '@/features/reports/components/Dashboard/BarChart';
import { PieChart } from '@/features/dashboard/components/PieChart';
import { Chart } from '@/features/reports/components/Dashboard/Chart';
import { ChangePasswordForm } from '@/components/ChangePasswordForm';
import { useUserStore } from '@/stores';
import { storage } from '@/utils/storage';
import {
  useLatestTrips,
  UseCompanyIncome,
  UseGetGoogleRequests,
  useInProgressTrips,
  UseCanceledRecurringAndFinishedTrips,
} from '../api';
import { CardDatas } from '../datas';
import { ChartComponent } from './Chart';
import { DashboardCards } from './DashboardCards';
import { RoutesInformation } from './RoutesInformation';
import { InProgressTripCard } from './InProgressTripCard';

export const DashBoard = () => {
  const { user } = useAuth();
  const { data: inProgressData, isLoading } = useInProgressTrips();
  const [needsToChangePassword, setNeedsToChangePassword] = useState(
    user.user.needToChangePassword,
  );
  const {
    open: openRoutesInfo,
    isOpen: isRoutesInfoOpened,
    close: closeRoutesInfo,
  } = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: latestTrips } = useLatestTrips();
  const { data: companyIncomes } = UseCompanyIncome();
  const { data: canceledRecurringAndFinishedTrips } =
    UseCanceledRecurringAndFinishedTrips();
  const { cardDatas, driversData, usersData } = CardDatas();
  const { data: googleRequests } = UseGetGoogleRequests();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const close = () => {
    setIsOpen(false);
  };
  const [selectedTotalTripsChartOption, setSelectedTotalTripsChartOption] =
    useState<'canceled' | 'recurring' | 'finished' | 'all'>('canceled');

  const totalTripsChartOptions = {
    canceled: {
      label: 'Viagens Canceladas',
      color: '#E45959',
      gradients: ['rgba(246, 104, 104, 1)', 'rgba(228, 89, 89, 0)'],
    },
    recurring: {
      label: 'Viagens Recorrentes',
      color: '#1C869D',
      gradients: ['rgba(38, 169, 177, 1)', 'rgba(28, 149, 157, 0)'],
    },
    finished: {
      label: 'Solitações finalizadas',
      color: '#1C659D',
      gradients: ['rgba(39, 131, 202, 1)', 'rgba(28, 101, 157, 0)'],
    },
    all: {
      label: 'Todas as viagens',
      color: '#1C659D',
      gradients: ['rgba(39, 131, 202, 1)', 'rgba(28, 101, 157, 0)'],
    },
  };
  return (
    <>
      {needsToChangePassword && (
        <Modal
          dialogContent={
            <ChangePasswordForm
              onSuccess={() => {
                useUserStore.setState({
                  user: {
                    user: {
                      ...user.user,
                      needToChangePassword: false,
                    },
                  },
                });
                const hasLocalStorage = storage.getItem({
                  key: 'user',
                  storageType: 'local',
                });
                if (hasLocalStorage) {
                  storage.setItem({
                    key: 'user',
                    storageType: 'local',
                    values: {
                      user: {
                        ...user.user,
                        needToChangePassword: false,
                      },
                    },
                  });
                }
                const hasSessionStorage = storage.getItem({
                  key: 'user',
                  storageType: 'session',
                });

                if (hasSessionStorage) {
                  storage.setItem({
                    key: 'user',
                    storageType: 'session',
                    values: {
                      user: {
                        ...user.user,
                        needToChangePassword: false,
                      },
                    },
                  });
                }
                setNeedsToChangePassword(false);
              }}
            />
          }
          title="Você precisa alterar sua senha"
          open={needsToChangePassword}
          size="xs"
          onClose={() => {}}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          right: '15px',
          zIndex: 990,
        }}
      >
        <Tooltip title="Atualizações">
          <IconButton>
            {theme.palette.mode === 'dark' ? (
              <Lottie
                style={{ width: '80px' }}
                onClick={() => setIsOpen(true)}
                animationData={robotDark}
              />
            ) : (
              <Lottie
                style={{ width: '80px' }}
                onClick={() => setIsOpen(true)}
                animationData={robot}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {isOpen && (
        <Modal
          title="Atualizações"
          dialogContent={<VersionUpdates />}
          open={isOpen}
          size="lg"
          onClose={close}
        />
      )}
      {user.user.accessType === 'master' && (
        <Tooltip
          sx={{ position: 'absolute', top: 10, right: 0 }}
          title="Informações das rotas"
        >
          <IconButton onClick={() => openRoutesInfo()}>
            <Info sx={{ color: '#F28608' }} />
          </IconButton>
        </Tooltip>
      )}
      <Modal
        style={{
          background: 'transparent',
          boxShadow: 'none',
        }}
        dialogContent={<RoutesInformation />}
        open={isRoutesInfoOpened}
        size="xl"
        onClose={closeRoutesInfo}
      />
      <Box
        sx={{
          background: theme.palette.mode === 'dark' ? '#212121' : '#FFF',
          display: 'flex',
          flexDirection: isSm ? 'column' : 'row',
          padding: isLg ? '0 10px' : '0px 80px',
          flex: 1,
        }}
      >
        <Box
          sx={{
            background: theme.palette.mode === 'dark' ? '#212121' : '#FFF',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Grid
            container
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginTop: isMobile ? '0px' : '-20px',
            }}
          >
            {cardDatas.map(data => (
              <DashboardCards
                driverData={driversData?.payload?.data}
                userData={usersData?.payload?.data}
                key={data.title}
                amount={data.amount}
                icon={data.icon}
                percentage={data.percentage}
                percentageColor={data.percentageColor}
                percentageDescription={data.percentageDescription}
                title={data.title}
                isLoading={data?.isLoading}
              />
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'light' ? '#cfcfcf' : '#353535',
              height: '280px',
            }}
          >
            <LineChart
              label="Faturamento"
              rangeType="year"
              chartValues={companyIncomes ? companyIncomes[0]?.amount : []}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'light' ? '#cfcfcf' : '#353535',
              height: '280px',
            }}
          >
            <BarChart
              label="Solicitações de viagens por mês"
              rangeType="year"
              chartValues={
                latestTrips
                  ? latestTrips?.reduce((acc: any, obj) => {
                      obj.amount.forEach((value, index) => {
                        acc[index] = (acc[index] || 0) + value;
                      });
                      return acc;
                    }, [])
                  : []
              }
            />
          </Box>

          {user.user.accessType === 'master' && (
            <ChartComponent
              percentageColor="green"
              percentage=""
              title="Requisições por mês"
              chartBg={theme.palette.mode === 'dark' ? '#21548E' : '#3E91F2'}
              lastUpdate="Atualizado há 1 minuto"
              type="bar"
              data={googleRequests}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#cfcfcf' : '#353535',
            width: '100%',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <InProgressTripCard
            isLoading={isLoading}
            inProgressData={inProgressData}
          />
          {user.user.accessType !== 'master' && (
            <Box
              sx={{
                flex: 1,
                marginBottom: '10px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  height: '250px',
                  display: 'flex',
                }}
              >
                {selectedTotalTripsChartOption === 'all' ? (
                  <Chart
                    legend
                    chartType="line"
                    customDataset={[
                      {
                        label: 'Canceladas',
                        data:
                          canceledRecurringAndFinishedTrips?.canceledTripsOfTheYear ||
                          [],
                        borderColor: 'rgba(228, 89, 89, 1)',
                        backgroundColor: (context: any) => {
                          const { ctx } = context.chart;
                          const gradient = ctx.createLinearGradient(
                            0,
                            0,
                            0,
                            250,
                          );
                          gradient.addColorStop(0, 'rgba(246, 104, 104, 1)');
                          gradient.addColorStop(1, 'rgba(228, 89, 89, 0)');
                          return gradient;
                        },
                        fill: false,
                        yAxisID: 'y',
                        tension: 0.5,
                      },
                      {
                        label: 'Solicitadas finalizadas',
                        data:
                          canceledRecurringAndFinishedTrips?.finishedNotRecurringTripsOfTheYear ||
                          [],
                        borderColor: 'rgba(28, 101, 157, 1)',
                        backgroundColor: (context: any) => {
                          const { ctx } = context.chart;
                          const gradient = ctx.createLinearGradient(
                            0,
                            0,
                            0,
                            250,
                          );
                          gradient.addColorStop(0, 'rgba(39, 131, 202, 1)');
                          gradient.addColorStop(1, 'rgba(28, 101, 157, 0)');
                          return gradient;
                        },
                        fill: false,
                        yAxisID: 'y',
                        tension: 0.5,
                      },
                      {
                        label: 'Recorrentes',
                        data:
                          canceledRecurringAndFinishedTrips?.finishedRecurringTripsOfTheYear ||
                          [],
                        borderColor: 'rgba(28, 134, 157, 1)',
                        backgroundColor: (context: any) => {
                          const { ctx } = context.chart;
                          const gradient = ctx.createLinearGradient(
                            0,
                            0,
                            0,
                            250,
                          );
                          gradient.addColorStop(0, 'rgba(38, 169, 177, 1)');
                          gradient.addColorStop(1, 'rgba(28, 149, 157, 0)');
                          return gradient;
                        },
                        fill: false,
                        yAxisID: 'y',
                        tension: 0.5,
                      },
                    ]}
                    rangeType="year"
                    label="Todas as viagens"
                    chartValues={{}}
                  />
                ) : (
                  <LineChart
                    label={
                      totalTripsChartOptions[selectedTotalTripsChartOption]
                        .label
                    }
                    rangeType="year"
                    color={
                      totalTripsChartOptions[selectedTotalTripsChartOption]
                        .color
                    }
                    gradients={
                      totalTripsChartOptions[selectedTotalTripsChartOption]
                        .gradients
                    }
                    chartValues={
                      canceledRecurringAndFinishedTrips
                        ? selectedTotalTripsChartOption === 'canceled'
                          ? canceledRecurringAndFinishedTrips.canceledTripsOfTheYear
                          : selectedTotalTripsChartOption === 'recurring'
                          ? canceledRecurringAndFinishedTrips.finishedRecurringTripsOfTheYear
                          : canceledRecurringAndFinishedTrips.finishedNotRecurringTripsOfTheYear
                        : []
                    }
                  />
                )}
              </Box>
              <PieChart
                setSelectedTotalTripsChartOption={
                  setSelectedTotalTripsChartOption
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
