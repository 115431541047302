/* eslint-disable no-param-reassign */
import { useState } from 'react';
import * as z from 'zod';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '@/utils/maskShared';

import { Form, InputField } from '@/components/Form';
import { useCreateMaster, useRoles } from '@/features/companyNew/api';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { useAuth } from '@/providers/auth';
import { Controller } from 'react-hook-form';
import { useNotificationStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { CreateCompanyNewDTO } from '../types';

const schema = z.object({
  corporateName: z.string(),

  email: z.string().email('Email inválido'),
  password: z
    .string()
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  fantasyName: z.string(),
  industry: z.string(),
  registeredNumber: z
    .string()
    .max(18, 'Máximo 18 caracteres')
    .min(18, 'Minimo 18 caracteres'),
  personToContact: z.string(),
  phone: z
    .string()
    .min(14, 'Minimo 14 caracteres')
    .max(15, 'Máximo de 15 caracteres'),
  zipCode: z.string().max(9, 'Máximo 9 caracteres'),
  address: z.string(),
  number: z.string(),
  city: z.string(),
  state: z.string(),
  neighborhood: z.string(),
  pricePerKm: z.string(),
  cancelationPrice: z.string(),
  initialPrice: z.string(),
  driverFees: z.string().optional(),
  payment: z.string(),
  multiply: z.number(),
  chargeInitialDisplacement: z.number().default(1),
  userEmail: z.string(),
  name: z.string(),
  nightAdditional: z.string().optional(),
  pricePerMinuteBeforeStart: z.string().optional(),
  pricePerMinute: z.string().optional(),
  timeToWaitBeforeStartBillingWhenTheTripIsStarted: z.string().optional(),
  timeToWaitBeforeStartBillingWhenTheTripIsNotStarted: z.string().optional(),
  userPhone: z
    .string()
    .min(14, 'Minimo 14 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z
    .string()
    .min(11, 'Deve ter pelo menos 11 caracteres'),
});

export function CreateCompanyNewForm() {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [trackerId, setTrackerId] = useState('');
  const { data, isLoading } = useRoles();
  const { mutateAsync, isLoading: loadingAdd } = useCreateMaster();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const paymentOptions = [
    {
      value: 'Padrão',
      label: 'Padrão',
    },
    {
      value: 'Tabelado',
      label: 'Tabelado',
    },
    {
      value: 'Tarifado',
      label: 'Tarifado',
    },
  ];

  const multiplyOptions = [
    {
      value: 2,
      label: 'Sim',
    },
    {
      value: 0,
      label: 'Não',
    },
  ];

  const chargeInitialDisplacementOptions = [
    {
      value: 1,
      label: 'Sim',
    },
    {
      value: 0,
      label: 'Não',
    },
  ];

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const [roles, setRoles] = useState<string[]>([]);

  const handlePermitions = (role: { type: string; id: string }) => {
    const findRole = roles.find(data => data === role.id);

    if (findRole) {
      setRoles(data => data.filter(info => info !== role.id));
    } else {
      setRoles([...roles, role.id]);
    }
  };

  return (
    <Box
      sx={{ background: theme.palette.mode === 'dark' ? '#212121' : '#FFF' }}
    >
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <Box>
          <Form<CreateCompanyNewDTO, typeof schema>
            id="create-companyNew"
            onSubmit={values => {
              if (
                !values.pricePerKm ||
                !values.cancelationPrice ||
                !values.initialPrice
              ) {
                addNotification({
                  message: 'Preencha os campos de preço',
                  type: 'error',
                  title: 'Erro',
                });
                return;
              }
              const data = {
                ...values,
                initialPrice: parseFloat(values.initialPrice).toFixed(2),
                pricePerKm: parseFloat(values.pricePerKm).toFixed(2),
                cancelationPrice: parseFloat(values.cancelationPrice).toFixed(
                  2,
                ),
                status: true,
                roles,
                trackerId,
              };
              mutateAsync(data);
            }}
            schema={schema}
          >
            {({ register, formState, control }) => (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'red',
                    fontSize: '22px',
                  }}
                >
                  {user.user.accessType === 'master'
                    ? 'Cadastro da locatária'
                    : 'Cadastro da empresa'}
                </Typography>
                <Grid container>
                  <Grid sm={4} xs={12} item>
                    <Box className={classes.wrapper}>
                      <InputField
                        size="small"
                        name="email"
                        type="email"
                        label="Email"
                        registration={register('email')}
                        error={!!formState.errors.email}
                        errorMessage={formState.errors.email?.message}
                        placeholder="Email"
                      />

                      <InputField
                        size="small"
                        name="corporateName"
                        type="text"
                        label="Razão Social"
                        registration={register('corporateName')}
                        error={!!formState.errors.corporateName?.message}
                        errorMessage={formState.errors.corporateName?.message}
                        placeholder="Nome real"
                      />

                      <InputField
                        size="small"
                        name="fantasyName"
                        type="text"
                        label="Nome Fantasia"
                        registration={register('fantasyName')}
                        placeholder="Nome"
                        error={!!formState.errors.fantasyName}
                        errorMessage={formState.errors.fantasyName?.message}
                      />
                      <InputField
                        size="small"
                        name="industry"
                        type="text"
                        label="Ramo"
                        placeholder="Ramo de atuação"
                        registration={register('industry')}
                        error={!!formState.errors.industry}
                        errorMessage={formState.errors.industry?.message}
                      />
                      <InputField
                        size="small"
                        name="registeredNumber"
                        type="text"
                        label="CNPJ"
                        placeholder="99.999.999/9999-99"
                        registration={register('registeredNumber')}
                        inputProps={{ minLength: 18, maxLength: 18 }}
                        error={!!formState.errors.registeredNumber}
                        errorMessage={
                          formState.errors.registeredNumber?.message
                        }
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = cnpjMask(value);
                        }}
                      />
                      <InputField
                        size="small"
                        name="personToContact"
                        type="text"
                        label="Pessoa para contato"
                        placeholder="Pessoa para contato"
                        registration={register('personToContact')}
                        error={!!formState.errors.personToContact}
                        errorMessage={formState.errors.personToContact?.message}
                      />
                      <InputField
                        size="small"
                        name="phone"
                        type="text"
                        label="Telefone"
                        placeholder="(99) 99999-9999"
                        inputProps={{ minLength: 14, maxLength: 15 }}
                        registration={register('phone')}
                        error={!!formState.errors.phone}
                        errorMessage={formState.errors.phone?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = phoneMask(
                            value.replace(/[^\d]+/g, ''),
                          );
                        }}
                      />
                      <InputField
                        size="small"
                        name="zipCode"
                        type="text"
                        label="CEP"
                        placeholder="99999-999"
                        inputProps={{ minLength: 9, maxLength: 9 }}
                        registration={register('zipCode')}
                        error={!!formState.errors.zipCode}
                        errorMessage={formState.errors.zipCode?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = cepMask(
                            value.replace(/(\d{5})(\d)/, '$1-$2'),
                          );
                        }}
                      />

                      <Controller
                        name="address"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="address"
                            type="text"
                            label="Endereço"
                            onChange={onChange}
                            value={value}
                            placeholder="Endereço"
                            errorMessage={formState.errors.address?.message}
                            error={!!formState.errors.address}
                          />
                        )}
                      />

                      <InputField
                        size="small"
                        name="number"
                        type="text"
                        label="Número"
                        placeholder="Número"
                        registration={register('number')}
                        error={!!formState.errors.number}
                        errorMessage={formState.errors.number?.message}
                      />

                      <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="city"
                            type="text"
                            label="Cidade"
                            onChange={onChange}
                            value={value}
                            placeholder="Cidade"
                            errorMessage={formState.errors.city?.message}
                            error={!!formState.errors.city}
                          />
                        )}
                      />

                      <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="state"
                            type="text"
                            label="Estado"
                            onChange={onChange}
                            value={value}
                            placeholder="Estado"
                            errorMessage={formState.errors.state?.message}
                            error={!!formState.errors.state}
                          />
                        )}
                      />

                      <Controller
                        name="neighborhood"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="neighborhood"
                            type="text"
                            label="Bairro"
                            onChange={onChange}
                            value={value}
                            placeholder="Bairro"
                            errorMessage={
                              formState.errors.neighborhood?.message
                            }
                            error={!!formState.errors.neighborhood}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Box className={classes.wrapper}>
                      <>
                        {user.user.accessType === 'master' && (
                          <Controller
                            name="driverFees"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputField
                                InputProps={{
                                  inputProps: { min: 0, step: 0.01 },
                                }}
                                size="small"
                                name="driverFees"
                                type="number"
                                label="Taxa do motorista"
                                onChange={onChange}
                                value={value}
                                placeholder="0"
                                errorMessage={
                                  formState.errors.driverFees?.message
                                }
                                error={!!formState.errors.driverFees}
                              />
                            )}
                          />
                        )}
                        <Controller
                          name="initialPrice"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: 0.01 },
                              }}
                              size="small"
                              name="initialPrice"
                              type="number"
                              label="Preço inicial"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.initialPrice?.message
                              }
                              error={!!formState.errors.initialPrice}
                            />
                          )}
                        />

                        <Controller
                          name="pricePerKm"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: 0.01 },
                              }}
                              size="small"
                              name="pricePerKm"
                              type="number"
                              label="Preço por km"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.pricePerKm?.message
                              }
                              error={!!formState.errors.pricePerKm}
                            />
                          )}
                        />

                        <Controller
                          name="cancelationPrice"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: 0.01 },
                              }}
                              size="small"
                              name="cancelationPrice"
                              type="number"
                              label="Preço por cancelamento"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.cancelationPrice?.message
                              }
                              error={!!formState.errors.cancelationPrice}
                            />
                          )}
                        />

                        <Controller
                          name="pricePerMinuteBeforeStart"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: 0.01 },
                              }}
                              size="small"
                              name="pricePerMinuteBeforeStart"
                              type="number"
                              label="Preço por minuto esperado (antes de iniciar a corrida)"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.pricePerMinuteBeforeStart
                                  ?.message
                              }
                              error={
                                !!formState.errors.pricePerMinuteBeforeStart
                              }
                            />
                          )}
                        />
                        <Controller
                          name="pricePerMinute"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: 0.01 },
                              }}
                              size="small"
                              name="pricePerMinute"
                              type="number"
                              label="Preço por minuto esperado (depois de iniciar a corrida)"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.pricePerMinute?.message
                              }
                              error={!!formState.errors.pricePerMinute}
                            />
                          )}
                        />
                        <Controller
                          name="timeToWaitBeforeStartBillingWhenTheTripIsNotStarted"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              size="small"
                              name="timeToWaitBeforeStartBillingWhenTheTripIsNotStarted"
                              type="number"
                              label="Tempo de tolerância antes de iniciar a corrida (em minutos)"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors
                                  .timeToWaitBeforeStartBillingWhenTheTripIsNotStarted
                                  ?.message
                              }
                              error={
                                !!formState.errors
                                  .timeToWaitBeforeStartBillingWhenTheTripIsNotStarted
                              }
                            />
                          )}
                        />
                        <Controller
                          name="timeToWaitBeforeStartBillingWhenTheTripIsStarted"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              size="small"
                              name="timeToWaitBeforeStartBillingWhenTheTripIsStarted"
                              type="number"
                              label="Tempo de tolerância depois de iniciar a corrida (em minutos)"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors
                                  .timeToWaitBeforeStartBillingWhenTheTripIsStarted
                                  ?.message
                              }
                              error={
                                !!formState.errors
                                  .timeToWaitBeforeStartBillingWhenTheTripIsStarted
                              }
                            />
                          )}
                        />

                        <Controller
                          name="nightAdditional"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              size="small"
                              name="nightAdditional"
                              type="number"
                              label="Adicional noturno (em %)"
                              onChange={onChange}
                              value={value}
                              placeholder="0"
                              errorMessage={
                                formState.errors.nightAdditional?.message
                              }
                              error={!!formState.errors.nightAdditional}
                            />
                          )}
                        />

                        <InputField
                          size="small"
                          name="chargeInitialDisplacement"
                          select
                          type="text"
                          label="Cobrar deslocamento inicial?"
                          registration={register('chargeInitialDisplacement')}
                          error={!!formState.errors.chargeInitialDisplacement}
                          errorMessage={
                            formState.errors.chargeInitialDisplacement?.message
                          }
                        >
                          {chargeInitialDisplacementOptions.map(option => (
                            <MenuItem key={option.label} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>

                        <InputField
                          size="small"
                          name="payment"
                          type="text"
                          select
                          label="Método de cobrança"
                          registration={register('payment')}
                          error={!!formState.errors.payment}
                          errorMessage={formState.errors.payment?.message}
                        >
                          {paymentOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>

                        <InputField
                          size="small"
                          name="multiply"
                          select
                          type="text"
                          label="Cobrar retorno à base?"
                          registration={register('multiply')}
                          error={!!formState.errors.multiply}
                          errorMessage={formState.errors.multiply?.message}
                        >
                          {multiplyOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </>
                    </Box>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Box className={classes.wrapper}>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? 'white' : 'red',
                          fontSize: '22px',
                        }}
                      >
                        Cadastro do usuário administrador
                      </Typography>
                      <InputField
                        size="small"
                        name="userEmail"
                        type="text"
                        label="Login"
                        placeholder="Login do usuário"
                        registration={register('userEmail')}
                        error={!!formState.errors.userEmail}
                        errorMessage={formState.errors.userEmail?.message}
                      />
                      <InputField
                        size="small"
                        name="password"
                        type="password"
                        label="Senha"
                        registration={register('password')}
                        error={!!formState.errors.password}
                        errorMessage={formState.errors.password?.message}
                        placeholder="Senha para acesso"
                      />
                      <InputField
                        size="small"
                        name="name"
                        type="text"
                        label="Nome"
                        placeholder="Nome do usuário"
                        registration={register('name')}
                        error={!!formState.errors.name}
                        errorMessage={formState.errors.name?.message}
                      />
                      <InputField
                        size="small"
                        name="userPhone"
                        type="text"
                        label="Telefone"
                        placeholder="(99) 99999-9999"
                        inputProps={{ minLength: 14, maxLength: 15 }}
                        registration={register('userPhone')}
                        error={!!formState.errors.userPhone}
                        errorMessage={formState.errors.userPhone?.message}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = phoneMask(
                            value.replace(/[^\d]+/g, ''),
                          );
                        }}
                      />
                      <InputField
                        size="small"
                        name="individualRegistration"
                        type="text"
                        label="CPF"
                        placeholder="999.999.999-99"
                        inputProps={{ minLength: 14, maxLength: 15 }}
                        registration={register('individualRegistration')}
                        error={!!formState.errors.individualRegistration}
                        errorMessage={
                          formState.errors.individualRegistration?.message
                        }
                        onChange={(event: any) => {
                          const { value } = event.target;
                          event.target.value = cpfMask(value);
                        }}
                      />
                      {user.user.accessType === 'master' && (
                        <>
                          <Box sx={{ margin: '10px' }}>
                            <Typography
                              sx={{
                                color:
                                  theme.palette.mode === 'dark'
                                    ? 'white'
                                    : 'red',
                                fontSize: '22px',
                              }}
                            >
                              Permissões
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {isLoading ? (
                                <Box>Carregando</Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    marginTop: '10px',
                                  }}
                                >
                                  {data?.map((role: any) => (
                                    <FormControlLabel
                                      sx={{ flex: '1' }}
                                      key={role.id}
                                      control={
                                        <Checkbox
                                          checked={
                                            !!roles.find(
                                              data => data === role.id,
                                            )
                                          }
                                          onChange={() =>
                                            handlePermitions(role)
                                          }
                                        />
                                      }
                                      label={role.type}
                                    />
                                  ))}
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Box sx={{ margin: '10px' }}>
                            <Typography
                              sx={{
                                color:
                                  theme.palette.mode === 'dark'
                                    ? 'white'
                                    : 'red',
                                fontSize: '22px',
                              }}
                            >
                              Id do Monitoramento
                            </Typography>
                            <TextField
                              sx={{ width: '90px' }}
                              size="small"
                              name="trackerId"
                              type="text"
                              label="Id"
                              onChange={e => setTrackerId(e.target.value)}
                              value={trackerId}
                              placeholder="111"
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>

                  <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={loadingAdd}
                    >
                      {loadingAdd ? 'Carregando...' : 'Adicionar'}
                    </Button>
                    <Button
                      onClick={() => navigate(-1)}
                      variant="contained"
                      color="primary"
                      disabled={loadingAdd}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Form>
        </Box>
      </Paper>
    </Box>
  );
}
