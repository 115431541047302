import { Search } from '@mui/icons-material';
import {
  Box,
  FormGroup,
  FormControl,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useMapConfig } from '@/providers/mapConfig';
import { useAuth } from '@/providers/auth';
import { driversProps } from '../types';
import { DriversList } from './DriversList';

type props = {
  map: any;
  drivers: driversProps[];
  setClickedDriver: (driver: driversProps) => void;
  close: () => void;
};

export const DriversTable = ({
  drivers,
  setClickedDriver,
  close,
  map,
}: props) => {
  const [filteredDriversInProgress, setFilteredDriversInProgress] = useState<
    driversProps[]
  >([]);
  const [onlyProgress, setOnlyProgress] = useState(false);
  const { user } = useAuth();
  const { toggle } = useDisclosure();
  const [result, setResult] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { zoomMap } = useMapConfig();

  const filteredDrivers: driversProps[] = useMemo(() => {
    const filter = (result: string, drivers: driversProps[]) => {
      const filteredDrivers = drivers?.filter((driver: driversProps) =>
        Object.values(driver).some(
          value =>
            typeof value === 'string' &&
            value.toString().toLowerCase().includes(result.toLowerCase()),
        ),
      );

      return filteredDrivers;
    };
    return filter(result, drivers);
  }, [result, drivers]);

  useEffect(() => {
    if (onlyProgress) {
      const filteredValues = drivers?.filter(
        (driver: driversProps) => driver.driverInProgress === true,
      );
      setFilteredDriversInProgress(filteredValues);
    }
  }, [drivers, onlyProgress]);

  const handleClick = (driver: driversProps) => {
    setClickedDriver(driver);
    toggle();
    close();
    zoomMap(driver.latitude, driver.longitude, map);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '10px',
        }}
      >
        <FormGroup>
          <FormControl>
            <OutlinedInput
              sx={{
                height: '55%',
                marginTop: '7px',
                width: isMobile ? '55%' : '100%',
                borderRadius: '10px',
                marginBottom: '7px',
              }}
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              value={result}
              placeholder="Pesquisar..."
              onChange={e => setResult(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
          {!isMobile && user.user.accessType === 'renter' && (
            <FormControlLabel
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              }}
              control={
                <Checkbox
                  onChange={() => setOnlyProgress(prev => !prev)}
                  checked={onlyProgress}
                />
              }
              label={<Typography variant="body2">Em viagem</Typography>}
            />
          )}
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          overflowY: 'hidden',
          '&:hover': {
            overflowY: 'auto',
          },

          '&::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightBlue',
            borderRadius: 2,
          },
        }}
      >
        {onlyProgress ? (
          <>
            {filteredDriversInProgress.map(driver => (
              <DriversList
                handleClick={handleClick}
                filteredDriver={driver}
                key={driver.id}
              />
            ))}
          </>
        ) : (
          <>
            {filteredDrivers.map(driver => (
              <DriversList
                handleClick={handleClick}
                filteredDriver={driver}
                key={driver.id}
              />
            ))}
          </>
        )}
      </Box>
    </>
  );
};
