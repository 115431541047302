import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ICreatePriceTagDTO, IPriceTags } from '../types';

export async function createTag(data: ICreatePriceTagDTO): Promise<IPriceTags> {
  return axios.post('taggedAdresses/createPriceTag', data);
}

export const useCreateTag = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newDriver => {
      await queryClient.cancelQueries('tags');

      const previousDrivers = queryClient.getQueryData<IPriceTags[]>('tags');

      queryClient.setQueryData('tags', [...(previousDrivers || []), newDriver]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('tags', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('tags');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Tarifa cadastrada com sucesso.',
      });
      navigate(-1);
    },
    mutationFn: createTag,
  });
};
