import { Cancel, Check, Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  UseGetMostCalledRoutes,
  UseGetRoutesErrors,
  UseGetSlowRoutes,
} from '../api';
import { RouteInformation } from './RouteInformation';

export const RoutesInformation = () => {
  const { data: slowRoutes, isLoading: isLoadingSlowRoutes } =
    UseGetSlowRoutes();
  const { data: mostCalledRoutes, isLoading: isLoadingMostCalledRoutes } =
    UseGetMostCalledRoutes();
  const { data: errors, isLoading: isLoadingErrors } = UseGetRoutesErrors();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <RouteInformation
          isLoading={isLoadingMostCalledRoutes}
          data={mostCalledRoutes || []}
          background="#EEEEEE"
          icon={<Check sx={{ color: '#17AE53' }} />}
          title="Rotas mais chamadas"
        />
        <RouteInformation
          isLoading={isLoadingErrors}
          data={errors?.clientErrors || []}
          icon={<Cancel sx={{ color: '#B81818' }} />}
          background="#FFDEDE"
          title="Rotas com mais erros do cliente"
        />
        <RouteInformation
          isLoading={isLoadingErrors}
          data={errors?.serverErrors || []}
          icon={<Cancel sx={{ color: '#B81818' }} />}
          background="#FFDEDE"
          title="Rotas com mais erros internos"
        />
        <RouteInformation
          isLoading={isLoadingSlowRoutes}
          background="#FFE4CB"
          data={slowRoutes || []}
          icon={<Info sx={{ color: '#F28608' }} />}
          title="Rotas mais lentas"
        />
      </Box>
    </>
  );
};
