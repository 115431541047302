import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { IVehicleClass } from '../types';

interface IRequest {
  id: string;
}

const getClass = async ({ id }: IRequest): Promise<IVehicleClass> => {
  const response = await axios.get(`/vehicleClasses/getVehicleClass/${id}`);
  return response.data.payload;
};

export const useGetClass = ({ id }: IRequest) => {
  const { user } = useAuth();
  return useQuery({
    enabled:
      !!user.user.company.permissions.find(role => role.name === 'veículos') &&
      user.user.accessType === 'renter',
    queryKey: ['classes', id],
    queryFn: () => getClass({ id }),
  });
};
