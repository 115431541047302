import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { IVehicleClass } from '../types';

const listClasses = async (): Promise<IVehicleClass[]> => {
  const response = await axios.get('/vehicleClasses/listVehicleClasses');
  return response.data.payload;
};

export const useListClasses = () => {
  const { user } = useAuth();
  return useQuery({
    enabled:
      !!user.user.company.permissions.find(role => role.name === 'veículos') &&
      user.user.accessType === 'renter',
    queryKey: ['classes'],
    queryFn: listClasses,
  });
};
