/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
import { MobileSideBar, SwipeableDrawerComponent } from '@/components';
import {
  Box,
  useMediaQuery,
  useTheme,
  Backdrop,
  CircularProgress,
  Badge,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@/providers/auth';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useMapConfig } from '@/providers/mapConfig';
import { format } from 'date-fns';
import { useTrackerAlert } from '@/providers/trackerAlert';
import { SettingsButton } from '@/components/SettingsButton';
import { Warning } from '@mui/icons-material';
import { useSocket } from '@/providers/socketProvider';
import { driversProps } from '..';
import { Map } from '../components/Map';
import { useLastPositions } from '../api';
import { SwipeableDrawer } from '../components/SwipeableDrawer';
import { DriverInfo } from '../components/DriverInfo';
import { GridContainer } from '../components/GridContainer';
import { Warnings } from '../components/Warnings';

export const MapScreen = () => {
  const { map, maps } = useMapConfig();
  const {
    close: closeWarnings,
    isOpen: isWarningsOpen,
    open: openWarnings,
  } = useDisclosure();

  const [mode, setMode] = useState('default');
  const { socket } = useSocket();
  const { close, toggle, isOpen } = useDisclosure();
  const { user } = useAuth();
  const hasTracker = !!user?.user?.company.trackerId;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: lastPositions,
    refetch: refetchLastPositions,
    isLoading,
  } = useLastPositions();
  const { warnings, hasAlertRole } = useTrackerAlert();
  const [drivers, setDrivers] = useState<driversProps[]>([]);
  const [clickedDriver, setClickedDriver] = useState<
    driversProps | undefined
  >();
  const [cellphoneTracker, setCellphoneTracker] = useState(() => {
    if (hasTracker) {
      return false;
    }
    return true;
  });

  // camadas do map
  // camada de tráfego

  const trafficMap = useCallback(() => {
    const trafficLayer: any = new maps.current.TrafficLayer();
    if (trafficLayer.map) {
      trafficLayer.setMap(null);
    } else {
      trafficLayer.setMap(map.current);
    }
  }, [map, maps]);

  // camada de transporte público
  const transitMap = useCallback(() => {
    const transitLayer: any = new maps.current.TransitLayer();
    if (transitLayer.map) {
      transitLayer.setMap(null);
    } else {
      transitLayer.setMap(map.current);
    }
  }, [map, maps]);

  // camada de ciclistas
  const ciclistMap = useCallback(() => {
    const bikeLayer: any = new maps.current.BicyclingLayer();
    if (bikeLayer.map) {
      bikeLayer.setMap(null);
    } else {
      bikeLayer.setMap(map.current);
    }
  }, [map, maps]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchLastPositions();
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [cellphoneTracker, refetchLastPositions, user.user.accessType]);

  useEffect(() => {
    if (lastPositions?.length) {
      const filteredDrivers = lastPositions?.map(vehicle => {
        return {
          ...vehicle,
          id: vehicle?.driverId,
          estimatedDateToFinishTrip: vehicle?.estimatedDateToFinishTrip,
          timeInTrip: vehicle?.timeInTrip,
          started: vehicle?.tripStarted,
          users: vehicle?.users,
          driverInProgress: vehicle?.driverInProgress,
          sector: vehicle?.sector,
          corporateName: vehicle?.corporateName,
          addressTo: vehicle?.addressTo,
          speed: vehicle?.trackerInfo.speed,
          date: vehicle?.trackerInfo.date,
          urlImage: vehicle?.urlImage,
          vehicleIcon: vehicle?.vehicleIcon,
          signal: vehicle?.trackerInfo.signal,
          satellite: vehicle?.trackerInfo.satellite,
          voltage: vehicle?.trackerInfo.voltage,
          ignition: vehicle?.trackerInfo.ignition,
          latitude: cellphoneTracker
            ? vehicle?.mobileInfo.latitude
            : vehicle?.trackerInfo.latitude,
          longitude: cellphoneTracker
            ? vehicle?.mobileInfo.longitude
            : vehicle?.trackerInfo.longitude,
          direction: cellphoneTracker
            ? vehicle?.mobileInfo.direction
            : vehicle?.trackerInfo.direction,
          bateryPercent: vehicle?.trackerInfo.bateryPercent,
          lastUpdateInTheCellPhone:
            vehicle?.mobileInfo.lastUpdateInTheCellPhone,
          speedInTheCellPhone: vehicle?.mobileInfo.speedInTheCellPhone,
          isDriverTrackerEnabled: vehicle?.isDriverTrackerEnabled,
        };
      });
      setDrivers(filteredDrivers);
    }
  }, [lastPositions, cellphoneTracker]);

  socket
    ?.off('sendPosition')
    .on(
      'sendPosition',
      async (data: {
        driverId: string;
        latitude: number;
        longitude: number;
        direction: number;
        timestamp: number;
        speed: number;
      }) => {
        if (!cellphoneTracker) {
          return;
        }
        const index = drivers?.findIndex(driver => driver.id === data.driverId);
        const driver = drivers[index];

        if (!driver) return;

        driver.latitude = data.latitude;
        driver.longitude = data.longitude;
        driver.direction = data.direction;
        driver.lastUpdateInTheCellPhone = format(
          new Date(data.timestamp),
          'dd/MM/yyyy HH:mm:ss',
        );
        driver.speedInTheCellPhone =
          Number(data.speed) >= 0 ? Number(data.speed) : 0;
        driver.isDriverTrackerEnabled = true;

        setDrivers([
          ...drivers.slice(0, index),
          driver,
          ...drivers.slice(index + 1, drivers.length),
        ]);
      },
    );

  return (
    <Box>
      {isMobile && (
        <>
          <Box
            sx={{ zIndex: '999', background: theme.palette.background.default }}
          >
            <MobileSideBar />
          </Box>
        </>
      )}

      {!isLoading ? (
        <>
          <Map
            drivers={
              cellphoneTracker
                ? drivers?.filter(driver => driver?.mobileInfo?.latitude)
                : drivers?.filter(driver => driver?.trackerInfo?.latitude)
            }
          />

          <Box
            sx={{
              position: 'absolute',
              top: '55px',
              right: '15px',
              zIndex: 990,
            }}
          >
            <SwipeableDrawer
              map={map}
              setClickedDriver={setClickedDriver}
              isOpen={isOpen}
              close={close}
              toggle={toggle}
              trafficMap={trafficMap}
              transitMap={transitMap}
              ciclistMap={ciclistMap}
              drivers={
                cellphoneTracker
                  ? drivers?.filter(driver => driver?.mobileInfo?.latitude)
                  : drivers?.filter(driver => driver?.trackerInfo?.latitude)
              }
            />
          </Box>
          {isWarningsOpen && (
            <SwipeableDrawerComponent
              close={closeWarnings}
              toggle={openWarnings}
              isOpen={isWarningsOpen}
              title="Avisos"
            >
              <Warnings warnings={warnings ?? []} />
            </SwipeableDrawerComponent>
          )}

          {!isMobile ? (
            <GridContainer
              hasAlertRole={hasAlertRole}
              numberOfWarnings={warnings?.length ?? 0}
              openWarnings={openWarnings}
              setClickedDriver={setClickedDriver}
              setMode={setMode}
              cellphoneTracker={cellphoneTracker}
              hasTracker={hasTracker}
              setCellphoneTracker={setCellphoneTracker}
              mode={mode}
              map={map}
              openSettings={() => {
                toggle();
              }}
              drivers={
                cellphoneTracker
                  ? drivers?.filter(driver => driver?.mobileInfo?.latitude)
                  : drivers?.filter(driver => driver?.trackerInfo?.latitude)
              }
            />
          ) : (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '10px',
                  display: 'flex',
                  gap: 1,
                }}
              >
                {hasAlertRole && (
                  <Badge color="primary" badgeContent={warnings?.length ?? 0}>
                    <Tooltip title="Avisos">
                      <IconButton onClick={() => openWarnings()}>
                        <Warning />
                      </IconButton>
                    </Tooltip>
                  </Badge>
                )}
                <SettingsButton handleClick={() => toggle()} />
              </Box>
            </>
          )}
        </>
      ) : (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {clickedDriver && (
        <DriverInfo
          mode={mode}
          clickedDriver={clickedDriver}
          setClickedDriver={setClickedDriver}
        />
      )}
    </Box>
  );
};
