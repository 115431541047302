import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { Statement } from './StatementRoute';

export const StatementRoutes = () => {
  return (
    <>
      <Can permissions={['company_admin', 'sector_admin']}>
        <Routes>
          <Route path="" element={<Statement />} />
        </Routes>
      </Can>
    </>
  );
};
