/* eslint-disable import/no-duplicates */
import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import ptLocale from 'date-fns/locale/pt';
import { parse } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import { IWorkJourney, useUpdateWorkJourney } from '../api';

export const EditWorkJourney = ({
  data,
  closeModal,
}: {
  data: IWorkJourney;
  closeModal: () => void;
}) => {
  const { addNotification } = useNotificationStore();
  const { mutateAsync, isLoading } = useUpdateWorkJourney();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const datePrefix = data.date;
  const [observation, setObservation] = useState(data.observation);
  const [startedAt, setStartedAt] = useState<any>(
    parse(
      `${datePrefix} ${data.startedAt}`,
      'dd/MM/yyyy HH:mm:ss',
      new Date(),
    ) || null,
  );
  const [enteredLunchAt, setEnteredLunchAt] = useState<any>(
    parse(
      `${datePrefix} ${data.enteredLunchAt}`,
      'dd/MM/yyyy HH:mm:ss',
      new Date(),
    ) || null,
  );
  const [exitedLunchAt, setExitedLunchAt] = useState<any>(
    parse(
      `${datePrefix} ${data.exitedLunchAt}`,
      'dd/MM/yyyy HH:mm:ss',
      new Date(),
    ) || null,
  );
  const [finishedAt, setFinishedAt] = useState<any>(
    parse(
      `${datePrefix} ${data.finishedAt}`,
      'dd/MM/yyyy HH:mm:ss',
      new Date(),
    ) || null,
  );
  const handleSubmit = () => {
    function isDateValid(dateString: Date) {
      const date = new Date(dateString);
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(date.getTime());
    }
    if (
      !isDateValid(startedAt) ||
      !isDateValid(finishedAt) ||
      !isDateValid(enteredLunchAt) ||
      !isDateValid(exitedLunchAt)
    ) {
      addNotification({
        title: 'Preencha todos os campos',
        type: 'error',
      });
      return;
    }
    mutateAsync(
      {
        workJourneyId: data.id,
        startedAt: startedAt?.toISOString(),
        enteredLunchAt: enteredLunchAt?.toISOString(),
        exitedLunchAt: exitedLunchAt?.toISOString(),
        finishedAt: finishedAt?.toISOString(),
        observation,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Data de entrada"
            value={startedAt}
            onChange={newValue => {
              setStartedAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Entrada para almoço"
            value={enteredLunchAt}
            onChange={newValue => {
              setEnteredLunchAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Retorno do almoço"
            value={exitedLunchAt}
            onChange={newValue => {
              setExitedLunchAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Saída"
            value={finishedAt}
            onChange={newValue => {
              setFinishedAt(newValue);
            }}
            renderInput={params => (
              <TextField sx={{ zIndex: 0 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: isMobile ? '0px' : '10px',
          marginTop: '15px',
          width: '250px',
        }}
      >
        <TextField
          value={observation}
          label="Observação"
          onChange={e => setObservation(e.target.value)}
          sx={{ zIndex: 0 }}
          size="small"
        />
      </Box>
      <Button
        onClick={() => handleSubmit()}
        disabled={isLoading}
        sx={{ marginTop: '10px' }}
        variant="contained"
        color="success"
      >
        Salvar
      </Button>
    </Box>
  );
};
