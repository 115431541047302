import { createContext, ReactNode, useContext } from 'react';
import { IWarning } from '@/features/mapScreen';
import { useWarnings } from '@/features/mapScreen/api/warnings';
import useSound from 'use-sound';
import { useHasPermission } from '@/hooks/useHasPermission';
import { useNotificationStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { useSocket } from './socketProvider';

interface TrackerAlertProviderProps {
  children: ReactNode;
}

interface TrackerAlertContextData {
  warnings: IWarning[];
  refetchAlerts: () => void;
  hasAlertRole: boolean;
}

const TrackerAlertContext = createContext({} as TrackerAlertContextData);

const TrackerAlertProvider = ({
  children,
}: TrackerAlertProviderProps): JSX.Element => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const hasAlertRole = useHasPermission('alerta de ignição');
  const [play] = useSound('./alert.mp3');
  const { socket } = useSocket();
  const { data: warnings, refetch: refetchAlerts } = useWarnings();

  socket?.off('trackerAlert').on('trackerAlert', async () => {
    if (hasAlertRole) {
      refetchAlerts();
      addNotification({
        type: 'warning',
        title: 'Alerta de ignição',
        message: 'Carro ligado sem movimento',
        handler: () => {
          navigate('/mapscreen');
        },
      });
      play();
    }
  });

  return (
    <TrackerAlertContext.Provider
      value={{ warnings: warnings ?? [], refetchAlerts, hasAlertRole }}
    >
      {children}
    </TrackerAlertContext.Provider>
  );
};

function useTrackerAlert(): TrackerAlertContextData {
  const context = useContext(TrackerAlertContext);

  if (!context) {
    throw new Error(
      'useTrackerAlertProvider must be used with a TrackerAlertProvider.',
    );
  }

  return context;
}

export { TrackerAlertProvider, useTrackerAlert };
