import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Modal } from '../Modal';

export const ConfirmModal = ({
  isOpen,
  close,
  title,
  handleConfirmation,
  yesButtonBackground,
  noButtonBackground,
  description,
  isLoading = false,
}: {
  isOpen: boolean;
  close: () => void;
  title?: string;
  handleConfirmation: any;
  yesButtonBackground?: string;
  noButtonBackground?: string;
  description?: string;
  isLoading?: boolean;
}) => {
  const ConfirmComponent = ({
    close,
    handleConfirmation,
    yesButtonBackground = '#59D273',
    noButtonBackground = '#9A9A9A',
    isLoading = false,
  }: {
    close: () => void;
    handleConfirmation: any;
    yesButtonBackground?: string;
    noButtonBackground?: string;
    isLoading?: boolean;
  }) => {
    return (
      <Box>
        <Typography>{description}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            sx={{
              borderRadius: '50px',
              width: '200px',
              background: yesButtonBackground,
              transition: '0.5s ease-in-out',
              color: '#FFF',
              '&:hover': {
                opacity: '0.9',
                background: yesButtonBackground,
              },
            }}
            onClick={handleConfirmation}
          >
            Sim
          </LoadingButton>
          <Button
            disabled={isLoading}
            sx={{
              borderRadius: '50px',
              width: '200px',
              background: noButtonBackground,
              transition: '0.5s ease-in-out',
              color: '#FFF',
              '&:hover': {
                opacity: '0.9',
                background: noButtonBackground,
              },
            }}
            onClick={close}
          >
            Não
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Modal
      title={title}
      dialogContent={
        <ConfirmComponent
          close={close}
          isLoading={isLoading}
          handleConfirmation={handleConfirmation}
          yesButtonBackground={yesButtonBackground}
          noButtonBackground={noButtonBackground}
        />
      }
      open={isOpen}
      size="sm"
      onClose={close}
    />
  );
};
