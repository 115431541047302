// Arquivo para programar Mascaras compartilhadas em várias Pages.

export const cnpjMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/^(\d{2})(\d)/, '$1.$2') // após o 2 dígito inseri um "."
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // após o 5 dígito inseri um "."
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // após o 9 dígito inseri um "/"
    .replace(/(\d{4})(\d)/, '$1-$2'); // após o 4 dígito depois da "/" inseri um "-"
};

export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d)/, '$1-$2'); // após o 4 dígito depois da "/" inseri um "-"
};

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const phoneMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
};
