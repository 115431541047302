import { Box, Typography } from '@mui/material';

interface IRouteProps {
  background: string;
  route: string;
  avg?: number;
  count?: number;
}

export const Route = ({
  route,
  avg,
  count,
  background = '#EEEEEE',
}: IRouteProps) => {
  return (
    <Box
      sx={{
        padding: '10px',
        background,
        borderRadius: '5px',
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography>{route.slice(0, 40)}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {avg && <Typography>{avg.toFixed(0)} ms</Typography>}
        {count && (
          <Typography>{`${count} ${count > 1 ? 'vezes' : 'vez'}`}</Typography>
        )}
      </Box>
    </Box>
  );
};
