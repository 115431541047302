import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { IVehicle } from '../types';

const listVehicles = async (): Promise<IVehicle[]> => {
  const response = await axios.get('/vehicles/listVehicles');
  return response.data.payload;
};

export const useListVehicles = () => {
  const { user } = useAuth();
  return useQuery({
    enabled:
      !!user.user.company.permissions.find(role => role.name === 'veículos') &&
      user.user.accessType === 'renter',
    queryKey: 'vehicles',
    queryFn: listVehicles,
  });
};
