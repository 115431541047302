import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ContentLayout } from '@/components';
import { useGetClass } from '../api';
import { UpdateVehicleClassComponent } from '../components/UpdateVehicleClass';

export const UpdateVehicleClass = () => {
  const { id } = useParams() as unknown as Record<string, any>;
  const { data, isLoading } = useGetClass({ id });
  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {data && (
            <ContentLayout>
              <UpdateVehicleClassComponent vehicleClass={data} />
            </ContentLayout>
          )}
        </>
      )}
    </>
  );
};
