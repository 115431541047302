import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

export const deleteUserImage = async (userId: string) => {
  try {
    await axios.delete(`users/delete_image/${userId}`);
  } catch (error) {
    console.log(error);
    throw new Error('Erro ao excluir a imagem.');
  }
};

type UseDeleteUserImageOptions = {
  config?: MutationConfig<typeof deleteUserImage>;
};

export const useDeleteUserImage = ({
  config,
}: UseDeleteUserImageOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('users');
    },
    onError: error => {
      console.error(error);
      addNotification({
        type: 'error',
        title: 'Erro',
        message: 'Ocorreu um erro ao excluir a imagem.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Imagem excluída com sucesso.',
      });
      window.location.reload();
    },
    ...config,
    mutationFn: deleteUserImage,
  });
};
