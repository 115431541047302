import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export async function updateAppointmentDate(data: {
  tripId: string;
  newScheduledDate: Date;
}): Promise<void> {
  return axios.patch(`appointments/updateAppointmentDate/${data.tripId}`, data);
}

export const useUpdateAppointmentDate = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatedTrip => {
      await queryClient.cancelQueries('sector-trips');
      const previous = queryClient.getQueryData<any[]>('sector-trips');
      queryClient.setQueryData('sector-trips', [
        previous?.filter(trip => trip !== updatedTrip),
      ]);

      return previous;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('sector-trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sector-trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    mutationFn: updateAppointmentDate,
  });
};
