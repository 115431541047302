/* eslint-disable react/no-array-index-key */
import { Box, Button, TextField, Typography } from '@mui/material';

type IAcceptTrip = {
  refusedTripReason: string;
  closeModal: () => void;
  handleReject: () => void;
  setRefusedTripReason: (reason: string) => void;
};
export const RejectTrip = ({
  closeModal,
  handleReject,
  setRefusedTripReason,
  refusedTripReason,
}: IAcceptTrip) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleReject();
  };
  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>Tem certeza que deseja recusar? </Typography>
        <TextField
          label="Selecione"
          size="small"
          value={refusedTripReason || ''}
          sx={{ width: '200px' }}
          onChange={e => setRefusedTripReason(e.target.value)}
        />
      </Box>
      <Box sx={{ display: 'flex', marginTop: '20px' }}>
        <Button
          sx={{ marginRight: '10px' }}
          color="error"
          variant="contained"
          type="submit"
          disabled={!refusedTripReason}
        >
          Sim
        </Button>
        <Button
          onClick={() => closeModal()}
          color="success"
          variant="contained"
          type="button"
        >
          Não
        </Button>
      </Box>
    </form>
  );
};
