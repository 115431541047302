import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Companies } from '../types';

// AxiosResponse
// CompanyValues

type UseCompanyOptions = {
  id: string;
  config?: QueryConfig<typeof getCompany>;
};

export async function getCompany({ id }: { id: string }): Promise<Companies> {
  const response = await axios.get(`companies/get/${id}`);
  return response.data.payload;
}

export const useCompany = ({ id, config }: UseCompanyOptions) => {
  return useQuery({
    queryKey: ['companies', id],
    queryFn: () => getCompany({ id }),
    ...config,
  });
};
