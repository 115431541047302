import { Form, InputField } from '@/components';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ICreateTaggedAddressDTO } from '../types';
import { useCreateTaggedAddress, useGetTaggedCompanyPrices } from '../api';

const schema = z.object({
  address: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  priceTagId: z.string({ required_error: 'Campo obrigatório' }),
});

export const CreateTaggedLocation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { companyId } = useParams();
  const { mutateAsync, isLoading } = useCreateTaggedAddress();
  const { data: taggedPrices } = useGetTaggedCompanyPrices({
    companyId: companyId || '',
  });
  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<ICreateTaggedAddressDTO, typeof schema>
          id="create-address"
          onSubmit={values => {
            return mutateAsync({ ...values });
          }}
          schema={schema}
          options={{
            defaultValues: {
              address: '',
              priceTagId: '',
            },
          }}
        >
          {({ formState, control }) => (
            <>
              <Typography
                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
              >
                Cadastro de localidade
              </Typography>
              <Box>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="address"
                      type="text"
                      label="Bairro"
                      value={value}
                      onChange={onChange}
                      placeholder="Santa Cruz"
                      error={!!formState.errors.address?.message}
                      errorMessage={formState.errors.address?.message}
                    />
                  )}
                />
                <Controller
                  name="priceTagId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="priceTagId"
                      select
                      type="text"
                      label="Tarifa"
                      onChange={onChange}
                      value={value}
                      error={!!formState.errors.priceTagId}
                      errorMessage={formState.errors.priceTagId?.message}
                    >
                      {taggedPrices?.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </InputField>
                  )}
                />
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Carregando' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
