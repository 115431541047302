import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { driversProps } from '../types';

// AxiosResponse
// CompanyValues

export async function loadUsers(
  companyId?: string,
): Promise<driversProps[] | any> {
  const response = await axios.get('lastPositions/getUsersLastPositions');
  return response.data.payload;
}

type UseLoadUsersOptions = {
  config?: QueryConfig<typeof loadUsers>;
};

export const UseLoadUsers = ({ config }: UseLoadUsersOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['usersOnMap'],
    enabled: user?.user?.accessType === 'company_admin',
    queryFn: () => loadUsers(),
    ...config,
  });
};
