import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { IVehicle } from '../types';

const getVehicle = async ({ id }: { id: string }): Promise<IVehicle> => {
  const response = await axios.get(`/vehicles/getVehicle/${id}`);
  return response.data.payload;
};

export const useGetVehicle = ({ id }: { id: string }) => {
  const { user } = useAuth();
  return useQuery({
    enabled: !!user.user.company.permissions.find(
      role => role.name === 'veículos',
    ),
    queryKey: ['vehicles', id],
    queryFn: () => getVehicle({ id }),
  });
};
