import { ConfirmModal, Loading, Modal, Table } from '@/components';
import { DeleteSharp, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useGetRecurringTrips } from '../../api/getRecurringTrips';
import { useDeleteRecurringTrip } from '../../api';
import { EditRecurringTrip } from '../EditRecurringTrip';

export interface IRecurringTripTableProps {
  id: string;
  passengers: string;
  addressFrom: string;
  addressTo: string;
  recurringTime: string;
  recurringDays: number[];
  cityOfOrigin: string;
  cityOfDestination: string;
}

export const RecurringTripsList = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetRecurringTrips();
  const {
    mutateAsync: deleteRecurringTrip,
    isLoading: isDeletingRecurringTrip,
  } = useDeleteRecurringTrip();
  const [selectedIdToRemove, setSelectedIdToRemove] = useState<string | null>();
  const [selectedIdToEdit, setSelectedIdToEdit] =
    useState<IRecurringTripTableProps | null>();
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '600px',
      }}
    >
      {isLoading || (data && !data[0]?.passengers) ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table<IRecurringTripTableProps>
          columns={[
            { title: 'Passageiro', field: 'passengers', colSpan: 2 },
            {
              title: 'Origem',
              field: 'cityOfOrigin',
              colSpan: 1,
              color: true,
              Cell({ entry: { cityOfOrigin, addressFrom } }) {
                return (
                  <Box
                    sx={{
                      ':hover': {
                        '.cityOfOrigin': {
                          display: 'none',
                        },
                        '.addressFrom': {
                          display: 'block',
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      }}
                      className="cityOfOrigin"
                    >
                      {cityOfOrigin}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'none',
                        color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      }}
                      className="addressFrom"
                    >
                      {addressFrom}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              title: 'Destino',
              field: 'cityOfDestination',
              colSpan: 1,
              color: true,
              Cell({ entry: { cityOfDestination, addressTo } }) {
                return (
                  <Box
                    sx={{
                      ':hover': {
                        '.cityOfDestination': {
                          display: 'none',
                        },
                        '.addressTo': {
                          display: 'block',
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      }}
                      className="cityOfDestination"
                    >
                      {cityOfDestination}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'none',
                        color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      }}
                      className="addressTo"
                    >
                      {addressTo}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              title: 'Horário recorrente',
              field: 'recurringTime',
              Cell({ entry: { recurringTime } }) {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '10px',
                        width: '94px',
                        height: '34px',
                        background: '#D9FFDF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography sx={{ color: '#3C8F1F' }}>
                        {recurringTime}
                      </Typography>
                    </Box>
                  </Box>
                );
              },
            },
            {
              title: 'Dias recorrentes',
              field: 'recurringDays',
              Cell({ entry: { recurringDays } }) {
                const days = [
                  {
                    id: 0,
                    name: 'Dom',
                  },
                  {
                    id: 1,
                    name: 'Seg',
                  },
                  {
                    id: 2,
                    name: 'Ter',
                  },
                  {
                    id: 3,
                    name: 'Qua',
                  },
                  {
                    id: 4,
                    name: 'Qui',
                  },
                  {
                    id: 5,
                    name: 'Sex',
                  },
                  {
                    id: 6,
                    name: 'Sab',
                  },
                ];
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {recurringDays
                      ?.sort((a, b) => a - b)
                      ?.map(day => (
                        <Box
                          key={day}
                          style={{
                            width: '33px',
                            height: '33px',
                            padding: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            background: '#3482AE',
                            border: '1px solid #3482AE',
                          }}
                        >
                          <Typography color="#FFF" fontSize={12}>
                            {days[day].name}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                );
              },
            },
            {
              title: '',
              field: 'id',
              Cell({ entry: data }) {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title="Editar">
                      <IconButton
                        disabled={isDeletingRecurringTrip}
                        onClick={() => {
                          setSelectedIdToEdit(data);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                      <IconButton
                        disabled={isDeletingRecurringTrip}
                        onClick={() => {
                          setSelectedIdToRemove(data.id);
                        }}
                      >
                        <DeleteSharp />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]}
          data={data || []}
          loading={isLoading}
        />
      )}
      <ConfirmModal
        isOpen={!!selectedIdToRemove}
        close={() => setSelectedIdToRemove(null)}
        isLoading={isDeletingRecurringTrip}
        handleConfirmation={() => {
          if (selectedIdToRemove) {
            deleteRecurringTrip(selectedIdToRemove);
            setSelectedIdToRemove(null);
          }
        }}
        title="Tem certeza que deseja realizar essa ação?"
        description="Todas as viagens agendadas associadas a esta configuração também serão deletadas"
      />
      {!!selectedIdToEdit && (
        <Modal
          title="Editar viagem recorrente"
          dialogContent={
            <EditRecurringTrip
              close={() => setSelectedIdToEdit(null)}
              {...selectedIdToEdit}
            />
          }
          open={!!selectedIdToEdit}
          size="sm"
          onClose={() => setSelectedIdToEdit(null)}
        />
      )}
    </Box>
  );
};
