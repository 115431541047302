import {
  Box,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '@/assets/img/OneLogo.png';
import { MobileSideBar, Modal } from '@/components';
import { useState } from 'react';
import robot from '@/assets/json/robot.json';
import robotDark from '@/assets/json/robotDark.json';
import Lottie from 'lottie-react';
import { useCustomTheme } from '@/providers/theme';
import { VersionUpdates } from '../components/VersionUpdates';

export const Home = () => {
  const { subTitle, homePageLogo } = useCustomTheme();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      background: theme.palette.background.default,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: ({ isDark }: { isDark?: boolean }) => (isDark ? 'white' : 'black'),
    },
  }));
  const classes = useStyles({ isDark });

  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isMobile ? (
        <>
          <Box sx={{ background: theme.palette.background.default }}>
            <MobileSideBar />
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: '15px',
                zIndex: 990,
              }}
            >
              <Tooltip title="Atualizações">
                <IconButton>
                  {theme.palette.mode === 'dark' ? (
                    <Lottie
                      style={{ width: '80px' }}
                      onClick={() => setIsOpen(true)}
                      animationData={robotDark}
                    />
                  ) : (
                    <Lottie
                      style={{ width: '80px' }}
                      onClick={() => setIsOpen(true)}
                      animationData={robot}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {isOpen && (
              <Modal
                title="Atualizações"
                dialogContent={<VersionUpdates />}
                open={isOpen}
                size="lg"
                onClose={close}
              />
            )}
            <Box className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transform: 'translate(0%, -50%)',
                }}
              >
                <img style={{ width: '100px' }} src={homePageLogo} alt="logo" />

                <Typography
                  variant="h1"
                  sx={{ fontSize: '30px', marginTop: '20px' }}
                  className={classes.text}
                >
                  Bem-vindo ao {subTitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ paddingLeft: '80px' }} className={classes.wrapper}>
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '15px',
              zIndex: 990,
            }}
          >
            <Tooltip title="Atualizações">
              <IconButton>
                {theme.palette.mode === 'dark' ? (
                  <Lottie
                    style={{ width: '80px' }}
                    onClick={() => setIsOpen(true)}
                    animationData={robotDark}
                  />
                ) : (
                  <Lottie
                    style={{ width: '80px' }}
                    onClick={() => setIsOpen(true)}
                    animationData={robot}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          {isOpen && (
            <Modal
              title="Atualizações"
              dialogContent={<VersionUpdates />}
              open={isOpen}
              size="sm"
              onClose={close}
            />
          )}
          <img style={{ width: '150px' }} src={Logo} alt="logo" />

          <Typography
            variant="h1"
            sx={{ fontSize: '3rem', marginLeft: '20px' }}
            className={classes.text}
          >
            Bem-vindo ao {subTitle}
          </Typography>
        </Box>
      )}
    </>
  );
};
