/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

export const getDrivers = async () => {
  try {
    const response = await axios.get('drivers/getAllDrivers');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

type UseDriversOptions = {
  config?: QueryConfig<typeof getDrivers>;
};

export const useDrivers = ({ config }: UseDriversOptions = {}) => {
  return useQuery({
    queryKey: ['allDrivers'],
    queryFn: () => getDrivers(),
    ...config,
  });
};
