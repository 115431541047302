import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteDriverProps, deleteDriverResponse } from '../types';

type UseDeleteDriverOptions = {
  config?: MutationConfig<typeof changeDriverStatus>;
};

export async function changeDriverStatus(
  data: deleteDriverProps,
): Promise<deleteDriverResponse> {
  return axios.put(`drivers/changeStatus/${data.id}`);
}

export const useChangeDriverStatus = ({
  config,
}: UseDeleteDriverOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('drivers');
      await queryClient.cancelQueries('vehicles');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('drivers', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('drivers');
      queryClient.invalidateQueries('vehicles');
      if (data.data.payload.status === false) {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Motorista inativado com sucesso.',
        });
      } else {
        addNotification({
          type: 'success',
          title: 'Sucesso',
          message: 'Motorista ativado com sucesso.',
        });
      }

      navigate('/drivers');
    },
    ...config,
    mutationFn: changeDriverStatus,
  });
};
