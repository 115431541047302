import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export interface ISelectedOption {
  id: string;
  name: string;
}

interface IAutoCompleteInput {
  inputId: string;
  selectedOption: ISelectedOption;
  onChange: (newValue: ISelectedOption | null) => void;
  options: ISelectedOption[];
  label: string;
  width?: number | string;
}

const filter = createFilterOptions<ISelectedOption>();

export const AutoCompleteInput = ({
  inputId,
  selectedOption,
  onChange,
  options,
  label,
  width = 300,
}: IAutoCompleteInput) => {
  return (
    <Autocomplete
      value={selectedOption}
      onChange={(event, newValue: any) => {
        onChange(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={inputId}
      options={options}
      getOptionLabel={option => {
        if (option.name === undefined) {
          return '';
        }
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.name;
        }
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      sx={{ width }}
      freeSolo
      renderInput={params => (
        <TextField {...params} size="small" label={label} />
      )}
    />
  );
};
