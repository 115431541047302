import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { listUserResponse } from '../types';

type UseUsersOptions = {
  id: string;
  config?: QueryConfig<typeof getUser>;
};

// AxiosResponse<CompanyValues>

export const getUser = async ({
  id,
}: {
  id: string;
}): Promise<listUserResponse> => {
  const response = await axios.get(`users/get/${id}`);
  return response.data.payload;
};

export const useUser = ({ id, config }: UseUsersOptions) => {
  return useQuery({
    queryKey: ['users', id],
    queryFn: () => getUser({ id }),
    ...config,
  });
};
