import { SwipeableDrawerComponent } from '@/components';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import Tour from 'reactour';
import { Steps } from '@/utils/steps';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { DriverDistanceRadiusSlider } from '../DriverDistanceRadiusSlider';

export const SettingsTutorial = ({
  isOpen,
  close,
  toggle,
  openTour,
  setOpenTour,
  animation,
}: any) => {
  const { steps } = Steps();
  const [automaticRedirectionMode, setAutomaticeRedirectionMode] =
    useState(true);
  const [fakeSlideValue, setFakeSlideValue] = useState(15);

  const handleFakeSlideValue = (
    event: Event,
    newValue: any,
    activeThumb: number,
  ) => {
    setFakeSlideValue(newValue);
  };

  const [status, setStatus] = useState(1);

  const handleClosestDrivers = useCallback(() => {
    setStatus(1);
  }, []);

  const handleIdleDrivers = useCallback(() => {
    setStatus(2);
  }, []);

  const handleCloseTour = () => {
    setOpenTour(false);
  };
  const accentColor = '#5cb7b7';

  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);

  return (
    <SwipeableDrawerComponent
      title="Configurações"
      isOpen={isOpen}
      close={() => close()}
      toggle={() => toggle()}
    >
      <Tour
        onRequestClose={handleCloseTour}
        steps={steps}
        isOpen={openTour}
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <Box
        sx={{
          padding: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {animation}
        </Box>
        <Typography sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}>
          Modo
        </Typography>
        <FormControlLabel
          data-tut="reactour__automaticRedirectionButton"
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          control={
            <Switch
              checked={automaticRedirectionMode}
              onChange={() => setAutomaticeRedirectionMode(true)}
              name="Busca automática de motorista"
              color="primary"
            />
          }
          label="Busca automática de motorista"
        />
      </Box>
      {automaticRedirectionMode && (
        <>
          <Box data-tut="reactour__slider">
            <DriverDistanceRadiusSlider
              title="Raio de busca de motoristas (KM)"
              ariaLabel="Distância máxima do motorista"
              handleChange={handleFakeSlideValue}
              slideValue={fakeSlideValue}
            />
          </Box>

          <Box
            sx={{
              padding: '10px',
              width: '100%',
            }}
          >
            <Typography
              sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}
            >
              Filtro de motorista
            </Typography>
            <Box
              sx={{
                borderRadius: '7px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid gray',
              }}
            >
              <Button
                data-tut="reactour__ClosestDrivers"
                sx={{
                  borderRight: '1px solid gray',
                  padding: '10px',
                  flex: '1',
                  textAlign: 'center',
                  borderRadius: 0,
                  backgroundColor: status === 1 ? '#7CB9E8' : '',
                  color: status === 1 ? 'white' : '',
                  '&:hover': {
                    background: status === 1 ? '#7CB9E8' : '',
                  },
                }}
                onClick={handleClosestDrivers}
              >
                Mais próximos
              </Button>
              <Button
                data-tut="reactour__IdleDrivers"
                sx={{
                  borderRight: '1px solid gray',
                  padding: '10px',
                  flex: '1',
                  textAlign: 'center',
                  borderRadius: 0,
                  backgroundColor: status === 2 ? '#7CB9E8' : '',
                  color: status === 2 ? 'white' : '',
                  '&:hover': {
                    background: status === 2 ? '#7CB9E8' : '',
                  },
                }}
                onClick={handleIdleDrivers}
              >
                Mais ociosos
              </Button>
            </Box>
          </Box>
        </>
      )}
    </SwipeableDrawerComponent>
  );
};
