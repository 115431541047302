import { useNavigate } from 'react-router-dom';
import { Button, Toolbar } from '@mui/material';

import { ListVehiclesClassesTable } from './ListVehicleClassesTable';
import { useListClasses } from '../api';

export function ListVehicleClassesComponent() {
  const { data, isLoading } = useListClasses();
  const navigate = useNavigate();

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('/classes/create')}
        >
          Cadastrar nova classe
        </Button>
      </Toolbar>
      <ListVehiclesClassesTable isLoading={isLoading} data={data || []} />
    </>
  );
}
