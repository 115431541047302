import { ISelectedOption } from '@/components';
import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomAutoCompleteInput } from './CustomAutoCompleteInput';

interface ISelectPassenger {
  data: { name: string; id: string; accessType: string }[];
  onChange: (option: ISelectedOption | null) => void;
  userIds: { key: number; id: string }[];
  onClose: () => void;
  onAdd?: () => void;
  passengerId?: string;
}

export const SelectPassenger = ({
  data,
  onChange,
  userIds,
  onClose,
  onAdd,
  passengerId,
}: ISelectPassenger) => {
  const [selectedPassenger, setSelectedPassenger] = useState<{
    id: string;
    name: string;
  }>({ id: '', name: '' });

  useEffect(() => {
    if (passengerId) {
      const passenger = data.find(item => item.id === passengerId);
      if (passenger) {
        setSelectedPassenger({
          id: passenger.id,
          name: passenger.name,
        });
      }
    }
  }, [data, passengerId]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        marginTop: '14px',
      }}
    >
      <CustomAutoCompleteInput
        inputId="select-passenger"
        label="Selecione o passageiro"
        onAdd={onAdd}
        onChange={option => {
          setSelectedPassenger({
            id: option?.id || '',
            name: option?.name || '',
          });
          onChange(option);
        }}
        options={data || []}
        selectedOption={selectedPassenger}
        width="100%"
      />
      {userIds.length > 1 && (
        <IconButton
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '10px',
          }}
        >
          <Close sx={{ fontSize: '15px' }} />
        </IconButton>
      )}
    </Box>
  );
};
