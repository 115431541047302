import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MobileSideBar } from '..';
import { PageHeader } from '../PageHeader';

interface PageLayoutProps {
  title: string | React.ReactNode;
  subTitle?: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

export function PageLayout({
  children,
  title,
  icon,
  subTitle,
}: PageLayoutProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      background: theme.palette.background.default,
      width: '100%',
      height: '100vh',
      paddingLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '40px',
    },
  }));
  const classes = useStyles({ isMobile });
  return (
    <>
      {!isMobile ? (
        <Box className={classes.wrapper}>
          <PageHeader icon={icon} title={title} subTitle={subTitle} />
          {children}
        </Box>
      ) : (
        <Box className={classes.wrapper}>
          <MobileSideBar />
          <PageHeader icon={icon} title={title} subTitle={subTitle} />
          {children}
        </Box>
      )}
    </>
  );
}
