import { Check } from '@mui/icons-material';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useRemoveWarning } from '../api';

interface IRequest {
  plate: string;
  noCommunicationTime: number;
  date: string;
}

export const Warning = ({ noCommunicationTime, plate, date }: IRequest) => {
  const { mutateAsync, isLoading } = useRemoveWarning();
  return (
    <Paper sx={{ padding: '10px', margin: '10px 0' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              Placa:
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>{plate}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              Carro ligado sem movimento:
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              {noCommunicationTime.toFixed(0)} min
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              Data:
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>{date}</Typography>
          </Box>
        </Box>
        <Tooltip title="Marcar como visto">
          <IconButton disabled={isLoading} onClick={() => mutateAsync(plate)}>
            <Check />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );
};
