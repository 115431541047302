import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

type IRequest = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

export async function changeUserPassword(data: IRequest): Promise<void> {
  const response = await axios.put('users/updateUserPassword', data);
  return response.data.payload;
}

export const useChangeUserPassword = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Senha alterada com sucesso.',
      });
    },
    mutationFn: changeUserPassword,
  });
};
