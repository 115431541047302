import { AutoCompleteInput } from '@/components';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

interface ISelectTripReportsFields {
  drivers: {
    id: string;
    name: string;
  }[];
  setDriver: (driver: string) => void;
}

export const SelectDriver = ({
  drivers,
  setDriver,
}: ISelectTripReportsFields) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formattedDrivers = drivers?.map(driver => ({
    id: driver.id,
    name: driver.name,
  }));
  const [selectedDriver, setSelectedDriver] = useState<{
    id: string;
    name: string;
  }>({ id: '', name: '' });

  useEffect(() => {
    setDriver(selectedDriver.id);
  }, [selectedDriver, setDriver]);
  return (
    <AutoCompleteInput
      inputId="select-driver"
      label="Filtrar por motorista"
      onChange={option => {
        setSelectedDriver({
          id: option?.id || '',
          name: option?.name || '',
        });
        setDriver(option?.id || '');
      }}
      options={formattedDrivers || []}
      selectedOption={selectedDriver}
      width={isMobile ? 170 : 200}
    />
  );
};
