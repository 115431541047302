/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  clientErrors: {
    route: string;
    count: number;
  }[];
  serverErrors: {
    route: string;
    count: number;
  }[];
}

export const getRoutesErrors = async (): Promise<IOutput> => {
  const response = await axios.get('app-logs/getRoutesWithErrors');
  return response.data.payload;
};

type UseGetRoutesErrorsOptions = {
  config?: QueryConfig<typeof getRoutesErrors>;
};

export const UseGetRoutesErrors = ({
  config,
}: UseGetRoutesErrorsOptions = {}) => {
  return useQuery({
    queryKey: ['routesErrors'],
    queryFn: () => getRoutesErrors(),
    ...config,
  });
};
