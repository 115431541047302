import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export async function updateVehicleStatus(id: string): Promise<void> {
  return axios.patch(`vehicles/updateVehicleStatus/${id}`);
}

export const useUpdateVehicleStatus = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries('vehicles');
      await queryClient.cancelQueries('drivers');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('vehicles', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('vehicles');
      queryClient.invalidateQueries('drivers');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Status do veículo atualizado com sucesso',
      });
    },
    mutationFn: updateVehicleStatus,
  });
};
