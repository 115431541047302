import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useAuth } from '@/providers/auth';
import { useQuery } from 'react-query';
import { loadOpenTripsResponse } from '@/features/tripRequest/types';

// eslint-disable-next-line consistent-return
export async function getTripsToBeValidated(
  companyId?: string,
): Promise<loadOpenTripsResponse[]> {
  const response = await axios.get('trips/getTripsToBeValidated');
  return response.data.payload;
}

type UseOpenTripsOptions = {
  config?: QueryConfig<typeof getTripsToBeValidated>;
};

export const useTripsToBeValidated = ({ config }: UseOpenTripsOptions = {}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['tripsToBeValidated'],
    enabled: user?.user?.accessType === 'sector_admin',
    queryFn: () => getTripsToBeValidated(),
    ...config,
  });
};
