import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetTaggedLocation } from '../api';
import { UpdateTaggedLocation } from '../components/UpdateTaggedLocation';

export function UpdateTaggedLocationRoute() {
  const { id } = useParams();

  const { data: taggedLocation, isLoading } = useGetTaggedLocation({
    id: id || '',
  });
  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {taggedLocation && (
        <UpdateTaggedLocation taggedLocation={taggedLocation} />
      )}
    </>
  );
}
