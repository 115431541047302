import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { ReportsList } from './ReportsList';

export const ReportsRoutes = () => {
  return (
    <>
      <Can permissions={['master', 'renter', 'company_admin', 'sector_admin']}>
        <Routes>
          {/* <Route path="" element={<Companies />} /> */}
          <Route path="" element={<ReportsList />} />
        </Routes>
      </Can>
    </>
  );
};
