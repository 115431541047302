import { FaCarSide } from 'react-icons/fa';

import { Box, Typography, useTheme } from '@mui/material';
import { driversProps } from '../types';

type IDriverList = {
  filteredDriver: driversProps;
  handleClick: (e: driversProps) => void;
};

export const DriversList = ({ filteredDriver, handleClick }: IDriverList) => {
  const theme = useTheme();

  const carInDarkTheme = ` ${
    filteredDriver.driverInProgress ? '#ECA233' : '#4ECB71'
  }`;

  const carInWhiteTheme = ` ${
    filteredDriver.driverInProgress ? '#ECA233' : '#2ED253'
  }`;

  return (
    <>
      <Box
        sx={{
          background: theme.palette.mode === 'dark' ? '#292929' : '#F4F4F4',
          padding: '10px',
          width: '100%',
          border: '1px solid 323220',
          borderBottom: `1px solid ${
            theme.palette.mode === 'dark' ? 'gray' : '#B7B7B7'
          }`,
          borderTop: `1px solid ${
            theme.palette.mode === 'dark' ? 'gray' : '#B7B7B7'
          }`,
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'ease-in-out 0.2s',
          }}
          onClick={() => handleClick(filteredDriver)}
        >
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  border: `1px solid ${
                    theme.palette.mode === 'dark'
                      ? carInDarkTheme
                      : carInWhiteTheme
                  }`,
                }}
              >
                <FaCarSide
                  style={{
                    color:
                      theme.palette.mode === 'dark'
                        ? carInDarkTheme
                        : carInWhiteTheme,
                  }}
                  size={20}
                />
              </Box>
              <Typography
                sx={{ marginLeft: '8px', fontWeight: 'bold' }}
                variant="subtitle1"
              >
                {filteredDriver.plate}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ fontSize: '17px' }} variant="subtitle1">
            {filteredDriver.name.split(' ')[0]}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
