import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { PlacesAutoComplete } from '../TripRequest/PlacesAutoComplete';
import { FavoriteAddressTooltip } from '../FavoriteAddressTooltip';

interface IRequest {
  value: string;
  handleAddress: (val: any, setValue: any, id: number) => void;
  setValue: any;
  placeholder: string;
  clean: (setValue: any, id?: number) => void;
  onAddFavoriteAddress: () => void;
  id?: number;
  isStopPoint?: boolean;
  zIndex?: number;
}

export const AddressInput = ({
  clean,
  handleAddress,
  placeholder,
  setValue,
  value,
  onAddFavoriteAddress,
  id,
  isStopPoint,
  zIndex,
}: IRequest) => {
  const theme = useTheme();
  const [favoriteAddressSelected, setFavoriteAddressSelected] = useState(false);
  return (
    <Box
      sx={{
        width: '280px',
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        zIndex,
      }}
    >
      <FavoriteAddressTooltip
        isSelected={favoriteAddressSelected}
        onClick={() => {
          if (!isStopPoint) {
            clean(setValue, id);
          }
          setFavoriteAddressSelected(prev => !prev);
        }}
      />
      {!favoriteAddressSelected ? (
        <Box sx={{ flex: 1 }}>
          <PlacesAutoComplete
            handleAddress={handleAddress}
            setValue={setValue}
            placeholder={placeholder}
            value={value}
            clean={clean}
            id={id}
          />
        </Box>
      ) : (
        <Button
          variant="contained"
          sx={{
            height: '40px',
            overflow: 'hidden',
            width: '280px',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'flex-start',
            '&:hover': {
              background: 'transparent',
            },
          }}
          onClick={() => onAddFavoriteAddress()}
        >
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? '7D8B8C' : 'black',
              textTransform: 'lowercase',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {value || 'Selecionar endereço'}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
