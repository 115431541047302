import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

type UseDeleteProfileOptions = {
  config?: MutationConfig<typeof deleteProfile>;
};

export async function deleteProfile(id: string): Promise<any> {
  return axios.delete(`permissions/deleteProfile/${id}`);
}

export const useDeleteProfile = ({ config }: UseDeleteProfileOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('profiles');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('profiles', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('profiles');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Perfil deletado com sucesso.',
      });
    },
    ...config,
    mutationFn: deleteProfile,
  });
};
