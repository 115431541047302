import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { IRecurringTrip } from '../types';

type UseRecurringTripsRequestOptions = {
  config?: MutationConfig<typeof updateRecurringTrips>;
};

interface IRequest {
  id: string;
  recurringTripDays?: {
    id?: string;
    recurringTripId?: string;
    day: number;
  }[];
  hour?: number;
  minute?: number;
}

export async function updateRecurringTrips(
  data: IRequest,
): Promise<IRecurringTrip> {
  const response = await axios.put(
    `/recurringTrips/updateRecurringTrip/${data.id}`,
    data,
  );
  return response.data.payload;
}

export const useUpdateRecurringTrips = ({
  config,
}: UseRecurringTripsRequestOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async recurringTrip => {
      await queryClient.cancelQueries('recurringTrips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('recurringTrips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('recurringTrips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Viagem recorrente salva com sucesso',
      });
    },
    ...config,
    mutationFn: updateRecurringTrips,
  });
};
