import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { SendNotificationsProps } from '../types';

type UseSendNotificationsOptions = {
  config?: MutationConfig<typeof sendNotification>;
};

export async function sendNotification(data: SendNotificationsProps) {
  try {
    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'no-cors',
      body: JSON.stringify(data),
    });
  } catch (err) {
    console.log(err);
  }
}

export const useSendNotification = ({
  config,
}: UseSendNotificationsOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async notification => {
      await queryClient.cancelQueries('notifications');

      const previousNotifications =
        queryClient.getQueryData<any[]>('notifications');

      queryClient.setQueryData('notification', [
        previousNotifications?.filter(trip => trip !== notification),
      ]);

      return previousNotifications;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('notifications', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('notifications');
    },
    ...config,
    mutationFn: sendNotification,
  });
};
