import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';
import { DriversValues, saveDriverResponse } from '../types';

export type UpdateCompanyData = {
  data: DriversValues;
};

export const updateDriver = ({
  data,
}: UpdateCompanyData): Promise<saveDriverResponse> => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return axios.put(`drivers/update/${data.id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof updateDriver>;
};

export const useUpdateDriver = ({ config }: UseUpdateCompaniesOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async updatingDriver => {
      await queryClient.cancelQueries(['drivers', updatingDriver?.data.id]);

      const previousDriver = queryClient.getQueryData<DriversValues>([
        'drivers',
        updatingDriver?.data.id,
      ]);

      queryClient.setQueryData(['drivers', updatingDriver?.data.id], {
        ...previousDriver,
        ...updatingDriver.data,
        id: updatingDriver.data.id,
      });

      return { previousDriver };
    },
    onError: (_, __, context: any) => {
      if (context?.previousDriver) {
        queryClient.setQueryData(
          ['drivers', context.previousDriver.id],
          context.previousDriver,
        );
      }
    },
    onSuccess: (_data, _variables, context) => {
      queryClient.refetchQueries(['drivers', context.previousDriver.id]);
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Motorista atualizado com sucesso.',
      });
      navigate('/drivers');
    },
    ...config,
    mutationFn: updateDriver,
  });
};
