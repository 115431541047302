import userPhoto from '@/assets/img/usuario.png';
import carPlate from '@/assets/img/plate.png';
import carLottie from '@/assets/json/carLottie.json';
import { GpsNotFixed, GpsOff, Phone, Speed } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import { driversProps } from '../types';

export const InfoWindow = ({
  urlImage,
  name,
  plate,
  phone,
  lastUpdateInTheCellPhone,
  isDriverTrackerEnabled,
  speedInTheCellPhone,
}: driversProps) => {
  const theme = useTheme();
  return (
    <>
      <Lottie
        animationData={carLottie}
        style={{
          position: 'absolute',
          top: '-50px',
          left: '110px',
          height: '300px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          flex: 1,
          padding: '20px 20px',
          height: '100%',
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img
            style={{
              width: '107px',
              height: '107px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
            src={urlImage || userPhoto}
            alt="Foto do motorista"
          />
          <Box
            sx={{
              display: 'flex',
              borderRadius: '8px',
              backgroundColor: '#D9D9D9',
              width: '178px',
              height: '41px',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '0.5rem',
            }}
          >
            <Phone />
            <Typography variant="body2" color="#000000">
              {phone}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${carPlate})`,
              alignItems: 'center',
              justifyContent: 'center',
              objectFit: 'contain',
              display: 'flex',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '41px',
              width: '125px',
              backgroundPosition: 'center',
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize="20px"
              sx={{ marginTop: '5px' }}
            >
              {plate}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              gap: '5px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography fontWeight="bold" variant="body2" color="#000000">
              {name}
            </Typography>
            <Box>
              <Typography
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                Hora da atualização
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                  textAlign: 'center',
                }}
              >
                {lastUpdateInTheCellPhone && <>{lastUpdateInTheCellPhone}</>}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              marginRight: '50px',
            }}
          >
            {isDriverTrackerEnabled ? <GpsNotFixed /> : <GpsOff />}
            <Typography
              style={{
                fontSize: '12px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {isDriverTrackerEnabled ? 'GPS Ligado' : 'GPS Desligado'}
            </Typography>
          </Box>
          <Box
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Speed />
            <Typography
              style={{
                fontSize: '12px',
                textAlign: 'center',
                color: theme.palette.mode === 'dark' ? '#ececec' : '#3E3E3E',
                fontWeight: 'bold',
              }}
            >
              {speedInTheCellPhone?.toFixed(2) || 0} KM
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
