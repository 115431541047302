export function convertToTimeFormat(minutes: number) {
  // Converte para segundos e arredonda para o inteiro mais próximo
  const totalSeconds = Math.round(minutes * 60);

  // Calcula horas, minutos e segundos
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const mins = Math.floor(remainingSeconds / 60);
  const secs = remainingSeconds % 60;

  // Formata para hh:mm:ss com dois dígitos
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(
    2,
    '0',
  )}:${String(secs).padStart(2, '0')}`;
}
