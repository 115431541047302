/* eslint-disable no-nested-ternary */
import { IconButton, Tooltip, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomTheme } from '@/providers/theme';
import { Can } from '../Can';
import { Link } from '../Elements';

type NavItemProps = {
  icon: React.ElementType;
  label: string;
  path: string;
  className: string;
  permissions?: string[];
  restriction?: string;
  roles?: string[];
};

export function NavItem({
  icon: Icon,
  label,
  className,
  path,
  permissions,
  restriction,
  roles,
}: NavItemProps) {
  const theme = useTheme();
  const location = useLocation();
  const [slug, setSlug] = useState(window.location.pathname);
  const navigate = useNavigate();
  useEffect(() => {
    setSlug(window.location.pathname);
    if (slug === '/') {
      navigate('/home');
    }
  }, [path, slug, location, navigate]);

  const { sideBarIconBackgroundColor } = useCustomTheme();

  const isDark = slug.includes(path) && theme.palette.mode === 'dark';

  return (
    <Can roles={roles} restriction={restriction} permissions={permissions}>
      <Link
        style={{
          width: '40px',
          justifyContent: 'center',
          display: 'flex',
          overflow: 'hidden',
        }}
        to={path}
      >
        <Tooltip
          style={{
            background: slug.includes(path) ? sideBarIconBackgroundColor : '',
            color: 'black',
            boxShadow: isDark
              ? '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
              : '',
            backgroundImage: isDark
              ? 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
              : '',
          }}
          title={label}
          placement="right"
          arrow
          sx={{ marginTop: 2 }}
        >
          <IconButton sx={{ borderRadius: 0, marginTop: '5px' }}>
            <Icon
              sx={{ width: '25px', height: '22px' }}
              style={{
                color:
                  slug.includes(path) && theme.palette.mode === 'light'
                    ? 'white'
                    : slug.includes(path) && theme.palette.mode === 'dark'
                    ? 'black'
                    : '',
              }}
              className={className}
            />
          </IconButton>
        </Tooltip>
      </Link>
    </Can>
  );
}
