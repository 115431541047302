import { Box, Typography } from '@mui/material';

interface IDetail {
  title: string;
  description: string;
  bold?: boolean;
}

export const Detail = ({ title, description, bold = false }: IDetail) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        gap: '10px',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{ fontWeight: bold ? 'bold' : 'regular' }}
        variant="subtitle1"
      >
        {title}:
      </Typography>
      <Typography
        sx={{ fontWeight: bold ? 'bold' : 'regular' }}
        variant="subtitle2"
      >
        {description}
      </Typography>
    </Box>
  );
};
