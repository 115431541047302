import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDriver } from '../api';
import { Driver } from '../routes/Driver';

export function UpdateDriver() {
  const dataParams = useParams() as unknown as Record<string, any>;
  const id = dataParams.driverId;
  const { data: driversQuery, isLoading } = useDriver({ id });
  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Driver driversQuery={driversQuery} />
      )}
    </>
  );
}
