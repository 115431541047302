import { Box, Slider, Typography } from '@mui/material';

type Props = {
  slideValue: number;
  title: string;
  handleChange: any;
  ariaLabel?: string;
  handleOnBlur?: () => void;
  isLoading?: boolean;
};

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 110,
    label: '110',
  },
];

export const DriverDistanceRadiusSlider = ({
  slideValue,
  handleChange,
  title,
  ariaLabel,
  isLoading,
  handleOnBlur,
}: Props) => {
  return (
    <Box
      sx={{
        padding: '10px',
        width: '100%',
      }}
    >
      <Typography sx={{ textAlign: 'start', padding: '10px', color: 'gray' }}>
        {title}
      </Typography>
      <Slider
        disabled={isLoading}
        aria-label={ariaLabel}
        value={slideValue}
        marks={marks}
        onChange={handleChange}
        valueLabelDisplay="on"
        onBlur={handleOnBlur}
        step={1}
        min={1}
        max={110}
      />
    </Box>
  );
};
