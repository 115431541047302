import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { IFilteredRecurringTrips } from '@/features/tripRequest/types';

// eslint-disable-next-line consistent-return
export async function getRecurringTrips(): Promise<IFilteredRecurringTrips[]> {
  const response = await axios.get('recurringTrips/findManyByCompanyId');
  return response.data.payload;
}

type UseGetRecurringTripsOptions = {
  config?: QueryConfig<typeof getRecurringTrips>;
};

export const useGetRecurringTrips = ({
  config,
}: UseGetRecurringTripsOptions = {}) => {
  return useQuery({
    queryKey: ['recurringTrips'],
    queryFn: () => getRecurringTrips(),
    ...config,
  });
};
