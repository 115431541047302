import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { useAuth } from '@/providers/auth';
import { IChatMessage } from '../types';

interface IOutput {
  total: number;
  messages: IChatMessage[];
}

const getNotCheckedMessages = async (): Promise<IOutput> => {
  const response = await axios.get('/chat/listRentersNotCheckedMessages');
  return response.data.payload;
};

export const useGetNotCheckedMessages = () => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['notCheckedMessages'],
    queryFn: () => getNotCheckedMessages(),
    enabled: user?.user?.accessType === 'renter',
  });
};
