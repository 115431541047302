import { PageLayout } from '@/components';
import { Engineering } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { OperatorsContainer } from '../components/OperatorsContainer';

export const OperatorsList = () => {
  const { subTitle } = useCustomTheme();
  return (
    <PageLayout
      title="Operadores"
      subTitle={subTitle}
      icon={<Engineering fontSize="large" style={{ color: '#0ebcdf' }} />}
    >
      <OperatorsContainer />
    </PageLayout>
  );
};
