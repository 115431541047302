import { Settings } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';

interface IRequest {
  handleClick: () => void;
}

export const SettingsButton = ({ handleClick }: IRequest) => {
  const theme = useTheme();
  return (
    <Tooltip
      sx={{
        width: '52px',
        height: '40px',
        marginRight: '10px',
        background:
          theme.palette.mode === 'dark'
            ? '#363636'
            : 'linear-gradient(180deg, #E9E9E9 0%, #A5A5A5 100%)',
        borderRadius: '10px',
      }}
      title="Configurações"
    >
      <IconButton onClick={() => handleClick()}>
        <Settings
          sx={{
            color: theme.palette.mode === 'dark' ? '#bdbdbd' : 'black',
            '&:hover': {
              transform: 'rotate(90deg)',
            },
            transform: 'rotate(0deg)',
            transition: 'ease-in-out 0.5s',
          }}
          fontSize="medium"
        />
      </IconButton>
    </Tooltip>
  );
};
