/* eslint-disable react/no-array-index-key */
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { IWorkJourney } from '../api';

interface IRequest {
  onClickEdit: (entry: IWorkJourney) => void;
  onClickAddWorkJourney: () => void;
}

export const ClockInColumns = ({
  onClickEdit,
  onClickAddWorkJourney,
}: IRequest) => {
  return {
    columns: [
      {
        key: 0,
        title: 'Data',
        field: 'date',
        colSpan: 2,
      },
      {
        key: 1,
        title: 'Início do Expediente',
        field: 'startedAt',
        colSpan: 2,
        Cell({ entry }: { entry: IWorkJourney }) {
          return (
            <Box
              sx={{
                '&:hover .startedAddress': {
                  display: 'flex',
                },
              }}
            >
              <Typography
                sx={{
                  color: entry.hasStartedLate ? '#D61E1E' : '#0EB183',
                }}
              >
                {entry.startedAt}
              </Typography>
              <Typography sx={{ display: 'none' }} className="startedAddress">
                {entry.startedAddress}
              </Typography>
            </Box>
          );
        },
      },
      {
        key: 2,
        title: 'Almoço',
        field: 'enteredLunchAt',
        colSpan: 2,
        Cell({ entry }: { entry: IWorkJourney }) {
          return (
            <Box
              sx={{
                '&:hover .lunchAddress': {
                  display: 'flex',
                },
              }}
            >
              <Typography
                sx={{
                  color: entry.hasPassedTheLunchTime ? '#D61E1E' : '#0EB183',
                }}
              >
                {entry.enteredLunchAt}
              </Typography>
              <Typography sx={{ display: 'none' }} className="lunchAddress">
                {entry.enteredLunchAddress}
              </Typography>
            </Box>
          );
        },
      },
      {
        key: 3,
        title: 'Retorno do Almoço',
        field: 'exitedLunchAt',
        colSpan: 2,
        Cell({ entry }: { entry: IWorkJourney }) {
          return (
            <Box
              sx={{
                '&:hover .exitedLunchAddress': {
                  display: 'flex',
                },
              }}
            >
              <Typography
                sx={{
                  color: entry.hasPassedTheLunchTime ? '#D61E1E' : '#0EB183',
                }}
              >
                {entry.exitedLunchAt}
              </Typography>
              <Typography
                sx={{ display: 'none' }}
                className="exitedLunchAddress"
              >
                {entry.exitedLunchAddress}
              </Typography>
            </Box>
          );
        },
      },
      {
        key: 4,
        title: 'Paradas',
        field: 'breaks',
        colSpan: 2,
        Cell({ entry: { breaks } }: { entry: IWorkJourney }) {
          const breakNames = [...new Set(breaks.map(b => b.name))];
          return (
            <Box>
              {breakNames.map((name, index) => (
                <Box sx={{ display: 'flex', gap: '2px' }} key={index}>
                  <Typography>{name}:</Typography>
                  <Box sx={{ display: 'flex', gap: '2px' }}>
                    <Typography>
                      {breaks
                        .filter(b => b.name === name)
                        .map(b => b.startedAt)
                        .join('/')}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          );
        },
      },
      {
        key: 5,
        title: 'Saída',
        field: 'finishedAt',
        colSpan: 2,
        Cell({ entry }: { entry: IWorkJourney }) {
          return (
            <Box
              sx={{
                '&:hover .finishedAddress': {
                  display: 'flex',
                },
              }}
            >
              <Typography
                sx={{
                  color: entry.hasFinishedEarly ? '#D61E1E' : '#0EB183',
                }}
              >
                {entry.finishedAt}
              </Typography>
              <Typography sx={{ display: 'none' }} className="finishedAddress">
                {entry.finishedAddress}
              </Typography>
            </Box>
          );
        },
      },
      {
        key: 6,
        title: 'Tempo em descanso',
        field: 'breakTime',
        colSpan: 2,
      },
      {
        key: 7,
        title: 'Total trabalhado',
        field: 'totalTimeWorked',
        colSpan: 2,
      },
      {
        key: 8,
        title: 'Horas extras',
        field: 'extraWorkedTime',
        colSpan: 2,
      },
      {
        key: 9,
        title: 'Motivo/Observação',
        field: 'observation',
        colSpan: 2,
      },
      {
        key: 10,
        title: 'Ações',
        field: 'id',
        colSpan: 2,
        CustomHeader: () => (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ textAlign: 'center' }}>Ações</Typography>
            <Tooltip
              sx={{ position: 'absolute', right: 0, top: '-8px' }}
              title="Adicionar jornada de trabalho"
            >
              <IconButton onClick={() => onClickAddWorkJourney()}>
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        Cell({ entry }: { entry: IWorkJourney }) {
          return (
            <Tooltip title="editar">
              <IconButton onClick={() => onClickEdit(entry)}>
                <Edit />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
  };
};
