/* eslint-disable import/no-unresolved */
import { Typography, Box, TextField, MenuItem } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useVersionLogs } from '@/features/versionLog/api';
import { useEffect, useMemo, useState } from 'react';

export const VersionUpdates = () => {
  const [version, setVersion] = useState(0);
  const { data, refetch } = useVersionLogs({ version });

  const sortedVersions = useMemo(() => {
    return data?.versions?.sort((a: any, b: any) => b - a) || [];
  }, [data?.versions]);

  useEffect(() => {
    refetch();
  }, [version]);

  useEffect(() => {
    if (sortedVersions.length > 0) {
      if (version === 0) {
        setVersion(sortedVersions[0]);
      }
    }
  }, [sortedVersions, version]);

  return (
    <>
      {!data ? (
        <Typography>Não foi possível carregar!</Typography>
      ) : (
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
        >
          <Typography>Versão:</Typography>
          <TextField
            style={{ width: '100px', textAlign: 'center' }}
            size="small"
            select
            value={version}
            variant="standard"
            onChange={e => setVersion(Number(e.target.value))}
          >
            {sortedVersions?.map((version: string) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {data?.dates?.map((date: any, i: any) => (
        <>
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            sx={{
              fontSize: '18px',
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <CalendarTodayIcon />
            <Typography sx={{ marginLeft: '10px', fontSize: '18px' }}>
              {date}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
            }}
          >
            {data?.updates.find(
              (hasUpdate: any) => hasUpdate.logDate === date,
            ) && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                  <ListAltIcon />
                  <Typography sx={{ marginLeft: '10px' }}>
                    Novas Funcionalidades
                  </Typography>
                </Box>
                <ul style={{ color: 'gray' }}>
                  {data?.updates
                    .filter((update: any) => update.logDate === date)
                    .map((filteredUpdates: any) => (
                      <li key={filteredUpdates.id}>
                        {filteredUpdates.description}
                      </li>
                    ))}
                </ul>
              </Box>
            )}
            {data?.bugs.find((hasBug: any) => hasBug.logDate === date) && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                  <ListAltIcon />
                  <Typography sx={{ marginLeft: '10px' }}>
                    Correção de bugs
                  </Typography>
                </Box>
                <ul style={{ color: 'gray' }}>
                  {data?.bugs
                    .filter((bug: any) => bug.logDate === date)
                    .map((filteredbugs: any) => (
                      <li key={filteredbugs.id}>{filteredbugs.description}</li>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </>
      ))}
    </>
  );
};
