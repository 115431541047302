import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UserValues, SaveUserResponse } from '..';

export async function createUser(data: UserValues): Promise<SaveUserResponse> {
  return axios.post('users/register', data);
}

export const useCreateUser = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newUser => {
      await queryClient.cancelQueries('users');

      const previousUsers = queryClient.getQueryData<UserValues[]>('users');

      queryClient.setQueryData('users', [...(previousUsers || []), newUser]);

      return previousUsers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('users', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Usuário salvo com sucesso.',
      });
      navigate('/users');
    },
    mutationFn: createUser,
  });
};
