/* eslint-disable no-nested-ternary */
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Car from '@/assets/img/car3d.png';
import { HighlightOff } from '@mui/icons-material';
import { driversProps } from '../types';

type IDriverInfo = {
  clickedDriver: driversProps;
  setClickedDriver: (mode: undefined) => void;
};

export const DriverInfoMobile = ({
  clickedDriver,
  setClickedDriver,
}: IDriverInfo) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '20%',
        left: '50%',
        marginLeft: isMobile ? '0' : '19.5px',
        transform: 'translate(-50%, 50%)',
        background: 'linear-gradient(180deg, #000B20 0%, #000000 68.75%)',
        boxShadow: ' 0px 5px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'start',
        width: '95%',
        color: 'white',
        padding: '10px',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Grid
          item
          sm={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                background: 'lightblue',
                width: '35px',
                height: '35px',
                position: 'relative',
              }}
            >
              {clickedDriver?.name?.split(' ')[0][0]}
              {clickedDriver?.driverInProgress ? (
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    background: '#973C09',
                    position: 'absolute',
                    bottom: '5px',
                    right: '3px',
                    border: '2px solid #000309',
                    overflow: 'auto',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    background: '#2ED253',
                    position: 'absolute',
                    bottom: '5px',
                    right: '3px',
                    border: '2px solid #000309',
                    overflow: 'auto',
                  }}
                />
              )}
            </Avatar>
          </Box>
          <Box
            sx={{
              width: '70px',
              height: '52px',
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              src={Car}
              alt="Carro"
            />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
          item
          sm={3}
        >
          <Box>
            <Typography
              sx={{ fontSize: isMobile ? '8px' : '12px', fontWeight: 'bold' }}
            >
              Passageiro
            </Typography>
            <Typography
              sx={{
                marginTop: '2px',
                fontSize: isMobile ? '8px' : '12px',
                maxWidth: isMobile ? '65px' : '110px',
              }}
            >
              {clickedDriver?.passenger?.split(' ')[0] || '...'}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontSize: isMobile ? '8px' : '12px',
                fontWeight: 'bold',
              }}
            >
              Destino
            </Typography>
            <Typography
              sx={{
                marginTop: '2px',
                maxWidth: isMobile ? '65px' : '90px',
                fontSize: isMobile ? '8px' : '12px',
                '& .fullAddress': {
                  display: 'none',
                },

                '&:hover .fullAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'block',
                },

                '&:hover .parcialAddress': {
                  transition: 'ease-in-out 0.2s',
                  display: 'none',
                },
              }}
            >
              {!clickedDriver?.addressTo && '...'}
              <span className="fullAddress">{clickedDriver?.addressTo}</span>
              {clickedDriver?.addressTo?.length > 20 ? (
                <span className="parcialAddress">
                  {clickedDriver?.addressTo?.slice(0, 20)}...
                </span>
              ) : (
                <span className="parcialAddress">
                  {clickedDriver?.addressTo}
                </span>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
          item
          sm={3}
        >
          <Box>
            <Typography
              sx={{ fontSize: isMobile ? '8px' : '12px', fontWeight: 'bold' }}
            >
              Setor
            </Typography>
            <Typography
              sx={{
                marginTop: '2px',
                fontSize: isMobile ? '8px' : '12px',
                maxWidth: isMobile ? '65px' : '110px',
              }}
            >
              {clickedDriver?.sector || '...'}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            <Typography
              sx={{ fontSize: isMobile ? '8px' : '12px', fontWeight: 'bold' }}
            >
              Empresa
            </Typography>
            <Typography
              sx={{
                maxWidth: isMobile ? '65px' : '110px',
                fontSize: isMobile ? '8px' : '12px',
                marginTop: '2px',
              }}
            >
              {clickedDriver?.corporateName || '...'}
            </Typography>
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
          item
          sm={3}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: isMobile ? '8px' : '12px', fontWeight: 'bold' }}
              >
                Horário previsto
              </Typography>
              <Typography
                sx={{
                  maxWidth: isMobile ? '65px' : '110px',
                  fontSize: isMobile ? '8px' : '12px',
                  marginTop: '2px',
                }}
              >
                {clickedDriver?.estimatedDateToFinishTrip || '...'}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography
                sx={{ fontSize: isMobile ? '8px' : '12px', fontWeight: 'bold' }}
              >
                Hora da atualização
              </Typography>
              <Typography
                sx={{
                  maxWidth: isMobile ? '65px' : '110px',
                  fontSize: isMobile ? '8px' : '12px',
                  marginTop: '2px',
                }}
              >
                {clickedDriver?.date}
              </Typography>
            </Box>
          </Box>

          <IconButton
            sx={{ padding: '0', color: 'white' }}
            onClick={() => setClickedDriver(undefined)}
          >
            <HighlightOff fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
        container
      >
        <Grid sx={{ flex: '1' }} item sm={4}>
          <Box
            sx={{
              background: ' #EFEFEF',
              borderRadius: '15px',
              color: '#071D47',
              marginTop: '10px',
              width: '95%',
            }}
          >
            <Typography
              sx={{
                height: '20px',
                padding: '8px',
                fontWeight: 'bold',
                fontSize: isMobile ? '8px' : '12px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {clickedDriver?.name?.split(' ')[0]}
            </Typography>
          </Box>
        </Grid>

        <Grid item sx={{ flex: '1' }} sm={4}>
          <Box
            sx={{
              background: ' #EFEFEF',
              borderRadius: '15px',
              color: '#071D47',
              marginTop: '10px',
              width: '95%',
            }}
          >
            <Typography
              sx={{
                height: '20px',
                padding: '8px',
                fontWeight: 'bold',
                fontSize: isMobile ? '8px' : '12px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Placa: {clickedDriver?.plate}
            </Typography>
          </Box>
        </Grid>

        <Grid sx={{ flex: '1' }} item sm={4}>
          <Box
            sx={{
              background: ' #EFEFEF',
              borderRadius: '15px',
              color: '#071D47',
              marginTop: '10px',
              width: '95%',
            }}
          >
            <Typography
              sx={{
                height: '20px',
                padding: '8px',
                fontWeight: 'bold',
                fontSize: isMobile ? '8px' : '12px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Status:{' '}
              {clickedDriver?.driverInProgress && clickedDriver?.started ? (
                <Typography
                  sx={{
                    fontSize: isMobile ? '8px' : '12px',
                    color: '#973C09',
                    marginLeft: '8px',
                    fontWeight: 'bold',
                  }}
                >
                  Em progresso
                </Typography>
              ) : clickedDriver?.driverInProgress && !clickedDriver?.started ? (
                <Typography
                  sx={{
                    fontSize: isMobile ? '8px' : '12px',
                    color: '#973C09',
                    marginLeft: '8px',
                    fontWeight: 'bold',
                  }}
                >
                  Em progresso
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: isMobile ? '8px' : '12px',
                    color: '#2ED253',
                    marginLeft: '8px',
                  }}
                >
                  Disponível
                </Typography>
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
