import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    //  backgroundColor: '#fdfdff',
  },
  pageHeader: {
    padding: '32px',
    display: 'flex',
    marginBottom: '16px',
  },
  pageIcon: {
    display: 'inline-block',
    padding: '16px',
    borderRadius: '8px',
    position: 'relative', // Para usar o box-shadow
    boxShadow: '0 0 0 1px transparent', // Inicialmente transparente para ajustar com borderImage
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 'inherit', // Mantém o borderRadius
      padding: '2px', // Largura da borda
      background: 'linear-gradient(to right, #02F7F1, #0D8EFF)',
      '-webkit-mask':
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      '-webkit-mask-composite': 'destination-out',
      maskComposite: 'exclude',
    },
  },
  pageTitle: {
    paddingLeft: '24px',
    '& .MuiTypography-subtitle2': {
      opacity: '0.6',
    },
  },
}));
