import { useTripCounter } from '@/features/dashboard';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PieChart3DComponent } from './PieChar3d';

interface IProps {
  setSelectedTotalTripsChartOption: (
    option: 'canceled' | 'recurring' | 'finished' | 'all',
  ) => void;
}

export function PieChart({ setSelectedTotalTripsChartOption }: IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: tripCounterData } = useTripCounter();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        height: '200px',
        justifyContent: 'space-around',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '300px' : '250px',
          height: '200px',
          overflow: 'hidden',
        }}
      >
        <PieChart3DComponent
          datasets={[
            {
              title: 'Canceladas',
              amount: tripCounterData?.canceledTrips || 0,
              color: '#E45959',
            },
            {
              title: 'Recorrentes',
              amount: tripCounterData?.finishedRecurringTrips || 0,
              color: '#1C869D',
            },
            {
              title: 'Finalizadas',
              amount: tripCounterData?.finishedTrips || 0,
              color: '#1C659D',
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '5px',
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            padding: '10px',
          }}
        >
          <button
            onClick={() => setSelectedTotalTripsChartOption('finished')}
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                background: '#1C659D',
                width: '12px',
                height: '12px',
                borderRadius: '12px',
                marginRight: '8px',
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? 'rgba(51, 51, 51, 1)'
                    : '#DFDFDF',
              }}
            >
              Solicitações finalizadas
            </Typography>
          </button>
          <button
            onClick={() => setSelectedTotalTripsChartOption('canceled')}
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                background: '#E45959',
                width: '12px',
                height: '12px',
                borderRadius: '12px',
                marginRight: '8px',
              }}
            />
            <Typography
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? 'rgba(51, 51, 51, 1)'
                    : '#DFDFDF',
              }}
              variant="body2"
            >
              Canceladas
            </Typography>
          </button>
          <button
            onClick={() => setSelectedTotalTripsChartOption('recurring')}
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                background: '#1C869D',
                width: '12px',
                height: '12px',
                borderRadius: '12px',
                marginRight: '8px',
              }}
            />
            <Typography
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? 'rgba(51, 51, 51, 1)'
                    : '#DFDFDF',
              }}
              variant="body2"
            >
              Recorrentes
            </Typography>
          </button>
          <button
            onClick={() => setSelectedTotalTripsChartOption('all')}
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? 'rgba(51, 51, 51, 1)'
                    : '#DFDFDF',
              }}
              variant="body2"
            >
              Todos
            </Typography>
          </button>
        </Box>
      </Box>
    </Box>
  );
}
