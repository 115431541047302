import { useUsersByCompany } from '@/features/users';
import { ISelectedOption } from '@/components';
import { SelectPassenger } from '../TripRequest/SelectPassenger';

interface IRequest {
  companyId: string;
  userIds: {
    key: number;
    id: string;
  }[];
  passengerId?: string;
  onChange: (event: ISelectedOption | null) => void;
  onClose: () => void;
  onAdd?: () => void;
}

export const SelectPassengerAsRenter = ({
  companyId,
  onChange,
  userIds,
  onClose,
  onAdd,
  passengerId,
}: IRequest) => {
  const { data, isLoading } = useUsersByCompany({ companyId });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <SelectPassenger
        onAdd={onAdd}
        passengerId={passengerId}
        data={data?.filter(user => user.accessType === 'user') || []}
        onChange={option => onChange(option)}
        onClose={() => onClose()}
        userIds={userIds}
      />
    </>
  );
};
