import { PageLayout } from '@/components';
import { Accessibility } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { ProfileContainer } from '../components/ProfileContainer';

export const ProfileList = () => {
  const { subTitle } = useCustomTheme();
  return (
    <PageLayout
      title="Perfis"
      subTitle={subTitle}
      icon={<Accessibility fontSize="large" style={{ color: '#0ebcdf' }} />}
    >
      <ProfileContainer />
    </PageLayout>
  );
};
