import * as z from 'zod';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { InputField } from '@/components/Form';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ConfirmModal } from '@/components';
import { useUpdateFixedPrice } from '../api';

interface IUpdatePriceList {
  id: string;
  addressFrom: string;
  addressTo: string;
  distance: number;
  price: number;
}

const schema = z.object({
  addressFrom: z.string({ required_error: 'Campo obrigatório' }),
  addressTo: z.string({ required_error: 'Campo obrigatório' }),
  distance: z.number(),
  price: z.number(),
});

export function UpdatePriceList({
  id,
  addressFrom,
  addressTo,
  distance,
  price,
}: IUpdatePriceList) {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { mutateAsync } = useUpdateFixedPrice();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
    form: {
      padding: '40px',
    },
  }));

  const classes = useStyles({ isMobile });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      addressFrom,
      addressTo,
      distance,
      price,
    },
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: IUpdatePriceList) => {
    const data = { ...values, id };
    mutateAsync({ data });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box>
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <ConfirmModal
          close={closeDirtyFormModal}
          isOpen={isFormDirty}
          description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
          handleConfirmation={() => navigate(-1)}
        />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'red',
              }}
            >
              Editar Preço
            </Typography>
            <Grid container>
              <Grid md={6} xs={12} item>
                <Controller
                  name="addressFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="addressFrom"
                      type="text"
                      label="Partida"
                      onChange={onChange}
                      value={value}
                      placeholder="Partida"
                      errorMessage={formState.errors.addressFrom?.message}
                      error={!!formState.errors.addressFrom}
                    />
                  )}
                />
                <Controller
                  name="addressTo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="addressTo"
                      type="text"
                      label="Destino"
                      onChange={onChange}
                      value={value}
                      placeholder="Destino"
                      errorMessage={formState.errors.addressTo?.message}
                      error={!!formState.errors.addressTo}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className={classes.wrapper}>
                  <Controller
                    name="distance"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        InputProps={{
                          inputProps: { min: 0, step: 0.01 },
                        }}
                        size="small"
                        name="distance"
                        type="text"
                        label="Distância (Km)"
                        onChange={data =>
                          onChange(Number(data.target.value.replace(/\D/, '')))
                        }
                        value={value}
                        placeholder="2"
                        errorMessage={formState.errors.distance?.message}
                        error={!!formState.errors.distance}
                      />
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                          inputProps: { min: 0, step: 0.01 },
                        }}
                        size="small"
                        name="price"
                        type="text"
                        label="Valor cobrado (R$)"
                        onChange={data =>
                          onChange(Number(data.target.value.replace(/\D/, '')))
                        }
                        value={value}
                        placeholder="20"
                        errorMessage={formState.errors.price?.message}
                        error={!!formState.errors.price}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                <Button variant="contained" color="success" type="submit">
                  Salvar
                </Button>
                <Button
                  onClick={() => handleGoBack()}
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </Button>
              </Box>
            </Grid>
          </>
        </form>
      </Paper>
    </Box>
  );
}
