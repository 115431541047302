/* eslint-disable react/destructuring-assignment */
import { ConfirmModal, ContentLayout, InputField } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { useAuth } from '@/providers/auth';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useRoles, useUpdateCompanyNew } from '../api';
import { Companies } from '../types';

type Props = {
  companiesQuery?: Companies;
  rolesQuery?: any;
};

export const CompanyUpdate = ({ companiesQuery, rolesQuery }: Props) => {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const [trackerId, setTrackerId] = useState(companiesQuery?.trackerId || '');
  const navigate = useNavigate();
  const { data, isLoading } = useRoles();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
    form: {
      padding: '40px',
    },
  }));
  const classes = useStyles({ isMobile });

  const paymentOptions = [
    {
      value: 'Padrão',
      label: 'Padrão',
    },
    {
      value: 'Tabelado',
      label: 'Tabelado',
    },
    {
      value: 'Tarifado',
      label: 'Tarifado',
    },
  ];

  const multiplyOptions = [
    {
      value: '',
      label: 'Selecione...',
    },
    {
      value: 2,
      label: 'Sim',
    },
    {
      value: 0,
      label: 'Não',
    },
  ];

  const chargeInitialDisplacementOptions = [
    {
      value: 1,
      label: 'Sim',
    },
    {
      value: 0,
      label: 'Não',
    },
  ];

  const { mutateAsync, isLoading: loadingUpdate } = useUpdateCompanyNew();

  const preloadedValues = {
    password: '',
    email: companiesQuery?.email,
    corporateName: companiesQuery?.corporateName,
    fantasyName: companiesQuery?.fantasyName,
    industry: companiesQuery?.industry,
    number: companiesQuery?.number,
    registeredNumber: companiesQuery?.registeredNumber,
    phone: companiesQuery?.phone?.toString(),
    personToContact: companiesQuery?.personToContact,
    zipCode: companiesQuery?.zipCode?.toString(),
    address: companiesQuery?.address,
    state: companiesQuery?.state,
    city: companiesQuery?.city,
    neighborhood: companiesQuery?.neighborhood,
    initialPrice: companiesQuery?.priceList[0].initialPrice,
    pricePerKm: companiesQuery?.priceList[0].pricePerKm,
    cancelationPrice: companiesQuery?.priceList[0].cancelationPrice,
    driverFees: companiesQuery?.priceList[0]?.driverFees || '0',
    payment: companiesQuery?.payment,
    multiply: companiesQuery?.priceList[0].multiply,
    chargeInitialDisplacement:
      companiesQuery?.priceList[0].chargeInitialDisplacement,
    userEmail: companiesQuery?.user[0]?.email,
    name: companiesQuery?.user[0]?.name,
    userPhone: companiesQuery?.user[0]?.phone,
    individualRegistration: companiesQuery?.user[0]?.individualRegistration,
    pricePerMinute: companiesQuery?.priceList[0].pricePerMinute,
    nightAdditional: companiesQuery?.priceList[0].nightAdditional,
    pricePerMinuteBeforeStart:
      companiesQuery?.priceList[0].pricePerMinuteBeforeStart,
    timeToWaitBeforeStartBillingWhenTheTripIsStarted:
      companiesQuery?.priceList[0]
        .timeToWaitBeforeStartBillingWhenTheTripIsStarted,
    timeToWaitBeforeStartBillingWhenTheTripIsNotStarted:
      companiesQuery?.priceList[0]
        .timeToWaitBeforeStartBillingWhenTheTripIsNotStarted,
  };

  const schema = z.object({
    email: z.string({ required_error: 'Campo obrigatório' }).email(),
    password: z.string().optional(),
    corporateName: z.string({ required_error: 'Campo obrigatório' }),
    fantasyName: z.string({ required_error: 'Campo obrigatório' }),
    industry: z.string({ required_error: 'Campo obrigatório' }),
    registeredNumber: z
      .string({ required_error: 'Campo obrigatório' })
      .max(18, 'Máximo 18 caracteres')
      .min(14, 'Minimo 14 caracteres'),
    phone: z
      .string({ required_error: 'Campo obrigatório' })
      .min(11, 'Minimo 11 caracteres')
      .max(15, 'Máximo de 15 caracteres'),
    personToContact: z.string({ required_error: 'Campo obrigatório' }),
    zipCode: z
      .string({ required_error: 'Campo obrigatório' })
      .min(8, 'Minimo 8 caracteres')
      .max(9, 'Máximo 9 caracteres'),
    address: z.string({ required_error: 'Campo obrigatório' }),
    number: z.string().optional(),
    city: z.string({ required_error: 'Campo obrigatório' }),
    state: z.string({ required_error: 'Campo obrigatório' }),
    neighborhood: z.string({ required_error: 'Campo obrigatório' }),
    pricePerKm: z.string().optional(),
    driverFees: z.string().optional(),
    nightAdditional: z.string().optional().nullable(),
    pricePerMinuteBeforeStart: z.string().optional().nullable(),
    pricePerMinute: z.string().optional().nullable(),
    timeToWaitBeforeStartBillingWhenTheTripIsStarted: z
      .string()
      .optional()
      .nullable(),
    timeToWaitBeforeStartBillingWhenTheTripIsNotStarted: z
      .string()
      .optional()
      .nullable(),
    cancelationPrice: z.string().optional(),
    initialPrice: z.string().optional(),
    payment: z.string({ required_error: 'Campo obrigatório' }),
    multiply: z.number(),
    chargeInitialDisplacement: z.number().default(1),
    userEmail: z.string({ required_error: 'Campo obrigatório' }),
    name: z.string(),
    userPhone: z
      .string({ required_error: 'Campo obrigatório' })
      .min(11, 'Minimo 11 caracteres')
      .max(15, 'Máximo de 15 caracteres'),
    individualRegistration: z
      .string({ required_error: 'Campo obrigatório' })
      .min(11, 'Deve ter pelo menos 11 caracteres'),
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const [roles, setRoles] = useState<string[]>([]);

  const handlePermitions = (role: { type: string; id: string }) => {
    const findRole = roles.find(data => data === role.id);

    if (findRole) {
      setRoles(data => data.filter(info => info !== role.id));
    } else {
      setRoles([...roles, role.id]);
    }
  };

  useEffect(() => {
    if (rolesQuery) {
      const filteredRoles = rolesQuery.map((role: { id: string }) => role.id);
      setRoles(filteredRoles);
    }
  }, [rolesQuery]);

  const OnSubmit = (values: any) => {
    const data = {
      ...values,
      initialPrice: parseFloat(values.initialPrice).toFixed(2),
      pricePerKm: parseFloat(values.pricePerKm).toFixed(2),
      cancelationPrice: parseFloat(values.cancelationPrice).toFixed(2),
      trackerId,
      status: true,
      userId: companiesQuery?.user[0].id,
      id: companiesQuery?.id,
      roles,
    };
    mutateAsync({ data });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentLayout>
      <Box
        sx={{ background: theme.palette.mode === 'dark' ? '#212121' : '#FFF' }}
      >
        <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
          <ConfirmModal
            close={closeDirtyFormModal}
            isOpen={isFormDirty}
            description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
            handleConfirmation={() => navigate(-1)}
          />
          <form className={classes.form} onSubmit={handleSubmit(OnSubmit)}>
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Editando empresa
              </Typography>
              <Grid container>
                <Grid item sm={4} xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="email"
                        type="email"
                        label="Email"
                        onChange={onChange}
                        value={value}
                        placeholder="Email da empresa"
                        errorMessage={formState.errors.email?.message}
                        error={!!formState.errors.email}
                      />
                    )}
                  />

                  <Controller
                    name="corporateName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="corporateName"
                        type="text"
                        label="Razão Social"
                        onChange={onChange}
                        value={value}
                        placeholder="Nome real da empresa"
                        errorMessage={formState.errors.corporateName?.message}
                        error={!!formState.errors.corporateName}
                      />
                    )}
                  />

                  <Controller
                    name="fantasyName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="fantasyName"
                        type="text"
                        label="Nome Fantasia"
                        onChange={onChange}
                        value={value}
                        placeholder="Nome da empresa"
                        errorMessage={formState.errors.fantasyName?.message}
                        error={!!formState.errors.fantasyName}
                      />
                    )}
                  />

                  <Controller
                    name="industry"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="industry"
                        type="text"
                        label="Ramo"
                        onChange={onChange}
                        value={value}
                        placeholder="Ramo de atuação da empresa"
                        errorMessage={formState.errors.industry?.message}
                        error={!!formState.errors.industry}
                      />
                    )}
                  />

                  <Controller
                    name="registeredNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="registeredNumber"
                        type="text"
                        label="CNPJ"
                        onChange={onChange}
                        value={value}
                        placeholder="99.999.999/9999-99"
                        inputProps={{ minLength: 14, maxLength: 18 }}
                        errorMessage={
                          formState.errors.registeredNumber?.message
                        }
                        error={!!formState.errors.registeredNumber}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="phone"
                        type="text"
                        label="Telefone"
                        onChange={onChange}
                        value={value}
                        placeholder="(99) 99999-9999"
                        inputProps={{ minLength: 11, maxLength: 15 }}
                        errorMessage={formState.errors.phone?.message}
                        error={!!formState.errors.phone}
                      />
                    )}
                  />

                  <Controller
                    name="personToContact"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="personToContact"
                        type="text"
                        label="Pessoa para contato"
                        onChange={onChange}
                        value={value}
                        placeholder="Pessoa para contato na Empresa"
                        errorMessage={formState.errors.personToContact?.message}
                        error={!!formState.errors.personToContact}
                      />
                    )}
                  />

                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="personToContact"
                        type="text"
                        label="CEP"
                        inputProps={{ minLength: 8, maxLength: 9 }}
                        onChange={onChange}
                        value={value}
                        placeholder="99999-999"
                        errorMessage={formState.errors.zipCode?.message}
                        error={!!formState.errors.zipCode}
                      />
                    )}
                  />

                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="address"
                        type="text"
                        label="Endereço"
                        onChange={onChange}
                        value={value}
                        placeholder="Logradouro e número da empresa"
                        errorMessage={formState.errors.address?.message}
                        error={!!formState.errors.address}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="city"
                        type="text"
                        label="Cidade"
                        onChange={onChange}
                        value={value}
                        placeholder="Cidade"
                        errorMessage={formState.errors.city?.message}
                        error={!!formState.errors.city}
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="state"
                        type="text"
                        label="Estado"
                        onChange={onChange}
                        value={value}
                        placeholder="Estado"
                        errorMessage={formState.errors.state?.message}
                        error={!!formState.errors.state}
                      />
                    )}
                  />

                  <Controller
                    name="number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="number"
                        type="text"
                        label="Número"
                        onChange={onChange}
                        value={value}
                        placeholder="Número"
                        errorMessage={formState.errors.number?.message}
                        error={!!formState.errors.number}
                      />
                    )}
                  />

                  <Controller
                    name="neighborhood"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="neighborhood"
                        type="text"
                        label="Bairro"
                        onChange={onChange}
                        value={value}
                        placeholder="Bairro"
                        errorMessage={formState.errors.neighborhood?.message}
                        error={!!formState.errors.neighborhood}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <Box className={classes.wrapper}>
                    {user.user.accessType === 'master' && (
                      <Controller
                        name="driverFees"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            size="small"
                            name="driverFees"
                            type="number"
                            label="Taxa do motorista"
                            InputProps={{
                              inputProps: { min: 0, step: 0.01 },
                            }}
                            onChange={onChange}
                            value={value}
                            placeholder="0"
                            errorMessage={formState.errors.driverFees?.message}
                            error={!!formState.errors.driverFees}
                          />
                        )}
                      />
                    )}
                    <Controller
                      name="initialPrice"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="initialPrice"
                          type="number"
                          label="Preço inicial"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={formState.errors.initialPrice?.message}
                          error={!!formState.errors.initialPrice}
                        />
                      )}
                    />

                    <Controller
                      name="pricePerKm"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          name="pricePerKm"
                          type="number"
                          label="Preço por km"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={formState.errors.pricePerKm?.message}
                          error={!!formState.errors.pricePerKm}
                        />
                      )}
                    />

                    <Controller
                      name="cancelationPrice"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="cancelationPrice"
                          type="number"
                          label="Preço por cancelamento"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors.cancelationPrice?.message
                          }
                          error={!!formState.errors.cancelationPrice}
                        />
                      )}
                    />
                    <Controller
                      name="pricePerMinuteBeforeStart"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="pricePerMinuteBeforeStart"
                          type="number"
                          label="Preço por minuto esperado (antes de iniciar a corrida)"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors.pricePerMinuteBeforeStart?.message
                          }
                          error={!!formState.errors.pricePerMinuteBeforeStart}
                        />
                      )}
                    />
                    <Controller
                      name="pricePerMinute"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: 0.01 },
                          }}
                          size="small"
                          name="pricePerMinute"
                          type="number"
                          label="Preço por minuto esperado (depois de iniciar a corrida)"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors.pricePerMinute?.message
                          }
                          error={!!formState.errors.pricePerMinute}
                        />
                      )}
                    />
                    <Controller
                      name="timeToWaitBeforeStartBillingWhenTheTripIsNotStarted"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="timeToWaitBeforeStartBillingWhenTheTripIsNotStarted"
                          type="number"
                          label="Tempo de tolerância antes de iniciar a corrida (em minutos)"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors
                              .timeToWaitBeforeStartBillingWhenTheTripIsNotStarted
                              ?.message
                          }
                          error={
                            !!formState.errors
                              .timeToWaitBeforeStartBillingWhenTheTripIsNotStarted
                          }
                        />
                      )}
                    />
                    <Controller
                      name="timeToWaitBeforeStartBillingWhenTheTripIsStarted"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="timeToWaitBeforeStartBillingWhenTheTripIsStarted"
                          type="number"
                          label="Tempo de tolerância depois de iniciar a corrida (em minutos)"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors
                              .timeToWaitBeforeStartBillingWhenTheTripIsStarted
                              ?.message
                          }
                          error={
                            !!formState.errors
                              .timeToWaitBeforeStartBillingWhenTheTripIsStarted
                          }
                        />
                      )}
                    />

                    <Controller
                      name="nightAdditional"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="nightAdditional"
                          type="number"
                          label="Adicional noturno (em %)"
                          onChange={onChange}
                          value={value}
                          placeholder="0"
                          errorMessage={
                            formState.errors.nightAdditional?.message
                          }
                          error={!!formState.errors.nightAdditional}
                        />
                      )}
                    />

                    <Controller
                      name="chargeInitialDisplacement"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="chargeInitialDisplacement"
                          type="text"
                          select
                          label="Cobrar deslocamento inicial?"
                          onChange={onChange}
                          value={value}
                          placeholder="R$: 00.00"
                          errorMessage={
                            formState.errors.chargeInitialDisplacement?.message
                          }
                          error={!!formState.errors.chargeInitialDisplacement}
                        >
                          {chargeInitialDisplacementOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      )}
                    />

                    <Controller
                      name="payment"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="payment"
                          type="text"
                          select
                          label="Método de cobrança"
                          onChange={onChange}
                          value={value}
                          errorMessage={formState.errors.payment?.message}
                          error={!!formState.errors.payment}
                        >
                          {paymentOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      )}
                    />

                    <Controller
                      name="multiply"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="multiply"
                          type="text"
                          select
                          label="Cobrar retorno à base?"
                          onChange={onChange}
                          value={value}
                          placeholder="R$: 00.00"
                          errorMessage={formState.errors.multiply?.message}
                          error={!!formState.errors.multiply}
                        >
                          {multiplyOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box className={classes.wrapper}>
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? 'white' : 'red',
                        fontSize: '22px',
                      }}
                    >
                      Editando usuário administrador
                    </Typography>

                    <Controller
                      name="userEmail"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="userEmail"
                          type="text"
                          label="Login"
                          onChange={onChange}
                          value={value}
                          placeholder="Login do usuário master"
                          errorMessage={formState.errors.userEmail?.message}
                          error={!!formState.errors.userEmail}
                        />
                      )}
                    />

                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="name"
                          type="text"
                          label="Nome"
                          onChange={onChange}
                          value={value}
                          placeholder="Nome do usuário"
                          errorMessage={formState.errors.name?.message}
                          error={!!formState.errors.name}
                        />
                      )}
                    />

                    <Controller
                      name="userPhone"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="userPhone"
                          type="text"
                          label="Telefone"
                          onChange={onChange}
                          value={value}
                          placeholder="(99) 99999-9999"
                          inputProps={{ minLength: 11, maxLength: 14 }}
                          errorMessage={formState.errors.userPhone?.message}
                          error={!!formState.errors.userPhone}
                        />
                      )}
                    />

                    <Controller
                      name="individualRegistration"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="individualRegistration"
                          type="text"
                          label="CPF"
                          onChange={onChange}
                          value={value}
                          placeholder="999.999.999-99"
                          inputProps={{ minLength: 11, maxLength: 14 }}
                          errorMessage={
                            formState.errors.individualRegistration?.message
                          }
                          error={!!formState.errors.individualRegistration}
                        />
                      )}
                    />

                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          size="small"
                          name="password"
                          type="password"
                          label="Senha"
                          onChange={onChange}
                          value={value}
                          placeholder="Senha"
                          errorMessage={formState.errors.password?.message}
                          error={!!formState.errors.password}
                        />
                      )}
                    />
                    {user.user.accessType === 'master' && (
                      <>
                        <Box sx={{ margin: '10px' }}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'white' : 'red',
                              fontSize: '22px',
                            }}
                          >
                            Permissões
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {isLoading ? (
                              <Box>Carregando</Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  marginTop: '10px',
                                }}
                              >
                                {data?.map((role: any) => (
                                  <FormControlLabel
                                    sx={{ flex: '1' }}
                                    key={role.id}
                                    control={
                                      <Checkbox
                                        checked={
                                          !!roles.find(data => data === role.id)
                                        }
                                        onChange={() => handlePermitions(role)}
                                      />
                                    }
                                    label={role.type}
                                  />
                                ))}
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ margin: '10px' }}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'white' : 'red',
                              fontSize: '22px',
                            }}
                          >
                            Id do Monitoramento
                          </Typography>

                          <TextField
                            sx={{ width: '90px' }}
                            size="small"
                            name="trackerId"
                            type="text"
                            label="Id"
                            onChange={e => setTrackerId(e.target.value)}
                            value={trackerId}
                            placeholder="111"
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={loadingUpdate}
                  >
                    {loadingUpdate ? 'Carregando...' : 'Salvar'}
                  </Button>
                  <Button
                    onClick={() => handleGoBack()}
                    variant="contained"
                    color="primary"
                    disabled={loadingUpdate}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            </>
          </form>
        </Paper>
      </Box>
    </ContentLayout>
  );
};
