import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

interface IRequest {
  id: string;
  email?: string;
  password?: string;
  name: string;
  urlImage?: string;
  phone: string;
  registration?: string;
  roleProfileId: string;
  thirdPartyCompany?: string;
  individualRegistration: string;
  sectorId: any;
  sectorName: string;
  willHaveAccess?: boolean;
  accessType: string | number;
  status: boolean;
  companyId: string;
  voucher?: number;
  sector?: {
    id: string;
    name: string;
  };
}

interface IOutput {
  accessType: string;
  companyId: string;
  email: string;
  id: string;
  individualRegistration: string;
  name: string;
  phone: string;
  sectorId: string;
  sectorName: string;
  status: boolean;
}

export async function createPassengerAsRenter(
  data: IRequest,
): Promise<IOutput> {
  const response = await axios.post('users/registerPassengerAsRenter', data);
  return response.data.payload;
}

export const useCreatePassengerAsRenter = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newUser => {
      await queryClient.cancelQueries('usersByCompany');

      const previousUsers =
        queryClient.getQueryData<IRequest[]>('usersByCompany');

      queryClient.setQueryData('usersByCompany', [
        ...(previousUsers || []),
        newUser,
      ]);

      return previousUsers;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('usersByCompany');
      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Usuário salvo com sucesso.',
      });
    },
    mutationFn: createPassengerAsRenter,
  });
};
