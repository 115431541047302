import { useNavigate } from 'react-router-dom';
import {
  Button,
  MenuItem,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Table as MuiTable,
  useMediaQuery,
  TableCell,
  useTheme,
} from '@mui/material';

// import { Table } from '@/components/Elements/Table';
import { Modal } from '@/components';
import { useState } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';
import { useCompaniesPrice, useCompaniesWithPrice } from '../api';
import { DeleteCompanyPrice } from './DeleteCompanyPrice';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';
// import { CompanyValues } from '../types';

export function CompaniesPriceListTable() {
  const navigate = useNavigate();
  const { close, toggle, isOpen } = useDisclosure();
  const [dataId, setDataId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [company, setCompany] = useState<string | undefined>();

  const { data: companiesWithPriceData } = useCompaniesWithPrice();

  const { isLoading, data, refetch } = useCompaniesPrice(company);

  const handleDeleteToggle = (id: string) => {
    toggle();
    setDataId(id);

    setOpenDeleteModal(true);
  };

  const handleChange = (id: string) => {
    setCompany(id);
    refetch();
  };
  const companyPriceListArray: {
    id: string;
    corporateName: string;
    addressFrom: string;
    addressTo: string;
    distance: string;
    price: string;
  }[] = data?.map(
    (info: {
      corporateName: string;
      addressFrom: string;
      addressTo: string;
      price: number;
    }) => {
      return {
        ...info,
        price: info.price.toFixed(2).replace('.', ','),
      };
    },
  );

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('/pricetable/create')}
        >
          Cadastrar novo preço
        </Button>
        {!!companiesWithPriceData?.length && (
          <TextField
            label="Selecione"
            select
            size="small"
            value={company || ''}
            sx={{ marginLeft: '0.5rem', width: '200px' }}
            onChange={e => handleChange(e.target.value)}
          >
            {companiesWithPriceData?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.corporateName}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Toolbar>

      {data === undefined ? (
        <>
          {isMobile ? (
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Partida</TableCell>
                  <TableCell>Destino</TableCell>
                  <TableCell>Valor cobrado (R$)</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
            </MuiTable>
          ) : (
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Empresa</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Partida</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Destino</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Distância (Km)
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Valor cobrado (R$)
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Ações</TableCell>
                </TableRow>
              </TableHead>
            </MuiTable>
          )}
        </>
      ) : (
        <>
          {isMobile ? (
            <MobileTable
              tableData={companyPriceListArray}
              toggleDelete={handleDeleteToggle}
              loading={isLoading}
            />
          ) : (
            <DesktopTable
              tableData={companyPriceListArray}
              toggleDelete={handleDeleteToggle}
              loading={isLoading}
            />
          )}
        </>
      )}
      {isOpen && openDeleteModal && (
        <Modal
          title="Excluir preço tabelado"
          dialogContent={<DeleteCompanyPrice close={close} id={dataId} />}
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
}
