import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { listSectorsResponse } from '../types';

// AxiosResponse
// CompanyValues

export async function getSectorsList(
  id?: string,
): Promise<listSectorsResponse[]> {
  const response = await axios.get('/sectors/getAll');
  return response.data.payload;
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getSectorsList>;
};

export const useSectorsList = ({ config }: UseCompaniesOptions = {}) => {
  return useQuery({
    queryKey: ['sectors'],
    queryFn: () => getSectorsList(),
    ...config,
  });
};
