import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useMutation } from 'react-query';

interface IRequest {
  userId: string;
  startedAt: string;
  enteredLunchAt: string;
  exitedLunchAt: string;
  finishedAt: string;
}

const addWorkJourney = async ({
  enteredLunchAt,
  exitedLunchAt,
  finishedAt,
  startedAt,
  userId,
}: IRequest): Promise<void> => {
  await axios.post(`/clockIn/addWorkJourneyAsManager/${userId}`, {
    enteredLunchAt,
    exitedLunchAt,
    finishedAt,
    startedAt,
  });
};

export const useAddWorkJourney = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('workjourney');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workjourney');
      addNotification({
        type: 'success',
        title: 'Criado com sucesso',
      });
    },
    mutationFn: addWorkJourney,
  });
};
