import TrafficIcon from '@mui/icons-material/Traffic';
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import { Tooltip } from '@mui/material';
import { ConfigButton } from '@/components';

export const TrafficLayerConfiguration = ({
  trafficMap,
  transitMap,
  ciclistMap,
}: {
  trafficMap: () => void;
  transitMap: () => void;
  ciclistMap: () => void;
}) => {
  const buttonOptions = [
    {
      handle: () => trafficMap(),
      component: (
        <Tooltip title="Trânsito">
          <TrafficIcon />
        </Tooltip>
      ),
      id: '1',
    },
    {
      handle: () => transitMap(),
      component: (
        <Tooltip title="Transporte público">
          <DirectionsTransitFilledIcon />
        </Tooltip>
      ),
      id: '2',
    },
    {
      handle: () => ciclistMap(),
      component: (
        <Tooltip title="Ciclista">
          <DirectionsBikeIcon />
        </Tooltip>
      ),
      id: '3',
    },
  ];
  return (
    <>
      {buttonOptions.map(button => (
        <ConfigButton key={button.id} handle={button.handle}>
          {button.component}
        </ConfigButton>
      ))}
    </>
  );
};
