import { AddCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { IStopPoint } from '.';

interface IStopPoints {
  setStopPoints: (stopPoints: any) => void;
  stopPoints: IStopPoint[];
}

export const StopPoints = ({ setStopPoints, stopPoints }: IStopPoints) => {
  const handleAddStopPoints = () => {
    setStopPoints((prev: any) => [...prev, { order: Date.now() }]);
  };
  return (
    <>
      <Tooltip title="Adicionar parada">
        <IconButton onClick={() => handleAddStopPoints()}>
          <AddCircle sx={{ color: 'green' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};
