import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { PageLayout } from '@/components';
import { useCustomTheme } from '@/providers/theme';
import { DirectionsCarFilled } from '@mui/icons-material';
import { TripsValidation } from '../components/TripsValidation';
import { SendTrip } from './SendTrip';
import { TripRequest } from './TripRequest';
import { SectorTrips } from '../components/SectorTrips';

export const TripRequestRoute = (): JSX.Element => {
  const { subTitle } = useCustomTheme();
  return (
    <Can permissions={['renter']}>
      <Routes>
        <Route
          path="/"
          element={
            <PageLayout
              title="Viagens"
              subTitle={subTitle}
              icon={
                <DirectionsCarFilled
                  fontSize="large"
                  style={{ color: '#0ebcdf' }}
                />
              }
            >
              <TripRequest />
            </PageLayout>
          }
        />
      </Routes>
    </Can>
  );
};

export const ValidationRoute = (): JSX.Element => {
  const { subTitle } = useCustomTheme();
  return (
    <Can permissions={['sector_admin']}>
      <Routes>
        <Route
          path="/"
          element={
            <PageLayout
              title="Viagens"
              subTitle={subTitle}
              icon={
                <DirectionsCarFilled
                  fontSize="large"
                  style={{ color: '#0ebcdf' }}
                />
              }
            >
              <TripsValidation />
            </PageLayout>
          }
        />
        <Route
          path="/request"
          element={
            <PageLayout
              title="Viagens"
              subTitle={subTitle}
              icon={
                <DirectionsCarFilled
                  fontSize="large"
                  style={{ color: '#0ebcdf' }}
                />
              }
            >
              <SendTrip />
            </PageLayout>
          }
        />
      </Routes>
    </Can>
  );
};

export const RequestAsAdminRoute = (): JSX.Element => {
  const { subTitle } = useCustomTheme();
  return (
    <Can permissions={['company_admin', 'sector_admin']}>
      <Routes>
        <Route
          path="/"
          element={
            <PageLayout
              title="Viagens"
              subTitle={subTitle}
              icon={
                <DirectionsCarFilled
                  fontSize="large"
                  style={{ color: '#0ebcdf' }}
                />
              }
            >
              <SectorTrips />
            </PageLayout>
          }
        />
      </Routes>
    </Can>
  );
};
