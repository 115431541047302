/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface IPdf {
  data: any[];
  headers: {
    label: string;
    key: string;
  }[];
  totalPrice?: number;
}

export const Pdf = ({ data, headers, totalPrice }: IPdf) => {
  const columns = headers
    .filter((data: any) => data.label !== 'Ações')
    .map((column: { label: string; key: string }) => {
      return {
        title: column.label,
        field: column.key,
        dataKey: column.key,
      };
    });
  const doc = new jsPDF('l');
  autoTable(doc, {
    theme: 'striped',
    columns,
    body: data,
    startY: 30,
  });

  if (totalPrice) {
    doc
      .setFont('sans-serif', 'bold')
      .setFontSize(20)
      .text(`Valor total: R$: ${totalPrice.toFixed(2)}`, 10, 10);
  }

  if (data[0]?.corporateName) {
    doc.save(`relatorio-${data[0]?.corporateName}-${Date.now()}`);
  } else {
    doc.save(`relatorio-${Date.now()}.pdf`);
  }
};
