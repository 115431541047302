import { PageLayout } from '@/components';
import { Coffee } from '@mui/icons-material';
import { useCustomTheme } from '@/providers/theme';
import { SectorsListTable } from '../components/SectorsListTable';

export function SectorsList() {
  const { subTitle } = useCustomTheme();
  return (
    <>
      <PageLayout
        title="Setores"
        subTitle={subTitle}
        icon={<Coffee fontSize="large" style={{ color: '#0ebcdf' }} />}
      >
        <SectorsListTable />
      </PageLayout>
    </>
  );
}
