import { NavItem } from './NavItem';

type NavItem = {
  icon: React.ElementType;
  label: string;
  path: string;
  className: string;
  permissions?: string[];
  restriction?: string;
  roles?: string[];
};

interface RenderNavItensProps {
  navItens: NavItem[];
}

export function RenderNavItens({ navItens }: RenderNavItensProps) {
  return (
    <>
      {navItens.map((item, index) => {
        return (
          <NavItem
            // eslint-disable-next-line react/no-array-index-key
            key={item.label + index}
            icon={item.icon}
            label={item.label}
            path={item.path}
            className={item.className}
            permissions={item.permissions}
            restriction={item.restriction}
            roles={item.roles}
          />
        );
      })}
    </>
  );
}
