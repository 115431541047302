import { ConfirmModal, ContentLayout, InputField } from '@/components';
import {
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { phoneMask } from '@/utils/maskShared';
import { useState } from 'react';
import { useAuth } from '@/providers/auth';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks/useDisclosure';
import { listSectorsResponse, useUpdateSector } from '..';

export const UpdateSector = ({
  sectorQuery,
}: {
  sectorQuery: listSectorsResponse | undefined;
}) => {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { accessType } = user.user;
  const theme = useTheme();
  const schema = z.object({
    name: z.string().optional(),
    phone: z.string().optional(),
    limitOfTrips: z.string().optional(),
    limitOfCosts: z.string().optional(),
    sectorId: z.any().optional(),
  });
  const { isLoading, mutateAsync } = useUpdateSector();

  const preloadedValues = {
    name: sectorQuery?.name,
    phone: sectorQuery?.phone,
    limitOfTrips: sectorQuery?.limitOfTrips?.toString() || '',
    limitOfCosts: sectorQuery?.limitOfCosts?.toString() || '',
    sectorId: sectorQuery?.sectorId || undefined,
  };

  const { handleSubmit, control, formState, watch, setValue, getValues } =
    useForm({
      defaultValues: preloadedValues,
      resolver: schema && zodResolver(schema),
    });

  watch('limitOfTrips');
  watch('limitOfCosts');

  const [hasTripLimit, setHasTripLimit] = useState(!!sectorQuery?.limitOfTrips);
  const [hasPriceLimit, setHasPriceLimit] = useState(
    !!sectorQuery?.limitOfCosts,
  );
  const [blockAtLimit, setBlockAtLimit] = useState(sectorQuery?.blockAtLimit);

  const OnSubmit = (values: any) => {
    const data = {
      ...values,
      companyId: sectorQuery?.companyId,
      id: sectorQuery?.id,
      blockAtLimit,
    };

    mutateAsync(data);
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentLayout>
      <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
        <ConfirmModal
          close={closeDirtyFormModal}
          isOpen={isFormDirty}
          description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
          handleConfirmation={() => navigate(-1)}
        />
        <Box>
          <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Editando setor
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="name"
                        type="text"
                        label="Nome"
                        onChange={onChange}
                        value={value}
                        disabled={accessType !== 'company_admin'}
                        errorMessage={formState.errors.name?.message}
                        error={!!formState.errors.name}
                      />
                    )}
                  />

                  <Controller
                    name="sectorId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="sectorId"
                        type="text"
                        label="ID do setor"
                        onChange={onChange}
                        value={value}
                        disabled={accessType !== 'company_admin'}
                        errorMessage={formState.errors.sectorId?.message}
                        error={!!formState.errors.sectorId}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        size="small"
                        name="phone"
                        type="text"
                        label="Telefone"
                        disabled={accessType !== 'company_admin'}
                        inputProps={{ minLength: 8, maxLength: 15 }}
                        value={value}
                        onChange={data => {
                          onChange(
                            phoneMask(data.target.value.replace(/[^\d]+/g, '')),
                          );
                        }}
                        errorMessage={formState.errors.phone?.message}
                        error={!!formState.errors.phone}
                      />
                    )}
                  />
                </Grid>
                <Grid container>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                    control={
                      <Checkbox
                        onChange={() => {
                          if (hasTripLimit) {
                            setValue('limitOfTrips', '');
                          }
                          setHasTripLimit(prev => !prev);
                        }}
                        checked={hasTripLimit}
                      />
                    }
                    label="Limite de corridas"
                  />
                </Grid>
                {hasTripLimit && (
                  <Grid sm={6} xs={12} item>
                    <Controller
                      name="limitOfTrips"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <InputField
                          size="small"
                          name="limitOfTrips"
                          type="number"
                          label=""
                          onChange={onChange}
                          value={getValues('limitOfTrips')}
                          errorMessage={formState.errors.limitOfTrips?.message}
                          error={!!formState.errors.limitOfTrips}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid container>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                    control={
                      <Checkbox
                        onChange={() => {
                          if (hasPriceLimit) {
                            setValue('limitOfCosts', '');
                          }
                          setHasPriceLimit(prev => !prev);
                        }}
                        checked={hasPriceLimit}
                      />
                    }
                    label="Limite de preço"
                  />
                </Grid>
                {hasPriceLimit && (
                  <Grid sm={6} xs={12} item>
                    <Controller
                      name="limitOfCosts"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <InputField
                          size="small"
                          name="limitOfCosts"
                          type="number"
                          label=""
                          onChange={onChange}
                          value={getValues('limitOfCosts')}
                          errorMessage={formState.errors.limitOfCosts?.message}
                          error={!!formState.errors.limitOfCosts}
                        />
                      )}
                    />
                  </Grid>
                )}
                {(hasPriceLimit || hasTripLimit) &&
                  accessType === 'company_admin' && (
                    <Grid container>
                      <FormControlLabel
                        sx={{
                          color:
                            theme.palette.mode === 'dark' ? 'white' : 'black',
                        }}
                        control={
                          <Checkbox
                            onChange={() => setBlockAtLimit(prev => !prev)}
                            checked={blockAtLimit}
                          />
                        }
                        label="Bloquear solicitações ao atingir o limite"
                      />
                    </Grid>
                  )}
              </Grid>
              <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                <Button
                  sx={{ width: '200px' }}
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Carregando...' : 'Salvar'}
                </Button>
                <Button
                  onClick={() => handleGoBack()}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Voltar
                </Button>
              </Box>
            </>
          </form>
        </Box>
      </Paper>
    </ContentLayout>
  );
};
