import { Box } from '@mui/material';
import { IWarning } from '../types';
import { Warning } from './Warning';

interface IRequest {
  warnings: IWarning[];
}

export const Warnings = ({ warnings }: IRequest) => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '10px',
        height: '100vh',
        overflowY: 'scroll',
      }}
    >
      {warnings.map(warning => (
        <Warning
          key={warning.plate}
          date={warning.date}
          plate={warning.plate}
          noCommunicationTime={warning.noCommunicationTime}
        />
      ))}
    </Box>
  );
};
