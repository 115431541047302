import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

export interface IGetGoogleRequestsRequest {
  startDate: string;
  endDate: string;
}

interface IGetGoogleRequestsResponse {
  id: string;
  client: string;
  api: string;
  createdAt: string;
  description: string;
  date: string;
  userName: string;
}

const getRequests = async ({
  startDate,
  endDate,
}: IGetGoogleRequestsRequest): Promise<IGetGoogleRequestsResponse[]> => {
  const response = await axios.get(
    `/googleRequests/requests?startDate=${startDate}&endDate=${endDate}`,
  );
  return response.data.payload;
};

export const useGetRequests = ({
  endDate,
  startDate,
}: IGetGoogleRequestsRequest) => {
  return useQuery({
    enabled: !!endDate && !!startDate,
    queryKey: ['getRequests', endDate, startDate],
    queryFn: () => getRequests({ endDate, startDate }),
  });
};
