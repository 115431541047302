import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { AcceptTripResponse, ValidateTripProps } from '../types';

export async function validatedTrip(
  data: ValidateTripProps,
): Promise<AcceptTripResponse> {
  const response = await axios.patch(`/trips/validateTrip/${data.id}`, data);
  return response.data.payload;
}

export const useValidateTrip = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async acceptedTrip => {
      await queryClient.cancelQueries('tripsToBeValidated');
      await queryClient.cancelQueries('sector-trips');

      const previoustrips =
        queryClient.getQueryData<any[]>('tripsToBeValidated');

      queryClient.setQueryData('tripsToBeValidated', [
        previoustrips?.filter(trip => trip !== acceptedTrip),
      ]);

      return previoustrips;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('tripsToBeValidated', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data.message,
        });
      }
    },
    onSuccess: newData => {
      queryClient.invalidateQueries('tripsToBeValidated');
      queryClient.invalidateQueries('sector-trips');
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
    },
    mutationFn: validatedTrip,
  });
};
