import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';

export type IUpdateFixedPriceData = {
  data: {
    id: string;
    addressFrom: string;
    addressTo: string;
    price: number;
    distance: number;
  };
};

export const updateFixedPrice = ({
  data,
}: IUpdateFixedPriceData): Promise<{
  id: string;
  addressFrom: string;
  addressTo: string;
  price: number;
  distance: number;
}> => {
  return axios.put(`priceTable/update/${data.id}`, data);
};

type UseUpdateFixedPriceOptions = {
  config?: MutationConfig<typeof updateFixedPrice>;
};

export const useUpdateFixedPrice = ({
  config,
}: UseUpdateFixedPriceOptions = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingFixedPrice => {
      await queryClient.cancelQueries(['prices', updatingFixedPrice?.data.id]);

      const previousFixedPrice = queryClient.getQueryData<{
        id: string;
        addressFrom: string;
        addressTo: string;
        price: number;
        distance: number;
      }>(['prices', updatingFixedPrice?.data.id]);

      queryClient.setQueryData(['prices', updatingFixedPrice?.data.id], {
        ...previousFixedPrice,
        ...updatingFixedPrice.data,
        id: updatingFixedPrice.data.id,
      });

      return { previousFixedPrice };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFixedPrice) {
        queryClient.setQueryData(
          ['companies', context.previousFixedPrice.id],
          context.previousFixedPrice,
        );
      }
    },
    onSuccess: (_data, _variables, context) => {
      queryClient.refetchQueries(['companies', context.previousFixedPrice.id]);

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Preço atualizado com sucesso.',
      });
      navigate('/companies');
    },
    ...config,
    mutationFn: updateFixedPrice,
  });
};
