import { useTheme } from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { UseFormSetValue } from 'react-hook-form';

interface IPlacesAutoComplete {
  handleAddress: (
    val: any,
    setValue: UseFormSetValue<any> | any,
    id: number,
  ) => void;
  setValue: UseFormSetValue<any> | any;
  placeholder: string;
  id?: any;
  value?: any;
  clean: (setValue: any, id: number) => void;
}

export const PlacesAutoComplete = ({
  handleAddress,
  setValue,
  placeholder,
  id,
  clean,
  value,
}: IPlacesAutoComplete) => {
  const theme = useTheme();
  return (
    <GooglePlacesAutocomplete
      autocompletionRequest={{
        componentRestrictions: {
          country: ['br'],
        },
      }}
      selectProps={{
        styles: {
          control: provided => ({
            ...provided,
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }),
          option: provided => ({
            ...provided,
            color: 'gray',
          }),
          input: provided => ({
            ...provided,
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }),
          valueContainer: provided => ({
            ...provided,
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }),
          singleValue: provided => ({
            ...provided,
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }),
        },
        placeholder,
        isClearable: true,
        onChange: val => {
          if (!val) {
            clean(setValue, id);
            return;
          }
          handleAddress(val, setValue, id);
        },
      }}
      debounce={2000}
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    />
  );
};
