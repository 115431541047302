import { Loading } from '@/components';
import { useUsers } from '@/features/users';
import { MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';

type IProps = {
  user: string | undefined;
  setUser: (user: string) => void;
};

export const SelectUser = ({ user, setUser }: IProps) => {
  const { data: users, isLoading } = useUsers();

  useEffect(() => {
    if (!isLoading) {
      const passenger = users.filter(
        (user: { accessType: string }) => user.accessType === 'user',
      );
      setUser(passenger[0]?.id || null);
    }
  }, [isLoading, setUser, users]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }
  return (
    <TextField
      sx={{ width: '200px', color: '#FFF', marginTop: '20px' }}
      select
      onChange={(e: any) => setUser(e.target.value)}
      placeholder="Selecione o passageiro"
      label="Passageiro"
      value={user}
    >
      {users?.map((user: any) => {
        if (user.accessType !== 'user') return;
        // eslint-disable-next-line consistent-return
        return (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
