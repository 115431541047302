import { InputField } from '@/components';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { IVehicleClass } from '../types';
import { useUpdateClass } from '../api';

interface IRequest {
  vehicleClass: IVehicleClass;
}

const schema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  initialPrice: z.number({ required_error: 'Campo obrigatório' }),
  additionalFeePercentage: z.number({ required_error: 'Campo obrigatório' }),
});

export const UpdateVehicleClassComponent = ({ vehicleClass }: IRequest) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync, isLoading } = useUpdateClass();
  const preloadedValues = {
    name: vehicleClass.name,
    initialPrice: vehicleClass.initialPrice,
    additionalFeePercentage: vehicleClass.additionalFeePercentage,
    id: vehicleClass.id,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const onSubmit = (values: {
    name: string;
    initialPrice: number;
    additionalFeePercentage: number;
  }) => {
    mutateAsync({
      id: vehicleClass.id,
      name: values.name,
      initialPrice: values.initialPrice,
      additionalFeePercentage: values.additionalFeePercentage,
    });
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 40 }}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
          >
            Editando veículo
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                size="small"
                name="name"
                type="text"
                placeholder="Executivo"
                label="Classe"
                onChange={onChange}
                value={value}
                errorMessage={formState.errors.name?.message}
                error={!!formState.errors.name}
              />
            )}
          />
          <Controller
            name="initialPrice"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 0.01 },
                }}
                size="small"
                name="initialPrice"
                type="text"
                placeholder="0"
                label="Preço"
                onChange={e => onChange(Number(e.target.value))}
                value={value}
                errorMessage={formState.errors.initialPrice?.message}
                error={!!formState.errors.initialPrice}
              />
            )}
          />

          <Controller
            name="additionalFeePercentage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 0.01 },
                }}
                size="small"
                name="additionalFeePercentage"
                type="text"
                placeholder="0"
                label="Porcentagem de taxa adicional"
                onChange={e => onChange(Number(e.target.value))}
                value={value}
                errorMessage={formState.errors.additionalFeePercentage?.message}
                error={!!formState.errors.additionalFeePercentage}
              />
            )}
          />

          <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Button variant="contained" color="success" type="submit">
              {isLoading ? 'carregando...' : 'Salvar'}
            </Button>
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
