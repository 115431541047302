import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Companies } from '../types';

// AxiosResponse
// CompanyValues

export async function getCompanies(id?: string): Promise<Companies[] | any > {
  const response = await axios.get('companies/getAll');
  return response.data.payload;
}

type UseCompaniesOptions = {
  config?: QueryConfig<typeof getCompanies>;
};

export const useCompanies = ({ config }: UseCompaniesOptions = {}) => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: () => getCompanies(),
    ...config,
  });
};
