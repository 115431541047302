/* eslint-disable no-param-reassign */
import * as z from 'zod';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { InputField } from '@/components/Form';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRoles } from '@/features/companyNew';
import { CheckOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IProfile } from '../types';
import { useUpdateProfile } from '../api';

const schema = z.object({
  name: z.string({ required_error: 'Campo obrigatório' }),
});

export function UpdateProfileForm({ id, name, rolesHasPermissions }: IProfile) {
  const {
    isOpen: isFormDirty,
    open: openDirtyFormModal,
    close: closeDirtyFormModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useUpdateProfile();
  const { data: roles, isLoading: isLoadingRoles } = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    rolesHasPermissions?.map(permission => permission?.permissionId),
  );

  const handleChange = (roleId: string) => {
    setSelectedRoles(prevRoles => {
      if (prevRoles.includes(roleId)) {
        return prevRoles.filter(data => data !== roleId);
      }
      return [...prevRoles, roleId];
    });
  };

  const preloadedValues = {
    name,
    id,
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: preloadedValues,
    resolver: schema && zodResolver(schema),
  });

  const OnSubmit = (values: any) => {
    const data = {
      ...values,
      id,
    };
    mutateAsync({
      ...data,
      roles: selectedRoles,
    });
  };

  const handleGoBack = () => {
    if (Object.keys(formState.dirtyFields).length) {
      openDirtyFormModal();
    } else {
      navigate(-1);
    }
  };

  const theme = useTheme();
  return (
    <Paper
      sx={{
        maxWidth: '600px',
        margin: 'auto',
        padding: '15px',
        borderRadius: '20px',
      }}
    >
      <ConfirmModal
        close={closeDirtyFormModal}
        isOpen={isFormDirty}
        description="Existem informações alteradas não salvas, dejesa voltar assim mesmo?"
        handleConfirmation={() => navigate(-1)}
      />
      <Box>
        <form onSubmit={handleSubmit(OnSubmit)} style={{ padding: '40px' }}>
          <>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : '#515151',
                fontSize: '22px',
                textAlign: 'center',
                fontWeight: 'medium',
              }}
            >
              Editando perfil
            </Typography>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  size="small"
                  name="name"
                  type="text"
                  label="Nome"
                  onChange={onChange}
                  value={value}
                  errorMessage={formState.errors.name?.message}
                  error={!!formState.errors.name}
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: '10px',
                flexWrap: 'wrap',
              }}
            >
              {isLoadingRoles ? (
                <Box>Carregando</Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    margin: '10px 0px',
                    flexWrap: 'wrap',
                    gap: '15px',
                    justifyContent: 'center',
                  }}
                >
                  {roles
                    ?.filter(role => !role.isCompanyPermission)
                    ?.map((role: any) => (
                      <Box
                        key={role.id}
                        sx={{
                          padding: '4px 15px',
                          borderRadius: '10px',
                          border:
                            theme.palette.mode === 'dark'
                              ? '1px solid #56acee'
                              : '1px solid #0b7ed4',
                          background: selectedRoles.includes(role.id)
                            ? '#0b7ed4'
                            : 'transparent',
                          color: selectedRoles.includes(role.id)
                            ? 'white'
                            : '#0b7ed4',
                          display: 'flex',
                          gap: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleChange(role.id)}
                      >
                        {role.type}
                        {selectedRoles.includes(role.id) && <CheckOutlined />}
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
          </>
          <Box sx={{ display: 'flex', gap: '5px', marginTop: '10px' }}>
            <Button
              variant="contained"
              color="success"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? 'Carregando...' : 'Salvar'}
            </Button>
            <Button
              onClick={() => handleGoBack()}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
}
