import { Star } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

interface IRequest {
  onClick: () => void;
  isSelected?: boolean;
}

export const FavoriteAddressTooltip = ({ onClick, isSelected }: IRequest) => {
  return (
    <Tooltip title="Endereço favorito">
      <IconButton onClick={() => onClick()}>
        <Star color={isSelected ? 'warning' : 'disabled'} />
      </IconButton>
    </Tooltip>
  );
};
