import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DriversValues, ISaveDriver, saveDriverResponse } from '..';

export async function createDriver(
  data: ISaveDriver,
): Promise<saveDriverResponse> {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return axios.post('drivers/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const useCreateDriver = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation(createDriver, {
    onMutate: async newDriver => {
      await queryClient.cancelQueries('drivers');

      const previousDrivers =
        queryClient.getQueryData<DriversValues[]>('drivers');

      queryClient.setQueryData('drivers', [
        ...(previousDrivers || []),
        newDriver,
      ]);

      return previousDrivers;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('drivers', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('drivers');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Motorista cadastrado com sucesso.',
      });

      navigate('/drivers');
    },
  });
};
