/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import ptLocale from 'date-fns/locale/pt';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Delete } from '@mui/icons-material';
import { IStopPoint } from '../TripRequest';
import { StopPoints } from '../TripRequest/StopPoints';

import { useJoinTrips } from '../../api';

interface IProps {
  ids: string[];
  usersSharingTrip: {
    userId: string;
    name: string;
  }[];
  adressesFrom: {
    address: string;
    lat: number;
    long: number;
    city: string;
  }[];
  adressesTo: {
    address: string;
    lat: number;
    long: number;
    city: string;
  }[];
  stopPoints: {
    address: string;
    lat: number;
    long: number;
  }[];
  close: () => void;
}

export function JoinTrip({
  ids,
  usersSharingTrip,
  close,
  adressesFrom,
  adressesTo,
  stopPoints: preSelectedStopPoints = [],
}: IProps) {
  const { mutateAsync, isLoading } = useJoinTrips();
  const { addNotification } = useNotificationStore();
  const [stopPoints, setStopPoints] = useState<IStopPoint[]>([]);
  const [observation, setObservation] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>();
  const [origin, setOrigin] = useState<{
    startLat: number;
    startLong: number;
    addressFrom: string;
    city: string;
  } | null>(null);
  const [destination, setDestination] = useState<{
    finalLat: number;
    finalLong: number;
    addressTo: string;
    city: string;
  } | null>(null);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!origin?.addressFrom || !destination?.addressTo) {
      addNotification({
        type: 'error',
        title: 'Erro',
        message: 'Por favor, preencha todos os campos.',
      });
      return;
    }
    mutateAsync(
      {
        ids,
        usersSharingTrip,
        stopPoints: stopPoints
          .filter(data => !!data.address)
          .map(stopPoint => ({
            ...stopPoint,
            userId: usersSharingTrip[0].userId,
          })),
        addressFrom: origin.addressFrom,
        addressTo: destination.addressTo,
        finalLat: destination.finalLat,
        finalLong: destination.finalLong,
        startLat: origin.startLat,
        startLong: origin.startLong,
        cityOfDestination: destination.city,
        cityOfOrigin: origin.city,
        observation,
        scheduledDate: scheduledDate ? new Date(scheduledDate) : undefined,
      },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  const handleAddressChange = (order: number, newAddress: string) => {
    const options = [...adressesTo, ...adressesFrom, ...preSelectedStopPoints];
    const findAddress = options.find(data => data.address === newAddress);
    setStopPoints(
      stopPoints.map(data =>
        data.order === order
          ? {
              ...data,
              lat: findAddress?.lat || 0,
              long: findAddress?.long || 0,
              address: newAddress,
            }
          : data,
      ),
    );
  };

  const uniqueAddresses = [
    ...adressesTo,
    ...adressesFrom,
    ...preSelectedStopPoints,
  ].filter(
    (value, index, self) =>
      index === self.findIndex(t => t.address === value.address),
  );

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      <form onSubmit={handleSave}>
        <Box sx={{ width: '300px' }}>
          Passageiros: {usersSharingTrip.map(({ name }) => name).join(', ')}
        </Box>
        <Box sx={{ width: '300px', marginTop: '15px', zIndex: 99999 }}>
          <TextField
            label="Origem"
            value={origin?.addressFrom}
            onChange={value => {
              const findAddress = adressesFrom.find(
                data => data.address === value.target.value,
              );
              setOrigin({
                startLat: findAddress?.lat || 0,
                startLong: findAddress?.long || 0,
                addressFrom: value.target.value,
                city: findAddress?.city || '',
              });
            }}
            select
            sx={{ width: '100%' }}
            size="small"
          >
            {adressesFrom
              .filter(
                (value, index, self) =>
                  index === self.findIndex(t => t.address === value.address),
              )
              .map((data, index) => (
                <MenuItem key={index} value={data.address}>
                  {data.address}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Box
          sx={{
            width: '300px',
            marginTop: '15px',
          }}
        >
          <TextField
            sx={{ width: '300px' }}
            size="small"
            placeholder="Observação (complemento)"
            value={observation}
            onChange={e => setObservation(e.target.value)}
          />
        </Box>

        <>
          {stopPoints.length >= 1 &&
            stopPoints
              .sort((a, b) => a.order - b.order)
              .map(stopPoint => (
                <Box
                  key={stopPoint.order}
                  sx={{
                    width: '300px',
                    marginTop: '15px',
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <TextField
                    label="Pontos de parada"
                    value={stopPoint.address}
                    onChange={event =>
                      handleAddressChange(stopPoint.order, event.target.value)
                    }
                    select
                    sx={{ width: '100%' }}
                    size="small"
                  >
                    {uniqueAddresses.map((data, index) => (
                      <MenuItem key={index} value={data.address}>
                        {data.address}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Tooltip title="Remover ponto de parada">
                      <IconButton
                        onClick={() => {
                          const filteredStopPoints = stopPoints.filter(
                            data => data.order !== stopPoint.order,
                          );
                          setStopPoints(filteredStopPoints);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
          <Box sx={{ width: '300px', marginTop: '15px', zIndex: 9 }}>
            <TextField
              label="Destino"
              value={destination?.addressTo}
              onChange={value => {
                const findAddress = adressesTo.find(
                  data => data.address === value.target.value,
                );
                setDestination({
                  finalLat: findAddress?.lat || 0,
                  finalLong: findAddress?.long || 0,
                  addressTo: value.target.value,
                  city: findAddress?.city || '',
                });
              }}
              select
              sx={{ width: '100%' }}
              size="small"
            >
              {adressesTo
                .filter(
                  (value, index, self) =>
                    index === self.findIndex(t => t.address === value.address),
                )
                .map((data, index) => (
                  <MenuItem key={index} value={data.address}>
                    {data.address}
                  </MenuItem>
                ))}
            </TextField>
          </Box>

          <Box
            sx={{
              width: '300px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <StopPoints stopPoints={stopPoints} setStopPoints={setStopPoints} />
          </Box>
        </>
        <Box>
          <FormControlLabel
            sx={{
              width: '100%',
              color: 'gray',
            }}
            control={
              <Checkbox
                onChange={() => {
                  setIsScheduled(!isScheduled);
                  setScheduledDate(null);
                }}
                checked={isScheduled}
              />
            }
            label="Agendar"
          />
          {isScheduled && (
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DateTimePicker
                label="Data inicial"
                value={scheduledDate}
                onChange={newValue => {
                  setScheduledDate(newValue);
                }}
                renderInput={params => (
                  <TextField
                    sx={{ zIndex: 0, width: '250px' }}
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        </Box>

        <Button
          disabled={
            !origin?.addressFrom ||
            !destination?.addressTo ||
            isLoading ||
            !!stopPoints.find(stopPoint => !stopPoint.address)
          }
          variant="contained"
          color="success"
          sx={{
            width: '200px',
            marginTop: '15px',
          }}
          type="submit"
        >
          {isLoading ? 'Carregando...' : 'Salvar'}
        </Button>
      </form>
    </Grid>
  );
}
