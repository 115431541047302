import create from 'zustand';

type TableStore = {
  filterFn: any;
};

export const useTableRecords = create<TableStore>(() => ({
  filterFn: {
    fn: (items: any) => {
      return items;
    },
  },
}));
