import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { CompaniesList } from './CompaniesPriceList';

import { CreatePrice } from './CreatePrice';
import { UpdatePrice } from './UpdatePrice';

export const PriceTableRoutes = () => {
  return (
    <>
      <Can permissions={['renter']}>
        <Routes>
          <Route path="/" element={<CompaniesList />} />
          <Route path="/create" element={<CreatePrice />} />
          <Route path="/:id" element={<UpdatePrice />} />
        </Routes>
      </Can>
    </>
  );
};
