import { useAuth } from '@/providers/auth';
import { validateUserPermissions } from '@/utils/ValidateUserPermissions';

type UseCanParams = {
  permissions?: string[];
  roles?: string[];
  restriction?: string;
};

export function useCan({
  permissions,
  restriction,
  roles,
}: UseCanParams): boolean {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return false;
  }

  if (
    restriction &&
    !user.user.company?.permissions
      ?.map(role => role.name)
      .includes(restriction)
  ) {
    return false;
  }

  if (user.user.accessType !== 'sector_admin' && roles && roles.length > 0) {
    const hasRole = roles.some(role =>
      user?.user?.profile?.map(role => role.name).includes(role),
    );
    if (!hasRole && user.user.accessType !== 'master') {
      return false;
    }
  }

  const userHasValidPermissions = validateUserPermissions({
    user,
    permissions,
  });
  return userHasValidPermissions;
}
