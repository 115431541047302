import { Loading } from '@/components';
import { useStatements } from '../api';
import { StatementTableContainer } from './StatementTableContainer';

export const SectorStatement = ({ sectorId }: { sectorId: string }) => {
  const { data, isLoading } = useStatements({ sectorId });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }
  return (
    <>
      <StatementTableContainer data={data} />
    </>
  );
};
